import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringScenariosScene = asyncComponent(
  () => import(/* webpackChunkName: "monitoringScenarios" */ "app/mon/project/scenes/MonitoringScenariosScene")
    .then(({ default: scene }) => scene),
  "MonitoringScenariosScene"
);

export { AsyncMonitoringScenariosScene };
export default AsyncMonitoringScenariosScene;
