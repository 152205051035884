import * as React from "react";

import Message from "app/localization/components/Message";

export type FormError = { name: string, message: string, values?: Record<string, any> };
export default FormError;

export type ErrorMap = { [key: string]: string | undefined };
export function toMap(errors: FormError[]): ErrorMap {
  return errors.reduce((map, each) => {
    map[each.name] = each.message;
    return map;
  }, {} as ErrorMap);
}

export function getErrorMessage(errorName: string, errors: FormError[]): string | undefined {
  return errors.find((error) => error.name === errorName)?.message;
}

export function lookupError(errorName: string, errors: FormError[]): FormError | undefined {
  return errors.find((error) => error.name === errorName);
}

export function lookupErrors(errorName: string, errors: FormError[]): FormError[] {
  return errors.filter((error) => error.name === errorName);
}

export function filterFormErrorName(errorName: string, errors: FormError[]): FormError[] {
  return errors.filter((error) => error.name !== errorName);
}

function groupBy(errors: FormError[], selector: (error: FormError) => string) {
  const groups = new Map<string, FormError[]>();
  errors.forEach((err) => {
    const groupKey = selector(err);
    const group = groups.get(groupKey);
    if (group) {
      group.push(err);
    } else {
      groups.set(groupKey, [err]);
    }
  });
  return groups;
}

export function groupByErrorName(errors: FormError[]) {
  return groupBy(errors, (err) => err.name);
}

export function groupByErrorMessage(errors: FormError[]) {
  return groupBy(errors, (err) => err.message);
}

export function getError(name: string, errors: FormError[]): JSX.Element | null {
  const matches = lookupErrors(name, errors);
  if (matches.length > 0) {
    return (
      <React.Fragment>
        {matches.map((e, i) => <Message messageID={e.message} key={["err", name, i].join("_")} values={e.values} />)}
      </React.Fragment>
    );
  }
  return null;
}
