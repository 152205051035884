import * as Moment from "moment";
import * as Zone from "moment-timezone";

const zones: Moment.MomentZone[] = [];
Zone.tz.names()
  .map((each) => Zone.tz.zone(each))
  .forEach((each) => each !== null && zones.push(each));

export const Zones = zones;

export function getZone(name: string) {
  return Zone.tz.zone(name);
}

export function getSystemZoneName(): string {
  return Zone.tz.guess();
}

export function isSystemTimeZone(otherZoneName: string): boolean {
  return getSystemZoneName() === otherZoneName;
}

export function setLocale(locale: string): string {
  return Moment.locale(locale);
}

export function setZone(timezone: string) {
  return Zone.tz.setDefault(timezone);
}

export function getTimeZoneOffset(baseZoneName: string, otherZoneName: string): number {
  const moment = Zone.utc();

  const systemUtcOffset = Zone.tz(moment, baseZoneName).utcOffset();
  const zoneUtcOffset = Zone.tz(moment, otherZoneName).utcOffset();
  const utcDiff = zoneUtcOffset - systemUtcOffset;

  return utcDiff;
}

export function formattedTimeZoneOffset(offset: number): string {
  return Zone.utc().utcOffset(offset).format("Z");
}

export function formattedTimeZoneAbbr(timezone: string): string {
  return Zone.tz(Zone.utc(), timezone).format("z");
}
