import { APIResult, fetch } from "app/utils/remote";

import {
  AddLoadTestData, DashboardLoadTestData, DuplicateLoadTestData, LoadTestData, LoadTestFilterOptions, LoadTestsChunk,
  LoadTestsDashboardData, UpdateLoadTestData
} from "./types";

export default class LoadTestAPI {

  public static fetchLoadTests(
    projectId: string, start: number, count: number, sortBy: string, search: string,
    softDeleted: boolean, filterOptions: LoadTestFilterOptions
  ): Promise<APIResult<LoadTestsChunk>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests`,
      data: {
        start, size: count, sortby: sortBy, q: search, softDeleted,
        ...filterOptions
      }
    });
  }

  public static fetchDashboardLoadTests(projectId: string): Promise<APIResult<DashboardLoadTestData[]>> {
    return fetch({ url: `/api/lt/projects/${projectId}/loadTests/dashboard` });
  }

  public static fetchDashboardChartsData(projectId: string): Promise<APIResult<LoadTestsDashboardData>> {
    return fetch({ url: `/api/lt/projects/${projectId}/loadTests/dashboard/charts` });
  }

  public static fetchLoadTest(projectId: string, loadTestId: string): Promise<APIResult<LoadTestData>> {
    return fetch({ url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}` });
  }

  public static fetchLoadTestStatus(projectId: string, loadTestId: string): Promise<APIResult<LoadTestData["status"]>> {
    return fetch({ url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/status` });
  }

  public static createLoadTest(projectId: string, data: AddLoadTestData): Promise<APIResult<LoadTestData>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests`,
      method: "POST",
      data
    });
  }

  public static updateLoadTest(projectId: string, loadTestId: string, loadTestVersion: number, data: UpdateLoadTestData):
    Promise<APIResult<LoadTestData>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}?version=${loadTestVersion}`,
      method: "PUT",
      data
    });
  }

  public static duplicateLoadTest(projectId: string, loadTestId: string, data: DuplicateLoadTestData):
    Promise<APIResult<LoadTestData>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/duplicate`,
      method: "POST",
      data
    });
  }

  public static deleteLoadTest(projectId: string, loadTestId: string): Promise<APIResult<void>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}`,
      method: "DELETE"
    });
  }

  public static deleteLoadTests(projectId: string, data: Array<{ id: string, version: number }>): Promise<APIResult<void>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests`,
      method: "DELETE",
      data
    });
  }

  public static restoreLoadTest(projectId: string, loadTestId: string): Promise<APIResult<LoadTestData>> {
    return fetch({ url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/restore`, method: "POST" });
  }

  public static updateLoadTestPin(projectId: string, loadTestId: string, pinning: boolean):
    Promise<APIResult<LoadTestData>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/pin`,
      method: "PUT",
      data: {
        pinning
      }
    });
  }
}
