import * as React from "react";
import { FormattedMessage } from "react-intl";

type Props = { messageID: string, default?: string, values?: Record<string, any>, className?: string };

class Message extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    const { messageID, values, className } = this.props;
    if (!messageID) {
      return null;
    }
    const defaultMessage = this.props.default === undefined ? messageID : this.props.default;
    return (
      <FormattedMessage id={messageID} defaultMessage={defaultMessage} values={values}>
        {(...nodes) => <span className={className}>{nodes}</span>}
      </FormattedMessage>
    );
  }
}

export default Message;
export { Message };
