import { asyncComponent } from "components/AsyncComponent";

const AsyncLoadTestMembersScene = asyncComponent(
  () => import(/* webpackChunkName: "loadTestMembers" */ "app/lt/project/scenes/LoadTestMembersScene")
    .then(({ default: scene }) => scene),
  "LoadTestMembersScene"
);

export { AsyncLoadTestMembersScene };
export default AsyncLoadTestMembersScene;
