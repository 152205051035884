import { action, computed, makeObservable, observable } from "mobx";

import { SlackConfigUpdate } from "components/slackConfig/SlackConfiguration";

import { ProjectAPI } from "app/core/project/remote/ProjectAPI";
import {
  GrafanaSettings, ProjectData, ProjectMembership, ProjectMembershipSortOption, ProjectRoles, ProjectStates, ProjectType,
  RepositoryAuthentication, RepositoryDynamicBranch, RepositorySettings, RepositoryStaticBranch, SystemGrafanaSettings
} from "app/core/project/types";
import { hasProjectRole } from "app/core/project/utils/roleValidation";
import { AbstractState } from "app/core/store/AbstractState";
import AppStores from "app/core/store/AppStores";
import { ApiCredentials, ApiVersion, SlackConfigData } from "app/core/types";
import { UserData } from "app/core/user/types";

import { MonitoringProjectAPI } from "app/mon/project/MonitoringProjectAPI";

import { LoadTestProjectAPI } from "app/lt/project/LoadTestProjectAPI";

export interface IProjectState {
  readonly _id: string;
  readonly _ver: number;

  readonly name: string;
  readonly baseShortName: string;
  readonly shortName: string;
  readonly deleteDateTime?: number;
  readonly hasSecret: boolean;
  readonly description: string;
  readonly color: string | undefined;
  readonly state: ProjectStates;
  readonly adminUser: string;
  readonly type: ProjectType;
  readonly role: ProjectRoles;
  readonly slackIntegration: SlackConfigData | undefined;
  readonly effectiveSlackIntegration: SlackConfigData | undefined;

  readonly members: ProjectMembership[];
  readonly membersTotal: number;
  readonly membersFiltered: number;

  readonly grafana: GrafanaSettings;
  readonly grafanaSystem: SystemGrafanaSettings;

  readonly apiCredentials: ApiCredentials[];
  readonly availableApiVersions: ApiVersion[];

  readonly tenant: { _id: string };
}

export abstract class ProjectState extends AbstractState<IProjectState> implements IProjectState {

  public readonly appStores: AppStores;

  public readonly _id: string;
  @observable public readonly _ver: number;

  @observable public readonly name: string;
  @observable public readonly baseShortName: string;
  @observable public readonly shortName: string;
  @observable public readonly deleteDateTime?: number;
  @observable public readonly hasSecret: boolean;
  @observable public readonly description: string;
  @observable public readonly color: string | undefined;
  @observable public readonly documentation: string;

  @observable public readonly state: ProjectStates;
  @observable public readonly adminUser: string;
  @observable public readonly type: ProjectType;

  @observable public readonly role: ProjectRoles;

  @observable public readonly slackIntegration: SlackConfigData | undefined;
  @observable public readonly effectiveSlackIntegration: SlackConfigData | undefined;

  @observable public readonly favorite: boolean;

  @observable public readonly members: ProjectMembership[];
  @observable public readonly membersTotal: number;
  @observable public readonly membersFiltered: number;

  @observable public readonly grafana: GrafanaSettings;
  @observable public readonly grafanaSystem: SystemGrafanaSettings;

  @observable public readonly apiCredentials: ApiCredentials[];
  @observable public readonly availableApiVersions: ApiVersion[];

  @observable public readonly tenant: { _id: string };

  public constructor(appStores: AppStores) {
    super();
    makeObservable(this);

    this.appStores = appStores;
  }

  public static initialState(project: ProjectData): IProjectState {
    const { role, description, grafana, ...rest } = project;
    // Properties 'grafana' and 'grafanaSystem' exist for Monitoring Projects only
    // TODO move handling of these properties into MonitoringProjectState
    const graf = grafana || {};
    return {
      shortName: "None",
      role: role || "None",
      description: description || "",
      members: [],
      membersTotal: 0,
      membersFiltered: 0,
      grafana: {
        dashboardPath: graf.dashboardPath || "",
        charts: graf.charts || []
      },
      grafanaSystem: {
        url: "",
        dashboardPath: "",
        orgID: "",
        charts: []
      },
      ...rest
    };
  }

  @computed
  public get isAdmin(): boolean {
    return hasProjectRole(this.role, "Admin");
  }

  @computed
  public get isReviewer(): boolean {
    return hasProjectRole(this.role, "Reviewer");
  }

  @computed
  public get isTester(): boolean {
    return hasProjectRole(this.role, "Tester");
  }

  @computed
  public get isTestManager(): boolean {
    return hasProjectRole(this.role, "TestManager");
  }

  @computed
  public get isActive(): boolean {
    return this.state === "Active";
  }

  public hasRole(role: ProjectRoles): boolean {
    return hasProjectRole(this.role, role);
  }

  @action.bound
  public getMembershipIdForUser(userId: string): string | undefined {
    const membership = this.members.find((member) => member.user._id === userId);
    return membership ? membership._id : undefined;
  }

  @action.bound
  private updateMemberCounts(value: number): void {
    this.setState({
      membersTotal: this.membersTotal + value || 0,
      membersFiltered: this.membersFiltered + value || 0
    });
  }

  @action.bound
  private putMember(prepend: boolean, member: ProjectMembership, updateMemberCount = true): ProjectMembership {
    const index = this.members.findIndex((each) => each.user._id === member.user._id);
    if (index > -1) {
      this.members[index] = member;
      return this.members[index];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      updateMemberCount && this.updateMemberCounts(1);
      if (prepend) {
        this.members.splice(0, 0, member);
        return this.members[0];
      } else {
        this.members.push(member);
        return this.members[this.members.length - 1];
      }
    }
  }

  @action.bound
  private putMembers(prepend: boolean, members: ProjectMembership[], updateMemberCount = true): ProjectMembership[] {
    return members.map((each) => this.putMember(prepend, each, updateMemberCount));
  }

  @action.bound
  private removeMember(member: ProjectMembership): void {
    const index = this.members.findIndex((each) => each.user._id === member.user._id);
    if (index > -1) {
      this.members.splice(index, 1);
      this.updateMemberCounts(-1);
    }
  }

  @action.bound
  private removeMembers(members: ProjectMembership[]): void {
    members.forEach(this.removeMember);
  }

  @action
  public async fetchMembers(
    append: boolean, start: number, count: number, sortBy: ProjectMembershipSortOption, ascending: boolean, search: string)
    : Promise<void> {
    const result = await ProjectAPI.loadMemberships(this._id, start, count, sortBy, ascending, search);

    if (result.success && result.data) {
      if (append) {
        this.putMembers(false, result.data.members, false);
      } else {
        this.setState({
          members: result.data.members,
          membersTotal: result.data.total,
          membersFiltered: result.data.count
        });
      }
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchMemberSuggestion(search: string): Promise<UserData[]> {
    const result = await ProjectAPI.suggestProjectMembers(this._id, search);
    if (result.success && result.data) {
      return result.data.matches;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async addMembers(emails: string[], role: ProjectRoles, inviteUnknownUser: boolean): Promise<ProjectMembership[]> {
    const result = await ProjectAPI.addProjectMembers(this._id, this._ver, emails, role, inviteUnknownUser);
    if (result.success && result.data) {
      return this.putMembers(true, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async inviteUsers(emails: string[], role: ProjectRoles): Promise<ProjectMembership[]> {
    const result = await ProjectAPI.inviteUsersToProject(this._id, this._ver, emails, role);
    if (result.success && result.data) {
      return this.putMembers(true, result.data);
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "project.members.invite.error"));
    }
  }

  public async inviteMember(memberId: string, memberVersion: number): Promise<void> {
    const result = await ProjectAPI.inviteProjectMember(this._id, memberId, memberVersion);
    if (result.success) {
      //
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "project.members.invite.error"));
    }
  }

  @action.bound
  public async updateMember(id: string, email: string, role: ProjectRoles, version: number): Promise<ProjectMembership> {
    const result = await ProjectAPI.updateProjectMember(this._id, id, version, email, role);
    if (result.success && result.data) {
      return this.putMember(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteMembers(members: Array<{ version: number, id: string }>): Promise<void> {
    const result = await ProjectAPI.removeProjectMembers(this._id, members);
    if (result.success) {
      this.removeMembers(this.members.filter((each) => members.findIndex((m) => m.id === each._id) > -1));
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateProjectCommon(
    name: string,
    baseShortName: string,
    type: ProjectType,
    description: string,
    state: ProjectStates,
    adminUser: string): Promise<void> {
    const result = await ProjectAPI.saveProjectDetails(this._id, this._ver, name, baseShortName, description, state, adminUser);

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateProject(
    name: string,
    baseShortName: string,
    type: ProjectType,
    description: string,
    state: ProjectStates,
    color: string | undefined,
    adminUser: string): Promise<void> {
    const result = type === "mon" ?
      await MonitoringProjectAPI.saveProjectDetails(this._id, this._ver, name, baseShortName, description, state, color, adminUser)
      :
      await LoadTestProjectAPI.saveProjectDetails(this._id, this._ver, name, baseShortName, description, state, color, adminUser);

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateProjectState(state: ProjectStates): Promise<void> {
    const result = await ProjectAPI.saveProjectState(this._id, this._ver, state);

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async toggleProjectActive(projectActive: boolean): Promise<void> {
    const result = await ProjectAPI.toggleProjectActiveState(this._id, this._ver, projectActive ? "Active" : "Inactive");

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateProjectDocumentation(documentation: string): Promise<void> {
    const result = await ProjectAPI.updateProjectDocumentation(this._id, this._ver, documentation);

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updatePicture(picture: File): Promise<void> {
    const result = await ProjectAPI.uploadPicture(this._id, this._ver, picture);
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateProjectFavorite(favorite: boolean): Promise<void> {
    const result = await ProjectAPI.updateProjectFavorite(this._id, favorite);
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchProjectSettings(): Promise<void> {
    // const result = await RemoteProjectService.loadProjectSettings(this._id);
    // if (result.success && result.data) {
    //   this.setState({
    //     repository: result.data.repository
    //   });
    // } else {
    //   throw ("errors" in result ? result.errors : result.message);
    // }
  }

  @action.bound
  public async pushRepositorySettings(settings: RepositorySettings): Promise<void> {
    // const result = await RemoteProjectService.saveRepositorySettings(this._id, this._ver, settings);
    // if (result.success && result.data) {
    //   this.incrementVersion();
    //   this.setState({ repository: result.data });
    // } else {
    //   throw ("errors" in result ? result.errors : result.message);
    // }
  }

  @action.bound
  public async pushRepositoryAuthSettings(settings: { auth: RepositoryAuthentication | null }): Promise<void> {
    // const result = await RemoteProjectService.saveRepositoryAuthentication(this._id, this._ver, settings);
    // if (result.success && result.data) {
    //   this.incrementVersion();
    //   this.setState({ repository: result.data });
    // } else {
    //   throw ("errors" in result ? result.errors : result.message);
    // }
  }

  @action.bound
  public async pushRepositoryBranchSettings(settings: RepositoryDynamicBranch | RepositoryStaticBranch): Promise<void> {
    // const result = await RemoteProjectService.saveRepositoryBranch(this._id, this._ver, settings);
    // if (result.success && result.data) {
    //   this.incrementVersion();
    //   this.setState({ repository: result.data });
    // } else {
    //   throw ("errors" in result ? result.errors : result.message);
    // }
  }

  @action.bound
  public async fetchProjectState(): Promise<void> {
    // Noop
  }

  @action.bound
  public async updateSlackIntegration(settings: SlackConfigUpdate): Promise<void> {
    const result = await ProjectAPI.updateSlackIntegration(this._id, this._ver, settings);
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async sendSlackTestMessage(settings: SlackConfigUpdate): Promise<void> {
    return await ProjectAPI.sendSlackTestMessage(this._id, settings);
  }

  @action.bound
  public async addApiCredentials(apiCredentialsName: string, apiId: string, scopes: string[], expire?: number): Promise<string> {
    const result = await ProjectAPI.addApiCredentials(this._id, apiCredentialsName, apiId, scopes, expire);

    if (result.success && result.data) {
      this.setState(result.data.project);
      return result.data.apiCredentialsSecret;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteApiCredentials(apiCredentialsId: string): Promise<void> {
    const result = await ProjectAPI.deleteApiCredentials(this._id, apiCredentialsId);

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async toggleApiCredentialsEnabled(apiCredentialsId: string, enabled: boolean): Promise<void> {
    const result = await ProjectAPI.toggleApiCredentialsEnabled(this._id, apiCredentialsId, enabled);

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

}

export default { ProjectState };
