import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringHistoryResultBrowserScene = asyncComponent(
  () => import(/* webpackChunkName: "projectHistory" */ "app/mon/project/scenes/MonitoringHistoryResultBrowserScene")
    .then(({ default: scene }) => scene),
  "MonitoringHistoryResultBrowserScene"
);

export { AsyncMonitoringHistoryResultBrowserScene };
export default AsyncMonitoringHistoryResultBrowserScene;
