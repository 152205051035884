import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { amber, grey, lightGreen, red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";

import TruncatingText from "components/TruncatingText";

import Status from "../status/Status";

type StatusValue = "ok" | "warning" | "unknown" | "critical" | "disabled";

type Props = {
  message: string | React.ReactElement;
  className?: string;
  full?: boolean;
  closer?: boolean | React.ReactElement;
  onClose?: () => void;
  status?: StatusValue;
  size?: "small" | "xsmall" | "inherit" | "large";
};

export default class NotificationMessage extends React.PureComponent<Props> {

  private getBackgroundColor(status: StatusValue): string {
    switch (status) {
      case "ok":
        return lightGreen[500];
      case "warning":
        return amber[600];
      case "critical":
        return red[600];
      case "disabled":
        return grey[500];
      default:
        return grey[400];
    }
  }

  public render() {
    const { children, className, closer, message, onClose, size, status } = this.props;
    const margin = (size === "xsmall" ? 0.5 : 1);

    let statusNode;
    if (status) {
      statusNode = (
        <Status value={status} size={size} color="inherit" />
      );
    }

    let closerNode;
    if (typeof closer === "object") {
      closerNode = closer;
    } else if (onClose && closer) {
      closerNode = (
        <IconButton className="xtc_close" color="inherit" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      );
    }

    return (
      <Box className={className}
        display="flex"
        flexDirection="column"
        bgcolor={status ? this.getBackgroundColor(status) : undefined}
        color="primary.contrastText"
        flexGrow={this.props.full ? 1 : undefined}
        justifyContent="center"
      >
        <Box display="flex" margin={margin} alignItems="center" justifyContent="space-between">
          <Box display="flex" margin={margin} alignItems="center">
            {statusNode}
            <Box marginLeft={margin}>
              <TruncatingText variant="inherit">
                {message}
              </TruncatingText>
            </Box>
          </Box>
          <Box>
            {closerNode}
          </Box>
        </Box>

        {children}

      </Box >
    );
  }
}
