// (C) Copyright 2015-2018 Hewlett Packard Enterprise Development LP
import JSCookie from "js-cookie";

const Cookies = {
  get(name: string) {
    return JSCookie.get(name);
  },
  set(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean) {
    return JSCookie.set(name, value, { expires, path, domain, secure });
  },
  remove(name: string, path?: string, domain?: string) {
    JSCookie.remove(name, { path, domain });
  },
  has(name: string) {
    return JSCookie.get(name);
  },
  keys() {
    return Object.keys(JSCookie.get());
  }
};
export default Cookies;
