import { APIResult, fetch } from "app/utils/remote";

import { DiffReportGenerationSettings, Report, ReportData, ReportGenerationSettings, ReportsData } from "app/lt/project/types";

export type ReportListingFilter = {
  search?: string;
  comparison?: boolean;
};

export default class LoadTestReportAPI {

  public static fetchReports(
    projectId: string, start: number, count: number,
    sortBy: string, search: string, softDeleted: boolean, loadTestId?: string
  ): Promise<APIResult<ReportsData>> {
    return fetch<APIResult<ReportsData>>({
      url: loadTestId ? `/api/lt/projects/${projectId}/loadTests/${loadTestId}/reports` : `/api/lt/projects/${projectId}/reports`,
      method: "GET",
      data: {
        start, size: count, sortby: sortBy, q: search, softDeleted
      }
    });
  }

  public static fetchProjectReports(projectId: string, start: number, count: number, sortBy: string, filter?: ReportListingFilter)
    : Promise<APIResult<ReportsData>> {
    const { search: q, comparison } = filter ?? {};

    return fetch<APIResult<ReportsData>>({
      url: `/api/lt/projects/${projectId}/reports`,
      method: "GET",
      data: {
        start, size: count, sortby: sortBy, q, comparison
      }
    });
  }

  public static fetchComparisonReports(
    projectId: string, start: number, count: number, sortBy: string, search: string, softDeleted: boolean
  ): Promise<APIResult<ReportsData>> {
    return fetch<APIResult<ReportsData>>({
      url: `/api/lt/projects/${projectId}/reports/comparison`,
      method: "GET",
      data: {
        start, size: count, sortby: sortBy, q: search, softDeleted
      }
    });
  }

  public static fetchPinnedProjectReports(projectId: string, start: number, count: number, sortBy: string, search: string)
    : Promise<APIResult<ReportsData>> {
    return fetch<APIResult<ReportsData>>({
      url: `/api/lt/projects/${projectId}/reports/pinned`,
      method: "GET",
      data: {
        start, size: count, sortby: sortBy, q: search
      }
    });
  }

  public static generateNewReport(
    projectId: string,
    loadTestId: string,
    generationSettings: ReportGenerationSettings
  ): Promise<APIResult<ReportData>> {
    return fetch<APIResult<ReportData>>({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/report/custom`,
      method: "POST",
      data: {
        ...generationSettings
      }
    });
  }

  public static generateNewIntermediateReport(
    projectId: string,
    loadTestId: string,
    downloadLogs: boolean,
    downloadResultBrowsers: boolean
  ): Promise<APIResult<ReportData>> {
    return fetch<APIResult<ReportData>>({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/report/intermediate`,
      method: "POST",
      data: {
        downloadLogs,
        downloadResultBrowsers
      }
    });
  }

  public static generateNewDiffReport(
    projectId: string,
    generationSettings: DiffReportGenerationSettings
  ): Promise<APIResult<ReportData>> {
    return fetch<APIResult<ReportData>>({
      url: `/api/lt/projects/${projectId}/report/diff`,
      method: "POST",
      data: {
        ...generationSettings
      }
    });
  }

  public static updateReport(
    projectId: string,
    reportId: string,
    label: string,
    description: string,
    version: number
  ): Promise<APIResult<ReportData>> {
    return fetch<APIResult<ReportData>>({
      url: `/api/lt/projects/${projectId}/reports/${reportId}`,
      method: "PUT",
      data: {
        label, description, version
      }
    });
  }

  public static deleteReport(
    projectId: string,
    reportId: string
  ): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/lt/projects/${projectId}/reports/${reportId}`,
      method: "DELETE"
    });
  }

  public static deleteReports(
    projectId: string,
    data: Array<{ id: string, version: number }>
  ): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/lt/projects/${projectId}/reports`,
      method: "DELETE",
      data
    });
  }

  public static restoreReport(
    projectId: string,
    reportId: string
  ): Promise<APIResult<ReportData>> {
    return fetch<APIResult<ReportData>>({
      url: `/api/lt/projects/${projectId}/reports/${reportId}/restore`,
      method: "POST"
    });
  }

  public static shareReport(
    projectId: string,
    reportId: string,
    useGlobal: boolean,
    validFromMilliseconds: number,
    validToMilliseconds: number
  ): Promise<APIResult<Report>> {
    return fetch<APIResult<Report>>({
      url: `/api/lt/projects/${projectId}/reports/${reportId}/share`,
      method: "POST",
      data: {
        useGlobal,
        validFromMilliseconds,
        validToMilliseconds
      }
    });
  }

  public static unshareReport(
    projectId: string,
    reportId: string
  ): Promise<APIResult<Report>> {
    return fetch<APIResult<Report>>({
      url: `/api/lt/projects/${projectId}/reports/${reportId}/share`,
      method: "DELETE"
    });
  }

  public static resurrectReport(projectId: string, reportId: string): Promise<APIResult<Report>> {
    return fetch<APIResult<Report>>({
      url: `/api/lt/projects/${projectId}/reports/${reportId}/resurrect`,
      method: "POST"
    });
  }

  public static updateReportPin(projectId: string, reportId: string, pinning: boolean): Promise<APIResult<Report>> {
    return fetch<APIResult<Report>>({
      url: `/api/lt/projects/${projectId}/reports/${reportId}/pin`,
      method: "PUT",
      data: {
        pinning
      }
    });
  }

  public static updateReportMarkedForComparison(
    projectId: string,
    reportId: string, markedForComparison: boolean
  ): Promise<APIResult<Report>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/reports/${reportId}/comparison`,
      method: "PUT",
      data: { markedForComparison }
    });
  }
}
