import React from "react";

import { Notification } from "./Notification";
import { NotificationData } from "./types";

type NotificationContainerProps = {
    notifications: NotificationData[];
    onClose: (id: string) => void;
};

export const NotificationContainer: React.FunctionComponent<NotificationContainerProps> = (props) => (
    <React.Fragment>
        {
            props.notifications.map((notification, idx) =>
            (<Notification
                key={`${notification.id}_${idx}`}
                modal={notification.modal}
                status={notification.status}
                message={notification.message}
                closer={true}
                onClose={() => props.onClose(notification.id)}
            />))
        }
    </React.Fragment>
);
