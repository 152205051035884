import { ProjectData } from "app/core/project/types";
import { TenantData } from "app/core/tenant/types";
import { UserData } from "app/core/user/types";
import { RoutePaths } from "app/routing/router/paths";
import { buildQuery } from "app/utils/remote";

const PATH_SEPARATOR = "/";

export function routeToDashboard(): string {
  return RoutePaths.dashboard.path;
}

export function routeToMyAccount(): string {
  return ["", RoutePaths.account.path].join(PATH_SEPARATOR);
}

export function routeToLogin(): string {
  return ["", RoutePaths.login.path].join(PATH_SEPARATOR);
}

export function routeToLogout(): string {
  return ["", RoutePaths.logout.path].join(PATH_SEPARATOR);
}

export function routeToResetPassword(): string {
  return ["", RoutePaths.resetPassword.path].join(PATH_SEPARATOR);
}

export function routeToNewPassword(b64EncodedEmail: string): string {
  return ["", RoutePaths.newPassword.path].join(PATH_SEPARATOR).concat("?state=", b64EncodedEmail);
}

export function routeToApiExplorer(): string {
  return "/exploreApi";
}

export function routeToTenants(): string {
  return "/tenants";
}

export function routeToProjects(): string {
  return ["", "projects"].join(PATH_SEPARATOR);
}

export function routeToTenant(id: string): string {
  return [routeToTenants(), id].join(PATH_SEPARATOR);
}

export function routeToTenantConfiguration(id: string): string {
  return [routeToTenant(id), "configuration"].join(PATH_SEPARATOR);
}

export function routeToTenantProjects(id: string): string {
  return [routeToTenant(id), "projects"].join(PATH_SEPARATOR);
}

export function routeToTenantMembers(id: string): string {
  return [routeToTenant(id), "members"].join(PATH_SEPARATOR);
}

export function routeToTenantResources(id: string): string {
  return [routeToTenant(id), "resources"].join(PATH_SEPARATOR);
}

export function routeToTenantAuditLog(id: string): string {
  return [routeToTenant(id), "auditLog"].join(PATH_SEPARATOR);
}

export function routeToProject(project: ProjectData | string, tenant?: string): string {
  if (arguments.length > 1 && tenant !== undefined) {
    return [routeToTenantProjects(tenant!), project].join(PATH_SEPARATOR);
  }
  if (typeof project === "string") {
    throw new Error("A tenant ID is needed to generate the route.");
  } else {
    const p = project as ProjectData;
    return [routeToTenantProjects(p.tenant._id), p._id].join(PATH_SEPARATOR);
  }
}

export function routeToProjectConfiguration(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "configuration"].join(PATH_SEPARATOR);
}

export function routeToProjectAuditLog(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "auditLog"].join(PATH_SEPARATOR);
}

export function routeToProjectMembers(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "members"].join(PATH_SEPARATOR);
}

export function routeToProjectScenarios(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "scenarios"].join(PATH_SEPARATOR);
}

export function routeToScenario(projectId: string, tenantId: string, scenarioId: string): string {
  return [routeToProjectScenarios(projectId, tenantId), scenarioId].join(PATH_SEPARATOR);
}

export function routeToProjectQuietPeriods(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "quietPeriods"].join(PATH_SEPARATOR);
}

export function routeToProjectOverview(project: string, tenant: string): string {
  return [routeToProject(project, tenant), ""].join(PATH_SEPARATOR);
}

export function routeToMonitoringProjectMetrics(project: string, tenant: string): string {
  return [routeToProject(project, tenant), "metrics"].join(PATH_SEPARATOR);
}

export function routeToProjectHistory(project: string, tenant: string): string {
  return [routeToProject(project, tenant), "history"].join(PATH_SEPARATOR);
}

export function routeToProjectHistoryRun(project: string, tenant: string, run: string): string {
  return [routeToProjectHistory(project, tenant), run].join(PATH_SEPARATOR);
}

export function routeToProjectHistoryRunLog(project: string, tenant: string, run: string): string {
  return [routeToProjectHistory(project, tenant), run, "log"].join(PATH_SEPARATOR);
}

export function routeToProjectHistoryTimers(project: string, run: string): string {
  return ["/api", "mon", "projects", project, "runs", run, "timersFile"].join(PATH_SEPARATOR);
}

export function routeToProjectDataExports(project: string, tenant: string): string {
  return [routeToProject(project, tenant), "exports"].join(PATH_SEPARATOR);
}

export function routeToProjectHistoryExport(project: string, params: {
  q: string, from: number, to: number, include: number
}): string {
  const query = buildQuery(params);
  return ["", "api", "mon", "projects", project, "exportRuns"].join(PATH_SEPARATOR) + query;
}

export function routeToProjectHistoryExportArchive(project: string, archive: string): string {
  return ["", "api", "mon", "projects", project, "exports", archive].join(PATH_SEPARATOR);
}

export function routeToProjectHistoryResultBrowser(project: string, tenant: string, run: string): string {
  return [routeToProjectHistory(project, tenant), run, "resultBrowser"].join(PATH_SEPARATOR);
}

export function routeToProjectExecution(project: string, tenant: string): string {
  return [routeToProject(project, tenant), "execution"].join(PATH_SEPARATOR);
}

export function imageUrlForProject(project: ProjectData | string): string {
  let pid = project;
  if (typeof project !== "string") {
    pid = project._id;
  }

  return `/api/core/projects/${pid}/picture`;
}

export function imageUrlForSysAdminProject(project: ProjectData | string): string {
  let pid = project;
  if (typeof project !== "string") {
    pid = project._id;
  }

  return `/api/core/system/projects/${pid}/picture`;
}

export function imageUrlForTenant(tenant: TenantData | string): string {
  let tid = tenant;
  if (typeof tenant !== "string") {
    tid = tenant._id;
  }

  return `/api/core/tenants/${tid}/picture`;
}

export const defaultAvatar = "/assets/img/avatar.png";
export function imageUrlForUser(user?: UserData | string): string {
  let uid = user;
  if (user && typeof user !== "string") {
    uid = user._id;
  }

  return uid ? `/api/core/users/${uid}/picture` : defaultAvatar;
}

export function resultBrowserUrl(project: string, run: string): string {
  return `/api/mon/projects/${project}/runs/${run}/resultBrowser/index.html`;
}

export function executionLogUrl(project: string, run: string): string {
  return `/api/mon/projects/${project}/runs/${run}/log`;
}

// --- Admin --

export function adminRoute(): string {
  return "/admin";
}

export function adminRouteToGrafanaLogin(pid: string): string {
  return "/api/grafana/login?xtcDash&xtcPid=" + pid;
}

export function adminRouteToTenants(): string {
  return [adminRoute(), "tenants"].join(PATH_SEPARATOR);
}

export function adminRouteToProjects(): string {
  return [adminRoute(), "projects"].join(PATH_SEPARATOR);
}

export function adminRouteToProjectEdit(pid: string): string {
  return [adminRouteToProjects(), pid].join(PATH_SEPARATOR);
}

export function adminRouteToUsers(): string {
  return [adminRoute(), "users"].join(PATH_SEPARATOR);
}

export function adminRouteToResources(): string {
  return [adminRoute(), "resources"].join(PATH_SEPARATOR);
}

export function adminRouteToAuditLog(): string {
  return [adminRoute(), "auditLog"].join(PATH_SEPARATOR);
}

// --- Load Testing ---

export function routeToLoadTestProjectComparisonReports(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "comparison"].join(PATH_SEPARATOR);
}

export function routeToLoadTestProjectComparisonReport(projectId: string, reportId: string): string {
  return `/api/lt/projects/${projectId}/loadTests/diffreports/${reportId}/html/index.html`;
}

export function routeToDiffReportArchiveFile(projectId: string, reportId: string): string {
  return `/api/lt/projects/${projectId}/loadTests/diffreports/${reportId}/archiveFile`;
}

export function routeToProjectDocumentation(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "documentation"].join(PATH_SEPARATOR);
}


export function routeToLoadTests(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "loadTests"].join(PATH_SEPARATOR);
}

export function routeToLoadTest(projectId: string, tenantId: string, loadTestId: string): string {
  return [routeToLoadTests(projectId, tenantId), loadTestId].join(PATH_SEPARATOR);
}

export function routeToLoadTestReport(projectId: string, loadTestId: string, reportId: string): string {
  return `/api/lt/projects/${projectId}/loadTests/${loadTestId}/reports/${reportId}/html/index.html`;
}

export function routeToLoadTestReportArchiveFile(projectId: string, loadTestId: string, reportId: string): string {
  return `/api/lt/projects/${projectId}/loadTests/${loadTestId}/reports/${reportId}/archiveFile`;
}

export function routeToLoadTestResult(projectId: string, loadTestId: string, resultId: string): string {
  return `/api/lt/projects/${projectId}/loadTests/${loadTestId}/results/${resultId}/archiveFile`;
}

export function routeToSharedLoadTestReport(token: string): string {
  return `/public/reports/${token}/index.html`;
}

export function routeToSharedLoadTestResult(token: string): string {
  return `/public/results/${token}/archive`;
}

export function routeToLoadTestResources(project: ProjectData | string, tenant?: string): string {
  return [routeToProject(project, tenant), "resources"].join(PATH_SEPARATOR);
}

export function routeToLoginProvider(type: "google" | "okta" | "azure", signUp?: boolean): string {
  return `/api/core/account/${type}${signUp ? "?signup=true" : ""}`;
}

export function routeToSlack(baseURI: string, containingType: string, containerId: string, containerVersion: number): string {
  const slackRedirectUri = new URL(`/api/core/integrations/slack/${containingType}s/${containerId}`, baseURI);
  return `/api/core/slack?scope=chat:write,chat:write.public&redirect_uri=${slackRedirectUri}&state=${containerVersion}`;
}

/**
 * Redirect the user to a given external login provider.
 *
 * @param type The type of login provider to use
 * @param signUp Indicates whether this redirection is part of a login or signup process
 */
export function redirectToLoginProvider(type: "google" | "okta" | "azure", signUp?: boolean) {
  //  The URL this redirects to is immediately redirected again from the ApplicationServer side in order to create the
  //  correct login flow with OIDC providers. Using react-router features instead of setting window.location directly
  //  breaks the server-side forward and thereby the whole login process
  redirectExternal(routeToLoginProvider(type, signUp));
}

/**
 * Redirect the browser to an external URL with a complete reload, bypassing normal routing. This is
 * intended for use cases where external web pages need to replace the XTC UI (e.g. when running an OIDC
 * flow)
 *
 * @param url The URL to redirect the browser to
 */
export function redirectExternal(url: string) {
  // NOTE: This is defined here and explicitly NOT in RouterStore as it bypasses the normal Router completely.
  window.location.href = url;
}
