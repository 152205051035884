import * as React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import clsx from "clsx";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";

import CustomDivider from "components/divider/CustomDivider";
import { RouterListItem } from "components/RouterControls";
import TruncatingText from "components/TruncatingText";

import { SideNavigationSlotContent } from "./SideNavigationSlot";
import { SUB_HEADER_HEIGHT } from "./SubHeaderSlot";

type NavigationItemProps = {
  className: string;
  labelId: string;
  labelValues?: Record<string, string>,
  path: string;
  icon: React.ReactElement<typeof SvgIcon>;
  selectedOnExactPath?: boolean;
  onClick?: () => void;
};
type NavigationItemInternalProps = NavigationItemProps & {
  isHeader?: boolean;
  isExpanded: boolean;
};
const NavItem: React.FunctionComponent<NavigationItemInternalProps> = (props) => {
  const { className, path, selectedOnExactPath, labelId, onClick, icon, isHeader } = props;

  const { pathname } = useLocation();
  const selected = selectedOnExactPath === true ? pathname === path : selectedOnExactPath === false ? pathname.startsWith(path) : false;

  const { formatMessage } = useIntl();
  const label = formatMessage({ id: labelId, defaultMessage: labelId }, props.labelValues);

  return (
    <RouterListItem divider={isHeader} className={className} onClick={onClick} path={path} selected={selected}
      style={{ height: isHeader ? SUB_HEADER_HEIGHT : undefined }}
    >
      <ListItemIcon style={{ minWidth: "unset", paddingRight: "16px" }} title={label} >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Box fontWeight={(selected || isHeader) ? "fontWeightRegular" : "fontWeightLight"}>
            <TruncatingText noWrap title={label} component="span" variant="body1" inheritFontWeight>
              {label}
            </TruncatingText>
          </Box>
        }
        disableTypography={true}
      />
    </RouterListItem >
  );
};

export type NavigationProps = {
  header: NavigationItemProps;
  entries: Array<NavigationItemProps | null | undefined | false | { divider: true }>;
};
const Navigation: React.FunctionComponent<NavigationProps> = (props) =>
  <SideNavigationSlotContent
    renderContent={(navigationSlotProps) =>
      <Box className={clsx("xtc_navigation", navigationSlotProps.expanded ? "xtc_expanded" : "xtc_closed")}
        display="flex" flexDirection="column">
        <List dense={true} disablePadding={true}>
          <NavItem {...props.header}
            isHeader={true}
            isExpanded={navigationSlotProps.expanded}
            onClick={() => {
              navigationSlotProps.onSelected();
              if (props.header.onClick) {
                props.header.onClick();
              }
            }}
          />

          {props.entries.map((eachEntryProps, idx) =>
            eachEntryProps &&
            (
              "divider" in eachEntryProps
                ? <CustomDivider key={`divider-${idx}`} />
                : <NavItem {...eachEntryProps}
              key={eachEntryProps.path}
              isExpanded={navigationSlotProps.expanded}
              onClick={() => {
                navigationSlotProps.onSelected();
                if (eachEntryProps.onClick) {
                  eachEntryProps.onClick();
                }
              }}
                />)
          )}
        </List>
      </Box>

    }
  />
  ;

export default Navigation;
export { Navigation };
