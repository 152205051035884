import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Message } from "app/localization/components/Message";

type Props = Partial<{
  id: string;
  headingID: string;
  detailsID: string;
  headerSupplemental: JSX.Element;
}>;

export default class Section extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    const { id, headingID, detailsID, headerSupplemental, children } = this.props;
    const component = headingID ? "section" : "div";
    return (
      <Box id={id} component={component} flexDirection="column" display="flex">
        {headingID &&
          <Box component="header" mb={2}>
            <Typography variant="h4"><Message messageID={headingID} /></Typography>
            {detailsID &&
              <Typography><Message messageID={detailsID} /></Typography>
            }

            {headerSupplemental}
          </Box>
        }
        {children}
      </Box >
    );
  }
}
