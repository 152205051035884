import * as React from "react";

import { useObserver } from "mobx-react";

import useAppStores from "app/core/store/useStore";

import { MessageCarousel } from "./MessageCarousel";

type Props = {
  updateSeconds: number;
};

const Component: React.ComponentType<Props> = (props) => {
  const { messageStore, userStore } = useAppStores();

  // initial fetch when component is mounted
  React.useEffect(() => {
    messageStore.fetchSystemMessages();
  }, [messageStore]);

  // fetch regularly using the provided update interval
  React.useEffect(() => {
    const timerId = setInterval(() => messageStore.fetchSystemMessages(), props.updateSeconds * 1000);
    return () => clearInterval(timerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateSeconds]);

  return useObserver(() => (messageStore.activeCriticalSystemMessages.length === 0
      ? null
      : <MessageCarousel
        messages={messageStore.activeCriticalSystemMessages
          .sort((eachMessage, otherMessage) => eachMessage.activeFrom - otherMessage.activeFrom)
        }
        viewSeconds={10}
        onDetailsClose={(id) => userStore.isLoggedIn && messageStore.readSystemMessage(id)}
      />
    ));
};

export { Component as MessagesFetcher };
export default Component;
