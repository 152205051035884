import { FormattedMessage, ValuedMessage } from "components/error/types";

import useAppStores from "app/core/store/useStore";

export interface Notifications {
    notify(msg: FormattedMessage, status?: Status, modal?: boolean, onClose?: () => void): void;
    error(key: string, values?: ValuedMessage["values"], modal?: boolean, onClose?: () => void): void;
    warning(key: string, values?: ValuedMessage["values"], modal?: boolean, onClose?: () => void): void;
    ok(key: string, values?: ValuedMessage["values"], modal?: boolean, onClose?: () => void): void;
}

export function useNotifications(): Notifications {
    const { notificationStore } = useAppStores();
    return notificationStore;
}
