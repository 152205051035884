import { action, makeObservable, observable, runInAction } from "mobx";

import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { APIResult } from "app/utils/remote";

import LoadTestAPI from "app/lt/project/LoadTestAPI";
import LoadTestConfigurationAPI from "app/lt/project/LoadTestConfigurationAPI";
import {
  AbortLoadTestData, AddLoadTestData, AwsMachines, CommonMachineConfiguration, CustomMachines, DuplicateLoadTestData, GcpMachines,
  LoadProfile, LoadTest, LoadTestData, LoadTestEvaluation, LoadTestExecutionState, LoadTestExecutionStatusHistory, LoadTestFilterOptions,
  Repository, ScenarioStatus, StepStatus, UnattendedExecutionOptions, UpdateLoadTestData
} from "app/lt/project/types";

import LoadTestEvaluationAPI from "../LoadTestEvaluationAPI";
import LoadTestExecutionAPI from "../LoadTestExecutionAPI";

type State = {
  readonly loadTests: LoadTest[];
  readonly totalCount: number;
  readonly filteredCount: number;
};

class LoadTestsStore extends AbstractState<State> implements State {
  private static readonly initialState: State = {
    loadTests: [],
    totalCount: 0,
    filteredCount: 0
  };

  private appStores: AppStores;

  @observable public readonly loadTests: LoadTest[];
  @observable public readonly totalCount: number;
  @observable public readonly filteredCount: number;

  public constructor(appStores: AppStores) {
    super();
    this.appStores = appStores;
    makeObservable(this);
    this.setState(LoadTestsStore.initialState);
  }

  public initStore() {
    this.setState(LoadTestsStore.initialState);
  }

  private indexOfLoadTest(ltid: string): number {
    return this.loadTests.findIndex((lt) => lt._id === ltid);
  }

  public findLoadTest(ltid: string): LoadTest | null {
    const index = this.indexOfLoadTest(ltid);
    return index < 0 ? null : this.loadTests[index];
  }


  @action.bound
  private putLoadTest(prepend: boolean, loadTest: LoadTest): void {
    const index = this.indexOfLoadTest(loadTest._id);
    if (index > -1) {
      this.loadTests[index] = loadTest;
    } else {
      if (prepend) {
        this.loadTests.splice(0, 0, loadTest);
      } else {
        this.loadTests.push(loadTest);
      }
    }
  }

  @action.bound
  private putLoadTests(append: boolean, loadTests: LoadTest[]): void {
    if (!append) {
      this.loadTests.splice(0, this.loadTests.length, ...loadTests);
    } else {
      loadTests.forEach((each) => this.putLoadTest(false, each));
    }
  }

  @action.bound
  private removeLoadTest(id: string): void {
    const index = this.indexOfLoadTest(id);
    if (index > -1) {
      this.loadTests.splice(index, 1);
    }
  }

  public async fetchLoadTests(
    append: boolean, projectID: string,
    start: number, count: number, sortBy: string, search: string, softDeleted: boolean,
    filterOptions: LoadTestFilterOptions
  ): Promise<void> {
    const result = await LoadTestAPI.fetchLoadTests(projectID, start, count, sortBy, search, softDeleted, filterOptions);
    if (result.success && result.data) {
      const { items, totalCount, filteredCount } = result.data;
      this.putLoadTests(append, items.map(LoadTestsStore.createLoadTest));
      this.setState({ filteredCount, totalCount });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  public async fetchLoadTest(projectId: string, id: string): Promise<void> {
    const result = await LoadTestAPI.fetchLoadTest(projectId, id);
    this.updateLoadTest(result);
  }

  public async fetchLoadTestStatus(projectId: string, loadTestId: string): Promise<void> {
    const loadTest = this.findLoadTest(loadTestId);
    if (loadTest) {
      const result = await LoadTestAPI.fetchLoadTestStatus(projectId, loadTestId);
      if (result.success && result.data) {
        runInAction(() => loadTest.status = Object.assign({}, loadTest.status, result.data));
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    }
  }

  public async fetchScenarioStatus(projectId: string, loadTestId: string): Promise<ScenarioStatus[]> {
    const loadTest = this.findLoadTest(loadTestId);
    if (loadTest) {
      const result = await LoadTestExecutionAPI.fetchScenarioStatus(projectId, loadTestId);
      if (result.success && result.data) {
        const newScenarioStatus = result.data;
        runInAction(() => loadTest.status.scenarioStatus = newScenarioStatus);
        return newScenarioStatus;
      }
    }
    return loadTest?.status?.scenarioStatus || [];
  }

  public async fetchStepStatus(projectId: string, loadTestId: string): Promise<StepStatus[]> {
    const loadTest = this.findLoadTest(loadTestId);
    if (loadTest) {
      const result = await LoadTestExecutionAPI.fetchStepStatus(projectId, loadTestId);
      if (result.success && result.data) {
        const newStepStatus = result.data;
        runInAction(() => loadTest.status.stepStatus = newStepStatus);
        return newStepStatus;
      }
    }
    return loadTest?.status?.stepStatus || [];
  }

  public async fetchLoadTestExecutionStatusHistory(projectId: string, loadTestId: string,
    start: number, end: number, points: number): Promise<LoadTestExecutionStatusHistory | null> {
    const loadTest = this.findLoadTest(loadTestId);
    if (loadTest) {
      try {
        const result = await LoadTestExecutionAPI.fetchLoadTestExecutionStatusHistory(projectId, loadTestId, start, end, points);
        if (result.success && result.data) {
          const newLoadTestExecutionStatusHistory = result.data;
          runInAction(() => loadTest.status.loadTestExecutionStatusHistory = newLoadTestExecutionStatusHistory);
          return newLoadTestExecutionStatusHistory;
        }
      } catch (e) {
        return null;
      }
    }

    return loadTest?.status?.loadTestExecutionStatusHistory || null;
  }

  public async createLoadTest(projectID: string, data: AddLoadTestData): Promise<void> {
    const result = await LoadTestAPI.createLoadTest(projectID, data);
    await this.appStores.projectStore.fetchProject(projectID);
    if (result.success && result.data) {
      this.putLoadTest(true, LoadTestsStore.createLoadTest(result.data));
      this.setState({
        totalCount: this.totalCount + 1,
        filteredCount: this.filteredCount + 1
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  public async editLoadTest(projectId: string, loadTest: LoadTest, data: UpdateLoadTestData): Promise<void> {
    const result = await LoadTestAPI.updateLoadTest(projectId, loadTest._id, loadTest._ver, data);
    this.updateLoadTest(result);
  }

  public async duplicateLoadTest(projectId: string, loadTestId: string, data: DuplicateLoadTestData): Promise<LoadTest> {
    const result = await LoadTestAPI.duplicateLoadTest(projectId, loadTestId, data);
    await this.appStores.projectStore.fetchProject(projectId);
    if (result.success && result.data) {
      const loadTestDuplicate = LoadTestsStore.createLoadTest(result.data);
      this.putLoadTest(true, loadTestDuplicate);
      this.setState({
        totalCount: this.totalCount + 1,
        filteredCount: this.filteredCount + 1
      });
      return loadTestDuplicate;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  public async deleteLoadTest(projectId: string, loadTestId: string): Promise<void> {
    const result = await LoadTestAPI.deleteLoadTest(projectId, loadTestId);
    if (result.success) {
      this.removeLoadTest(loadTestId);
      this.setState({
        totalCount: this.totalCount - 1,
        filteredCount: this.filteredCount - 1
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  public async deleteLoadTests(projectId: string, loadTests: LoadTest[]): Promise<void> {
    const result = await LoadTestAPI.deleteLoadTests(projectId, loadTests.map((lt) => ({ id: lt._id, version: lt._ver })));
    if (result.success) {
      loadTests.forEach((lt) => this.removeLoadTest(lt._id));
      this.setState({
        totalCount: this.totalCount - loadTests.length,
        filteredCount: this.filteredCount - loadTests.length
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async restoreLoadTest(projectId: string, loadtestId: string): Promise<void> {
    const result = await LoadTestAPI.restoreLoadTest(projectId, loadtestId);
    if (result.success && result.data) {
      this.removeLoadTest(result.data._id);
      this.setState({
        totalCount: this.totalCount - 1,
        filteredCount: this.filteredCount - 1
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }


  public async saveExecutionConfiguration(
    projectId: string, loadTest: LoadTest, executionOptions: UnattendedExecutionOptions): Promise<void> {
    const result = await LoadTestConfigurationAPI.saveExecutionConfiguration(projectId, loadTest._id, loadTest._ver, executionOptions);
    this.updateLoadTest(result);
  }

  public async deleteExecutionConfiguration(projectId: string, loadTest: LoadTest): Promise<void> {

    const result = await LoadTestConfigurationAPI.deleteExecutionConfiguration(projectId, loadTest._id, loadTest._ver);
    this.updateLoadTest(result);
  }

  public async saveLoadProfileConfiguration(
    projectId: string, loadTest: LoadTest, loadProfile: LoadProfile): Promise<void> {

    const result = await LoadTestConfigurationAPI.saveLoadProfileConfiguration(projectId, loadTest._id, loadTest._ver, loadProfile);
    this.updateLoadTest(result);
  }

  public async saveRepositoryConfiguration(
    projectId: string, loadTest: LoadTest, repositorySettings: Repository): Promise<void> {

    const result = await LoadTestConfigurationAPI.saveRepositoryConfiguration(projectId, loadTest._id, loadTest._ver, repositorySettings);
    this.updateLoadTest(result);
  }

  public async deleteRepositoryConfiguration(projectId: string, loadTest: LoadTest): Promise<void> {

    const result = await LoadTestConfigurationAPI.deleteRepositoryConfiguration(projectId, loadTest._id, loadTest._ver);
    this.updateLoadTest(result);
  }

  public async saveCommonMachineConfiguration(
    projectId: string, loadTest: LoadTest, commonMachineConfiguration: CommonMachineConfiguration): Promise<void> {
    const result = await LoadTestConfigurationAPI.saveCommonMachineConfiguration(
      projectId, loadTest._id, loadTest._ver, commonMachineConfiguration);
    this.updateLoadTest(result);
  }

  public async saveAwsMachineConfiguration(
    projectId: string, loadTest: LoadTest, awsMachines: AwsMachines): Promise<void> {

    const result = await LoadTestConfigurationAPI.saveAwsMachineConfiguration(projectId, loadTest._id, loadTest._ver, awsMachines);
    this.updateLoadTest(result);
  }

  public async deleteAwsMachineConfiguration(projectId: string, loadTest: LoadTest): Promise<void> {

    const result = await LoadTestConfigurationAPI.deleteAwsMachineConfiguration(projectId, loadTest._id, loadTest._ver);
    this.updateLoadTest(result);
  }

  public async saveGcpMachineConfiguration(
    projectId: string, loadTest: LoadTest, gcpMachines: GcpMachines): Promise<void> {

    const result = await LoadTestConfigurationAPI.saveGcpMachineConfiguration(projectId, loadTest._id, loadTest._ver, gcpMachines);
    this.updateLoadTest(result);
  }

  public async deleteGcpMachineConfiguration(projectId: string, loadTest: LoadTest): Promise<void> {

    const result = await LoadTestConfigurationAPI.deleteGcpMachineConfiguration(projectId, loadTest._id, loadTest._ver);
    this.updateLoadTest(result);
  }

  public async saveHetznerMachineConfiguration(
    projectId: string, loadTest: LoadTest, hetznerMachines: GcpMachines): Promise<void> {

    const result = await LoadTestConfigurationAPI.saveHetznerMachineConfiguration(projectId, loadTest._id, loadTest._ver, hetznerMachines);
    this.updateLoadTest(result);
  }

  public async deleteHetznerMachineConfiguration(projectId: string, loadTest: LoadTest): Promise<void> {

    const result = await LoadTestConfigurationAPI.deleteHetznerMachineConfiguration(projectId, loadTest._id, loadTest._ver);
    this.updateLoadTest(result);
  }

  public async saveCustomMachineConfiguration(
    projectId: string, loadTest: LoadTest, customMachines: CustomMachines): Promise<void> {

    const result = await LoadTestConfigurationAPI.saveCustomMachineConfiguration(projectId, loadTest._id, loadTest._ver, customMachines);
    this.updateLoadTest(result);
  }

  public async deleteCustomMachineConfiguration(projectId: string, loadTest: LoadTest): Promise<void> {
    const result = await LoadTestConfigurationAPI.deleteCustomMachineConfiguration(projectId, loadTest._id, loadTest._ver);
    this.updateLoadTest(result);
  }

  public async startLoadTest(projectId: string, loadTestId: string): Promise<void> {
    const loadTest = this.findLoadTest(loadTestId);
    if (loadTest) {
      const result = await LoadTestExecutionAPI.startLoadTest(projectId, loadTestId);
      if (result.success && result.data) {
        const newExecutionState = result.data as LoadTestExecutionState;
        runInAction(() => loadTest.status.executionState = newExecutionState);
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    }
  }

  public async abortLoadTest(projectId: string, loadTestId: string, abortData: AbortLoadTestData): Promise<void> {
    const loadTest = this.findLoadTest(loadTestId);
    if (loadTest) {
      const result = await LoadTestExecutionAPI.stopLoadTest(projectId, loadTest._id, abortData);
      if (result.success && result.data) {
        const newExecutionState = result.data;
        // Refetch load test data due to increment of result/report counters
        if (abortData.saveResults || abortData.createReport) {
          this.fetchLoadTest(projectId, loadTestId);
        } else {
          runInAction(() => loadTest.status.executionState = newExecutionState);
        }
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    }
  }

  public async updateLoadTestPin(projectId: string, loadTest: LoadTest): Promise<void> {
    const result = await LoadTestAPI.updateLoadTestPin(projectId, loadTest._id, !loadTest.pinned);
    this.updateLoadTest(result);
  }

  public async saveLoadTestEvaluation(projectId: string, loadTest: LoadTest, evaluation: LoadTestEvaluation): Promise<void> {
    const result = await LoadTestEvaluationAPI.saveLoadTestEvaluation(projectId, loadTest._id, loadTest._ver, evaluation);
    this.updateLoadTest(result);
  }

  public async deleteLoadTestEvaluation(projectId: string, loadTest: LoadTest): Promise<void> {
    const result = await LoadTestEvaluationAPI.deleteLoadTestEvaluation(projectId, loadTest._id, loadTest._ver);
    this.updateLoadTest(result);
  }

  public async saveProperties(projectId: string, loadTest: LoadTest, properties?: string, secretProperties?: string): Promise<void> {
    const result = await LoadTestConfigurationAPI.saveProperties(projectId, loadTest._id, loadTest._ver, properties, secretProperties);
    this.updateLoadTest(result);
  }

  public updateLoadTestHasPinnedArtifacts(loadTest: LoadTest, hasPinnedArtifacts: boolean) {
    loadTest.hasPinnedArtifacts = hasPinnedArtifacts;
    this.putLoadTest(false, loadTest);
  }

  /**
   * If the passed API result indicates success, the received data is transformed into desired type and saved -
   * either replacing an existing load test or added as new one. If the API result indicated an error, the error
   * is thrown.
   *
   * @param result - the API result
   */
  private updateLoadTest(result: APIResult<LoadTestData>) {
    if (result.success && result.data) {
      this.putLoadTest(false, LoadTestsStore.createLoadTest(result.data));
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  /**
   * Creates a load test object from the given load test data.
   *
   * @param data the load test data received as JSON
   */
  private static createLoadTest(data: LoadTestData): LoadTest {
    return {
      ...data,
      status: { ...data.status, machineStatus: [], scenarioStatus: [] }
    };
  }
}

export { LoadTestsStore };
export default LoadTestsStore;
