import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";

import { ProjectState } from "app/core/project/store/ProjectState";
import useAppStores from "app/core/store/useStore";
import { TenantState } from "app/core/tenant/store/TenantState";

export function useAppTitle(tenant?: TenantState | null, project?: ProjectState | null) {
  const existingTitle = useRef(document.title);
  const intl = useIntl();
  const location = useLocation();
  const { loadTestsStore, userStore } = useAppStores();

  const { pathname } = location;

  // compute the desired application window title (memoized)
  const appTitle = React.useMemo(() => {
    const pathSegments = pathname.split("/").filter((s) => s.length !== 0);
    const numPathSegments = pathSegments.length;
    const subPath = numPathSegments > 0 ? pathSegments[numPathSegments - 1] : null;
    const loadTest = numPathSegments > 1 && pathSegments[numPathSegments - 2] === "loadTests" && subPath !== null
      ? loadTestsStore.findLoadTest(subPath) : null;

    const messageParams: Record<string, string | undefined> = {
      tenant: tenant?.name,
      project: project?.name
    };

    let messageKey: string = "";
    if (project) {
      if (loadTest) {
        messageKey = "application.title.project.loadTest.details";
        messageParams.loadTest = loadTest?.name;
      } else if (subPath !== project._id) {
        messageKey = `application.title.project.${subPath}`;
      } else {
        messageKey = "application.title.project.dashboard";
      }
    } else if (tenant) {
      if (subPath !== tenant._id) {
        messageKey = `application.title.tenant.${subPath}`;
      } else {
        messageKey = "application.title.tenant.dashboard";
      }
    } else if (userStore.isLoggedIn && numPathSegments === 0) {
      messageKey = "application.title.user.dashboard";
    } else if (numPathSegments > 0 && pathSegments[0] === "admin") {
      messageKey = "application.title.admin";
    } else if (numPathSegments > 0 && pathSegments[0] === "exploreApi") {
      messageKey = "application.title.apiExplorer";
    }

    return messageKey && intl.formatMessage({
      id: messageKey,
      defaultMessage: "Xceptance Test Center"
    }, messageParams);
  }, [pathname, userStore.isLoggedIn, tenant?._id, tenant?.name, project?._id, project?.name]);

  React.useEffect(() => {
    if (appTitle) {
      document.title = appTitle;
    }
    return () => {
      document.title = existingTitle.current; // reset the title after component unmount
    };
  }, [appTitle]); // Run effect if and only if computed title has changed
}
