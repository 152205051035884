import { ProjectSortoptions, SystemGrafanaSettings, SystemProjectData } from "app/core/project/types";
import { SaveTenantData, SystemTenantData } from "app/core/tenant/types";
import { UserData, UserStates, UserSystemRole } from "app/core/user/types";
import { AsyncAPIResult, fetch } from "app/utils/remote";

import {
  MonitoringExecutorSettings, PersistenceSettings, SystemMonitoringLocation, SystemMonitoringProjectData
} from "app/mon/project/types";

type ListTenantsResult<T extends SystemTenantData = SystemTenantData> = {
  tenants: T[];
  total: number;
  count: number;
};

type UpdateTenantResult = {
  tenant: SystemTenantData;
};

type UpdateTenantsResult = SystemTenantData[];

type ListUsersResult = {
  users: UserData[];
  total: number;
  count: number;
};

type UpdateUserResult = {
  user: UserData;
};

type UpdateUsersResult = UserData[];

type SearchUserResult = {
  matches: string[]
};

type ProjectsResult<T extends SystemProjectData = SystemProjectData> = {
  projects: T[],
  total: number,
  count: number
};

type ProjectResult<T extends SystemProjectData = SystemProjectData> = T;

export type RestoreAllSecretsResult = {
  failedProjects: string[],
  noBackupProjects: string[],
  noBackupProjectIDs: string[]
};

export type RecreateSecretsResult = {
  failedProjects: string[]
};

export class SystemAdminAPI {

  public static async listProjects(start: number, size: number, sortby: ProjectSortoptions, q: string, softDeleted: boolean)
    : AsyncAPIResult<ProjectsResult> {
    return fetch({
      url: "/api/core/system/projects",
      data:
      {
        start,
        size,
        sortby,
        q,
        softDeleted
      }
    });
  }

  public static async saveMonitoringProjectGrafanaSettings(
    projectId: string, version: number, settings: SystemGrafanaSettings
  ): AsyncAPIResult<ProjectResult<SystemMonitoringProjectData>> {
    return fetch({
      url: `/api/mon/system/projects/${projectId}/grafana`, method: "PUT", data: { ...settings, version }
    });
  }

  public static async saveMonitoringProjectPersistenceSettings(
    projectId: string, version: number, settings: PersistenceSettings
  ): AsyncAPIResult<ProjectResult<SystemMonitoringProjectData>> {
    return fetch({
      url: `/api/mon/system/projects/${projectId}/persistence`, method: "PUT", data: { ...settings, version }
    });
  }

  public static async saveMonitoringProjectExecutorSettings(
    projectId: string, version: number, locations: SystemMonitoringLocation[], executorSettings: MonitoringExecutorSettings
  ): AsyncAPIResult<ProjectResult<SystemMonitoringProjectData>> {
    return fetch({
      url: `/api/mon/system/projects/${projectId}/executor`,
      method: "PUT",
      data: {
        locations: locations.filter((each) => each.selected).map((each) => each.region),
        executorSettings,
        version
      }
    });
  }

  public static async restoreProjectSecret(projectId: string): AsyncAPIResult<void> {
    return fetch({
      url: `/api/core/system/projects/${projectId}/restoreSecret`,
      method: "POST"
    });
  }

  public static async recreateProjectSecrets(projectIDs: string[]): AsyncAPIResult<RecreateSecretsResult> {
    return fetch({
      url: "/api/core/system/projects/recreateSecrets",
      method: "POST",
      data: projectIDs
    });
  }

  public static async restoreAllProjectSecrets(): AsyncAPIResult<RestoreAllSecretsResult> {
    return fetch({
      url: "/api/core/system/projects/restoreAllSecrets",
      method: "POST"
    });
  }

  public static async updateProject(project: SystemProjectData): AsyncAPIResult<ProjectResult> {
    return fetch({
      url: `/api/core/system/projects/${project._id}`, method: "PUT", data: { ...project, version: project._ver }
    });
  }

  private static fetchTenants(filter?: { start: number, size: number, paid: boolean, sortby: string, q: string, softDeleted: boolean })
    : AsyncAPIResult<ListTenantsResult> {
    return fetch({ url: "/api/core/system/tenants", data: filter });
  }

  public static async listTenants(start: number, size: number, paid: boolean, sortby: string, q: string, softDeleted: boolean)
    : AsyncAPIResult<ListTenantsResult> {
    return this.fetchTenants({ start, size, paid, sortby, q, softDeleted });
  }

  public static async listAllTenants(): AsyncAPIResult<ListTenantsResult> {
    return this.fetchTenants();
  }

  public static async createTenant(payload: SaveTenantData): AsyncAPIResult<UpdateTenantResult> {
    return fetch({
      url: "/api/core/system/tenants",
      method: "POST",
      data: {
        ...payload
      }
    });
  }

  public static async saveTenantDetails(id: string, version: number, payload: SaveTenantData): AsyncAPIResult<UpdateTenantResult> {
    return fetch({
      url: `/api/core/system/tenants/${id}`,
      method: "PUT",
      data: {
        version, ...payload
      }
    });
  }

  public static async removeTenant(tenants: Array<{ id: string, version: number }>): AsyncAPIResult<never> {
    return fetch({ url: "/api/core/system/tenants", method: "DELETE", data: tenants });
  }

  public static async restoreTenant(tenantId: string): AsyncAPIResult<SystemTenantData> {
    return fetch({
      url: `/api/core/system/tenants/${tenantId}/restore`,
      method: "POST"
    });
  }

  public static async lockTenants(tenants: Array<{ id: string, version: number }>): AsyncAPIResult<UpdateTenantsResult> {
    return fetch({ url: "/api/core/system/tenants/lock", method: "POST", data: tenants });
  }

  public static async unlockTenants(tenants: Array<{ id: string, version: number }>): AsyncAPIResult<UpdateTenantsResult> {
    return fetch({ url: "/api/core/system/tenants/unlock", method: "POST", data: tenants });
  }

  public static async listUsers(start: number, size: number, sortby: string, q: string): AsyncAPIResult<ListUsersResult> {
    return fetch({ url: "/api/core/system/users", data: { start, size, sortby, q } });
  }

  public static async saveUserDetails(
    id: string, version: number,
    firstName: string, lastName: string, email: string, role: UserSystemRole, state: UserStates)
    : AsyncAPIResult<UpdateUserResult> {
    return fetch({
      url: `/api/core/system/users/${id}`,
      method: "PUT",
      data: {
        firstName, lastName, email, role, state, version
      }
    });
  }

  public static async createUser(firstName: string, lastName: string, email: string, role: UserSystemRole, state: UserStates)
    : AsyncAPIResult<UpdateUserResult> {
    return fetch({
      url: "/api/core/system/users", method: "POST", data: { firstName, lastName, email, role, state }
    });
  }

  public static async removeUsers(users: Array<{ id: string, version: number }>): AsyncAPIResult<never> {
    return fetch({ url: "/api/core/system/users", method: "DELETE", data: users });
  }

  public static async lockUsers(users: Array<{ id: string, version: number }>): AsyncAPIResult<UpdateUsersResult> {
    return fetch({ url: "/api/core/system/users/lock", method: "POST", data: users });
  }

  public static async unlockUsers(users: Array<{ id: string, version: number }>): AsyncAPIResult<UpdateUsersResult> {
    return fetch({ url: "/api/core/system/users/unlock", method: "POST", data: users });
  }

  public static async searchUser(query: string): AsyncAPIResult<SearchUserResult> {
    return fetch({ url: "/api/core/system/users/search", data: { q: query }, cache: true });
  }

  public static async unbindSSO(user: { id: string, version: number }): AsyncAPIResult<UpdateUserResult> {
    return fetch({ url: "/api/core/system/users/unbind", method: "POST", data: user });
  }

  public static async unbind2FA(user: { id: string, version: number }): AsyncAPIResult<UpdateUserResult> {
    return fetch({ url: "/api/core/system/users/unbind2FA", method: "POST", data: user });
  }

  public static async resetPassword(user: { id: string, version: number }): AsyncAPIResult<UpdateUserResult> {
    return fetch({ url: "/api/core/system/users/resetPassword", method: "POST", data: user });
  }
}
