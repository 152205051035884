import { TenantRoles } from "app/core/tenant/types";

const orderedRoles: TenantRoles[] = ["Guest", "User", "BillingAdmin", "Admin"];

export type Privilege = "<" | "<=" | "=" | ">=" | ">";

export function hasTenantRole(role: TenantRoles, required: TenantRoles, modifier?: Privilege): boolean {
    if (role && required) {
        const roleIndex = orderedRoles.indexOf(role);
        const requiredIndex = orderedRoles.indexOf(required);
        switch (modifier) {
            case "<": return roleIndex < requiredIndex;
            case "<=": return roleIndex <= requiredIndex;
            case "=": return roleIndex === requiredIndex;
            case ">": return roleIndex > requiredIndex;
            default: return roleIndex >= requiredIndex;
        }
    } else {
        return false;
    }
}

export function validateTenantRole<R>(role: TenantRoles, required: TenantRoles, returnOnValid: R, modifier?: Privilege): R | undefined {
    return hasTenantRole(role, required, modifier) ? returnOnValid : undefined;
}
