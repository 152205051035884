import { action, makeObservable, observable } from "mobx";

import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { DefaultLocale, DefaultTimezone, Locale, Messages, SUPPORTED_LANGUAGES, Timezone } from "app/localization/package";
import { IntlService } from "app/localization/remote/intl-service";
import { getSystemZoneName, setLocale, setZone } from "app/localization/timezones";

export type State = {
  readonly locale: Locale;
  readonly timezone: Timezone;
  readonly messages: Messages;
};

class LocalizationStore extends AbstractState<State> implements State {

  @observable public readonly locale: Locale;
  @observable public readonly timezone: Timezone;
  @observable public readonly messages: Messages;

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();
    makeObservable(this.setState({
      locale: DefaultLocale || /* always use default for now */ LocalizationStore.getInitialLocale(appStores),
      timezone: LocalizationStore.getInitialTimezone(appStores),
      messages: {}
    }));
    this.appStores = appStores;
  }

  private static getInitialLocale(appStores: AppStores): Locale {
    const userLanguage = appStores.userStore.user ? appStores.userStore.user.language : null;
    const language = userLanguage || localStorage.getItem("xtc.user.lang") || navigator.language;
    const initialLocale = SUPPORTED_LANGUAGES.find((each) => language === each)
      || SUPPORTED_LANGUAGES.find((each) => (language || "").startsWith(each))
      || DefaultLocale;

    return initialLocale;
  }

  private static getInitialTimezone(appStores: AppStores): Timezone {
    const userZone = appStores.userStore.user ? appStores.userStore.user.timeZone : null;
    const zone = userZone || DefaultTimezone || getSystemZoneName(); // currently we are using always UTC and not browser timezone
    const initialZone = zone || DefaultTimezone;

    return initialZone;
  }

  @action.bound
  public async changeLocale(locale: Locale): Promise<void> {
    const result = await IntlService.getLocaleData(DefaultLocale || /* always use default for now */ locale);
    setLocale(locale);
    this.setState({ messages: result.messages, locale });
    localStorage.setItem("xtc.user.lang", locale);
  }

  @action.bound
  public changeTimezone(timezone: Timezone): void {
    setZone(timezone);
    this.setState({ timezone });
  }

  @action.bound
  public async changeLocaleAndTimezone(locale: Locale, timezone: Timezone): Promise<void> {
    this.changeTimezone(timezone);
    return await this.changeLocale(locale);
  }

}

export { LocalizationStore };
export default LocalizationStore;
