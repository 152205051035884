
/** Convert a number to a formatted string by adding a
 * number of leading characters to fit the expected length.
 *
 * @param {Number} [value] The number to format
 * @param {Number} [length] The expected lenght of the output string (Default is 2)
 * @param {String} [char] The character that will be prepended (Default is 0)
 * @return {String} The formatted number string
 */
export function paddedNumber(value?: number | null, length?: number, char?: string): string {
  const num = value ? value : 0;
  const charCount = Math.max((length ? length : 2) - num.toString().length, 0);
  const tmpArray = new Array(charCount);
  tmpArray.fill(char ? char : 0);
  tmpArray.push(num);
  return tmpArray.join("");
}

/**
 * Converts a number of bytes to a string with the format "###,# XX", where XX is the unit matching the value most closely.
 * Example: 123456789 -> 123,5 MiB
 *
 * @param bytes - the number of bytes
 * @returns the value including a unit
 */
export function formatBytes(bytes: number): string {
  const units = ["B", "KiB", "MiB", "GiB", "TiB", "PiB"];
  let value = bytes;
  let unit = "";

  for (unit of units) {
    if (value >= 1024) {
      value = value / 1024;
    } else {
      break;
    }
  }

  const remainder = value % 1;

  return (remainder ? value.toFixed(1) : value.toFixed(0)) + " " + unit;
}
