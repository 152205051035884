import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-pluralrules/polyfill";

import { Locale, Messages } from "app/localization/package";

export function loadLocaleMessages(locale: Locale): Promise<Messages> {
  return localeLoader[locale].messages();
}

export function loadLocaleData(locale: Locale): Promise<void> {
  return localeLoader[locale].localeDate();
}

type Loader = {
  [key: string]: {
    messages: () => Promise<Messages>,
    localeDate: () => Promise<void>
  }
};

const localeLoader: Loader = {
  en: {
    messages: () => new Promise<Messages>((resolve, reject) => {
      import(/* webpackChunkName: "locale-en" */ "./messages/en").then(({ default: messages }) => resolve(messages), reject);
    }),
    localeDate: () =>
      Promise.all([
        import(/* webpackChunkName: "locale-en" */ "@formatjs/intl-pluralrules/locale-data/en.js"),
        import(/* webpackChunkName: "locale-en" */ "@formatjs/intl-relativetimeformat/locale-data/en.js")
      ]).then()
  },
  de: {
    messages: () => new Promise<Messages>((resolve, reject) => {
      import(/* webpackChunkName: "locale-de" */ "./messages/de").then(({ default: messages }) => resolve(messages), reject);
    }),
    localeDate: () =>
      Promise.all([
        import(/* webpackChunkName: "locale-de" */ "@formatjs/intl-pluralrules/locale-data/de.js"),
        import(/* webpackChunkName: "locale-de" */ "@formatjs/intl-relativetimeformat/locale-data/de.js")
      ]).then()
  }
};
