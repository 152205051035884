import * as React from "react";

import clsx from "clsx";

import { styled } from "@mui/material/styles";
import { default as MuiTypography, TypographyProps } from "@mui/material/Typography";

const PREFIX = "MuiTypography";

const classes = {
  mono: `${PREFIX}-mono`,
  plain: `${PREFIX}-plain`
};

const StyledMuiTypography = styled(MuiTypography)((
  {
    theme
  }
) => ({
  [`& .${classes.mono}`]: {
    fontFamily: theme.typography.monoRoboto.fontFamily
  },

  [`& .${classes.plain}`]: {
    fontFamily: theme.typography.plainRoboto.fontFamily
  }
}));

export type Props = TypographyProps<React.ElementType<any>, { family?: Family }>;
export type Family = "condensed" | "plain" | "mono";

const Typography: React.FunctionComponent<Props> = (props) => {
  const { family = "condensed", className, ...other } = props;
  return (
    <StyledMuiTypography
      className={
        clsx(className, family === "mono" && classes.mono, family === "plain" && classes.plain)
      }
      {...other}
    />);
};

export default Typography;
