
import "whatwg-fetch";

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "HEAD" | "PATCH";

export type RequestOptions = Partial<{
  method?: RequestMethod,
  background?: boolean,
  cache?: boolean,
  credentials?: "same-origin" | "include" | "omit",
  data?: Record<string, unknown>
}>;

const buildParams = (obj?: any) => {
  const params: string[] = [];
  if (obj) {
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        const value = obj[property];
        if (null !== value && undefined !== value) {
          (Array.isArray(value) ? value : [value]).forEach((v) => params.push(`${property}=${encodeURIComponent(v)}`));
        }
      }
    }
  }
  return params;
};

export const buildQuery = (obj: any) => {
  const params = Array.isArray(obj) ? obj : buildParams(obj);
  return params.length > 0 ? "?" + params.join("&") : "";
};

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

export default class RemoteService {

  public static doCall(url: string, options?: RequestOptions): Promise<Response> {
    const opts = options || {};
    const method = opts.method || "GET";
    const background = opts.background || true;

    const data = opts.data;
    if ((method === "POST" || method === "PUT") && data && "file" in data) {
      const d = data as { file: File };
      if (d.file instanceof File) {
        return this.doUpload(url, method, d);
      }
    }

    const parts = url.split("?");
    let params: string[] = [];
    if (parts.length > 1) {
      params = parts[1].split("&");
      url = parts[0];
    }

    if (background) {
      params.push("background=true");
    }

    if (method === "GET") {
      if (!opts.cache) {
        params.push("_=" + Date.now());
      }
      if (opts.data) {
        params.push(...buildParams(opts.data));
      }
    }

    const requestOpts: RequestInit = { method, headers };
    requestOpts.credentials = opts.credentials || "same-origin";
    if ((method === "POST" || method === "PUT" || method === "DELETE") && opts.data) {
      requestOpts.body = JSON.stringify(opts.data);
    }

    return fetch(url + buildQuery(params), requestOpts);
  }

  private static doUpload(url: string, method: RequestMethod, data: { [key: string]: string | Blob }): Promise<Response> {
    const newHeaders: Array<[string,string]> = [["Accept", "application/json"]];
    const fd = new FormData();
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.apply(data, [key])) {
        fd.append(key, data[key]);
      }
    }
    return fetch(url, { method, headers: newHeaders, credentials: "same-origin", body: fd });
  }
}
