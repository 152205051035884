import { action, makeObservable, observable, override } from "mobx";

import { IProjectState, ProjectState } from "app/core/project/store/ProjectState";
import { RepositoryAuthentication, RepositoryDynamicBranch, RepositorySettings, RepositoryStaticBranch } from "app/core/project/types";
import AppStores from "app/core/store/AppStores";

import { MonitoringProjectAPI } from "app/mon/project/MonitoringProjectAPI";
import {
  AddScenarioDataRecipientResult, AddSubscriptionData, DefaultScenarioConfig, HistoryExport, IdWithVersionData, MonitoringExecutionState,
  MonitoringExecutorSettings, MonitoringProjectData, MonitoringStatus, NewQuietPeriod, PersistenceSettings, QuietPeriod, ScenarioData,
  ScenarioExecutionSettings, ScenarioSettings, ScenarioSubscriptionData, SubscriptionData, SuccessCriteria, SystemMonitoringLocation,
  SystemMonitoringProjectData
} from "app/mon/project/types";

import { QuietPeriodSortOption } from "../components/projectDetail/configuration/quietperiods/QuietPeriodConfiguration";
import { SYSTEM_DEFAULT_MAX_RUNTIME } from "../constants";

interface IMonitoringProjectState extends IProjectState {
  readonly monitoringStatus?: MonitoringStatus;

  readonly repository: RepositorySettings;
  readonly persistence: PersistenceSettings;
  readonly scenarioDefaults: DefaultScenarioConfig;
  readonly scenarios: ScenarioData[];

  readonly quietPeriods: QuietPeriod[];
  readonly quietPeriodsTotal: number;
  readonly quietPeriodsFiltered: number;

  readonly locations: SystemMonitoringLocation[];
  readonly executorSettings: MonitoringExecutorSettings;

  readonly scenarioStates: MonitoringExecutionState[];

  readonly historyExports: HistoryExport[];
}

export class MonitoringProjectState extends ProjectState implements IMonitoringProjectState {
  @observable public readonly monitoringStatus?: MonitoringStatus;

  @observable public readonly repository: RepositorySettings;
  @observable public readonly persistence: PersistenceSettings;
  @observable public readonly scenarioDefaults: DefaultScenarioConfig;
  @observable public readonly scenarios: ScenarioData[];

  @observable public readonly scenarioStates: MonitoringExecutionState[];

  @observable public readonly locations: SystemMonitoringLocation[];
  @observable public readonly executorSettings: MonitoringExecutorSettings;

  @observable public readonly quietPeriods: QuietPeriod[];
  @observable public readonly quietPeriodsTotal: number;
  @observable public readonly quietPeriodsFiltered: number;

  @observable public readonly historyExports: HistoryExport[];

  public constructor(appStores: AppStores, project: MonitoringProjectData | SystemMonitoringProjectData) {
    super(appStores);

    makeObservable(this);
    this.setState({
      scenarios: [],
      scenarioDefaults: {
        notifications: {
          enabled: false,
          replyTo: "",
          subscriptions: [],
          notificationThreshold: 1,
          notificationThresholdHistorySize: 1
        },
        interval: 0,
        retry: {
          enabled: false,
          interval: 0,
          maxAttempts: 0
        },
        maxRuntime: SYSTEM_DEFAULT_MAX_RUNTIME,
        properties: undefined,
        successCriteria: {
          criterions: {}
        },
        locations: [],
        maintenanceWindows: []
      },
      repository: {
        type: "git",
        url: "",
        branch: {
          kind: "static",
          name: ""
        },
        suitePath: "",
        auth: undefined
      },
      persistence: {
        daysToKeepFailedRunArtifacts: 1,
        daysToKeepSucceededRunArtifacts: 1,
        daysToKeepRuns: 1
      },
      locations: [],
      executorSettings: [],
      scenarioStates: [],
      quietPeriods: [],
      quietPeriodsTotal: 0,
      quietPeriodsFiltered: 0,
      historyExports: [],
      ...ProjectState.initialState(project)
    });
  }

  @override
  public setState(state: Partial<IMonitoringProjectState>) {
    super.setState(state);
    return this;
  }

  public findScenario(id: string): ScenarioData | null {
    return this.scenarios.find((each) => each._id === id) || null;
  }

  @action.bound
  private putScenario(prepend: boolean, scenario: ScenarioData): ScenarioData {
    const index = this.scenarios.findIndex((each) => each._id === scenario._id);
    if (index > -1) {
      this.scenarios[index] = scenario;
      return this.scenarios[index];
    } else {
      if (prepend) {
        this.scenarios.splice(0, 0, scenario);
        return this.scenarios[0];
      } else {
        this.scenarios.push(scenario);
        return this.scenarios[this.scenarios.length - 1];
      }
    }
  }

  @action.bound
  private putScenarios(scenarios: ScenarioData[]): ScenarioData[] {
    return scenarios.map((each) => this.putScenario(false, each));
  }

  @action.bound
  private removeScenario(id: string): void {
    const index = this.scenarios.findIndex((each) => each._id === id);
    if (index > -1) {
      this.scenarios.splice(index, 1);
    }
  }

  @action.bound
  private updateQuietPeriodsCounts(value: number): void {
    this.setState({
      quietPeriodsTotal: this.quietPeriodsTotal + value || 0,
      quietPeriodsFiltered: this.quietPeriodsFiltered + value || 0
    });
  }

  @action.bound
  private putQuietPeriod(prepend: boolean, quietPeriod: QuietPeriod, updateQuietPeriodsCounts = true): QuietPeriod {
    const index = this.quietPeriods.findIndex((each) => each._id === quietPeriod._id);
    if (index > -1) {
      this.quietPeriods[index] = quietPeriod;
      return this.quietPeriods[index];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      updateQuietPeriodsCounts && this.updateQuietPeriodsCounts(1);
      if (prepend) {
        this.quietPeriods.splice(0, 0, quietPeriod);
        return this.quietPeriods[0];
      } else {
        this.quietPeriods.push(quietPeriod);
        return this.quietPeriods[this.quietPeriods.length - 1];
      }
    }
  }

  @action.bound
  private putQuietPeriods(prepend: boolean, quietPeriods: QuietPeriod[], updateQuietPeriodsCounts = true): QuietPeriod[] {
    return quietPeriods.map((each) => this.putQuietPeriod(prepend, each, updateQuietPeriodsCounts));
  }

  @action.bound
  private removeQuietPeriod(quietPeriod: QuietPeriod): void {
    const index = this.quietPeriods.findIndex((each) => each._id === quietPeriod._id);
    if (index > -1) {
      this.quietPeriods.splice(index, 1);
      this.updateQuietPeriodsCounts(-1);
    }
  }

  @action.bound
  private putScenarioRecipient(prepend: boolean, scenario: ScenarioData, recipient: ScenarioSubscriptionData, replaceRecipient?: string)
    : ScenarioSubscriptionData {
    const index = scenario.configuration.notifications.subscriptions.findIndex((each) => each._id === (replaceRecipient || recipient._id));
    if (index > -1) {
      scenario.configuration.notifications.subscriptions[index] = recipient;
      return scenario.configuration.notifications.subscriptions[index];
    } else {
      if (prepend) {
        scenario.configuration.notifications.subscriptions.splice(0, 0, recipient);
        return scenario.configuration.notifications.subscriptions[0];
      } else {
        scenario.configuration.notifications.subscriptions.push(recipient);
        return scenario.configuration.notifications.subscriptions[scenario.configuration.notifications.subscriptions.length - 1];
      }
    }
  }

  @action.bound
  private removeScenarioRecipient(scenario: ScenarioData, recipientId: string): void {
    const index = scenario.configuration.notifications.subscriptions.findIndex((each) => each._id === recipientId);
    if (index > -1) {
      scenario.configuration.notifications.subscriptions.splice(index, 1);
    }
  }

  @action.bound
  private removeScenarioRecipients(scenario: ScenarioData, recipientIds: string[]): void {
    recipientIds.map((each) => this.removeScenarioRecipient(scenario, each));
  }

  @action.bound
  private putDefaultRecipient(prepend: boolean, recipient: SubscriptionData, currentRecipient?: string): SubscriptionData {
    const index = this.scenarioDefaults.notifications.subscriptions.findIndex((each) => each._id === (currentRecipient || recipient._id));
    if (index > -1) {
      this.scenarioDefaults.notifications.subscriptions[index] = recipient;
      return this.scenarioDefaults.notifications.subscriptions[index];
    } else {
      if (prepend) {
        this.scenarioDefaults.notifications.subscriptions.splice(0, 0, recipient);
        return this.scenarioDefaults.notifications.subscriptions[0];
      } else {
        this.scenarioDefaults.notifications.subscriptions.push(recipient);
        return this.scenarioDefaults.notifications.subscriptions[this.scenarioDefaults.notifications.subscriptions.length - 1];
      }
    }
  }

  @action.bound
  private removeDefaultRecipient(recipientId: string): void {
    const index = this.scenarioDefaults.notifications.subscriptions.findIndex((each) => each._id === recipientId);
    if (index > -1) {
      this.scenarioDefaults.notifications.subscriptions.splice(index, 1);
    }
  }

  @action.bound
  private removeDefaultRecipients(recipientIds: string[]): void {
    recipientIds.forEach(this.removeDefaultRecipient);
  }

  @action.bound
  private scenarioDefaultsUpdated(data: { _ver: number, scenarioDefaults: DefaultScenarioConfig }): Promise<void> {
    const { _ver, scenarioDefaults } = data;
    this.setState({ _ver, scenarioDefaults });
    return this.fetchScenarioSettings();
  }

  @override
  public async fetchProjectSettings(): Promise<void> {
    await this.fetchScenarioSettings();
    const result = await MonitoringProjectAPI.loadProjectSettings(this._id);
    if (result.success && result.data) {
      this.setState({
        repository: result.data.repository,
        persistence: result.data.persistence,
        scenarioDefaults: result.data.scenarioDefaults,
        executorSettings: result.data.executorSettings
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchScenarioSettings(): Promise<void> {
    const result = await MonitoringProjectAPI.loadProjectScenarios(this._id);
    if (result.success && result.data) {
      this.setState({ scenarios: result.data });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushDefaultExecutionSettings(settings: ScenarioExecutionSettings): Promise<void> {
    const result = await MonitoringProjectAPI.saveExecutionDefaults(this._id, this._ver, settings);
    if (result.success && result.data) {
      return this.scenarioDefaultsUpdated(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushDefaultPropertiesSettings(settings: string): Promise<void> {
    const result = await MonitoringProjectAPI.savePropertiesDefaults(this._id, this._ver, settings);
    if (result.success && result.data) {
      return this.scenarioDefaultsUpdated(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushDefaultNotificationSettings(threshold: number, thresholdHistorySize: number, replyTo: string, enabled: boolean)
    : Promise<void> {
    const settings = { notifications: { threshold, thresholdHistorySize, replyTo, enabled } };
    const result = await MonitoringProjectAPI.saveNotificationSettingsDefault(this._id, this._ver, settings);
    if (result.success && result.data) {
      return this.scenarioDefaultsUpdated(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchRecipientSuggestions(search: string): Promise<Array<{ name: string, email: string }>> {
    const result = await MonitoringProjectAPI.suggestRecipientsForNotificationDefaults(this._id, search);
    if (result.success && result.data) {
      return result.data.matches;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushDefaultRecipient(recipient: AddSubscriptionData): Promise<string> {
    const result = await MonitoringProjectAPI.addRecipientToNotificationDefaults(this._id, this._ver, recipient);
    if (result.success && result.data) {
      this.putDefaultRecipient(true, result.data);
      await this.fetchScenarioSettings();
      return result.data._id;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateDefaultRecipient(currentReceiver: string, subscriber: SubscriptionData): Promise<void> {
    const result = await MonitoringProjectAPI.updateDefaultNotificationRecipient(
      this._id, subscriber._ver, currentReceiver, { subscriber }
    );

    if (result.success && result.data) {
      this.putDefaultRecipient(false, result.data, currentReceiver);
      await this.fetchScenarioSettings();
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteDefaultRecipients(recipients: IdWithVersionData[]): Promise<void> {
    const result = await MonitoringProjectAPI.removeRecipientsFromNotificationDefaults(this._id, recipients);
    if (result.success) {
      // Get all emails and remove them
      const emails: string[] = recipients.map((r) => r.id);
      this.removeDefaultRecipients(emails);
      await this.fetchScenarioSettings();
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushDefaultNotificationsActive(active: boolean): Promise<void> {
    const result = await MonitoringProjectAPI.toggleDefaultNotificationsActive(this._id, this._ver, active);
    if (result.success && result.data) {
      const { _ver, scenarioDefaults: { notifications } } = result.data;
      return this.scenarioDefaultsUpdated({ _ver, scenarioDefaults: { ...this.scenarioDefaults, notifications } });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateDefaultCriteriaSettings(criterias: SuccessCriteria): Promise<void> {
    const result = await MonitoringProjectAPI.saveSuccessCriteriaDefaults(this._id, this._ver, criterias.criterions);
    if (result.success && result.data) {
      return this.scenarioDefaultsUpdated(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushScenario(scenario: ScenarioSettings): Promise<ScenarioData> {
    const result = await MonitoringProjectAPI.createScenario(this._id, scenario);
    if (result.success && result.data) {
      return this.putScenario(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateScenario(scenario: Required<ScenarioSettings>, version: number): Promise<ScenarioData> {
    const result = await MonitoringProjectAPI.updateScenario(this._id, version, scenario);
    if (result.success && result.data) {
      return this.putScenario(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchScenario(scenarioId: string): Promise<ScenarioData> {
    const result = await MonitoringProjectAPI.loadScenario(this._id, scenarioId);
    if (result.success && result.data) {
      return result.data;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteScenario(id: string, version: number): Promise<void> {
    const result = await MonitoringProjectAPI.removeScenarios(this._id, [{ id, version }]);
    if (result.success) {
      this.removeScenario(id);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushScenarioActive(id: string, version: number, active: boolean): Promise<void> {
    const result = await MonitoringProjectAPI.toggleScenarioActive(this._id, [{ id, version }], active);
    if (result.success && result.data) {
      this.putScenarios(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async forceScenariosubscriber(id: string, version: number, recipient: ScenarioSubscriptionData)
    : Promise<AddScenarioDataRecipientResult> {
    const scenario = this.findScenario(id);
    if (!scenario) {
      throw new Error("Scenario not found for id: " + id);
    }
    const result = await MonitoringProjectAPI.forceSubscriber(this._id, id, version, recipient);
    if (result.success && result.data) {
      this.putScenarioRecipient(true, scenario, result.data);
      return {
        recipientId: result.data._id,
        scenario
      };
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteScenarioRecipients(scenarioId: string, recipients: IdWithVersionData[]): Promise<ScenarioData> {
    const scenario = this.findScenario(scenarioId);
    if (!scenario) {
      throw new Error("Scenario not found for id: " + scenarioId);
    }

    const result = await MonitoringProjectAPI.removeRecipientsFromNotifications(this._id, scenarioId, recipients);
    if (result.success) {
      // Get all emails and remove them
      const emails: string[] = recipients.map((r) => r.id);
      this.removeScenarioRecipients(scenario, emails);
      return scenario;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushScenariotNotificationsActive(id: string, version: number, active: boolean): Promise<ScenarioData> {
    const scenario = this.findScenario(id);
    if (!scenario) {
      throw new Error("Scenario not found for id: " + id);
    }
    const result = await MonitoringProjectAPI.toggleNotificationsActive(this._id, id, version, active);
    if (result.success && result.data) {
      this.putScenario(false, result.data);
      return result.data;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchScenarioStates(): Promise<MonitoringExecutionState[]> {
    const result = await MonitoringProjectAPI.loadMonitoringScenarioStates(this._id);
    if (result.success) {
      const scenarioStates = result.data || [];
      this.setState({ scenarioStates });
      return scenarioStates;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @override
  public async fetchProjectState(): Promise<void> {
    const result = await MonitoringProjectAPI.loadMonitoringStatus(this._id);
    if (result.success) {
      const monitoringStatus = result.data;
      this.setState({ monitoringStatus });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @override
  public async pushRepositorySettings(settings: RepositorySettings): Promise<void> {
    const result = await MonitoringProjectAPI.saveRepositorySettings(this._id, this._ver, settings);
    if (result.success && result.data) {
      const { _ver, repository } = result.data;
      this.setState({ _ver, repository });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @override
  public async pushRepositoryAuthSettings(settings: { auth: RepositoryAuthentication | null }): Promise<void> {
    const result = await MonitoringProjectAPI.saveRepositoryAuthentication(this._id, this._ver, settings);
    if (result.success && result.data) {
      const { _ver, repository } = result.data;
      this.setState({ _ver, repository });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @override
  public async pushRepositoryBranchSettings(settings: RepositoryDynamicBranch | RepositoryStaticBranch): Promise<void> {
    const result = await MonitoringProjectAPI.saveRepositoryBranch(this._id, this._ver, settings);
    if (result.success && result.data) {
      const { _ver, repository } = result.data;
      this.setState({ _ver, repository });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action
  public async fetchQuietPeriods(
    append: boolean, start: number, count: number, sortBy: QuietPeriodSortOption, ascending: boolean, search: string)
    : Promise<void> {
    const result = await MonitoringProjectAPI
      .loadMonitoringQuietPeriodsSettings(this._id, start, count, sortBy, ascending, search);

    if (result.success && result.data) {
      if (append) {
        this.putQuietPeriods(false, result.data.quietPeriods, false);
      } else {
        this.setState({
          quietPeriods: result.data.quietPeriods,
          quietPeriodsTotal: result.data.total,
          quietPeriodsFiltered: result.data.filtered
        });
      }
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async createQuietPeriod(quietPeriod: NewQuietPeriod): Promise<QuietPeriod> {
    const result = await MonitoringProjectAPI.createMonitoringQuietPeriod(this._id, quietPeriod);
    if (result.success && result.data) {
      return this.putQuietPeriod(true, { ...result.data });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateQuietPeriod(quietPeriod: QuietPeriod): Promise<QuietPeriod> {
    const result = await MonitoringProjectAPI.updateMonitoringQuietPeriodsSettings(this._id, quietPeriod);

    if (result.success && result.data) {
      return this.putQuietPeriod(false, { ...result.data }, false);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteQuietPeriod(quietPeriod: QuietPeriod)
    : Promise<void> {
    const result = await MonitoringProjectAPI.removeMonitoringQuietPeriodsSettings(this._id, quietPeriod);
    if (result.success) {
      this.removeQuietPeriod(quietPeriod);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchExports(): Promise<void> {
    const result = await MonitoringProjectAPI.loadHistoryExports(this._id);
    if (result.success && result.data) {
      this.setState({ historyExports: result.data });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

}

export default { MonitoringProjectState };
