import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringHistoryLogScene = asyncComponent(
  () => import(/* webpackChunkName: "projectHistory" */ "app/mon/project/scenes/MonitoringHistoryLogScene")
    .then(({ default: scene }) => scene),
  "MonitoringHistoryLogScene"
);

export { AsyncMonitoringHistoryLogScene };
export default AsyncMonitoringHistoryLogScene;
