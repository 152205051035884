import * as React from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import BackIcon from "@mui/icons-material/ArrowBackOutlined";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { RouterIconButton } from "components/RouterControls";
import TruncatingText from "components/TruncatingText";

export const SUB_HEADER_HEIGHT = "38px";

type SubHeaderContentPropsChangeHandler =
  (contentProps: SubHeaderSlotContentProps | null, contentExtensionProps: SubHeaderSlotContentExtensionProps | null) => void;

const SubHeaderSlotContentManager = (() => {
  let contentProps: SubHeaderSlotContentProps | null = null;
  let contentExtensionProps: SubHeaderSlotContentExtensionProps | null = null;

  let contentPropsChangeHandler: SubHeaderContentPropsChangeHandler | null = null;

  return {
    contentProps: () => contentProps,
    contentExtensionProps: () => contentExtensionProps,
    register: (handler: SubHeaderContentPropsChangeHandler) => {
      contentPropsChangeHandler = handler;
      handler(contentProps, contentExtensionProps);
    },
    setContentProps: (props: SubHeaderSlotContentProps | null) => {
      contentProps = props;
      if (contentPropsChangeHandler) {
        contentPropsChangeHandler(contentProps, contentExtensionProps);
      }
    },
    setContentExtensionProps: (props: SubHeaderSlotContentExtensionProps | null) => {
      contentExtensionProps = props;
      if (contentPropsChangeHandler) {
        contentPropsChangeHandler(contentProps, contentExtensionProps);
      }
    },
    deregister: () => {
      contentPropsChangeHandler = null;
    }
  };
})();

type SubHeaderSlotContentExtensionProps = {
  backButtonPath?: string,
  actions?: {
    mode?: "replace" | "append" | "prepend",
    items: React.ReactNode
  }
};
const SubHeaderSlotContentExtension = (props: SubHeaderSlotContentExtensionProps) => {
  React.useEffect(() => {
    SubHeaderSlotContentManager.setContentExtensionProps(props);
    return () => { SubHeaderSlotContentManager.setContentExtensionProps(null); };
  });
  return null;
};

type SubHeaderSlotContentProps = {
  header?: string,
  headerValues?: Record<string, string | undefined | null>,
  backButtonPath?: string,
  showDefaultBackButton?: boolean,
  actionItems?: React.ReactNode
};
const SubHeaderSlotContent = (props: SubHeaderSlotContentProps) => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    const pathName = pathname.substring(pathname.lastIndexOf("/") + 1);
    const headerLabel = formatMessage({
      id: props.header || `navigation.subheader.locationPath.${pathName || "root"}`,
      defaultMessage: formatMessage({ id: "navigation.subheader.dashboard", defaultMessage: "undefined" })
    }, props.headerValues);

    SubHeaderSlotContentManager.setContentProps({
      ...props,
      header: headerLabel
    });
  });

  return null;
};

type SubHeaderSlotProps = {};
const SubHeaderSlot: React.FunctionComponent<SubHeaderSlotProps> = (props) => {
  const [contentState, setContentState] = React.useState<{
    contentProps: SubHeaderSlotContentProps | null,
    contentExtensionProps: SubHeaderSlotContentExtensionProps | null
  }>({ contentProps: null, contentExtensionProps: null });

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();


  React.useEffect(() => {
    SubHeaderSlotContentManager.register((contentProps, contentExtensionProps) =>
      setContentState({ contentProps, contentExtensionProps })
    );
    return () => SubHeaderSlotContentManager.deregister();
  }, []);

  const renderContentActionItems = () => contentState.contentProps?.actionItems;
  const renderExtensionActionItems = () => contentState.contentExtensionProps?.actions?.items;
  const renderContentWithExtensionActionItems = () => {
    switch (contentState.contentExtensionProps?.actions?.mode || "append") {
      case "replace":
        return contentState.contentExtensionProps?.actions?.items;
      case "prepend":
        return <React.Fragment>
          {renderExtensionActionItems()}
          {renderContentActionItems()}
        </React.Fragment>;
      default:
        return <React.Fragment>
          {renderContentActionItems()}
          {renderExtensionActionItems()}
        </React.Fragment>;
    }
  };

  const backButtonPath = contentState.contentExtensionProps?.backButtonPath || contentState.contentProps?.backButtonPath;

  return (
    <Box className="xtc_subheader" display="flex" flexDirection="column" flexShrink={0} flexGrow={0}
      height={SUB_HEADER_HEIGHT}
      bgcolor="background.paper"
      borderColor="divider"
      style={{ borderBottomWidth: "1px", borderBottomStyle: "solid" }}
    >
      <Box display="flex" flexDirection="row" flexGrow={1} alignItems="center" justifyContent="center" paddingX={theme.main.contentPadding}>
        {(backButtonPath || contentState.contentProps?.showDefaultBackButton) &&
          <Box marginRight={0.5}  className="xtc_back">
            <RouterIconButton size="small" color="primary"
              title={formatMessage({ id: "navigation.subheader.back" })}
              path={backButtonPath}
              onClick={backButtonPath ? undefined : () => navigate(-1)}
            >
              <BackIcon />
            </RouterIconButton>
          </Box>
        }
        <TruncatingText className="xtc_navTitle" variant="body1" noWrap title={contentState.contentProps?.header}>
          {contentState.contentProps?.header}
        </TruncatingText>

        <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center" marginX={1}>
          {!contentState.contentExtensionProps
            ? renderContentActionItems()
            : renderContentWithExtensionActionItems()
          }
        </Box>
      </Box>
    </Box>
  );
};

export { SubHeaderSlot, SubHeaderSlotContent, SubHeaderSlotContentExtension, SubHeaderSlotContentManager };
