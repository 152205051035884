import * as React from "react";

import clsx from "clsx";
import { Moment } from "moment";
import * as Zone from "moment-timezone";

import Box from "@mui/material/Box";
import { TextFieldProps } from "@mui/material/TextField";
import {
  DesktopDateTimePicker as DateTimePicker, DesktopDateTimePickerProps as PickerProps
} from "@mui/x-date-pickers/DesktopDateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

import PlainButton from "components/button/PlainButton";
import Typography from "components/Typography";

import useAppStores from "app/core/store/useStore";
import Message from "app/localization/components/Message";

const INPUT_FORMAT = "YYYY-MM-DD HH:mm:ss";

function hasAmPm(aLocale?: string, aFormat?: string) {
  if (!!aLocale && !!aFormat) {
    const localTime: string = Zone.utc().locale(aLocale).format(aFormat).toUpperCase();
    return localTime.indexOf("AM") > -1 || localTime.indexOf("PM") > -1;
  }
  return undefined;
};

export interface ShortcutItem {
  label: React.ReactNode;
  getValue: () => Moment;
};

function useDefaultShortcuts(timezone: string): ShortcutItem[] {
  const dateTimeFactory = React.useCallback(() => Zone.tz(timezone).milliseconds(0), [timezone]);
  const shortcuts = React.useMemo(() => [{
    label: <Message messageID="DateTimeInput.shortcuts.now" />,
    getValue: () => dateTimeFactory().add(1, "second")
  }, {
    label: <Message messageID="DateTimeInput.shortcuts.halfHour" />,
    getValue: () => dateTimeFactory().add(30, "minutes")
  }, {
    label: <Message messageID="DateTimeInput.shortcuts.hour" />,
    getValue: () => dateTimeFactory().add(1, "hour")
  }, {
    label: <Message messageID="DateTimeInput.shortcuts.twoHours" />,
    getValue: () => dateTimeFactory().add(2, "hours")
  }, {
    label: <Message messageID="DateTimeInput.shortcuts.threeHours" />,
    getValue: () => dateTimeFactory().add(3, "hours")
  }, {
    label: <Message messageID="DateTimeInput.shortcuts.sixHours" />,
    getValue: () => dateTimeFactory().add(6, "hours")
  }], [dateTimeFactory]);
  return shortcuts;
}

export interface Props extends PickerProps<Moment> {
  locale?: string;
  textFieldProps?: TextFieldProps;
  onChangePassInvalidDateEnabled?: boolean;
  shortcuts?: ShortcutItem[];
  withShortcuts?: boolean;
};
export type ValidationErrorHandler = NonNullable<Props["onError"]>;

const Component: React.FunctionComponent<Props> = (props) => {
  const {
    format = INPUT_FORMAT,
    views = ["year", "month", "day", "hours", "minutes", "seconds"],
    ampm,
    className,
    textFieldProps,
    shortcuts,
    slotProps: inSlotProps,
    timezone = "UTC",
    viewRenderers = { hours: renderTimeViewClock, minutes: renderTimeViewClock, seconds: renderTimeViewClock },
    withShortcuts,
    onChange,
    onError,
    ...rest
  } = props;

  const { localizationStore } = useAppStores();
  const [fieldError, setFieldError] = React.useState<React.ReactNode>();
  const handleError = React.useMemo<ValidationErrorHandler>(() => onError ? onError : (aReason, aValue) => {
    let errMsg = null;
    if (aReason !== null) {
      switch (aReason) {
        case "disablePast":
        case "minDate":
        case "minTime":
          errMsg = <Message messageID="DateTimeInput.invalid.min" />;
          break;
        case "maxDate":
        case "maxTime":
        case "disableFuture":
          errMsg = <Message messageID="DateTimeInput.invalid.max" />;
          break;
        case "invalidDate":
          errMsg = <Message messageID="DateTimeInput.invalid.date" />;
          break;
      }
    }

    setFieldError(errMsg);
  }, [onError]);

  const defaultShortcuts = useDefaultShortcuts(timezone);
  const selectionShortcuts = shortcuts ?? (withShortcuts ? defaultShortcuts : undefined);

  const { textField, ...other } = (inSlotProps ?? {});
  const textFieldError = !!fieldError || textFieldProps?.error;
  const slotProps: Props["slotProps"] = {
    ...other,
    textField: {
      error: textFieldProps?.error ?? textFieldError,
      helperText: textFieldProps?.helperText ?? fieldError,
      className: clsx(textFieldProps?.className, "xtc_dateTimeInput"),
      FormHelperTextProps: {
        ...(textFieldProps?.FormHelperTextProps),
        className: clsx(textFieldError ? "xtc_error" : "xtc_help", textFieldProps?.FormHelperTextProps?.className)
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <DateTimePicker
        className={className}
        format={format}
        views={views}
        ampm={ampm ?? hasAmPm(props.locale ?? localizationStore.locale, format)}
        onChange={(date, context) => {
          const isValid = date?.isValid() && context.validationError === null;
          onChange?.(isValid || props.onChangePassInvalidDateEnabled ? date : null, context);
          if (!isValid) {
            handleError(context.validationError ?? "invalidDate", date ?? null);
          }
        }}
        slotProps={slotProps}
        onError={handleError}
        viewRenderers={viewRenderers}
        timezone={timezone}
        {...rest}
      />
      {selectionShortcuts && selectionShortcuts.length > 0 &&
        <Box
          className={clsx(className, "xtc_dateTimeShortcuts")}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginY: 1,
            "& > *:not(:last-child)": {
              marginRight: 1
            }
          }} >
          {selectionShortcuts.map((shortcut, idx) =>
            <PlainButton
              key={`picker_shortcut_${idx}`}
              hoverColor="secondary"
              sx={{ color: "primary.main" }}
              onClick={() => onChange?.(shortcut.getValue(), { validationError: null })}
            >
              <Typography variant="button">{shortcut.label}</Typography>
            </PlainButton>
          )}
        </Box>
      }
    </Box>
  );
};

const DateTimeInput = Component;

export default DateTimeInput;
export { DateTimeInput };
