import { ProjectRoles } from "app/core/project/types";

const orderedRoles: ProjectRoles[] = ["Guest", "Reviewer", "Tester", "TestManager", "Admin"];

export type Privilege = "<" | "<=" | "=" | ">=" | ">";

export function hasProjectRole(role: ProjectRoles, required: ProjectRoles, modifier?: Privilege): boolean {
  if (role && required) {
    const roleIndex = orderedRoles.indexOf(role);
    const requiredIndex = orderedRoles.indexOf(required);
    switch (modifier) {
      case "<": return roleIndex < requiredIndex;
      case "<=": return roleIndex <= requiredIndex;
      case "=": return roleIndex === requiredIndex;
      case ">": return roleIndex > requiredIndex;
      default: return roleIndex >= requiredIndex;
    }
  } else {
    return false;
  }
}

export function validateProjectRole<R>(role: ProjectRoles, required: ProjectRoles, returnOnValid: R, modifier?: Privilege): R | undefined {
  return hasProjectRole(role, required, modifier) ? returnOnValid : undefined;
}
