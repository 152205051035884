import OrgIcon from "@mui/icons-material/BusinessOutlined";

import Navigation, { NavigationProps } from "app/core/components/Navigation";
import { routeToTenant } from "app/routing/routes";

type ProjectNavigationProps = NavigationProps & {
    tenantId: string;
    tenantName: string;
};

export const ProjectNavigation: React.FunctionComponent<ProjectNavigationProps> = (props) =>
    <Navigation header={props.header} entries={
        [
            ...props.entries,
            {
                divider: true
            },
            {
                icon: <OrgIcon />,
                className: "xtc_backToOrg",
                labelId: "navigation.dashboard.org",
                labelValues: { name: props.tenantName },
                path: routeToTenant(props.tenantId)
            }
        ]
    } />;
