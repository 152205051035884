import { action, makeObservable, observable } from "mobx";

import { SystemAdminAPI } from "app/admin/SystemAdminAPI";
import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { UserState } from "app/core/user/store/UserState";
import { UserData, UserStates, UserSystemRole } from "app/core/user/types";

type State = {
  readonly users: UserData[];
  readonly usersTotal: number;
  readonly usersFiltered: number;
};

class AdminUserStore extends AbstractState<State> implements State {

  private static readonly initialState: State = { users: [], usersTotal: 0, usersFiltered: 0 };

  @observable public readonly users: UserState[];
  @observable public readonly usersTotal: number;
  @observable public readonly usersFiltered: number;

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState(AdminUserStore.initialState));

    this.appStores = appStores;
  }


  public initStore(): void {
    this.setState(AdminUserStore.initialState);
  }

  public findUser(id: string): UserState | null {
    return this.users.find((each) => each._id === id) || null;
  }

  @action.bound
  public putUser(prepend: boolean, user: UserData): UserState {
    const index = this.users.findIndex((each) => each._id === user._id);
    const userState = new UserState(user, this.appStores);
    if (index > -1) {
      this.users[index] = userState;
      return this.users[index];
    } else {
      if (prepend) {
        this.users.splice(0, 0, userState);
        return this.users[0];
      } else {
        this.users.push(userState);
        return this.users[this.users.length - 1];
      }
    }
  }

  @action.bound
  public putUsers(append: boolean, users: UserData[]): UserState[] {
    if (!append) {
      this.setState({ users: [] });
    }
    return users.map((each) => this.putUser(false, each));
  }

  @action.bound
  public removeUser(id: string): void {
    const index = this.users.findIndex((each) => each._id === id);
    if (index > -1) {
      this.users.splice(index, 1);
    }
  }

  @action.bound
  public async fetchUsers(append: boolean, start: number, count: number, sortBy: string, search: string): Promise<void> {
    const result = await SystemAdminAPI.listUsers(start, count, sortBy, search);
    if (result.success && result.data) {
      this.putUsers(append, result.data.users);
      this.setState({
        usersTotal: result.data.total,
        usersFiltered: result.data.count
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushUser(firstName: string, lastName: string, email: string, role: UserSystemRole, state: UserStates): Promise<UserState> {
    const result = await SystemAdminAPI.createUser(firstName, lastName, email, role, state);
    if (result.success && result.data) {
      const user = this.putUser(true, result.data.user);
      this.setState({
        usersTotal: this.usersTotal + 1,
        usersFiltered: this.usersFiltered + 1
      });
      return user;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateUser(id: string, firstName: string, lastName: string, email: string, role: UserSystemRole, state: UserStates)
    : Promise<UserState> {
    const user = this.findUser(id);
    if (user) {
      const result = await SystemAdminAPI.saveUserDetails(id, user._ver, firstName, lastName, email, role, state);
      if (result.success && result.data) {
        user.setState(result.data.user);
        return user;
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    } else {
      throw new Error("No User for id: " + id);
    }
  }

  @action.bound
  public async deleteUser(id: string): Promise<void> {
    const user = this.findUser(id);
    if (user) {
      const result = await SystemAdminAPI.removeUsers([{ id, version: user._ver }]);
      if (result.success) {
        this.removeUser(id);
        this.setState({
          usersTotal: this.usersTotal - 1,
          usersFiltered: this.usersFiltered - 1
        });
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    } else {
      throw new Error("No User for id: " + id);
    }
  }

  @action.bound
  public async updateUserActive(id: string, active: boolean): Promise<void> {
    const user = this.findUser(id);
    if (user) {
      const result = active
        ? await SystemAdminAPI.unlockUsers([{ id, version: user._ver }])
        : await SystemAdminAPI.lockUsers([{ id, version: user._ver }]);

      if (result.success && result.data) {
        user.setState(result.data[0]);
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    } else {
      throw new Error("No User for id: " + id);
    }
  }

  @action.bound
  public async unbindFromSSO(id: string): Promise<void> {
    const user = this.findUser(id);
    if (user) {
      const result = await SystemAdminAPI.unbindSSO({ id, version: user._ver });
      if (result.success && result.data) {
        user.setState(result.data.user);
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    } else {
      throw new Error(`No User for id: ${id}`);
    }
  }

  @action.bound
  public async unbindFrom2FA(id: string): Promise<void> {
    const user = this.findUser(id);
    if (user) {
      const result = await SystemAdminAPI.unbind2FA({ id, version: user._ver });
      if (result.success && result.data) {
        user.setState(result.data.user);
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    } else {
      throw new Error(`No User for id: ${id}`);
    }
  }

  @action.bound
  public async resetPassword(id: string): Promise<void> {
    const user = this.findUser(id);
    if (user) {
      const result = await SystemAdminAPI.resetPassword({ id, version: user._ver });
      if (result.success && result.data) {
        user.setState(result.data.user);
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    } else {
      throw new Error(`No User for id: ${id}`);
    }
  }
}

export { AdminUserStore };
export default AdminUserStore;
