import * as React from "react";

import { Box, IconButton, MobileStepper } from "@mui/material";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { PlainButton } from "components/button/PlainButton";
import TruncatingText from "components/TruncatingText";

import { Message as CommonMessage, SystemMessage } from "../types";

import { MessageDetails } from "./MessageDetails";

type Props = {
  messages: CommonMessage[];
  viewSeconds: number;
  onDetailsClose: (id: string) => void
};

const MessageCarousel: React.ComponentType<Props> = (props) => {

  const [messageIdx, setMessageIdx] = React.useState(0);
  const [messageDetails, setMessageDetails] = React.useState<CommonMessage>();
  const [stepIdx, setStepIdx] = React.useState(0);

  const stepCount = Math.min(props.messages.length, 10);

  function nextMessage(): void {
    setMessageIdx((index) => index + 1 > props.messages.length - 1 ? 0 : index + 1);
    setStepIdx((index) => index + 1 > stepCount - 1 ? 0 : index + 1);
  }

  function previoustMessage(): void {
    setMessageIdx((index) => index - 1 < 0 ? props.messages.length - 1 : index - 1);
    setStepIdx((index) => index - 1 < 0 ? stepCount - 1 : index - 1);
  }

  React.useEffect(() => {
    const timer = setInterval(nextMessage, props.viewSeconds * 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.messages.length, props.viewSeconds]);


  const message = props.messages[Math.min(messageIdx, props.messages.length - 1)];
  return !message ? null :
    <React.Fragment>
      <Box display="flex" flexDirection="row" bgcolor="secondary.main" color="secondary.contrastText" className="xtc_messageCarousel">
        {props.messages.length > 1 &&
          <IconButton
            className="xtc_carouselNav_prev"
            color="inherit"
            size="small"
            onClick={() => previoustMessage()}
          >
            <ArrowLeftIcon />
          </IconButton>
        }

        <PlainButton
          hoverColor="secondary"
          style={{ width: "100%" }}
          focusRipple={true}
          onClick={() => setMessageDetails(message)}
        >
          <Box key={"xtc_messageCount_" + props.messages.length}
            display="flex"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" alignItems="center" marginBottom={props.messages.length > 1 ? 0.5 : 0}>
              <TruncatingText variant="subtitle2" noWrap={true}>
                {message.subject}
              </TruncatingText>
              {props.messages.length > 1 &&
                <MobileStepper
                  // classes={{ dotActive: classes.dotActive }}
                  variant="dots"
                  steps={stepCount}
                  position="static"
                  activeStep={stepIdx}
                  backButton={null}
                  nextButton={null}
                  style={{
                    backgroundColor: "inherit",
                    padding: "0px"
                  }}
                />
              }
            </Box>
          </Box>
        </PlainButton>

        {props.messages.length > 1 &&
          <IconButton
            className="xtc_carouselNav_next"
            color="inherit"
            size="small"
            onClick={nextMessage}
          >
            <ArrowRightIcon />
          </IconButton>
        }
      </Box>

      {messageDetails && messageDetails.type === "SYSTEM" &&
        <MessageDetails
          message={messageDetails as SystemMessage}
          onClose={() => {
            props.onDetailsClose(messageDetails._id);
            setMessageDetails(undefined);
          }}
        />
      }
    </React.Fragment>;
};

export { MessageCarousel };
export default MessageCarousel;
