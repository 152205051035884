import * as React from "react";

type Props = {
  elementProps?: { style?: Partial<React.CSSProperties> }
  stopPropagation?: boolean
  onAccept?: () => void
  onCancel?: () => void
};

export default class InputHandler extends React.PureComponent<Props> {

  public render(): React.ReactNode {
    return (
      <div
        {...this.props.elementProps}
        onKeyDown={(e) => {
          const target: any = e.target;
          // e.preventDefault();
          if (this.props.stopPropagation) {
            e.stopPropagation();
          }

          if (e.keyCode === 27) {
            if (this.props.onCancel) {
              this.props.onCancel();
            }
          } else if (e.keyCode === 13 && !(target && target.localName === "textarea")) {
            if (this.props.onAccept) {
              this.props.onAccept();
            }
          }
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
