import * as React from "react";
import { Outlet, useParams } from "react-router-dom";

import { observer } from "mobx-react";

import Box from "@mui/material/Box";

import ErrorNotification from "components/error/Error";
import Loading from "components/loading/Loading";

import { UserLockOut } from "app/core/components/UserLockOut";
import useAppStores from "app/core/store/useStore";
import TenantSideNavigation from "app/core/tenant/components/TenantSideNavigation";
import { LOCK_OUT_MESSAGE } from "app/core/tenant/types";
import { useFetch } from "app/utils/remote";

const TenantRoute: React.FunctionComponent = observer(() => {
  const { tenantsStore } = useAppStores();
  const { tid } = useParams();

  const tenant = !!tid ? tenantsStore.findTenant(tid) : null;

  React.useEffect(() => {
    tenantsStore.setCurrentTenant(tenant);
    return () => {
      tenantsStore.setCurrentTenant(null);
    };
  }, [tenant]);

  const loadStore = useFetch(() => tenantsStore.fetchMyTenant(tid!), [tid]);

  if (!loadStore.fetched) {
    return <Loading message="loading" />;
  }
  if (!tenant || loadStore.error?.isHandledResponseError) {
    return null;
  }
  if (tenant?.isLocked || loadStore.error?.message.startsWith(LOCK_OUT_MESSAGE)) {
    const details = loadStore.error?.message ?? tenant?.lockDetails ?? LOCK_OUT_MESSAGE;
    return <UserLockOut details={details} />;
  }
  if (loadStore.error) {
    return <ErrorNotification message={loadStore.error.message} />;
  }

  return (
    <React.Fragment>
      <TenantSideNavigation
        tenantId={tenant._id}
        tenantName={tenant.name}
        tenantIsAdmin={tenant.isAdmin}
        tenantIsBillingAdmin={tenant.isBillingAdmin}
      />
      <Box display="flex" flexDirection="column" flexGrow={1} flexShrink={1}>
        <Outlet />
      </Box>
    </React.Fragment>
  );
});

export { TenantRoute };
export default TenantRoute;

export const RequiresTenantAdmin: React.FunctionComponent = () => {
  const { tenantsStore: { currentTenant } } = useAppStores();
  if (currentTenant && currentTenant.isAdmin) {
    return <Outlet />;
  }
  return null;
};

export const RequiresTenantBillingAdmin: React.FunctionComponent = () => {
  const { tenantsStore: { currentTenant } } = useAppStores();
  if (currentTenant && currentTenant.isBillingAdmin) {
    return <Outlet />;
  }
  return null;
};
