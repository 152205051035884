import * as React from "react";

type ContentPropsChangeHandler = (contentProps: SideNavigationSlotContentProps | null) => void;

const SlotContentManager = (() => {
  let contentProps: SideNavigationSlotContentProps | null = null;
  let contentPropsChangeHandler: ContentPropsChangeHandler | null = null;

  return {
    register: (handler: ContentPropsChangeHandler) => {
      contentPropsChangeHandler = handler;
      handler(contentProps);
    },
    setContentProps: (props: SideNavigationSlotContentProps | null) => {
      contentProps = props;
      if (contentPropsChangeHandler) {
        contentPropsChangeHandler(contentProps);
      }
    },
    deregister: () => {
      contentPropsChangeHandler = null;
    }
  };
})();

type SideNavigationSlotContentProps = {
  renderContent: (sideNavigationSlotProps: SideNavigationSlotProps) => React.ReactNode;
};
const SideNavigationSlotContent: React.FunctionComponent<SideNavigationSlotContentProps> = (props) => {

  React.useEffect(() => {
    SlotContentManager.setContentProps(props);
  });

  return null;
};

type SideNavigationSlotProps = {
  expanded: boolean;
  onSelected: () => void;
};
const SideNavigationSlot: React.FunctionComponent<SideNavigationSlotProps> = (props) => {

  const [contentProps, setContentProps] = React.useState<SideNavigationSlotContentProps | null>(null);

  React.useEffect(() => {
    SlotContentManager.register(setContentProps);
    return () => {
      SlotContentManager.deregister();
    };
  }, []);

  // require fragment to workaround function component return type issue (is just expecting an element or null to be returned)
  return <React.Fragment>{contentProps?.renderContent(props)}</React.Fragment>;
};

export { SideNavigationSlot, SideNavigationSlotContent, SlotContentManager };
