import { ProjectData } from "app/core/project/types";
import { TenantData } from "app/core/tenant/types";
import { AuthenticationResult, ProjectVisit, SuccessResult, TwoFAConfig, TwoFASetupSetupResponse, UserData } from "app/core/user/types";
import { imageUrlForUser } from "app/routing/routes";
import { APIResult, AsyncAPIResult, fetch, FetchResult } from "app/utils/remote";

type DashboardResult = APIResult<{
  tenants: TenantData[];
  projects: ProjectData[];
}>;

type VisitsResult = APIResult<{
  visits: ProjectVisit[]
}>;

class UserAPI {

  public static isLoggedIn(): Promise<APIResult<SuccessResult>> {
    return fetch<APIResult<SuccessResult>>({ url: "/api/core/account/login", method: "GET" });
  }

  public static loginUser(user: string, pass: string, token: string, unlockToken?: string):
    Promise<APIResult<AuthenticationResult>> {
    return fetch<APIResult<AuthenticationResult>>({
      url: "/api/core/account/login",
      method: "POST",
      data: {
        email: user,
        password: pass,
        authToken: token,
        unlockToken
      }
    });
  }

  public static loginUser2FA(code: string, isRecovery: boolean): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({
      url: "/api/core/account/2fa-login",
      method: "POST",
      data: {
        code: code.replace(/\s/g, ""),
        isRecovery
      }
    });
  }

  public static change2FA(version: number, enabled: boolean, currentPassword: string, currentCode?: string):
    Promise<APIResult<TwoFASetupSetupResponse>> {
    return fetch<APIResult<TwoFASetupSetupResponse>>({
      url: "/api/core/account/2fa-step1",
      method: "POST",
      data: {
        version,
        enabled,
        password: currentPassword,
        code: currentCode
      }
    });
  }

  public static confirm2FA(version: number, code: string, password?: string): Promise<APIResult<TwoFAConfig>> {
    return fetch<APIResult<TwoFAConfig>>({
      url: "/api/core/account/2fa-confirm",
      method: "POST",
      data: {
        version,
        code,
        password
      }
    });
  }

  public static getAuthToken(): Promise<string> {
    return fetch<FetchResult<{ token: string }>>({ url: "/api/core/account/authtoken" }).then((result) => result.token);
  }

  public static getUserDashboard(): Promise<DashboardResult> {
    return fetch<DashboardResult>({ url: "/api/core/account/dashboard" });
  }

  public static getProjectVisits(): Promise<VisitsResult> {
    return fetch<VisitsResult>({ url: "/api/core/account/projectVisits"});
  }

  public static uploadPicture(user: UserData | string, picture: File): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: imageUrlForUser(user), method: "POST", data: { file: picture } });
  }

  public static deletePicture(user: UserData | string): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: imageUrlForUser(user), method: "DELETE" });
  }

  public static saveLogin(email: string, password: string): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: "/api/core/account/login", method: "PUT", data: { email, password } });
  }

  public static saveProfile(firstName: string, lastName: string): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: "/api/core/account/profile", method: "PUT", data: { firstName, lastName } });
  }

  public static savePreferences(timezone: string, language: string): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: "/api/core/account/preferences", method: "PUT", data: { timezone, language } });
  }

  public static changePhone(phoneNumber: string): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: "/api/core/account/phone", method: "PUT", data: { phoneNumber } });
  }

  public static verifyPhone(phoneNumber: string, verificationCode: string): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: "/api/core/account/verifyphone", method: "POST", data: { phoneNumber, verificationCode } });
  }

  public static removePhone(phoneNumber: string): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: "/api/core/account/phone", method: "DELETE", data: { phoneNumber } });
  }

  public static changePassword(curPwd: string, newPwd: string): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({ url: "/api/core/account/changepass", method: "POST", data: { passwd: curPwd, newPasswd: newPwd } });
  }

  public static logoutUser(): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({ url: "/api/core/account/logout", method: "POST" });
  }

  public static createAccount(
    firstName: string, lastName: string, email: string, password: string, passwordRepeat: string, locale: string, timezone: string)
    : Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: "/api/core/account", method: "POST", data: {
        firstName, lastName, email, password, passwordRepeat, locale, timezone
      }
    });
  }

  public static createExternalAccount(
    firstName: string, lastName: string, email: string, issuer: string, subject: string, locale: string, timezone: string)
    : Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: "/api/core/externalAccount", method: "POST", data: {
        firstName, lastName, email, issuer, subject, locale, timezone
      }
    });
  }

  public static createInvitationAccount(
    token: string, firstName: string, lastName: string, email: string, password: string, passwordRepeat: string,
    locale: string, timezone: string): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: "/api/core/invitationAccount", method: "POST", data: {
        token, firstName, lastName, email, password, passwordRepeat, locale, timezone
      }
    });
  }

  public static activateAccount(token: string): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({ url: "/api/core/account/activate", method: "POST", data: { token } });
  }

  public static resetPassword(email: string): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({ url: "/api/core/account/resetpass", method: "POST", data: { email } });
  }

  public static newPassword(token: string, password: string, passwordRepeat: string)
    : AsyncAPIResult<never, { password?: string, passwordRepeat?: string }, string> {
    return fetch({
      url: "/api/core/account/newpass",
      method: "POST",
      data: { token, password, passwordRepeat }
    });
  }

  public static unbindSSO(password: string, version: number): Promise<APIResult<UserData>> {
    return fetch<APIResult<UserData>>({
      url: "/api/core/account/unbindSSO",
      method: "POST",
      data: { password, version }
    });
  }
}

export { UserAPI };
export default UserAPI;
