import { AdminUserStore } from "app/admin/store/AdminUserStore";
import { AdminTenantStore } from "app/admin/store/AdminTenantStore";
import { AdminProjectStore } from "app/admin/store/AdminProjectStore";
import { AppStores } from "app/core/store/AppStores";

class AdminStores {

  public readonly tenantsStore: AdminTenantStore;
  public readonly projectsStore: AdminProjectStore;
  public readonly userStore: AdminUserStore;

  public constructor(appStores: AppStores) {
    this.tenantsStore = new AdminTenantStore(appStores);
    this.projectsStore = new AdminProjectStore(appStores);
    this.userStore = new AdminUserStore(appStores);
  }
}

export { AdminStores };
export default AdminStores;
