import { SlackConfigUpdate } from "components/slackConfig/SlackConfiguration";

import {
  ProjectData, ProjectMembership, ProjectMembershipSortOption, ProjectRoles, ProjectSortoptions, ProjectStates
} from "app/core/project/types";
import { UserData } from "app/core/user/types";
import { APIResult, AsyncAPIResult, fetch } from "app/utils/remote";

type ProjectsResult = {
  projects: ProjectData[],
  total: number,
  count: number
};

type MembershipsResult = {
  count: number,
  total: number,
  members: ProjectMembership[]
};

type RemoveMembershipResult = {
  total: number
};

type ProjectMemberSuggestResult = {
  matches: UserData[]
};

type AddApiCredentialsResult = {
  project: ProjectData,
  apiCredentialsSecret: string
};

export class ProjectAPI {

  public static async filteredTenantProjects(
    tenantId: string, start: number, count: number, sortBy: ProjectSortoptions, searchBy: string, softDeleted: boolean
  ): Promise<APIResult<ProjectsResult>> {
    return fetch<APIResult<ProjectsResult>>({
      url: `/api/core/tenants/${tenantId}/projects`,
      data: { start, size: count, sortby: sortBy, q: searchBy, softDeleted }
    });
  }

  public static async listProjects(): Promise<APIResult<ProjectData[]>> {
    return fetch({ url: "/api/core/projects" });
  }

  public static async listTenantProjects(tenantId: string): Promise<APIResult<ProjectData[]>> {
    return fetch({ url: `/api/core/tenants/${tenantId}/myprojects` });
  }

  public static removeTenantProjects(tenantId: string, projects: Array<{ id: string, version: number }>): Promise<APIResult<never>> {
    return fetch({ url: `/api/core/tenants/${tenantId}/projects`, method: "DELETE", data: projects });
  }

  public static restoreTenantProject(tenantId: string, projectId: string): Promise<APIResult<ProjectData>> {
    return fetch({ url: `/api/core/tenants/${tenantId}/projects/${projectId}/restore`, method: "POST" });
  }

  public static loadProjectDetails(id: string): Promise<APIResult<ProjectData>> {
    return fetch({ url: `/api/core/projects/${id}` });
  }

  public static async cloneProject(
    projectId: string,
    name: string,
    baseShortName: string,
    description: string,
    state: ProjectStates,
    adminUser: string
  ): AsyncAPIResult<ProjectData,
    { name?: string, baseShortName?: string, description?: string, adminUser?: string, state?: string },
    string> {
    return await fetch({
      url: `/api/core/projects/${projectId}/clone`,
      method: "POST",
      data: { name, baseShortName, description, state, adminUser }
    });
  }

  public static saveProjectDetails(
    projectId: string, projectVersion: number,
    name: string, baseShortName: string, description: string, state: ProjectStates, adminUser: string)
    : Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}`,
      method: "PUT",
      data: { version: projectVersion, name, baseShortName, description, state, adminUser }
    });
  }

  public static saveProjectState(projectId: string, projectVersion: number, state: ProjectStates)
    : Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/state`,
      method: "PUT",
      data: { version: projectVersion, state }
    });
  }

  public static toggleProjectActiveState(projectId: string, projectVersion: number, state: ProjectStates)
    : Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/toggleActive`,
      method: "PUT",
      data: { version: projectVersion, state }
    });
  }

  public static updateProjectDocumentation(projectId: string, projectVersion: number, documentation: string)
    : Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/edit/documentation`,
      method: "PUT",
      data: { version: projectVersion, documentation }
    });
  }

  public static uploadPicture(projectId: string, projectVersion: number, picture: File): Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/picture`,
      method: "POST",
      data: { file: picture, version: projectVersion }
    });
  }


  public static loadMemberships(
    projectId: string,
    start: number, count: number, sortBy: ProjectMembershipSortOption, ascending: boolean, search: string
  ): Promise<APIResult<MembershipsResult>> {
    const data: any = {};
    data.start = start;
    data.size = count;
    if (sortBy !== "none") {
      data.sortby = (ascending ? "" : "-") + sortBy;
    }
    data.q = search;

    return fetch<APIResult<MembershipsResult>>({ url: `/api/core/projects/${projectId}/members`, data });
  }

  public static addProjectMembers(
    projectId: string,
    projectVersion: number,
    emails: string[],
    role: ProjectRoles,
    inviteUnknownUser: boolean
  ): Promise<APIResult<ProjectMembership[]>> {
    return fetch<APIResult<ProjectMembership[]>>({
      url: `/api/core/projects/${projectId}/members`,
      method: "POST",
      data: { emails, role, version: projectVersion, inviteUnknownUser }
    });
  }

  public static async inviteUsersToProject(projectId: string, version: number, emails: string[], role: ProjectRoles)
    : Promise<APIResult<ProjectMembership[]>> {
    return fetch<APIResult<ProjectMembership[]>>({
      url: `/api/core/projects/${projectId}/members?invite`,
      method: "POST",
      data: { version, emails, role }
    });
  }

  public static async inviteProjectMember(projectId: string, memberId: string, memberVersion: number): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/core/projects/${projectId}/inviteMember`,
      method: "POST",
      data: { id: memberId, version: memberVersion }
    });
  }

  public static updateProjectMember(
    projectId: string,
    memberId: string,
    memberVersion: number,
    email: string,
    role: ProjectRoles
  ): Promise<APIResult<ProjectMembership>> {
    return fetch<APIResult<ProjectMembership>>({
      url: `/api/core/projects/${projectId}/members/${memberId}`,
      method: "PUT",
      data: { email, role, version: memberVersion }
    });
  }

  public static removeProjectMembers(
    projectId: string,
    members: Array<{ version: number, id: string }>
  ): Promise<APIResult<RemoveMembershipResult>> {
    return fetch<APIResult<RemoveMembershipResult>>({
      url: `/api/core/projects/${projectId}/members`,
      method: "DELETE",
      data: members
    });
  }

  public static suggestProjectMembers(projectId: string, query: string): Promise<APIResult<ProjectMemberSuggestResult>> {
    return fetch<APIResult<ProjectMemberSuggestResult>>({ url: `/api/core/projects/${projectId}/members/suggest`, data: { q: query } });
  }

  public static updateProjectFavorite(projectId: string, favorite: boolean): Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/favorite`,
      method: "POST",
      data: {
        favorite
      }
    });
  }

  public static updateSlackIntegration(projectId: string, version: number, settings: SlackConfigUpdate): Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/integrations/slack`,
      method: "POST",
      data: {
        ...settings,
        version
      }
    });
  }

  public static async sendSlackTestMessage(projectId: string, settings: SlackConfigUpdate): Promise<void> {
    await fetch({
      url: `/api/core/projects/${projectId}/integrations/slack/test`,
      method: "POST",
      data: settings
    });
  }

  public static addApiCredentials(projectId: string, apiCredentialsName: string, apiId: string, scopes: string[], expire?: number) {
    return fetch<APIResult<AddApiCredentialsResult>>({
      url: `/api/core/projects/${projectId}/apiCredentials/add`,
      method: "POST",
      data: {
        apiCredentialsName,
        apiVersion: apiId,
        scopes,
        expire
      }
    });
  }

  public static deleteApiCredentials(projectId: string, apiCredentialsId: string) {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/apiCredentials/delete`,
      method: "POST",
      data: {
        apiCredentialsId
      }
    });
  }

  public static toggleApiCredentialsEnabled(projectId: string, apiCredentialsId: string, enabled: boolean) {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/apiCredentials/toggleEnabled`,
      method: "POST",
      data: {
        apiCredentialsId,
        enabled
      }
    });
  }

}
