import { asyncComponent } from "components/AsyncComponent";

const AsyncTenantAuditLogScene = asyncComponent(
  () => import(
    /* webpackChunkName: "tenantAuditLogs" */ "app/core/tenant/scenes/TenantAuditLogScene"
  ).then(({ default: scene }) => scene),
  "TenantAuditLogScene"
);

export { AsyncTenantAuditLogScene };
export default AsyncTenantAuditLogScene;
