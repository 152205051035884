import { AdminStores } from "app/admin/store/AdminStores";
import { MessageStore } from "app/core/message/store/MessageStore";
import { ProjectStore } from "app/core/project/store/ProjectStore";
import { TenantStore } from "app/core/tenant/store/TenantStore";
import { UserStore } from "app/core/user/store/UserStore";
import { LocalizationStore } from "app/localization";
import { RouterStore } from "app/routing/store/RouterStore";

import { ProjectHistoryStore } from "app/mon/project/store/ProjectHistoryStore";

import { LoadTestsStore } from "app/lt/project/store/LoadTestsStore";

import AuditLogStore from "../auditLog/store/AuditLogStore";

import NotificationStore from "./NotificationStore";
import { SessionStore } from "./SessionStore";

class AppStores {

  public readonly userStore: UserStore = new UserStore(this);
  public readonly auditLogStore: AuditLogStore = new AuditLogStore(this);
  public readonly localizationStore: LocalizationStore = new LocalizationStore(this);
  public readonly tenantsStore: TenantStore = new TenantStore(this);
  public readonly projectStore: ProjectStore = new ProjectStore(this);
  public readonly projectHistory: ProjectHistoryStore = new ProjectHistoryStore(this);
  public readonly loadTestsStore: LoadTestsStore = new LoadTestsStore(this);
  public readonly routerStore: RouterStore = new RouterStore(this);
  public readonly sessionStore: SessionStore = new SessionStore(this);
  public readonly messageStore: MessageStore = new MessageStore(this);
  public readonly notificationStore: NotificationStore = new NotificationStore(this);

  public readonly adminStores: AdminStores = new AdminStores(this);
}

// create application-global state
export const appStores = new AppStores();

export { AppStores };
export default AppStores;
