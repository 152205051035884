import * as React from "react";

import clsx from "clsx";

import Box from "@mui/material/Box";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import { Message } from "app/localization/components/Message";

const PREFIX = "XtcCheckToggle";

const classes = {
  root: `${PREFIX}-root`
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`& .${classes.root}`]: {
    "label + &": {
      marginTop: theme.spacing(2)
    }
  }
}));

type Props = Partial<{
  id: string;
  className: string;
  labelID: string;
  labelDefault?: string;
  labelColor?: string;
  controlColor?: "default" | "primary" | "secondary";
  hint: React.ReactNode;
  icon: React.ReactElement<any>;
  checkedLabelID: string;
  unCheckedLabelID: string;
  toggle: boolean;
  reverse: boolean;
  defaultChecked: boolean;
  disabled: boolean;
  onChange: (checked: boolean) => void;
}>;
type State = { checked: boolean };
export default class CheckToggle extends React.PureComponent<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = { checked: !!props.defaultChecked };
  }

  public componentDidUpdate(prevProps: Props) {
    this.setState({
      checked: !!this.props.defaultChecked
    });
  }

  private _onChange(): void {
    const { onChange } = this.props;
    const checked = !this.state.checked;
    this.setState({ checked });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onChange && onChange(checked);
  }

  private getLabel(): string {
    const { checkedLabelID, unCheckedLabelID, labelID } = this.props;
    const { checked } = this.state;

    if (checked && checkedLabelID) {
      return checkedLabelID;
    } else if (!checked && unCheckedLabelID) {
      return unCheckedLabelID;
    } else {
      return labelID || "";
    }
  }

  private hasLabel(): boolean {
    return !!this.props.labelID || !!this.props.checkedLabelID || !!this.props.unCheckedLabelID;
  }

  public render(): React.ReactNode {
    const label = this.hasLabel() ? (
      <Box flexDirection="row" alignContent="center" display="flex" color={this.props.labelColor}>
        {this.props.icon}
        <Box paddingLeft={this.props.icon ? 0.5 : undefined} flexGrow={1} className="xtc_label">
          <Message messageID={this.getLabel()} default={this.props.labelDefault} />
          {this.props.hint}
        </Box>
      </Box >
    ) : undefined;

    const controlColor = this.props.controlColor || "primary";
    const controlClassName = "xtc_control";
    const control = !!this.props.toggle
      ? (
        <Switch
          className={controlClassName}
          disabled={!this.props.onChange}
          color={controlColor}
          checked={this.state.checked}
          onChange={!this.props.onChange ? undefined : (e: any) => this._onChange()}
        />
      )
      : (
        <CheckBox
          className={controlClassName}
          disabled={!this.props.onChange}
          color={controlColor}
          checked={this.state.checked}
          onChange={!this.props.onChange ? undefined : (e: any) => this._onChange()}
        />
      );

    return (
      <StyledFormControlLabel
        id={this.props.id}
        className={clsx("xtc_toggle", classes.root, this.props.className)}
        color={!this.props.onChange ? "grey" : undefined}
        control={control}
        label={label}
        labelPlacement={this.props.reverse ? "start" : "end"}
      />
    );
  }
}

