import { ApiCredentials, ApiVersion, Base, SlackConfigData } from "app/core/types";
import { UserData } from "app/core/user/types";

export type TenantRoles = "None" | "Guest" | "User" | "BillingAdmin" | "Admin";
export type TenantStates = "Active" | "Locked";

export type TenantSortoptions = "name" | "email" | "role" | "state" | "_id";

export type TenantMembership = Base & {
  user: UserData
  role: TenantRoles
  isLocked: boolean
};

export type TenantMemberships = {
  members: TenantMembership[];
  total: number;
  count: number;
};

export type Contract = {
  from: number,
  to: number | null,
  info: string | null
};

export type TenantData = Base & {
  name: string;
  baseShortName: string;
  description: string;
  deleteDateTime?: number;
  adminUser: string;
  color?: string;
  hasLogo: boolean;
  state: TenantStates;
  role?: TenantRoles;
  favorite?: boolean;
  isLocked?: boolean;
  platformPrivileges: PlatformPrivileges;
  requiresSSO: boolean;
  permittedIssuers?: string[];
  requires2FA: boolean;
  allowsUserInvitation: boolean;
  contract?: Contract;
  projectCount?: number;
  slackIntegration?: SlackConfigData;
  apiCredentials: ApiCredentials[];
  availableApiVersions: ApiVersion[];
};

export interface SystemTenantData extends TenantData {
  shortName: string;
}

export type PlatformPrivileges = {
  canCreateLoadTest?: boolean;
  canCreateMonitoring?: boolean;
  allowsCustomMachines?: boolean;
  liveMetrics?: boolean;
  ltOtelEnabled?: boolean;
};

export type SaveTenantData = {
  name: string;
  baseShortName: string;
  description: string;
  adminUser: string | null;
  state: TenantStates;
  platformPrivileges: PlatformPrivileges;
  contract?: Contract;
};

export interface TenantStorageUsage {
  year: number;
  month: number;
  name?: string;
  shortName: string;
  tid?: string;
  totalSize: number;
  bucketSizes: Record<"reports" | "results", number>;
  projects: ProjectStorageUsage[];
};

export interface ProjectStorageUsage {
  name?: string;
  shortName: string;
  totalSize: number;
  bucketSizes?: Record<"reports" | "results", number>;
};

export type TenantStorageUsageData = {
  total: number;
  items: TenantStorageUsage[];
};


export interface TenantMachineUsage {
  year: number;
  month: number;
  shortName: string;
  name?: string;
  tid?: string;
  aws: TenantMachineMinutes[];
  gcp: TenantMachineMinutes[];
  hetzner: TenantMachineMinutes[];
  customMinutes?: number;
  totalMinutes: number;
  totalRuns: number;
  projects: ProjectMachineUsage[];
};

type TenantMachineMinutes = {
  instanceType: string;
  totalMinutes: number;
};

export interface ProjectMachineUsage {
  shortName: string;
  name?: string;
  aws?: CloudMachineUsage[];
  gcp?: CloudMachineUsage[];
  hetzner?: CloudMachineUsage[];
  custom?: MachineUsage;
  totalRuns: number;
};

type MachineUsage = Record<"totalMinutes" | "minInstances" | "maxIntances" | "totalInstances", number>;
type CloudMachineUsage = { instanceType: string } & MachineUsage;

export type TenantMachineUsageData = {
  total: number;
  items: TenantMachineUsage[];
};

export const LOCK_OUT_MESSAGE = "error.privileges.userLockedFromTenant";
