import { UserSystemRole } from "app/core/user/types";

const orderedRoles: UserSystemRole[] = ["User", "BillingAdmin", "OrganizationsAdmin", "Admin"];

export type Privilege = "<" | "<=" | "=" | ">=" | ">";

export function hasSystemRole(role: UserSystemRole, required: UserSystemRole, modifier?: Privilege): boolean {
  if (role && required) {
    const roleIndex = orderedRoles.indexOf(role);
    const requiredIndex = orderedRoles.indexOf(required);
    switch (modifier) {
      case "<": return roleIndex < requiredIndex;
      case "<=": return roleIndex <= requiredIndex;
      case "=": return roleIndex === requiredIndex;
      case ">": return roleIndex > requiredIndex;
      default: return roleIndex >= requiredIndex;
    }
  } else {
    return false;
  }
}

export function validateSystemRole<R>(role: UserSystemRole, required: UserSystemRole, returnOnValid: R, modifier?: Privilege)
  : R | undefined {
  return hasSystemRole(role, required, modifier) ? returnOnValid : undefined;
}
