import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringOverviewScene = asyncComponent(
  () => import(/* webpackChunkName: "project" */ "app/mon/project/scenes/MonitoringOverviewScene")
    .then(({ default: scene }) => scene),
  "MonitoringOverviewScene"
);

export { AsyncMonitoringOverviewScene };
export default AsyncMonitoringOverviewScene;
