import Cookies from "js-cookie";

export function getErrorFromCookie(): string | undefined {
    return Cookies.get("xtcError");
}

export function getOkFromCookie(): string | undefined {
    return Cookies.get("xtcOk");
}

export function resetErrorCookie(): void {
    Cookies.remove("xtcError");
}

export function resetOkCookie(): void {
    Cookies.remove("xtcOk");
}
