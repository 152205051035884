import { asyncComponent } from "components/AsyncComponent";

const AsyncLoadTestProjectDashboardScene = asyncComponent(
  () => import(
    /* webpackChunkName: "loadTestDashboard" */ "app/lt/project/scenes/LoadTestProjectDashboardScene"
  ).then(({ default: scene }) => scene),
  "LoadTestProjectDashboardScene"
);

export { AsyncLoadTestProjectDashboardScene };
export default AsyncLoadTestProjectDashboardScene;
