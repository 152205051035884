import { asyncComponent } from "components/AsyncComponent";

const AsyncAuditLogScene = asyncComponent(
  () => import(
    /* webpackChunkName: "adminAuditLogs" */ "app/admin/scenes/AuditLogScene"
  ).then(({ default: scene }) => scene),
  "AuditLogScene"
);

export { AsyncAuditLogScene };
export default AsyncAuditLogScene;
