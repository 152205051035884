import * as React from "react";

import Error from "components/error/Error";
import Loading from "components/loading/Loading";

import ApiExplorer, { ApiExplorerProps } from "app/core/apiExplorer/components/ApiExplorer";
import OpenApiAPI from "app/core/apiExplorer/OpenApiAPI";
import { OpenApiSpecList } from "app/core/apiExplorer/types";
import { useAppTitle } from "app/utils/appTitle";
import { useFetch } from "app/utils/remote";

type Props = {
  apiExplorerProps?: ApiExplorerProps
};
const ApiExplorerScene: React.FunctionComponent<Props> = (props) => {
  const [specList, setSpecList] = React.useState<OpenApiSpecList>({ specs: [] });

  useAppTitle();

  const { fetched, error } = useFetch(() => OpenApiAPI.fetchOpenApiSpecList().then((result) => {
    if (result.success && result.data) {
      setSpecList(result.data);
    } else {
      throw result;
    }
  }), []);

  if (error) {
    return error.isHandledResponseError ? null : <Error message={error.message} />;
  }
  return !fetched ? <Loading message="loading" /> : <ApiExplorer {...props.apiExplorerProps} specList={specList} />;
};

export default ApiExplorerScene;
export { Props as ApiExplorerSceneProps };
