import { action, makeObservable, observable } from "mobx";

import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { ProjectVisit, TwoFAConfig, TwoFASetupSetupResponse, UserData, UserStates, UserSystemRole } from "app/core/user/types";
import { UserAPI } from "app/core/user/UserAPI";
import { Locale, Timezone } from "app/localization/types";
import { imageUrlForUser } from "app/routing/routes";

interface State {
  readonly _id: string;
  readonly _ver: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumber: string | null;
  readonly email: string;
  readonly role: UserSystemRole;
  readonly state: UserStates;
  readonly timeZone: Timezone;
  readonly language: Locale;
  readonly avatarUrl: string | null;
  readonly lastLogOn: number | null;
  readonly issuer: string | null;
  readonly issuerURL: string | null;
  readonly hasPassword: boolean;
  readonly twoFARequired: boolean;
  readonly hasAvatar: boolean;
  readonly visits: ProjectVisit[];
  /**
   * The authentication method references (AMRs) this user used to authenticate. This corresponds to the concept of the
   * amr field in OpenID Connect access & identity tokens. Expected values are the same except we add an additional value
   * for OIDC as an external login method.
   */
  readonly authenticationMethods: string[];
}

export class UserState extends AbstractState<State> implements State {

  private readonly appStores: AppStores;

  public readonly _id: string;
  @observable public readonly _ver: number;
  @observable public readonly firstName: string;
  @observable public readonly lastName: string;
  @observable public readonly phoneNumber: string;
  @observable public readonly email: string;
  @observable public readonly role: UserSystemRole;
  @observable public readonly state: UserStates;
  @observable public readonly timeZone: Timezone;
  @observable public readonly language: Locale;
  @observable public readonly avatarUrl: string;
  @observable public readonly lastLogOn: number;
  @observable public readonly issuer: string | null;
  @observable public readonly issuerURL: string | null;
  @observable public readonly hasPassword: boolean;
  @observable public readonly twoFARequired: boolean;
  @observable public readonly authenticationMethods: string[];
  @observable public readonly hasAvatar: boolean;
  @observable public readonly visits: ProjectVisit[];

  public constructor(user: UserData, appStores: AppStores) {
    super();

    makeObservable(this.setState({ ...user, avatarUrl: imageUrlForUser(user), visits: [] }));

    this.appStores = appStores;
  }

  @action.bound
  public async requestPhoneVerification(phoneNumber: string): Promise<void> {
    const result = await UserAPI.changePhone(phoneNumber);

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw "errors" in result ? result.errors : result.message;
    }
  }

  @action.bound
  public async submitPhoneVerificationCode(phoneNumber: string, verificationCode: string): Promise<void> {
    const result = await UserAPI.verifyPhone(phoneNumber, verificationCode);

    if (result.success && result.data) {
      this.setState(result.data);
      return this.appStores.notificationStore.ok("account.profile.saved");
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async removePhone(phoneNumber: string): Promise<void> {
    const result = await UserAPI.removePhone(phoneNumber);

    if (result.success && result.data) {
      this.setState(result.data);
      return this.appStores.notificationStore.ok("account.profile.saved");
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const result = await UserAPI.changePassword(oldPassword, newPassword);

    if (result.success && result.data) {
      this.setState(result.data);
      return this.appStores.notificationStore.ok("account.password.saved");
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async changeEmail(email: string, password: string): Promise<void> {
    const result = await UserAPI.saveLogin(email, password);

    if (result.success && result.data) {
      this.setState(result.data);
      this.appStores.notificationStore.ok("account.email.saved");
    } else if (result.errors) {
      throw result.errors;
    } else {
      this.appStores.notificationStore.error("account.email.saved.error");
    }
  }

  @action.bound
  public async changeTimezoneAndLocale(timezone: Timezone, locale: Locale): Promise<void> {
    const result = await UserAPI.savePreferences(timezone, locale);

    if (result.success && result.data) {
      await this.appStores.localizationStore.changeLocale(locale);
      this.appStores.localizationStore.changeTimezone(timezone);

      this.setState(result.data);
      this.appStores.notificationStore.ok("account.locale.saved");
    } else if (result.errors) {
      throw result.errors;
    } else {
      this.appStores.notificationStore.error("account.locale.saved.error");
    }
  }

  @action.bound
  public async changeProfile(firstName: string, lastName: string): Promise<void> {
    const result = await UserAPI.saveProfile(firstName, lastName);

    if (result.success && result.data) {
      this.setState(result.data);
      this.appStores.notificationStore.ok("account.profile.saved");
    } else if (result.errors) {
      throw result.errors;
    } else {
      this.appStores.notificationStore.error("account.profile.saved.error");
    }
  }

  @action.bound
  public async changeAvatar(avatar: File): Promise<void> {
    const result = await UserAPI.uploadPicture(this._id, avatar);

    if (result.success && result.data) {
      this.setState(result.data);
      this.appStores.notificationStore.ok("account.avatar.saved");
    } else {
      this.appStores.notificationStore.error("account.avatar.saved.error");
    }
  }

  @action.bound
  public async deleteAvatar(): Promise<void> {
    const result = await UserAPI.deletePicture(this._id);

    if (result.success && result.data) {
      this.setState(result.data);
      this.appStores.notificationStore.ok("account.avatar.deleted");
    } else {
      this.appStores.notificationStore.error("account.avatar.deleted.error");
    }
  }

  @action.bound
  public async change2FA(enabled: boolean, password: string, code?: string): Promise<TwoFASetupSetupResponse> {
    const result = await UserAPI.change2FA(this._ver, enabled, password, code);

    if (result.success && result.data) {
      // while this could be the first step of enabling 2FA, it's not required until confirmed
      this.setState({ twoFARequired: false, _ver: result.data._ver });
      return result.data;
    } else if (result.errors) {
      throw result.errors;
    } else {
      throw (result.message ?? "account.2fa.saved.error");
    }
  }

  @action.bound
  public async confirm2FA(code: string, password?: string): Promise<TwoFAConfig> {
    const result = await UserAPI.confirm2FA(this._ver, code, password);

    if (result.success && result.data) {
      this.setState({ twoFARequired: true, _ver: result.data._ver, authenticationMethods: [...this.authenticationMethods, "mfa"] });
      return result.data;
    } else if (result.errors) {
      throw result.errors;
    } else {
      throw (result.message ?? "account.2fa.saved.error");
    }
  }

  @action.bound
  public async unbindSSO(password: string): Promise<void> {
    try {
      const result = await UserAPI.unbindSSO(password, this._ver);
      if (result.success && result.data) {
        this.setState(result.data);
      } else if (result.errors) {
        throw result.errors;
      } else {
        throw (result.message ?? "account.sso.unbind.error");
      }
    } catch (e) {
      if (!!e.status) {
        throw new Error("account.sso.unbind.error");
      }
      throw e;
    }
  }

  @action.bound
  public async fetchProjectVisits(): Promise<void> {
    const result = await UserAPI.getProjectVisits();

    if (result.success && result.data) {
      const { visits } = result.data;

      if (visits) {
        this.setState({visits});
      }
    } else {
      this.appStores.notificationStore.error("application.error.data.missing");
    }
  }
}
