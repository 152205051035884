import { SlackConfigUpdate } from "components/slackConfig/SlackConfiguration";

import { TenantData, TenantMembership, TenantMemberships, TenantRoles, TenantSortoptions } from "app/core/tenant/types";
import { UserData } from "app/core/user/types";
import { APIResult, fetch } from "app/utils/remote";

type AddApiCredentialsResult = {
  tenant: TenantData,
  apiCredentialsSecret: string
};

export class TenantAPI {

  public static async fetchMembers(tenantId: string, start: number, count: number,
    sortBy: TenantSortoptions | undefined, searchBy: string | undefined
  ): Promise<APIResult<TenantMemberships>> {
    return fetch<APIResult<TenantMemberships>>({
      url: `/api/core/tenants/${tenantId}/members`,
      data: { start, size: count, sortby: sortBy || "", q: searchBy || "" }
    });
  }

  public static async addMembers(tenantId: string, version: number, emails: string[], role: TenantRoles, inviteUnknownUser: boolean)
    : Promise<APIResult<TenantMembership[]>> {
    return fetch<APIResult<TenantMembership[]>>({
      url: `/api/core/tenants/${tenantId}/members`,
      method: "POST",
      data: { version, emails, role, inviteUnknownUser }
    });
  }

  public static async inviteUsers(tenantId: string, version: number, emails: string[], role: TenantRoles)
    : Promise<APIResult<TenantMembership[]>> {
    return fetch<APIResult<TenantMembership[]>>({
      url: `/api/core/tenants/${tenantId}/members?invite`,
      method: "POST",
      data: { version, emails, role }
    });
  }

  public static async inviteMember(tenantId: string, memberId: string, memberVersion: number): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/core/tenants/${tenantId}/inviteMember`,
      method: "POST",
      data: { id: memberId, version: memberVersion }
    });
  }

  public static async updateMember(tenantId: string, memberId: string, version: number, email: string, role: TenantRoles, isLocked: boolean)
    : Promise<APIResult<TenantMembership>> {
    return fetch<APIResult<TenantMembership>>({
      url: `/api/core/tenants/${tenantId}/members/${memberId}`,
      method: "PUT",
      data: { version, email, role, isLocked }
    });
  }

  public static async deleteMembers(tenantId: string, members: Array<{ version: number, id: string }>)
    : Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/core/tenants/${tenantId}/members`,
      method: "DELETE",
      data: members
    });
  }

  public static async uploadPicture(tenantId: string, tenantVersion: number, picture: File): Promise<APIResult<TenantData>> {
    return fetch<APIResult<TenantData>>({
      url: `/api/core/tenants/${tenantId}/picture`,
      method: "POST",
      data: { file: picture, version: tenantVersion }
    });
  }

  public static async deletePicture(tenantId: string, tenantVersion: number): Promise<APIResult<TenantData>> {
    return fetch<APIResult<TenantData>>({
      url: `/api/core/tenants/${tenantId}/picture`,
      method: "DELETE",
      data: { version: tenantVersion }
    });
  }

  public static async listTenants(): Promise<APIResult<TenantData[]>> {
    return fetch<APIResult<TenantData[]>>({ url: "/api/core/tenants" });
  }

  public static async getTenantDetails(id: string): Promise<APIResult<TenantData>> {
    return fetch<APIResult<TenantData>>({ url: `/api/core/tenants/${id}` });
  }

  public static async saveTenantDetails(
    id: string,
    version: number,
    data: Pick<TenantData, "name" | "description" | "baseShortName" | "state" | "color">
  ): Promise<APIResult<TenantData>> {
    return fetch<APIResult<TenantData>>({ url: `/api/core/tenants/${id}`, data: { ...data, version }, method: "PUT" });
  }

  public static async searchUsers(tenantId: string, query: string): Promise<APIResult<{ matches: UserData[] }>> {
    return fetch<APIResult<{ matches: UserData[] }>>({ url: `/api/core/tenants/${tenantId}/users/search`, data: { q: query } });
  }

  public static async updateSecuritySettings(
    tenantId: string, version: number, data: Pick<TenantData, "allowsUserInvitation" | "requiresSSO" | "permittedIssuers" | "requires2FA">
  ): Promise<APIResult<TenantData>> {
    return fetch<APIResult<TenantData>>({
      url: `/api/core/tenants/${tenantId}/security`,
      method: "PUT",
      data: { ...data, version }
    });
  }

  public static updateTenantFavorite(tenantId: string, favorite: boolean): Promise<APIResult<TenantData>> {
    return fetch<APIResult<TenantData>>({
      url: `/api/core/tenants/${tenantId}/favorite`,
      method: "POST",
      data: {
        favorite
      }
    });
  }

  public static updateSlackIntegration(tenantId: string, version: number, settings: SlackConfigUpdate): Promise<APIResult<TenantData>> {
    return fetch<APIResult<TenantData>>({
      url: `/api/core/tenants/${tenantId}/integrations/slack`,
      method: "POST",
      data: {
        ...settings,
        version
      }
    });
  }

  public static async sendSlackTestMessage(tenantId: string, settings: SlackConfigUpdate): Promise<void> {
    await fetch({
      url: `/api/core/tenants/${tenantId}/integrations/slack/test`,
      method: "POST",
      data: settings
    });
  }

  public static addApiCredentials(tenantId: string, apiCredentialsName: string, apiId: string, scopes: string[], expire?: number) {
    return fetch<APIResult<AddApiCredentialsResult>>({
      url: `/api/core/tenants/${tenantId}/apiCredentials/add`,
      method: "POST",
      data: {
        apiCredentialsName,
        apiVersion: apiId,
        scopes,
        expire
      }
    });
  }

  public static deleteApiCredentials(tenantId: string, apiCredentialsId: string) {
    return fetch<APIResult<TenantData>>({
      url: `/api/core/tenants/${tenantId}/apiCredentials/delete`,
      method: "POST",
      data: {
        apiCredentialsId
      }
    });
  }

}
