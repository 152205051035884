export const RoutePaths = {
  dashboard: {
    path: "/",
    pathPattern: "/"
  },
  login: {
    path: "login",
    pathPattern: "/login"
  },
  logout: {
    path: "logout",
    pathPattern: "/logout"
  },
  account: {
    path: "account",
    pathPattern: "/account"
  },
  resetPassword: {
    path: "resetPassword",
    pathPattern: "/resetPassword"
  },
  newPassword: {
    path: "newPassword",
    pathPattern: "/newPassword"
  },
  register: {
    path: "register",
    pathPattern: "/register"
  },
  registerExternal: {
    path: "register_external",
    pathPattern: "/register_external"
  },
  registerInvitation: {
    path: "register_invitation",
    pathPattern: "/register_invitation/:token"
  },
  signupComplete: {
    path: "signupComplete",
    pathPattern: "/signupComplete/:token"
  },
  admin: {
    path: "admin",
    pathPattern: "/admin",
    users: {
      path: "users",
      pathPattern: "/users"
    },
    tenants: {
      path: "tenants",
      pathPattern: "/tenants"
    }
  },
  tenants: {
    path: "tenants",
    pathPattern: "/tenants",
    tenant: {
      path: "",
      pathPattern: "/:tid",
      admin: {
        path: "admin",
        pathPattern: "/admin"
      }
    }
  }
};
