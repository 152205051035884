import * as React from "react";

import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

import FormField from "components/forms/FormField";
import SelectBox, { Props as SelectBoxProps, SelectChangeEvent } from "components/forms/SelectBox";
import TruncatingText from "components/TruncatingText";

import { ApiVersionState } from "app/core/types";
import Message from "app/localization/components/Message";
import { FormError, getErrorMessage } from "app/utils/error";

export type ApiVersionSelectApiVersion = {
  id: string;
  state: ApiVersionState;
};

type Props = {
  apiVersionId: string;
  supportedApiVersions: ApiVersionSelectApiVersion[];
  onApiVersionChange: (apiVersionId: string) => void;
  errors?: FormError[];
  disableFormField?: boolean;
  selectBoxProps?: SelectBoxProps
};

const ApiVersionSelect: React.FunctionComponent<Props> = (props) => {

  const apiVersionId = props.supportedApiVersions.find((each) => each.id === props.apiVersionId)?.id || "";
  const error = !apiVersionId ? "error.apiVersionUnsupported" : props.errors && getErrorMessage("apiVersion", props.errors);

  const selectBox = (
    <SelectBox
      {...props.selectBoxProps}
      value={apiVersionId}
      onChange={(e: SelectChangeEvent) => props.onApiVersionChange(e.target.value)}
    >
      {props.supportedApiVersions.map((each) => {
        const isDeprecated = each.state === "DEPRECATED";
        const isPreview = each.state === "PREVIEW";
        const isRemoved = props.supportedApiVersions.findIndex((eachVersion) => eachVersion.id === each.id) === -1;
        const isInvalid = isRemoved || each.state === "SHUTDOWN";
        const isLatest = each.state === "LATEST";

        const fontColor = isLatest || isPreview || isInvalid ? "white" : "black";

        return (
          <MenuItem
            className="xtc_apiVersion"
            key={"xtc_apiVersionId_" + each.id}
            value={each.id}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <TruncatingText className="xtc_apiVersionID" noWrap>
                <Message messageID={"api.id." + each.id} />
              </TruncatingText>
              {each.state !== "AVAILABLE" &&
                <Chip
                  className="xtc_apiVersionLabel"
                  size="small"
                  sx={{ marginLeft: 1, height: "unset", color: fontColor }}
                  color={isInvalid ? "error"
                    : isDeprecated ? "warning"
                      : isPreview ? "info"
                        : isLatest
                          ? "success"
                          : undefined
                  }
                  label={
                    <TruncatingText noWrap >
                      <Message messageID={"api.state." + each.state} />
                    </TruncatingText>
                  }
                />
              }
            </Box>
          </MenuItem>
        );
      })}
    </SelectBox>
  );

  return (props.disableFormField
    ? selectBox
    : <FormField
      className="xtc_apiVersion"
      label={<Message messageID="ApiVersionSelect.version.label" />}
      error={!!error && <Message messageID={error} />}
    >
      {selectBox}
    </FormField >
  );
};

export default ApiVersionSelect;
export { Props as ApiVersionSelectProps };
