import { APIResult, fetch } from "app/utils/remote";

import { LoadTestResult, LoadTestResultsChunk, LoadTestResultState } from "app/lt/project/types";

export default class LoadTestResultAPI {

  public static fetchResults(
    projectId: string, start: number, count: number,
    sortBy: string, search: string, softDeleted: boolean, loadTestId: string,
    states?: LoadTestResultState[], validOnly: boolean = false
  ): Promise<APIResult<LoadTestResultsChunk>> {
    return fetch<APIResult<LoadTestResultsChunk>>({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/results`,
      method: "GET",
      data: {
        start, size: count, sortby: sortBy, q: search, softDeleted, states, valid: validOnly
      }
    });
  }

  public static deleteResult(
    projectId: string,
    loadTestId: string,
    resultId: string
  ): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/results/${resultId}`,
      method: "DELETE"
    });
  }

  public static restoreResult(
    projectId: string,
    loadTestId: string,
    resultId: string
  ): Promise<APIResult<LoadTestResult>> {
    return fetch<APIResult<LoadTestResult>>({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/results/${resultId}/restore`,
      method: "POST"
    });
  }

  public static deleteResults(
    projectId: string,
    loadTestId: string,
    data: Array<{ id: string, version: number }>
  ): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/results`,
      method: "DELETE",
      data
    });
  }

  public static shareResult(
    projectId: string,
    resultId: string,
    useGlobal: boolean,
    validFromMilliseconds: number,
    validToMilliseconds: number
  ): Promise<APIResult<LoadTestResult>> {
    return fetch<APIResult<LoadTestResult>>({
      url: `/api/lt/projects/${projectId}/results/${resultId}/share`,
      method: "POST",
      data: {
        useGlobal,
        validFromMilliseconds,
        validToMilliseconds
      }
    });
  }

  public static unshareResult(
    projectId: string,
    resultId: string
  ): Promise<APIResult<LoadTestResult>> {
    return fetch<APIResult<LoadTestResult>>({
      url: `/api/lt/projects/${projectId}/results/${resultId}/share`,
      method: "DELETE"
    });
  }

  public static updateResultPin(projectId: string, resultId: string, pinning: boolean): Promise<APIResult<LoadTestResult>> {
    return fetch<APIResult<LoadTestResult>>({
      url: `/api/lt/projects/${projectId}/results/${resultId}/pin`,
      method: "PUT",
      data: {
        pinning
      }
    });
  }
}
