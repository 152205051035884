import { action, computed, makeObservable, observable } from "mobx";

import { RecreateSecretsResult, RestoreAllSecretsResult, SystemAdminAPI } from "app/admin/SystemAdminAPI";
import { ProjectState } from "app/core/project/store/ProjectState";
import { ProjectSortoptions, SystemGrafanaSettings, SystemProjectData } from "app/core/project/types";
import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";

import { MonitoringExecutorSettings, PersistenceSettings, SystemMonitoringLocation } from "app/mon/project/types";

type State = {
  readonly projects: ProjectState[];
  readonly projectsTotal: number;
  readonly projectsFiltered: number;
};

class AdminProjectStore extends AbstractState<State> implements State {

  private static readonly initialState: State = { projects: [], projectsTotal: 0, projectsFiltered: 0 };

  @observable public readonly projects: ProjectState[];
  @observable public readonly projectsTotal: number;
  @observable public readonly projectsFiltered: number;

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState(AdminProjectStore.initialState));

    this.appStores = appStores;
  }

  public initStore(): void {
    this.setState(AdminProjectStore.initialState);
  }

  @computed
  public get systemProjects(): ProjectState[] {
    return this.projects;
  }

  @action.bound
  public putProject(prepend: boolean, project: SystemProjectData): ProjectState {
    const projectState = this.appStores.projectStore.putProject(false, project);
    const index = this.projects.findIndex((each) => each._id === project._id);
    if (index > -1) {
      this.projects[index] = projectState;
      return this.projects[index];
    } else {
      if (prepend) {
        this.projects.splice(0, 0, projectState);
        return this.projects[0];
      } else {
        this.projects.push(projectState);
        return this.projects[this.projects.length - 1];
      }
    }
  }

  @action.bound
  public putProjects(append: boolean, projects: SystemProjectData[]): ProjectState[] {
    if (!append) {
      this.setState({ projects: [] });
    }

    return projects.map((each) => this.putProject(false, each));
  }

  @action.bound
  public removeProject(id: string): void {
    const index = this.projects.findIndex((each) => each._id === id);
    if (index > -1) {
      this.projects.splice(index, 1);
      this.appStores.projectStore.removeProject(id);
    }
  }

  @action.bound
  public async fetchProjects(
    append: boolean, start: number, count: number, sortBy: ProjectSortoptions, search: string, softDeleted: boolean
  ): Promise<void> {
    const result = await SystemAdminAPI.listProjects(start, count, sortBy, search, softDeleted);
    if (result.success && result.data) {
      this.putProjects(append, result.data.projects);
      this.setState({
        projectsTotal: result.data.total,
        projectsFiltered: result.data.count
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateMonitoringExecutorSettings(
    projectId: string, version: number, locations: SystemMonitoringLocation[], executorSettings: MonitoringExecutorSettings
  ): Promise<void> {
    const result = await SystemAdminAPI.saveMonitoringProjectExecutorSettings(projectId, version, locations, executorSettings);
    if (result.success && result.data) {
      const foundProject = this.appStores.projectStore.findProject(projectId);
      if (foundProject) {
        foundProject.setState(result.data);
      }
      this.putProject(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateMonitoringPersistenceSettings(
    projectId: string, version: number, settings: PersistenceSettings
  ): Promise<void> {
    const p = this.appStores.projectStore.findProject(projectId);
    if (!p || p.type !== "mon") {
      throw new Error("Nu pagadi!");
    }

    const result = await SystemAdminAPI.saveMonitoringProjectPersistenceSettings(projectId, version, settings);
    if (result.success && result.data) {
      const foundProject = this.appStores.projectStore.findProject(projectId);
      if (foundProject) {
        foundProject.setState(result.data);
      }
      this.putProject(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateMonitoringGrafanaSettings(
    projectId: string, version: number, settings: SystemGrafanaSettings
  ): Promise<void> {
    const p = this.appStores.projectStore.findProject(projectId);
    if (!p || p.type !== "mon") {
      throw new Error("Nu pagadi!");
    }
    const result = await SystemAdminAPI.saveMonitoringProjectGrafanaSettings(projectId, version, settings);
    if (result.success && result.data) {
      const foundProject = this.appStores.projectStore.findProject(projectId);
      if (foundProject) {
        foundProject.setState(result.data);
      }
      this.putProject(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async restoreSecret(projectId: string): Promise<void> {
    const result = await SystemAdminAPI.restoreProjectSecret(projectId);
    if (!result.success) {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async recreateSecrets(projectIDs: string[]): Promise<RecreateSecretsResult> {
    const result = await SystemAdminAPI.recreateProjectSecrets(projectIDs);
    if (result.success && result.data) {
      return result.data;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async restoreAllSecrets(): Promise<RestoreAllSecretsResult> {
    const result = await SystemAdminAPI.restoreAllProjectSecrets();
    if (result.success && result.data) {
      return result.data;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateProject(project: SystemProjectData): Promise<void> {
    const result = await SystemAdminAPI.updateProject(project);
    if (result.success && result.data) {
      const foundProject = this.appStores.projectStore.findProject(project._id);
      if (foundProject) {
        foundProject.setState(result.data);
      }
      this.putProject(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

}

export { AdminProjectStore };
export default AdminProjectStore;
