import FormError from "./FormError";
import HandledResponseError from "./HandledResponseError";

/**
 * Retrieves the message of the given request error.
 *
 * @param reason the request error
 * @returns request error message
 */
export function getRequestErrorMessage(reason: any): string {
  const message: string = typeof reason === "string" ? reason :
    "message" in reason && typeof reason.message === "string" ? reason.message : String(reason);
  if (message.startsWith("[object ")) {
    return "application.error.request.failure";
  }
  return message;
};

export function filterRequestErrors(reason: any): string | FormError[] | undefined {
  if (reason && !(reason instanceof HandledResponseError)) {
    if (Array.isArray(reason)) {
      return reason;
    }
    return getRequestErrorMessage(reason);
  }
  return;
}

export function handleReason(reason: any, handleStringReason: (sReason: string) => void): void {
  const filteredReason = filterRequestErrors(reason);
  if (typeof filteredReason === "string") {
    handleStringReason(filteredReason);
  } else if (filteredReason !== undefined) {
    throw filteredReason;
  }
}

export function handleReject<T>(
  executor: (...args: any) => Promise<T>,
  handler: (reason: string) => void
): (...args: any) => Promise<T | void> {
  return (...args) => executor(...args).catch((reason) => handleReason(reason, handler));
}
