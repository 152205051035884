import { asyncComponent } from "components/AsyncComponent";


const AsyncManageProjectsScene = asyncComponent(
  () => import(/* webpackChunkName: "admin" */ "app/admin/scenes/ManageProjects").then(({ default: scene }) => scene),
  "AdminManageProjects"
);

export { AsyncManageProjectsScene };
export default AsyncManageProjectsScene;
