import * as React from "react";

import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import MembersIcon from "@mui/icons-material/GroupOutlined";
import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import ScenariosIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import AuditLogIcon from "@mui/icons-material/PolicyOutlined";
import ExportIcon from "@mui/icons-material/SaveAltOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import MetricsIcon from "@mui/icons-material/ShowChartOutlined";
import QuietPeriodsIcon from "@mui/icons-material/SnoozeOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import ProjectIcon from "@mui/icons-material/WhatshotOutlined";

import { ProjectNavigation } from "app/core/project/components/ProjectNavigation";
import { ProjectStates } from "app/core/project/types";
import {
  routeToMonitoringProjectMetrics, routeToProject, routeToProjectAuditLog, routeToProjectConfiguration, routeToProjectDataExports,
  routeToProjectDocumentation, routeToProjectHistory, routeToProjectMembers, routeToProjectQuietPeriods, routeToProjectScenarios
} from "app/routing/routes";

type Props = {
  projectId: string,
  projectName: string,
  isProjectTester: boolean,
  isProjectReviewer: boolean,
  isProjectAdmin: boolean,
  tenantId: string,
  tenantName: string,
  projectState: ProjectStates
};
const MonitoringProjectSideNavigation: React.FunctionComponent<Props> = (props) => {
  const { projectId, projectName, isProjectTester, isProjectAdmin, isProjectReviewer, tenantId, tenantName, projectState } = props;
  const icon = projectState === "Archived"
    ? <StopCircleOutlinedIcon style={{ color: "darkred" }}/>
    : projectState === "Inactive"
      ? <PauseCircleOutlineOutlinedIcon style={{ color: "darkred" }} />
      : <ProjectIcon />;

  return <ProjectNavigation
    tenantName={tenantName}
    tenantId={tenantId}
    header={{
      className: "xtc_monitoringProjectHome",
      labelId: projectName,
      icon,
      path: routeToProject(projectId, tenantId)
    }}
    entries={[
      {
        className: "xtc_monitoringProjectDashboard",
        labelId: "navigation.dashboard",
        icon: <DashboardIcon />,
        path: routeToProject(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectReviewer && {
        className: "xtc_monitoringProjectDocumentation",
        labelId: "navigation.documentation",
        icon: <NoteAltOutlinedIcon />,
        path: routeToProjectDocumentation(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectTester && {
        className: "xtc_monitoringProjectScenarios",
        labelId: "navigation.scenarios",
        icon: <ScenariosIcon />,
        path: routeToProjectScenarios(projectId, tenantId),
        selectedOnExactPath: true
      },
      {
        className: "xtc_monitoringProjectMetrics",
        labelId: "project.details.dashboard",
        icon: <MetricsIcon />,
        path: routeToMonitoringProjectMetrics(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectReviewer && {
        className: "xtc_monitoringProjectHistory",
        labelId: "project.details.history",
        icon: <HistoryIcon />,
        path: routeToProjectHistory(projectId, tenantId),
        selectedOnExactPath: false
      },
      isProjectTester && {
        className: "xtc_monitoringProjectMembers",
        labelId: "navigation.members",
        icon: <MembersIcon />,
        path: routeToProjectMembers(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectTester && {
        className: "xtc_monitoringQuietPeriodsConfig",
        labelId: "navigation.quietPeriods",
        icon: <QuietPeriodsIcon />,
        path: routeToProjectQuietPeriods(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectTester && {
        className: "xtc_monitoringProjectConfig",
        labelId: "navigation.settings",
        icon: <SettingsIcon />,
        path: routeToProjectConfiguration(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectReviewer && {
        className: "xtc_monitoringProjectExports",
        labelId: "project.details.exports",
        icon: <ExportIcon />,
        path: routeToProjectDataExports(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectAdmin && {
        className: "xtc_monitoringProjectAuditLog",
        labelId: "navigation.auditLog",
        icon: <AuditLogIcon />,
        path: routeToProjectAuditLog(projectId, tenantId),
        selectedOnExactPath: true
      }
    ]}
  />
    ;
};

export default MonitoringProjectSideNavigation;
export { MonitoringProjectSideNavigation };
