
import * as React from "react";
import * as ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";

import { configure as configureMobx } from "mobx";

import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { appStores } from "app/core/store/AppStores";
import { Provider as StoreProvider } from "app/core/store/injectStore";
import { LOCK_OUT_MESSAGE } from "app/core/tenant/types";
import { Localized } from "app/localization/components/Localized";
import { AppRouter } from "app/routing/router/AppRouter";
import { appTheme } from "app/theme/theme";
import { addAppResponseHandler } from "app/utils/remote/request";

configureMobx({
  enforceActions: "always"  // mobx strict mode (mutate state only within actions)
  // observableRequiresReaction: true,
  // computedRequiresReaction: true,
  // reactionRequiresObservable: true,
  // reactionScheduler: (f): void => {
  //   console.log("Running an event after a delay:", f);
  //   setTimeout(f, 100);
  // }
});
// initialize user session
appStores.sessionStore.sessionPrepare();

addAppResponseHandler("session_handler", (response) => {
  if (response.status === 401) {
    appStores.sessionStore.sessionExpired();
    return true;
  }
  return false;
});
addAppResponseHandler("access_handler", (response) => {
  if (response.status === 403) {
    const lockMessage = response.headers.get("xtc_error_details");
    if (lockMessage?.startsWith(LOCK_OUT_MESSAGE)) {
      appStores.tenantsStore.currentTenant?.lock(lockMessage);
      throw lockMessage;
    } else {
      appStores.sessionStore.permissionDenied(lockMessage);
      return true;
    }
  }
  return false;
});

const container = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <StoreProvider value={appStores}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={appStores.localizationStore.locale}>
            <Localized>
              <RouterProvider router={AppRouter} />
            </Localized>
          </LocalizationProvider>
        </StoreProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
  , container
);
