import * as React from "react";

import { getRequestErrorMessage } from "../error/commonErrorHandling";
import HandledResponseError from "../error/HandledResponseError";
import { CancelablePromise } from "../promise";

type TError = { message: string, isHandledResponseError: boolean };

export type LoadState = { fetched: boolean, error: TError | null };
export function useFetch(fetch: () => Promise<any>, fetchDeps?: any[]) {
  const [loadState, setLoadState] = React.useState<LoadState>(() => ({ fetched: false, error: null }));
  const doFetch = () => {
    setLoadState({ fetched: false, error: null });

    const prom = new CancelablePromise(fetch());
    prom.then(
      () => setLoadState({ fetched: true, error: null }),
      (reason) => {
        setLoadState({
          fetched: true,
          error: {
            message: getRequestErrorMessage(reason),
            isHandledResponseError: reason instanceof HandledResponseError
          }
        });
      }
    );

    return () => {
      setLoadState({ fetched: false, error: null });
      prom.cancel();
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(doFetch, fetchDeps);
  return loadState;
}
