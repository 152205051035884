import { APIResult, fetch } from "app/utils/remote";

import { TenantMachineUsageData, TenantStorageUsageData } from "./types";

export default class TenantResourcesAPI {

  public static fetchStorageRecords(
    tenantId: string, start: number, count: number
  ): Promise<APIResult<TenantStorageUsageData>> {
    return fetch<APIResult<TenantStorageUsageData>>({
      url: `/api/core/tenants/${tenantId}/resources/storage`,
      method: "GET",
      data: {
        start,
        size: count
      }
    });
  }

  public static fetchMachineRecords(
    tenantId: string, start: number, count: number
  ): Promise<APIResult<TenantMachineUsageData>> {
    return fetch<APIResult<TenantMachineUsageData>>({
      url: `/api/core/tenants/${tenantId}/resources/machines`,
      method: "GET",
      data: {
        start,
        size: count
      }
    });
  }
}
