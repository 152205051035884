import "react-mde/lib/styles/css/react-mde-all.css";

import * as React from "react";
import { useIntl } from "react-intl";
import ReactMarkdown from "react-markdown";
import ReactMde, { getDefaultCommandMap } from "react-mde";

import remarkGfm from "remark-gfm";

import Box from "@mui/material/Box";

const PREFIX = "MarkdownEditor";

const classes = {
  reactMde: `${PREFIX}-reactMde`,
  preview: `${PREFIX}-preview`,
  toolbar: `${PREFIX}-toolbar`
};

type Props = {
  hideBorder?: boolean
  minPreviewHeight?: number
  initialEditorHeight?: number
  heightUnits?: string
  value: string,
  className?: string,
  onChange?: (markdown: string) => void
};

export default function MarkdownEditor(props: Props) {
  const { value, hideBorder, onChange } = props;

  const { formatMessage } = useIntl();

  const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");
  const parseMarkdown = (markdown: string) => Promise.resolve(<ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>);

  return (
    <Box width={1} className={props.className} sx={{
      [`& .${classes.reactMde}`]: {
        border: hideBorder ? "unset" : undefined,
        borderRadius: hideBorder ? "unset" : undefined
      },

      [`& .${classes.preview} .mde-preview-content`]: {
        padding: hideBorder ? "10px 0 0" : undefined,
        overflowWrap: "anywhere"
      },

      [`& .${classes.toolbar}`]: {
        display: !onChange ? "none" : undefined
      }
    }}>
      <ReactMde
        commands={onChange ? undefined : getDefaultCommandMap()}
        value={value}
        onChange={onChange}
        selectedTab={onChange ? selectedTab : "preview"}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={parseMarkdown}
        l18n={{
          write: formatMessage({ id: "markdown.write" }),
          preview: formatMessage({ id: "markdown.preview" }),
          uploadingImage: "",
          pasteDropSelect: ""
        }}
        minPreviewHeight={props.minPreviewHeight}
        initialEditorHeight={props.initialEditorHeight}
        heightUnits={props.heightUnits}
        classes={classes}
      />
    </Box>
  );
}
