import * as React from "react";

import Box from "@mui/material/Box";

import { default as Typography, Props as TypographyProps } from "./Typography";

type Props = TypographyProps & {

  /* Start workaround for material-ui/core issue #15827
  See: https://github.com/mui-org/material-ui/issues/15827 */
  component?: React.ElementType<any>
  // End workaround for material-ui/core issue #15827

  inheritFontWeight?: boolean
};

const TruncatingText: React.FunctionComponent<Props> = (props) => {
  const { inheritFontWeight, style, variant = "inherit", ...rest } = props;
  const typoStyle = Object.assign(
    style ?? {},
    // CSS class 'MuiTypography-noWrap' already includes those rules
    props.noWrap ? undefined : { textOverflow: "ellipsis", overflow: "hidden" },
    inheritFontWeight ? { fontWeight: "inherit" } : undefined
  ) as React.CSSProperties;
  return (
    <Box display="grid">
      <Typography {...rest} style={typoStyle} variant={variant} />
    </Box >
  );
};

export default TruncatingText;
export { Props as TruncatingTextProps };
