import * as React from "react";

import clsx from "clsx";

import { default as Select, SelectProps } from "@mui/material/Select";

export type { SelectChangeEvent } from "@mui/material/Select";

export type Props = SelectProps & {
  dropOut?: "bottomRight" | "bottomLeft";
  filled?: boolean;
};

const SelectBox: React.FunctionComponent<Props> = React.forwardRef((props, ref) => {
  const { dropOut, className, filled = true, ...selectProps } = props;

  if (dropOut || !selectProps.MenuProps) {
    const currentDropOut = dropOut || "bottomRight";
    const dropHorizontal = currentDropOut === "bottomLeft" ? "right" : "left";

    selectProps.MenuProps = {
      anchorOrigin: { vertical: "bottom", horizontal: dropHorizontal },
      transformOrigin: { vertical: "top", horizontal: dropHorizontal },
      className: clsx("xtc_menu", className)
    };
  }

  return <Select
    className="xtc_selectbox"
    variant={props.input ? undefined : (filled ? "filled" : "standard")}
    ref={ref}
    {...selectProps}
  />;
});

export default SelectBox;
