import { action, computed, makeObservable, observable, override } from "mobx";

import { IProjectState, ProjectState } from "app/core/project/store/ProjectState";
import { RepositoryAuthentication, RepositorySettings, RepositoryStaticBranch } from "app/core/project/types";
import AppStores from "app/core/store/AppStores";
import { APIResult } from "app/utils/remote";

import LoadTestReportAPI from "app/lt/project/LoadTestReportAPI";
import LoadTestResultAPI from "app/lt/project/LoadTestResultAPI";
import {
  BuildToolSettings, DiffReportGenerationSettings, LoadTest, LoadTestingProjectSettings, LoadTestProjectData, LoadTestProjectStatus,
  LoadTestResult, Report, ReportGenerationSettings, SystemLoadTestProjectData
} from "app/lt/project/types";

import { LoadTestProjectAPI } from "../LoadTestProjectAPI";

// tslint:disable-next-line: no-empty-interface
interface ILoadTestProjectState extends IProjectState {
  settings: LoadTestingProjectSettings;
  results: LoadTestResult[];
  reports: Report[];
  totalCount: number;
  filteredCount: number;
  status?: LoadTestProjectStatus;
}

export class LoadTestProjectState extends ProjectState implements ILoadTestProjectState {
  @observable public readonly settings: LoadTestingProjectSettings;

  @observable public readonly results: LoadTestResult[];
  @observable public readonly reports: Report[];
  @observable public readonly totalCount: number;
  @observable public readonly filteredCount: number;
  @observable public readonly status: LoadTestProjectStatus;

  public constructor(appStores: AppStores, project: LoadTestProjectData | SystemLoadTestProjectData) {
    super(appStores);
    makeObservable(this);
    this.setState({
      results: [],
      reports: [],
      totalCount: 0,
      filteredCount: 0,
      settings: {
        repository: {
          suitePath: "",
          type: "git",
          url: ""
        },
        build: {
          tool: "maven"
        },
        sharingPresets: {
          reportSharingEnabled: false,
          resultSharingEnabled: false
        },
        defaultXltVersionId: "",
        supportedDefaultXltVersions: []
      },
      status: { running: false, scheduled: false },
      ...ProjectState.initialState(project)
    });
  }

  @override
  public setState(state: Partial<ILoadTestProjectState>) {
    super.setState(state);
    return this;
  }

  @computed
  public get loadTestRports(): Report[] {
    return this.reports.filter((each) => each.type === "loadTest");
  }

  @computed
  public get comparisonReports(): Report[] {
    return this.reports.filter((each) => each.type === "diff");
  }

  @override
  public async pushRepositorySettings(settings: RepositorySettings): Promise<void> {
    const result = await LoadTestProjectAPI.saveRepositorySettings(this._id, this._ver, settings);
    if (result.success && result.data) {
      const { _ver, repository } = result.data;
      this.setState({ _ver, settings: { ...this.settings, repository } });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @override
  public async pushRepositoryAuthSettings(settings: { auth: RepositoryAuthentication | null }): Promise<void> {
    const result = await LoadTestProjectAPI.saveRepositoryAuthentication(this._id, this._ver, settings);
    if (result.success && result.data) {
      const { _ver, repository } = result.data;
      this.setState({ _ver, settings: { ...this.settings, repository } });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @override
  public async pushRepositoryBranchSettings(settings: RepositoryStaticBranch): Promise<void> {
    const result = await LoadTestProjectAPI.saveRepositoryBranch(this._id, this._ver, settings);
    if (result.success && result.data) {
      const { _ver, repository } = result.data;
      this.setState({ _ver, settings: { ...this.settings, repository } });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushBuildToolSettings(settings: BuildToolSettings): Promise<void> {
    const result = await LoadTestProjectAPI.saveBuildToolSettings(this._id, this._ver, settings);
    if (result.success && result.data) {
      const { _ver, build } = result.data;
      this.setState({ _ver, settings: { ...this.settings, build } });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async discardBuildDependencyCache(): Promise<void> {
    const result = await LoadTestProjectAPI.discardBuildDependencyCache(this._id);
    if (!result.success) {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  // ~~~~~~~~~~~
  // Report State
  // ~~~~~~~~~~~

  @action.bound
  private putReport(prepend: boolean, report: Report): void {
    const { reports } = this;
    const index = reports.findIndex((each) => each.id === report.id);
    if (index > -1) {
      reports[index] = report;
    } else {
      if (prepend) {
        reports.splice(0, 0, report);
      } else {
        reports.push(report);
      }
    }
  }

  @action.bound
  private putReports(append: boolean, reports: Report[]): void {
    const { reports: stateReports } = this;
    if (!append) {
      stateReports.splice(0, stateReports.length, ...reports);
    } else {
      reports.map((each) => this.putReport(false, each));
    }
  }

  @action.bound
  private removeReport(id: string): void {
    const { reports } = this;
    const index = reports.findIndex((each) => each.id === id);
    if (index > -1) {
      reports.splice(index, 1);
    }
  }

  @action.bound
  private updateReportCount(totalCount: number, filteredCount: number) {
    this.setState({
      totalCount,
      filteredCount
    });
  }

  @action.bound
  public async fetchReports(
    append: boolean, projectID: string,
    start: number, count: number, sortBy: string, search: string, softDeleted: boolean, loadTestId?: string
  ): Promise<void> {
    const result = await LoadTestReportAPI.fetchReports(projectID, start, count, sortBy, search, softDeleted, loadTestId);
    if (result.success && result.data) {
      // TODO append
      this.putReports(append, result.data.reports);
      this.updateReportCount(result.data.total, result.data.filtered);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchProjectReports(
    append: boolean, projectID: string,
    start: number, count: number, sortBy: string, search: string
  ): Promise<void> {
    const result = await LoadTestReportAPI.fetchProjectReports(projectID, start, count, sortBy, { search, comparison: true });
    if (result.success && result.data) {
      this.putReports(append, result.data.reports);
      this.updateReportCount(result.data.total, result.data.filtered);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchComparisonReports(
    append: boolean, projectID: string,
    start: number, count: number, sortBy: string, search: string, softDeleted: boolean
  ): Promise<void> {
    const result = await LoadTestReportAPI.fetchComparisonReports(projectID, start, count, sortBy, search, softDeleted);
    if (result.success && result.data) {
      this.putReports(append, result.data.reports);
      this.updateReportCount(result.data.total, result.data.filtered);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  private async handleGenerateReportResult(result: APIResult<Report>, projectId: string, loadTestId: string): Promise<void> {
    if (result.success && result.data) {
      try {
        await this.appStores.loadTestsStore.fetchLoadTest(projectId, loadTestId);
      } finally {
        this.putReport(true, result.data);
        this.updateReportCount(this.totalCount + 1, this.filteredCount + 1);
      }
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async generateReport(projectID: string, loadTestId: string, data: ReportGenerationSettings): Promise<void> {
    const result = await LoadTestReportAPI.generateNewReport(projectID, loadTestId, data);
    return this.handleGenerateReportResult(result, projectID, loadTestId);
  }

  @action.bound
  public async generateIntermediateReport(
    projectID: string, loadTestId: string,
    downloadLogs: boolean, downloadResultsBrowsers: boolean
  ): Promise<void> {
    const result = await LoadTestReportAPI.generateNewIntermediateReport(projectID, loadTestId, downloadLogs, downloadResultsBrowsers);
    return this.handleGenerateReportResult(result, projectID, loadTestId);
  }

  @action.bound
  public async generateNewDiffReport(projectID: string, data: DiffReportGenerationSettings): Promise<void> {
    const result = await LoadTestReportAPI.generateNewDiffReport(projectID, data);
    if (!result.success) {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateReport(projectId: string, reportId: string, label: string, description: string, version: number): Promise<void> {
    const result = await LoadTestReportAPI.updateReport(projectId, reportId, label, description, version);
    if (result.success && result.data) {
      this.putReport(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteReport(projectId: string, reportId: string): Promise<void> {
    const result = await LoadTestReportAPI.deleteReport(projectId, reportId);
    if (result.success) {
      this.removeReport(reportId);
      this.updateReportCount(this.totalCount - 1, this.filteredCount - 1);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async restoreReport(projectId: string, reportId: string): Promise<void> {
    const result = await LoadTestReportAPI.restoreReport(projectId, reportId);
    if (result.success && result.data) {
      this.removeReport(reportId);
      this.updateReportCount(this.totalCount - 1, this.filteredCount - 1);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async resurrectReport(projectId: string, reportId: string): Promise<void> {
    const result = await LoadTestReportAPI.resurrectReport(projectId, reportId);
    if (result.success && result.data) {
      this.putReport(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateDiffReportPin(projectId: string, reportId: string, pinning: boolean): Promise<void> {
    const result = await LoadTestReportAPI.updateReportPin(projectId, reportId, pinning);
    if (result.success && result.data) {
      this.putReport(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateReportPin(projectId: string, reportId: string, loadTest: LoadTest, pinning: boolean): Promise<void> {
    const result = await LoadTestReportAPI.updateReportPin(projectId, reportId, pinning);
    if (result.success && result.data) {
      this.putReport(false, result.data);
      this.appStores.loadTestsStore.updateLoadTestHasPinnedArtifacts(loadTest, this.hasPinnedArtifacts());
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateResultPin(projectId: string, loadTestResultId: string, loadTest: LoadTest, pinning: boolean): Promise<void> {
    const result = await LoadTestResultAPI.updateResultPin(projectId, loadTestResultId, pinning);
    if (result.success && result.data) {
      this.putResult(false, result.data);
      this.appStores.loadTestsStore.updateLoadTestHasPinnedArtifacts(loadTest, this.hasPinnedArtifacts());
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateReportMarkedForComparison(projectId: string, reportId: string, isToBeCompared: boolean): Promise<void> {
    const result = await LoadTestReportAPI.updateReportMarkedForComparison(projectId, reportId, isToBeCompared);
    if (result.success && result.data) {
      this.putReport(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  private hasPinnedArtifacts(): boolean {
    return this.results.some((r) => r.pinned) || this.reports.some((r) => r.pinned);
  }

  @action.bound
  public async deleteReports(projectId: string, reports: Report[]): Promise<void> {
    const result = await LoadTestReportAPI.deleteReports(projectId, reports.map((r) => ({ id: r.id, version: r.version })));
    if (result.success) {
      reports.forEach((r) => this.removeReport(r.id));
      this.updateReportCount(this.totalCount - reports.length, this.filteredCount - reports.length);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushShareReportsConfig(shared: boolean, validToMilliseconds?: number | undefined): Promise<void> {
    const result = await LoadTestProjectAPI.updateShareReportsConfig(this._id, this._ver, shared, validToMilliseconds);
    if (result.success && result.data) {
      this.setState({
        _ver: result.data._ver,
        settings: {
          ...this.settings,
          sharingPresets: result.data.sharingPresets
        }
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  };

  @action.bound
  public async pushShareResultsConfig(shared: boolean, validToMilliseconds?: number | undefined): Promise<void> {
    const result = await LoadTestProjectAPI.updateShareResultsConfig(this._id, this._ver, shared, validToMilliseconds);
    if (result.success && result.data) {
      this.setState({
        _ver: result.data._ver,
        settings: {
          ...this.settings,
          sharingPresets: result.data.sharingPresets
        }
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  };

  @action.bound
  public async pushDefaultXltVersion(defaultXltVersionId: string): Promise<void> {
    const result = await LoadTestProjectAPI.updateDefaultXltVersion(this._id, this._ver, defaultXltVersionId);
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  };

  @action.bound
  public async deleteCustomReportShares(): Promise<number> {
    const result = await LoadTestProjectAPI.deleteCustomReportShares(this._id);
    if (result.success && result.data) {
      this.setState({
        reports: this.reports.map((r) =>
          result.data?.find((rep) => rep === r.id) ? { ...r, sharedUrl: undefined } : r)
      });
      return result.data?.length ?? null;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteCustomResultShares(): Promise<number> {
    const result = await LoadTestProjectAPI.deleteCustomResultShares(this._id);
    if (result.success && result.data) {
      this.setState({
        results: this.results.map((r) =>
          result.data?.find((rep) => rep === r.id) ? { ...r, sharedUrl: undefined } : r)
      });
      return result.data?.length ?? null;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  // ~~~~~~~~~~~
  // Result State
  // ~~~~~~~~~~~

  @action.bound
  private putResult(prepend: boolean, result: LoadTestResult): void {
    const { results } = this;
    const index = results.findIndex((each) => each.id === result.id);
    if (index > -1) {
      results[index] = result;
    } else {
      if (prepend) {
        results.splice(0, 0, result);
      } else {
        results.push(result);
      }
    }
  }

  @action.bound
  private putResults(append: boolean, results: LoadTestResult[]): void {
    const { results: stateResults } = this;
    if (!append) {
      stateResults.splice(0, stateResults.length, ...results);
    } else {
      results.map((each) => this.putResult(false, each));
    }
  }

  @action.bound
  private removeResult(id: string): void {
    const { results } = this;
    const index = results.findIndex((each) => each.id === id);
    if (index > -1) {
      results.splice(index, 1);
    }
  }

  @action.bound
  private updateResultCount(totalCount: number, filteredCount: number) {
    this.setState({
      totalCount,
      filteredCount
    });
  }

  @action.bound
  public async fetchResults(
    append: boolean, projectID: string,
    start: number, count: number, sortBy: string, search: string, softDeleted: boolean, loadTestId: string
  ): Promise<void> {
    const result = await LoadTestResultAPI.fetchResults(projectID, start, count, sortBy, search, softDeleted, loadTestId);
    if (result.success && result.data) {
      // TODO append
      this.putResults(append, result.data.items);
      this.updateResultCount(result.data.totalCount, result.data.filteredCount);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchLatestResult(projectId: string, loadTestId: string): Promise<LoadTestResult | undefined> {
    const result = await LoadTestResultAPI.fetchResults(projectId, 0, 1, "-creationTime", "", false, loadTestId, ["FINISHED"], true);
    if (result.success && result.data) {
      return result.data.items.length > 0 ? result.data.items[0] : undefined;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteResult(projectId: string, loadTestId: string, resultId: string): Promise<void> {
    const result = await LoadTestResultAPI.deleteResult(projectId, loadTestId, resultId);
    if (result.success) {
      this.removeResult(resultId);
      this.updateResultCount(this.totalCount - 1, this.filteredCount - 1);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async restoreResult(projectId: string, loadTestId: string, resultId: string): Promise<void> {
    const result = await LoadTestResultAPI.restoreResult(projectId, loadTestId, resultId);
    if (result.success && result.data) {
      this.removeResult(resultId);
      this.updateResultCount(this.totalCount - 1, this.filteredCount - 1);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteResults(projectId: string, loadTestId: string, results: LoadTestResult[]): Promise<void> {
    const result = await LoadTestResultAPI.deleteResults(projectId, loadTestId, results.map((r) => ({ id: r.id, version: r.version })));
    if (result.success) {
      results.forEach((r) => this.removeResult(r.id));
      this.updateResultCount(this.totalCount - results.length, this.filteredCount - results.length);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  // ~~~~~~~~~~~
  // Share Report
  // ~~~~~~~~~~~
  @action.bound
  public async shareReport(projectId: string,
    reportId: string,
    useGlobal: boolean,
    validFromMilliseconds: number,
    validToMilliseconds: number)
    : Promise<void> {
    const result = await LoadTestReportAPI.shareReport(projectId, reportId, useGlobal, validFromMilliseconds, validToMilliseconds);
    if (result.success && result.data) {
      this.putReport(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async unshareReport(projectId: string, reportId: string): Promise<void> {
    const result = await LoadTestReportAPI.unshareReport(projectId, reportId);
    if (result.success && result.data) {
      this.putReport(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  // ~~~~~~~~~~~
  // Share Result
  // ~~~~~~~~~~~
  @action.bound
  public async shareResult(
    projectId: string,
    resultId: string,
    useGlobal: boolean,
    validFromMilliseconds: number,
    validToMilliseconds: number)
    : Promise<void> {
    const result = await LoadTestResultAPI.shareResult(projectId, resultId, useGlobal, validFromMilliseconds, validToMilliseconds);
    if (result.success && result.data) {
      this.putResult(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async unshareResult(projectId: string, resultId: string): Promise<void> {
    const result = await LoadTestResultAPI.unshareResult(projectId, resultId);
    if (result.success && result.data) {
      this.putResult(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // update the project properties
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  @action.bound
  public async updateProperties(properties?: string, secretProperties?: string): Promise<void> {
    const result = await LoadTestProjectAPI.updateProperties(this._id, this._ver, properties, secretProperties);
    if (result.success && result.data) {
      this.setState({
        _ver: result.data._ver,
        settings: { ...this.settings, properties: result.data.properties, secretProperties: result.data.secretProperties }
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async setProjectStatus(hasRunning: boolean, hasScheduled: boolean) {
    this.setState({ status: { running: hasRunning, scheduled: hasScheduled } });
  }
}
