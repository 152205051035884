import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringMembersScene = asyncComponent(
  () => import(/* webpackChunkName: "monitoringMembers" */ "app/mon/project/scenes/MonitoringMembersScene")
    .then(({ default: scene }) => scene),
  "MonitoringMembersScene"
);

export { AsyncMonitoringMembersScene };
export default AsyncMonitoringMembersScene;
