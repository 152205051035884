import { AsyncAPIResult, fetch } from "app/utils/remote";

import { ApiAuditLogResult, AuditLogResult } from "../types";

export class AuditLogAPI {

  public static async systemAuditLogData(
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, typeCodes?: string[]
  ): AsyncAPIResult<AuditLogResult> {
    return fetch<AsyncAPIResult<AuditLogResult>>({
      url: "/api/core/audit",
      data: { start, size, sortby, q, from: fromMillis, to: toMillis, typeCodes }
    });
  }

  public static async systemApiAuditLogData(
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, activities?: string[]
  ): AsyncAPIResult<ApiAuditLogResult> {
    return fetch<AsyncAPIResult<ApiAuditLogResult>>({
      url: "/api/core/api-audit",
      data: { start, size, sortby, q, from: fromMillis, to: toMillis, activities }
    });
  }

  public static async projectUserAuditLogData(
    projectId: string,
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, typeCodes?: string[]
  ): AsyncAPIResult<AuditLogResult> {
    return fetch<AsyncAPIResult<AuditLogResult>>({
      url: `/api/core/audit/projects/${projectId}`,
      data: { start, size, sortby, q, from: fromMillis, to: toMillis, typeCodes }
    });
  }

  public static async projectApiAuditLogData(
    projectId: string,
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, activities?: string[]
  ): AsyncAPIResult<ApiAuditLogResult> {
    return fetch<AsyncAPIResult<ApiAuditLogResult>>({
      url: `/api/core/api-audit/projects/${projectId}`,
      data: { start, size, sortby, q, from: fromMillis, to: toMillis, activities }
    });
  }

  public static async tenantUserAuditLogData(
    tenantId: string,
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, typeCodes?: string[]
  ): AsyncAPIResult<AuditLogResult> {
    return fetch<AsyncAPIResult<AuditLogResult>>({
      url: `/api/core/audit/tenants/${tenantId}`,
      data: { start, size, sortby, q, from: fromMillis, to: toMillis, typeCodes }
    });
  }

  public static async tenantApiAuditLogData(
    tenantId: string,
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, activities?: string[]
  ): AsyncAPIResult<ApiAuditLogResult> {
    return fetch<AsyncAPIResult<ApiAuditLogResult>>({
      url: `/api/core/api-audit/tenants/${tenantId}`,
      data: { start, size, sortby, q, from: fromMillis, to: toMillis, activities }
    });
  }

}
