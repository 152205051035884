import * as React from "react";

import Divider, { DividerProps } from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

const StyledDivider = styled(Divider, {
  name: "XtcDivider",
  skipVariantsResolver: true,
  skipSx: true
})(({ theme }) => ({
    marginTop: theme.spacing(2)
}));


const CustomDivider: React.FunctionComponent<DividerProps> = (props) => <StyledDivider light {...props} />;

export { CustomDivider };
export default CustomDivider;
