/* eslint-disable */

export const messages = {
  "loading": "Lade Daten...",
  "setting.none": "Kein(s)",
  "route.logout": "Abmelden...",
  "labels.paging.pageSize": "Einträge pro Seite",
  "labels.header.myaccount": "Mein Konto",
  "labels.header.logout": "Abmelden",
  "labels.header.admin": "System",
  "labels.header.admin.users": "Nutzer",
  "labels.header.admin.tenants": "Organisationen",
  "labels.header.admin.projects": "Projekte",
  "labels.footer.contact": "Kontakt",
  "labels.footer.privacy": "Datenschutz",
  "labels.footer.currentTimezone": "Zeitzone:",
  "login.authToken.error": "Es ist ein Fehler beim Laden des Authentifierzungs-Tokens aufgetreten: {reason}",
  "login.error.email.empty": "Bitte geben Sie eine E-Mail-Adresse ein",
  "login.error.password.empty": "Bitte geben Sie ein Passwort ein",
  "login.error.userpass": "Die eingegebene Kombination aus E-Mail-Adresse und Passwort ist ungültig",
  "login.registerBtnLabel": "Registrieren",
  "login.resetPasswordBtnLabel": "Passwort vergessen",
  "login.email": "E-Mail",
  "login.password": "Passwort",
  "login.loginBtnLabel": "Anmelden",
  "dashboard.noTenants": "Sie sind derzeit keinen Organisationen zugeordnet.",
  "dashboard.noProjects": "Derzeit sind Sie bei keinem Projekt Mitglied.",
  "dashboard.userProjectCount": "Mitglied bei Projekten",
  "dashboard.projects.headline": "Meine Projekte",
  "dashboard.organizations.headline": "Meine Organisationen",
  "breadcrumb.home": "Übersicht",
  "breadcrumb.configuration": "Konfiguration",
  "breadcrumb.history": "Historie",
  "breadcrumb.history.details.log": "Ausführungslogbuch",
  "breadcrumb.history.details.resultBrowser": "Ausführungsdetails",
  "breadcrumb.account": "Mein Konto",
  "breadcrumb.users": "Nutzerverwaltung",
  "breadcrumb.tenants": "Organisationen",
  "breadcrumb.tenants.configure": "Konfiguration",
  "breadcrumb.admin.tenants": "Organisationsverwaltung",
  "breadcrumb.login": "Anmelden",
  "breadcrumb.projects": "Projekte",
  "breadcrumb.admin.projects": "Projektverwaltung",
  "breadcrumb.organizations": "Organisationen",
  "breadcrumb.dashboard": "Metriken",
  "breadcrumb.Admin": "Administrator",
  "breadcrumb.User": "Mitglied",
  "breadcrumb.Guest": "Gast",
  "breadcrumb.Reviewer": "Prüfer",
  "breadcrumb.Tester": "Tester",
  "breadcrumb.TestManager": "Testmanager",
  "navigation.home": "Übersicht",
  "navigation.projects": "Projekte",
  "navigation.organizations": "Organisationen",
  "locale.de": "Deutsch",
  "locale.en": "Englisch",
  "time.milliSeconds.short": "ms",
  "time.seconds.short": "sek",
  "time.minutes.short": "min",
  "time.hours.short": "std",
  "time.days.short": "d",
  "time.unit.ms": "ms",
  "table.sortBy": "Sortieren",
  "table.search": "Suchen",
  "filter.time.from": "Von",
  "filter.time.from.title": "Von Startzeitpunkt",
  "filter.time.to": "Bis",
  "filter.time.to.title": "Bis Endzeitpunkt",
  "filter.time.error.outOfRange": "Der Filter ist außerhalb der erwarteten Zeit eingestellt.",
  "filter.time.error.fromAfterTo": "Die gefilterte Startzeit ist nach der gefilterten Endzeit.",
  "application.error.session.expired": "Sitzung abgelaufen",
  "application.error.request.failure": "Serveranfrage fehlgeschlagen",
  "application.error.data.missing": "Die erforderlichen Daten konnten nicht vom Server geladen werden",
  "item.state.added": "Hinzugefügt",
  "item.state.excluded": "Entfernt",
  "account.resetPassword.heading": "Passwort zurücksetzen",
  "account.resetPassword.subheading": "Haben Sie Ihr Passwort vergessen? Keine Sorge. Fordern Sie hiermit einfach ein neues an. Sie erhalten eine E-Mail mit einem Code zum Ändern des Passworts, welcher im nächsten Schritt benötigt wird. Es ist wichtig, dass die Seite bis zum Ende des Prozesses geöffnet bleibt.",
  "account.resetPassword.email": "E-Mail-Adresse",
  "account.resetPassword.emailRepeat": "E-Mail-Adresse wiederholen",
  "account.resetPassword.resetBtnLabel": "Zurücksetzen",
  "account.resetPassword.cancelBtnLabel": "Abbrechen",
  "account.newPassword.heading": "Neues Passwort festlegen",
  "account.newPassword.subheading": "Sie haben eine E-Mail erhalten, die einen Code zum Ändern des Passworts enthält. Kopieren Sie diesen und fügen Sie ihn in das entsprechende Feld unten ein. Es ist nur eine begrenzte Zeit lang möglich, das Passwort zu ändern. Falls die Zeit abgelaufen ist, nutzen Sie erneut die 'Passwort vergessen'-Funktion auf der Anmeldeseite.",
  "account.newPassword.subheading.email": "Sie haben eine E-Mail auf \"{email}\" erhalten, die einen Code zum Ändern des Passworts enthält. Kopieren Sie diesen und fügen Sie ihn in das entsprechende Feld unten ein. Es ist nur eine begrenzte Zeit lang möglich, das Passwort zu ändern. Falls die Zeit abgelaufen ist, nutzen Sie erneut die 'Passwort vergessen'-Funktion auf der Anmeldeseite.",
  "account.newPassword.token": "Code (in E-Mail enthalten)",
  "account.newPassword.accept": "Akzeptieren",
  "account.newPassword.cancel": "Abbrechen",
  "account.notifications.newPassword": "Neues Passwort festgelegt. Loggen Sie sich mit Ihrem neuen XTC-Passwort ein.",
  "account.newPassword.error.noToken": "Darf nicht leer sein",
  "account.newPassword.error.passwordBlank": "Darf nicht leer sein",
  "account.newPassword.error.passwordRepeatBlank": "Darf nicht leer sein",
  "account.newPassword.error.passwordNoMatch": "Passwörter stimmen nicht überein",
  "account.newPassword.error.tokenOutdated": "Passwortänderung nicht mehr möglich. Nutzen Sie die 'Passwort vergessen'-Funktion auf der Anmeldeseite, um eine Passwortänderung erneut anzufordern.",
  "account.signup.heading": "Treten Sie den Test-Experten bei!",
  "account.signup.subheading": "Erstellen Sie ein persönliches Nutzerkonto",
  "account.signup.firstName": "Vorname",
  "account.signup.lastName": "Nachname",
  "account.signup.email": "E-Mail-Adresse",
  "account.signup.password": "Passwort",
  "account.signup.passwordRepeat": "Passwort wiederholen",
  "account.signup.registerBtnLabel": "Registrieren",
  "account.signup.cancelBtnLabel": "Abbrechen",
  "account.signupComplete.heading": "Vielen Dank für Ihre Anmeldung!",
  "account.signupComplete.description": "Bitte klicken Sie auf die untere Schaltfläche, um das Konto zu aktivieren und die Registrierung abzuschließen.",
  "account.signupComplete.activateBtnLabel": "Aktivieren",
  "account.notifications.activationTokenSent": "Sie haben es fast geschafft. Um die Registrierung abzuschließen, folgen Sie bitte den Anweisungen in der E-Mail, die soeben an die Adresse {email} gesendet wurde.",
  "account.notifications.signupComplete": "Herzlichen Glückwunsch, die Registrierung Ihres Kontos ist abgeschlossen und es kann jetzt verwendet werden. Viel Spaß mit XTC!",
  "account.notifications.passwordReset": "Ihr Code zum Ändern des Passworts wurde für \"{email}\" angefordert. Kopieren Sie den Code aus der E-Mail und ändern Sie jetzt das Passwort.",
  "account.settings.heading": "Nutzerkonto",
  "account.settings.heading.details": "Bearbeiten Sie die Details Ihres Nutzerkontos",
  "account.settings.credentials": "Zugangsdaten",
  "account.settings.credentials.description": "Verwalten Sie Ihre Zugangsdaten",
  "account.settings.general": "Benutzerprofil",
  "account.settings.general.description": "Verwalten Sie Ihr Benutzerprofil",
  "account.settings.preferences": "Einstellungen",
  "account.settings.preferences.description": "Bearbeiten Sie die Einstellungen Ihres Benutzerprofils",
  "account.settings.general.avatar": "Avatar",
  "account.settings.general.firstName": "Vorname",
  "account.settings.general.lastName": "Nachname",
  "account.settings.general.newFirstName": "Neuer Vorname",
  "account.settings.general.newLastName": "Neuer Nachname",
  "account.settings.general.newAvatar": "Wählen Sie ein Profilbild",
  "account.profile.saved": "Profil aktualisiert",
  "account.settings.general.saved": "Grundlegende Benutzerinformationen gespeichert",
  "account.settings.general.phone": "Telefonnummer",
  "account.settings.general.newPhone": "Ihre Telefonnummer",
  "account.settings.general.noPhone": "Keine Telefonnummer verfügbar",
  "account.settings.general.phone.verify": "Verifizieren",
  "account.settings.general.phone.help": "Ihre Telefonnummer wird für Folgendes benutzt:",
  "account.settings.general.phone.help.1": "Erhalt von Szenariobenachrichtigungen direkt auf dem Telefon per SMS.",
  "account.settings.general.phone.verification.explanation": "Um sicherzustellen, dass die Benachrichtigungen erhalten werden, ist eine Validierung nötig. Wenn Sie fortfahren, wird eine SMS mit einer sechsstelligen Nummer an {phoneNumber} gesendet. Diese muss im nächsten Dialog eingegeben werden.",
  "account.settings.general.phone.verification.title": "Verifizierung der Telefonnummer",
  "account.settings.general.phone.verification.code": "Verifizierungscode",
  "account.settings.general.phone.verification.incorrect": "Ungültiger Verifizierungscode",
  "account.settings.general.phone.verification.sent": "Eine SMS wurde an {phoneNumber} verschickt",
  "account.settings.general.phone.verification.verify": "Verifizieren",
  "account.settings.general.phone.remove.message": "Sind Sie sich sicher, dass Sie Ihre Telefonnummer entfernen wollen?",
  "account.settings.general.phone.remove.invalid": "Die übertragene Telefonnummer stimmt nicht mit der Telefonnummer des Kontos überein",
  "account.email.heading": "E-Mail-Adresse",
  "account.email.saved": "E-Mail-Adresse aktualisiert",
  "account.emailNew": "Neue E-Mail-Adresse",
  "account.emailNewReapeat": "E-Mail-Adresse wiederholen",
  "account.emailRepeat.noMatch": "E-Mail-Adressen stimmen nicht überein",
  "account.password": "Passwort",
  "account.password.heading": "Passwort",
  "account.password.save": "Akzeptieren",
  "account.password.saved": "Passwort aktualisiert",
  "account.password.saved.error": "Das Speichern des Passworts ist fehlgeschlagen",
  "account.passwordCurrent": "Aktuelles Passwort",
  "account.passwordNew": "Neues Passwort",
  "account.passwordNewReapeat": "Passwort wiederholen",
  "account.password.incorrect": "Das eingegebene Passwort stimmt nicht mit unseren Aufzeichnungen überein",
  "account.settings.preferences.saved": "Einstellung aktualisiert",
  "account.settings.preferences.locale": "Gebietsschema",
  "account.locale.saved": "Gebietsschema aktualisiert",
  "account.settings.preferences.locale.en": "Englisch (Vereinigte Staaten)",
  "account.settings.preferences.locale.de": "Deutsch",
  "account.settings.preferences.timezone": "Zeitzone",
  "account.settings.preferences.timezone.UTC": "UTC (Koordinierte Weltzeit)",
  "account.settings.preferences.newLocale": "Neues Gebietsschema",
  "account.settings.preferences.newTimezone": "Neue Zeitzone",
  "account.errors.login": "Anmeldung fehlgeschlagen",
  "admin.tenants.headline": "Organisationsverwaltung",
  "admin.tenants.details": "Allgemein",
  "admin.tenants.details.name": "Name",
  "admin.tenants.details.tenant": "Organisation",
  "admin.tenants.details.shortName": "Kürzel",
  "admin.tenants.details.description": "Beschreibung",
  "admin.tenants.details.adminUser": "Administrator",
  "admin.tenants.details.state": "Status",
  "admin.tenants.details.new": "Neu",
  "admin.tenants.details.del": "Löschen",
  "admin.tenants.details.save": "Speichern",
  "admin.tenants.details.addDate": "Erstellungsdatum",
  "admin.tenants.details.experimental": "Experimentelle Features",
  "admin.tenants.details.experimental.allowLt": "Erlaube das Erzeugen von Lasttest-Projekten",
  "admin.tenants.details.new.headline": "Neue Organisation",
  "admin.tenants.details.edit.headline": "Organisation bearbeiten",
  "admin.tenants.delete.confirm": "Das Löschen einer Organisation kann nicht rückgängig gemacht werden. Sind Sie sicher?",
  "admin.tenants.delete.error": "Organisation konnte nicht gelöscht werden",
  "admin.tenants.load.error": "Organisationsdaten konnten nicht geladen werden",
  "admin.tenants.save.error": "Organisationsdaten konnten nicht gespeichert werden",
  "admin.tenants.contextMenu.edit": "Bearbeiten",
  "admin.tenants.contextMenu.delete": "Löschen",
  "admin.tenants.contextMenu.lock": "Sperren",
  "admin.tenants.contextMenu.unlock": "Entsperren",
  "admin.tenants.multiple.locked": "Sperren",
  "admin.tenants.multiple.active": "Entsperren",
  "admin.tenants.error.shortName.duplicate": "Dieses Kürzel wird bereits verwendet",
  "admin.projects.details": "Systemeinstellungen",
  "admin.projects.details.menu": "Einstellungen",
  "admin.projects.details.accept": "Akzeptieren",
  "admin.projects.details.project": "Projekt",
  "admin.projects.details.type": "Typ",
  "admin.projects.details.edit": "Bearbeiten",
  "admin.projects.details.name": "Name",
  "admin.projects.details.adminUser": "Administrator",
  "admin.projects.details.state": "Status",
  "admin.projects.details.addDate": "Erstellungsdatum",
  "admin.projects.details.edit.headline": "Projekt bearbeiten",
  "admin.projects.contextMenu.edit": "Bearbeiten",
  "admin.projects.details.executor": "Exekutoren",
  "admin.projects.details.executor.description": "Legen Sie fest, welche Exekutoren für dieses Projekt provisioniert wurden.",
  "admin.projects.details.persistence": "Datenhaltung",
  "admin.projects.details.persistence.help": "Legen Sie fest, wie lange bestimmte Daten für dieses Projekt aufbewahrt werden sollen.",
  "admin.projects.details.persistence.runs.daysArtifactsSucceeded.edit": "Aufbewahrungszeit für Artefakte erfolgreicher Ausführungen (Tage)",
  "admin.projects.details.persistence.runs.daysArtifactsFailed.edit": "Aufbewahrungszeit für Artefakte sonstiger Ausführungen (Tage)",
  "admin.projects.details.persistence.runs.daysRuns.edit": "Aufbewahrungszeit für Ausführungen (Tage)",
  "admin.projects.details.grafana.url": "Adresse (URL)",
  "admin.projects.details.grafana.dashboardPath": "Grafana-Dashboard-Pfad",
  "admin.projects.details.grafana.orgId": "Organisations-ID",
  "admin.projects.details.grafana.authToken": "Authentifizierung",
  "admin.projects.details.grafana.authToken.help": "Legen Sie den Grafana-API-Key fest, welcher zur anonymisierten Anmeldung der Benutzer dieses Projekts verwendet werden soll.",
  "admin.projects.details.grafana.url.edit": "Grafana Adresse (URL)",
  "admin.projects.details.grafana.subPath": "Untergeordneter Grafana-Pfad",
  "admin.projects.details.grafana.dashboardPath.edit": "Grafana Dashboard-Pfad",
  "admin.projects.details.grafana.orgId.edit": "Grafana Organisations-ID",
  "admin.projects.details.grafana.authToken.edit": "Grafana API-Key",
  "admin.projects.details.grafana.charts": "Übersichtsdiagramme",
  "admin.projects.details.grafana.charts.help": "Konfigurieren Sie Diagramme, welche den Benutzern dieses Projekts auf der Projektübersicht angezeigt werden.",
  "admin.projects.details.grafana.charts.edit": "Übersichtsdiagramme",
  "admin.projects.details.grafana.chart.editing": "Diagramm bearbeiten",
  "admin.projects.details.grafana.chart.new": "Neues Diagramm",
  "admin.projects.details.grafana.chart.dashboardPath": "Grafana Dashboard-Pfad",
  "admin.projects.details.grafana.chart.panelID": "Grafana Panel-ID",
  "admin.projects.details.grafana.chart.wide": "Diagrammbreite",
  "admin.projects.details.grafana.chart.wide.false": "Schmal",
  "admin.projects.details.grafana.chart.wide.true": "Breit",
  "admin.projects.details.grafana.chart.back": "Akzeptieren",
  "admin.projects.details.grafana": "Grafana",
  "admin.projects.details.grafana.help": "Legen Sie die grundlegenden Grafana-Einstellungen fest. Diese werden benötigt, bevor weitere Einstellungen vorgenommen werden können.",
  "admin.projects.details.grafana.none": "Nicht konfiguriert",
  "validationError.dashboardPath": "Dashboard-Pfad ungültig",
  "validationError.panelId": "Panel-Identifikator ungültig",
  "validationError.authToken": "Authentifizierungs-Token ungültig",
  "admin.users.headline": "Nutzerverwaltung",
  "admin.users.details.logo": "Profilbild",
  "admin.users.details.user": "Benutzer",
  "admin.users.details.addDate": "Hinzugefügt am",
  "admin.users.details.name": "Name",
  "admin.users.details.firstName": "Vorname",
  "admin.users.details.lastName": "Nachname",
  "admin.users.details.lastLogin": "Letzter Login",
  "admin.users.details.email": "E-Mail-Adresse",
  "admin.users.details.role": "Systemrolle",
  "admin.users.details.state": "Status",
  "admin.users.details.new": "Neu",
  "admin.users.details.del": "Löschen",
  "admin.users.details.save": "Akzeptieren",
  "admin.users.details.new.headline": "Neuer Nutzer",
  "admin.users.details.edit.headline": "Nutzer bearbeiten",
  "admin.users.delete.confirm": "Das Löschen eines Nutzers kann nicht rückgängig gemacht werden. Sind Sie sicher?",
  "admin.users.contextMenu.edit": "Bearbeiten",
  "admin.users.contextMenu.delete": "Löschen",
  "admin.users.contextMenu.lock": "Sperren",
  "admin.users.contextMenu.unlock": "Entsperren",
  "admin.users.multiple.locked": "Sperren",
  "admin.users.multiple.active": "Aktivieren",
  "admin.tableView.noData": "Keine entsprechenden Einträge verfügbar",
  "tenant.details.admin": "Einstellungen",
  "tenant.details.heading": "Organisation",
  "tenant.details.subheading": "Details und Projekte",
  "tenant.details.projects.heading": "Projekte von {org}",
  "tenant.details.members": "Mitglieder",
  "tenant.details.projects": "Projekte",
  "tenant.admin.details": "Allgemein",
  "tenant.admin.details.help": "Spezifizieren Sie allgemeine Details wie Name oder Beschreibung. Diese sind für die meisten Nutzer sichtbar.",
  "tenant.admin.details.name": "Name",
  "tenant.admin.details.shortName": "Kürzel",
  "tenant.admin.details.description": "Beschreibung",
  "tenant.admin.logo": "Logo",
  "tenant.admin.logo.help": "Legen Sie ein Logo für diese Organisation fest. Dieses ist für die meisten Nutzer sichtbar.",
  "tenant.admin.heading": "Einstellungen für {org} bearbeiten",
  "tenant.admin.menu": "Bereiche",
  "tenant.admin.general": "Allgemein",
  "tenant.admin.members": "Mitglieder",
  "tenant.admin.projects": "Projekte",
  "tenant.admin.general.headline": "Details",
  "tenant.manage.members.addDate": "Beitrittsdatum",
  "tenant.manage.members.user": "Nutzer",
  "tenant.manage.members.name": "Name",
  "tenant.manage.members.email": "E-Mail-Adresse",
  "tenant.manage.members.role": "Rolle",
  "tenant.manage.members.state": "Status",
  "tenant.manage.members.del": "Entfernen",
  "tenant.manage.members.add": "Mitglieder hinzufügen",
  "tenant.manage.members.add.help": "Sie können einen oder mehrere Nutzer einer Organisation hinzufügen und ihnen gleichzeitig eine Rolle zuweisen. Beachten Sie, dass der oder die Nutzer sich mit den entsprechenden E-Mail-Adressen bereits registriert haben müssen.\n\nWenn Sie mehrere Nutzer auf einmal hinzufügen möchten, geben Sie einfach eine Liste (Komma, Leerzeichen oder Leerzeile als Trenner) von E-Mail-Adressen ein. Allen Nutzern wird die gleiche Rolle zugewiesen.",
  "tenant.manage.members.add.email": "Eingabe einer oder mehrerer E-Mail-Adressen separiert durch Komma, Leerzeichen oder Zeilenumbruch",
  "tenant.manage.members.delete.confirm": "Sind Sie wirklich sicher, dass Sie das ausgewählte Mitglied aus dieser Organisation und all ihren Projekten entfernen möchten?",
  "tenant.manage.members.edit": "Mitgliedschaft bearbeiten",
  "tenant.manage.members.edit.menu": "Bearbeiten",
  "tenant.manage.members.edit.email": "E-Mail-Adresse",
  "tenant.manage.members.edit.role": "Rolle",
  "tenant.manage.projects.addDate": "Erstellungsdatum",
  "tenant.manage.projects.project": "Projekt",
  "tenant.manage.projects.type": "Typ",
  "tenant.manage.projects.name": "Name",
  "tenant.manage.projects.shortName": "Kürzel",
  "tenant.manage.projects.description": "Beschreibung",
  "tenant.manage.projects.adminUser": "Administrator",
  "tenant.manage.projects.state": "Status",
  "tenant.manage.projects.save": "Akzeptieren",
  "tenant.manage.projects.delete.title": "Projekt{numProjects, plural, one {} other {e}} löschen",
  "tenant.manage.projects.delete.message": "Sie sind im Begriff, folgende{numProjects, plural, one {s} other {}} Projekt{numProjects, plural, one {} other {e}} zu löschen:",
  "tenant.manage.projects.delete.outcome": "Dies löscht {numProjects, plural, one {das} other {die}} Projekt{numProjects, plural, one {} other {e}} mit den dazugehörigen Daten.",
  "tenant.manage.project.edit.mon.headline": "Monitoring-Projekt bearbeiten",
  "tenant.manage.project.edit.lt.headline": "Lasttest-Projekt bearbeiten",
  "tenant.manage.projects.contextMenu.edit": "Bearbeiten",
  "tenant.manage.projects.contextMenu.delete": "Löschen",
  "tenant.manage.projects.contextMenu.clone": "Duplizieren",
  "tenant.manage.projects.clone.mon.headline": "Monitoring-Projekt duplizieren",
  "tenant.manage.projects.clone.lt.headline": "Lasttest-Projekt duplizieren",
  "tenant.manage.projects.clone.help": "Kopiert das ausgewählte Projekt inkl. der gesamten Konfiguration. Beachten Sie bitte, dass die erstellte Kopie dennoch explizit aktiviert werden muss.",
  "tenant.manage.projects.create.mon": "Monitoring-Projekt",
  "tenant.manage.projects.create.lt": "Lasttest-Projekt",
  "tenant.manage.projects.add.mon": "Neues Monitoring-Projekt",
  "tenant.manage.projects.add.lt": "Neues Lasttest-Projekt",
  "tenant.manage.project.edit.name": "Name",
  "tenant.manage.project.edit.shortName": "Kürzel",
  "tenant.manage.project.edit.add": "Akzeptieren",
  "tenant.monitoring.status.errorsLastDay": "Fehlschläge in den letzen 24 Std.",
  "project.state.active": "Aktiviert",
  "project.state.inactive": "Deaktiviert",
  "project.state.accept": "Akzeptieren",
  "project.details.edit.state": "Status",
  "project.details.edit.projectName": "Name",
  "project.details.edit.projectName.error.empty": "Name ist leer",
  "project.details.edit.projectShortName": "Kürzel",
  "project.details.edit.projectDescription": "Beschreibung",
  "project.details.edit.accept": "Akzeptieren",
  "project.details.health.state.ok": "Zustand gut",
  "project.details.health.state.warning": "Warnung:",
  "project.details.health.state.critical": "Kritisch: ",
  "project.details.health.state.fatal": "Schwerwiegend:",
  "project.details.date.showing.from": "Zeitraum von",
  "project.details.date.showing.to": "bis",
  "project.details.date.from": "Zeitraum von",
  "project.details.date.to": "bis",
  "project.details.validations": "Prüfkriterien",
  "project.details.validations.add": "Neues Prüfkriterium",
  "project.details.validation.accept": "Akzeptieren",
  "project.details.validations.type.RequestRuntime": "Anfragelaufzeit",
  "project.details.validations.type.RequestResponseSize": "Antwortgröße",
  "project.details.validations.type.RequestFailure": "Anfragefehler",
  "project.details.charts.add": "Neues Diagramm",
  "project.details.chart.edit.accept": "Akzeptieren",
  "project.details.overview": "Zusammenfassung",
  "project.details.overview.time.select.M": "Zeige die letzten {minutes} Minuten",
  "project.details.overview.time.select.H": "Zeige die letzte Stunde",
  "project.details.overview.time.select.Hs": "Zeige die letzten {hours} Stunden",
  "project.details.overview.time.select.D": "Zeige die letzten 24 Stunden",
  "project.details.overview.time.select.Ds": "Zeige die letzten {days} Tage",
  "project.details.overview.time.select.HM": "Zeige die letzten Stunde und {minutes} Minuten",
  "project.details.overview.time.select.HsM": "Zeige die letzten {hours} Stunden und {minutes} Minuten",
  "project.details.overview.last": "Zeige die vorangegangenen",
  "project.details.overview.last.hours": "Stunden",
  "project.details.overview.last.minutes": "Minuten",
  "project.details.overview.charts.timeDiffWarning": "Zeitdarstellung in Diagrammen mit abweichender Zeitzone",
  "project.details.summary.status.critical": "Letzte Ausführung fehlgeschlagen",
  "project.details.summary.status.ok": "Letzte Ausführung erfolgreich",
  "project.details.summary.status.unknown": "Keine Ausführungsergebnisse",
  "project.details.summary.errorCount": "Fehlschläge (24 Std.)",
  "project.details.summary.scenario.noLastFail": "Keine Fehlschläge (24 Std.)",
  "project.details.summary.scenario.lastFail": "Letzer Fehlschlag",
  "project.details.summary.scenario.noResults": "Keine Ergebnisse (24 Std.)",
  "project.details.summary.scenario.successCount": "Erfolg",
  "project.details.summary.scenario.errorCount": "Kritisch",
  "project.details.summary.scenario.failCount": "Fehler",
  "project.details.summary.scenario.abortedCount": "Abgebrochen",
  "project.details.execution": "Ausführung",
  "project.details.dashboard": "Metriken",
  "project.details.dashboard.timeDiffWarning": "Zeitdarstellung in Metriken mit abweichender Zeitzone",
  "project.details.history": "Ausführungshistorie",
  "project.details.history.details": "Details",
  "project.details.history.filter.state": "Erfolgreiche Ausführungen verbergen",
  "project.details.history.timers": "Messdaten",
  "project.details.history.sortBy": "Sortieren",
  "project.details.history.sortBy.id": "Ausführungsnummer",
  "project.details.history.sortBy.state": "Status",
  "project.details.history.sortBy.scriptName": "Szenarioname",
  "project.details.history.sortBy.startTime": "Startzeit",
  "project.details.history.sortBy.endTime": "Endzeit",
  "project.details.history.sortBy.duration": "Laufzeit",
  "project.details.history.sortBy.actionName": "Fehlgeschlagene Aktion",
  "project.details.history.sortBy.error": "Fehlertyp",
  "project.details.history.state.error": "Fehler",
  "project.details.history.resultBrowser": "Ausführungsdetails",
  "project.details.history.logs": "Ausführungslogbuch",
  "project.details.history.logs.timeDiffWarning": "Zeitdarstellung im Ausführungslogbuch mit abweichender Zeitzone",
  "project.details.history.run": "Ausführungsnummer",
  "project.details.history.start": "Startzeit",
  "project.details.history.location": "Region",
  "project.details.history.retry": "Wiederholungslauf",
  "project.details.history.executionReason": "Ausführungsgrund",
  "project.details.history.executionReason.regular": "Reguläre Ausführung",
  "project.details.history.executionReason.retry": "Wiederholungslauf Nr. ",
  "project.details.history.include": "Einschließlich",
  "project.details.history.success.include": "Erfolge eingeblendet",
  "project.details.history.success.exclude": "Erfolge ausgeblendet",
  "project.details.history.warning.include": "Probleme eingeblendet",
  "project.details.history.warning.exclude": "Probleme ausgeblendet",
  "project.details.history.error.include": "Fehler eingeblendet",
  "project.details.history.error.exclude": "Fehler ausgeblendet",
  "project.details.history.aborted.include": "Abbrüche eingeblendet",
  "project.details.history.aborted.exclude": "Abbrüche ausgeblendet",
  "project.details.history.running.include": "Laufende eingeblendet",
  "project.details.history.running.exclude": "Laufende ausgeblendet",
  "project.details.history.action.failed": "Aktion fehlgeschlagen",
  "project.details.history.unknown.failed": "Fehler aufgetreten",
  "project.details.history.criterion.failed": "Erfolgskriterium nicht erfüllt",
  "project.details.history.criterion.failed.message": "{value} {unit} überschreitet das Limit von {limit} {unit}",
  "project.details.history.criteria.failed": "Erfolgskriterien nicht erfüllt",
  "project.details.history.criteria.failed.message": "Mehrere Erfolgskriterien wurden nicht erfüllt",
  "project.details.history.state.ok": "Erfolgreich",
  "project.details.history.state.ok.message": "Das Szenario wurde erfolgreich beendet.",
  "project.details.history.state.scenario.failed.message": "Szenario fehlgeschlagen",
  "project.details.history.state.scenario.failed.at": "am",
  "project.details.history.state.scenario.failed.inAction": "in der Aktion",
  "project.details.history.state.scenario.failed.withMessage": "mit folgender Meldung",
  "project.details.history.state.scenario.error": "Fehler aufgetreten",
  "project.details.history.state.error.message": "Aufgrund eines Fehlers konnte das Szenario nicht ausgeführt werden.",
  "project.details.history.state.error.insufficientPermissionForLog": "Kontaktieren Sie Ihren Vorgesetzten, wenn Sie mehr Informationen benötigen.",
  "project.details.history.state.error.lookAtLog": "Weitere Informationen könnten im Ausführungslogbuch vorliegen.",
  "project.details.history.error.causes": "Mögliche Ursachen für diesen Fehler könnten folgende sein:",
  "project.details.history.error.causes.list.1": "Das Java-Projekt in der Quellcode-Ablage konnte nicht erfolgreich kompiliert werden.",
  "project.details.history.error.causes.list.2": "Die Quellcode-Ablage ist fehlkonfiguriert.",
  "project.details.history.error.causes.list.3": "Die Quellcode-Ablage ist nicht erreichbar.",
  "project.details.history.error.causes.list.4": "Der Klassenname, welcher am Szenario konfiguriert ist, bezeichnet keine existierende Java-Klasse in der Quellcode-Ablage.",
  "project.details.history.state.warning.action": "Fehlgeschlagene Aktion",
  "project.details.history.state.warning.noAction": "Fehler",
  "project.details.history.state.warning.successCriteria": "Erfolgskriterien nicht erfüllt",
  "project.details.history.state.warning.successCriteria.message": "Ein oder mehrere Erfolgskriterien wurden nicht erfüllt. Detaillierte Informationen können der Kriterienliste entnommen werden.",
  "project.details.history.state.warning.message": "Das Szenario ist mit folgender Nachricht fehlgeschlagen:",
  "project.details.history.state.aborted": "Abgebrochen",
  "project.details.history.state.aborted.message": "Die Ausführung dauerte zu lange und wurde abgebrochen.",
  "project.details.history.state.running.message": "Wird gerade ausgeführt",
  "project.details.history.duration": "Laufzeit",
  "project.details.history.end": "Endzeit",
  "project.details.history.executionResult": "Ergebnis der Ausführung",
  "project.details.history.successCriteria": "Erfolgskriterien",
  "project.details.history.failedRequests": "Fehlgeschlagene Anfragen",
  "project.details.history.slowRequests": "Langlaufende Anfragen",
  "project.details.history.stackTrace": "Fehlerprotokoll",
  "project.details.history.quietPeriod": "Innerhalb einer Ruhezeit",
  "project.details.history.quietPeriod.active": "Ja",
  "project.details.history.quietPeriod.inactive": "Nein",
  "project.details.configuration": "Konfiguration",
  "project.details.configuration.menu": "Einstellungen",
  "project.details.configuration.repository": "Quellcode-Ablage",
  "project.details.configuration.persistence": "Datenaufbewahrung",
  "project.details.configuration.execution": "Ausführung",
  "project.details.configuration.scenarios": "Szenarien",
  "project.details.configuration.scenarios.defaults": "Szenariovoreinstellungen",
  "project.details.configuration.scenarios.defaults.help": "Legen Sie die grundlegenden Einstellungen für alle Szenarien fest. Diese können individuell für jedes Szenario überschrieben werden.",
  "project.details.configuration.criterias": "Erfolgskriterien",
  "project.details.configuration.error.reset": "Zurücksetzen der Einstellungen fehlgeschlagen.",
  "project.details.configuration.general": "Allgemein",
  "project.details.configuration.general.help": "Legen Sie die allgemeinen Details wie Name und Beschreibung des Projektes fest. Diese sind für die meisten Mitglieder der Organisation sichtbar.",
  "project.details.configuration.general.status": "Status",
  "project.details.configuration.general.name": "Name",
  "project.details.configuration.general.shortName": "Kürzel",
  "project.details.configuration.general.description": "Beschreibung",
  "project.details.configuration.general.logo": "Projekt-Logo",
  "project.details.configuration.general.logo.help": "Wählen Sie ein Logo für dieses Projekt. Dieses ist für die meisten Mitglieder der Organisation sichtbar.",
  "project.details.configuration.repository.none": "Nicht konfiguriert",
  "project.details.configuration.repository.location": "Quellcode-Ablage",
  "project.details.configuration.repository.location.help": "Legen Sie den Aufbewahrungsort des Quellcodes sowie den relativen Pfad zur Test-Suite fest.",
  "project.details.configuration.repository.location.none": "Nicht konfiguriert",
  "project.details.configuration.repository.location.url": "Adresse der Quellcode-Ablage (URL)",
  "project.details.configuration.repository.location.url.edit": "Adresse der Quellcode-Ablage (URL)",
  "project.details.configuration.repository.location.suitePath": "Relativer Pfad zur Test-Suite",
  "project.details.configuration.repository.location.suitePath.edit": "Relativer Pfad zur Test-Suite",
  "project.details.configuration.repository.auth": "Authentifizierung",
  "project.details.configuration.repository.auth.help": "Legen Sie die Authentifizierungsinformationen fest, falls der Zugang zur Quellcode-Ablage beschränkt ist.",
  "project.details.configuration.repository.auth.none": "Keine Authentifizierung / Anonymer Zugang",
  "project.details.configuration.repository.auth.username": "Benutzername",
  "project.details.configuration.repository.auth.password": "Passwort",
  "project.details.configuration.repository.auth.passphrase": "Schlüssel-Kennwort",
  "project.details.configuration.repository.auth.key": "SSH-Schlüssel",
  "project.details.configuration.repository.auth.key.description": "Privater Schlüssel",
  "project.details.configuration.repository.auth.newPassword": "Neues Passwort",
  "project.details.configuration.repository.auth.newPassphrase": "Neues Schlüssel-Kennwort",
  "project.details.configuration.repository.auth.newKey": "Neuer OpenSSH-kompatibler privater Schlüssel",
  "project.details.configuration.repository.auth.type": "Authentifizierungsmethode",
  "project.details.configuration.repository.auth.type.creds": "Anmeldedaten",
  "project.details.configuration.repository.auth.type.sshkey": "SSH-Schlüssel",
  "project.details.configuration.repository.auth.remove.confirm": "Möchten Sie wirklich die Authentifizierungsinformationen löschen?",
  "project.details.configuration.repository.branch": "Versionierungszweig",
  "project.details.configuration.repository.branch.help": "Legen Sie den Namen des zu verwendenden Versionierungszweigs fest oder geben Sie die URL einer Ressource an, aus welcher der Name mit Hilfe eines regulären Ausdrucks ermittelt werden kann.",
  "project.details.configuration.repository.branch.help.staticOnly": "Legen Sie den Namen des zu verwendenden Versionierungszweigs fest.",
  "project.details.configuration.repository.branch.none": "Nicht konfiguriert",
  "project.details.configuration.repository.branch.name": "Name",
  "project.details.configuration.repository.branch.url": "Adresse (URL)",
  "project.details.configuration.repository.branch.regex": "Regulärer Ausdruck",
  "project.details.configuration.repository.branch.matchingGroup": "Übereinstimmungsgruppe",
  "project.details.configuration.repository.branch.type": "Typ",
  "project.details.configuration.repository.branch.type.static": "Fester Versionierungszweig",
  "project.details.configuration.repository.branch.type.static.description": "Fest/Statisch",
  "project.details.configuration.repository.branch.type.dynamic": "Dynamischer Versionierungszweig",
  "project.details.configuration.repository.branch.type.dynamic.description": "Dynamisch",
  "project.details.configuration.persistence.help": "Erfahren Sie, welche Daten wie lange aufbewahrt werden.",
  "project.details.configuration.persistence.accept": "Akzeptieren",
  "project.details.configuration.persistence.runs.expiration": "Ausführungen",
  "project.details.configuration.persistence.runs.expiration.artifacts": "Ausführungs-Artefakte",
  "project.details.configuration.persistence.runs.daysArtifactsFailed": "Sonstige",
  "project.details.configuration.persistence.runs.daysArtifactsSucceeded": "Erfolgreich",
  "project.details.configuration.persistence.runs.daysRuns": "Historie",
  "project.details.configuration.persistence.none": "Nicht konfiguriert",
  "project.details.configuration.execution.help": "Legen Sie fest, wie und wo die Szenarien ausgeführt werden sollen.",
  "project.details.configuration.execution.reset": "Möchten Sie wirklich die Ausführungseinstellungen auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.execution.interval": "Intervall",
  "project.details.configuration.execution.interval.edit": "Intervall",
  "project.details.configuration.execution.interval.reset": "Möchten Sie wirklich das Ausführungsintervall auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.execution.retry": "Automatische Wiederholung",
  "project.details.configuration.execution.retry.enabled": "Status",
  "project.details.configuration.execution.retry.enabled.active": "Aktiviert",
  "project.details.configuration.execution.retry.enabled.inactive": "Deaktiviert",
  "project.details.configuration.execution.retry.interval": "Intervall",
  "project.details.configuration.execution.retry.interval.edit": "Intervall",
  "project.details.configuration.execution.retry.count": "Versuche",
  "project.details.configuration.execution.retry.count.edit": "Versuche (max.)",
  "project.details.configuration.execution.retry.reset": "Möchten Sie wirklich die Wiederholungseinstellungen auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.execution.maxRuntime": "Maximale Laufzeit",
  "project.details.configuration.execution.maxRuntime.edit": "Maximale Laufzeit",
  "project.details.configuration.execution.maxRuntime.reset": "Möchten Sie wirklich die maximale Laufzeit auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.execution.error.reset": "Zurücksetzen der Ausführungseinstellungen fehlgeschlagen.",
  "project.details.configuration.execution.location": "Regionen",
  "project.details.configuration.execution.location.unavailable": "Unprovisioniert",
  "project.details.configuration.execution.location.available": "Provisioniert",
  "project.details.configuration.execution.location.excluded": "Entfernt",
  "project.details.configuration.execution.location.none": "Keine",
  "project.details.configuration.execution.location.noneAvailable": "Keine provisionierten Regionen verfügbar.",
  "project.details.configuration.execution.location.reset": "Möchten Sie wirklich die Regionsauswahl auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.execution.location.unavailable.show": "Unprovisionierte angezeigt",
  "project.details.configuration.execution.location.unavailable.hide": "Unprovisionierte verborgen",
  "project.details.configuration.execution.location.EU": "Europa",
  "project.details.configuration.execution.location.NA": "Amerika",
  "project.details.configuration.execution.location.AS": "Asien-Pazifik",
  "project.details.configuration.execution.location.Unknown": "Sonstige Regionen",
  "project.details.configuration.notifications": "Benachrichtigungen",
  "project.details.configuration.notifications.help": "Legen Sie fest, ob und an wen Benachrichtigungen versendet werden sollen.",
  "project.details.configuration.notifications.reset": "Möchten Sie wirklich die Benachrichtigungseinstellungen auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.notifications.type.email": "E-Mail-Adresse",
  "project.details.configuration.notifications.type.text": "SMS",
  "project.details.configuration.notifications.include": "Einschließlich",
  "project.details.configuration.notifications.subscriber": "Abonnent",
  "project.details.configuration.notifications.subscriberList": "Abonnentenliste",
  "project.details.configuration.notifications.subscriber.default": "(von den Szenariovoreinstellungen)",
  "project.details.configuration.notifications.subscriber.state": "Status",
  "project.details.configuration.notifications.subscriber.channels.active": "Aktive Kanäle",
  "project.details.configuration.notifications.subscriber.type.email.available": "E-Mail",
  "project.details.configuration.notifications.subscriber.type.email.unavailable": "E-Mail (Keine E-Mail-Adresse konfiguriert)",
  "project.details.configuration.notifications.subscriber.type.text.available": "SMS-Benachrichtigung",
  "project.details.configuration.notifications.subscriber.type.text.unavailable": "SMS-Benachrichtigung (Keine Telefonnummer hinterlegt)",
  "project.details.configuration.notifications.subscriber.list.empty": "Keine voreingestellten Abonnenten konfiguriert. Bitte konfigurieren Sie diese in den Szenariovoreinstellungen.",
  "project.details.configuration.notifications.scenario.remove": "Nutze Voreinstellungen",
  "project.details.configuration.notifications.scenario.remove.message": "Möchten Sie wirklich den Abonneten auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.notifications.scenario.lock": "Erzwinge inaktiv",
  "project.details.configuration.notifications.scenario.unlock": "Erzwinge aktiv",
  "project.details.configuration.notifications.active.slider": "Aktiviert",
  "project.details.configuration.notifications.inactive.slider": "Deaktiviert",
  "project.details.configuration.notifications.active": "Aktiv",
  "project.details.configuration.notifications.inactive": "Inaktiv",
  "project.details.configuration.notifications.excluded": "Entfernt",
  "project.details.configuration.notifications.count": "Aktive Abonnenten",
  "project.details.configuration.notifications.add.user": "Nutzer",
  "project.details.configuration.notifications.add.user.title": "Nutzer hinzufügen",
  "project.details.configuration.notifications.edit.user.title": "Nutzer bearbeiten",
  "project.details.configuration.notifications.add.custom": "Freier Eintrag",
  "project.details.configuration.notifications.add.custom.title": "Freien Eintrag hinzufügen",
  "project.details.configuration.notifications.edit.custom.title": "Freien Eintrag bearbeiten",
  "project.details.configuration.notifications.edit": "Bearbeiten",
  "project.details.configuration.notifications.lock": "Deaktivieren",
  "project.details.configuration.notifications.lock.warn": "Achtung! Alle Benachrichtigungen sind momentan deaktiviert.",
  "project.details.configuration.notifications.unlock": "Aktivieren",
  "project.details.configuration.notifications.remove": "Entfernen",
  "project.details.configuration.notifications.remove.message": "Möchten Sie den Abonnenten wirklich entfernen?",
  "project.details.configuration.notifications.edit.name": "Name des Abonnenten",
  "project.details.configuration.notifications.edit.email": "E-Mail-Adresse",
  "project.details.configuration.notifications.edit.text": "Telefonnummer",
  "project.details.configuration.notifications.edit.state": "Erhalte Benachrichtigung per",
  "project.details.configuration.notifications.failThreshold": "Schwellwert",
  "project.details.configuration.notifications.failThreshold.edit": "Mindestanzahl aufeinanderfolgender Fehlschläge",
  "project.details.configuration.notifications.failThreshold.single": "Verschicke eine Benachrichtigung, sobald ein Szenario fehlgeschlagen ist.",
  "project.details.configuration.notifications.failThreshold.multiple": "Verschicke eine Benachrichtigung, wenn ein Szenario mindestens {threshold} Mal in Folge fehlgeschlagen ist.",
  "project.details.configuration.notifications.failThreshold.reset": "Möchten Sie wirklich den Schwellwert für aufeinanderfolgende Fehlschläge auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.notifications.replyTo": "Anwortempfängeradresse",
  "project.details.configuration.notifications.replyTo.edit": "Anwortempfängeradresse",
  "project.details.configuration.notifications.replyTo.defined": "{address} wird als Standard-Anwortempfängeradresse verwendet.",
  "project.details.configuration.notifications.replyTo.none": "Keine Standard-Anwortempfängeradresse festgelegt.",
  "project.details.configuration.notifications.replyTo.reset": "Möchten Sie wirklich die Anwortempfängeradresse auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.properties": "Java-Properties",
  "project.details.configuration.properties.secret": "Geheime Java-Properties",
  "project.details.configuration.properties.none": "Keine Java-Properties definiert.",
  "project.details.configuration.properties.help": "Spezielle Java-Properties, die für die Ausführung verwendet werden.",
  "project.details.configuration.properties.secret.help": "Geheime Java-Properties, die für die Ausführung verwendet werden. Diese Properties stehen wie gewohnt zur Verfügung, werden aber in der UI und in Reports nicht wieder ausgegeben.",
  "project.details.configuration.properties.edit": "Java-Properties",
  "project.details.configuration.properties.reset": "Möchten Sie wirklich die Java-Properties auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.scenarios.help": "Verwalten Sie die Szenarien dieses Projektes.",
  "project.details.configuration.scenarios.add": "Neues Szenario",
  "project.details.configuration.scenarios.edit.help": "Definieren Sie szenariospezifische Einstellungen.",
  "project.details.configuration.scenario.close": "Zurück",
  "project.details.configuration.scenario.general": "Allgemein",
  "project.details.configuration.scenario.general.help": "Legen Sie die Szenariovoreinstellungen fest.",
  "project.details.configuration.scenario.overwritten": "Voreinstellungen überschrieben",
  "project.details.configuration.scenario.remove": "Löschen",
  "project.details.configuration.scenarios.remove.message": "Möchten Sie das Szenario wirklich löschen?",
  "project.details.configuration.scenario.edit": "Bearbeiten",
  "project.details.configuration.scenario.lock": "Deaktivieren",
  "project.details.configuration.scenario.unlock": "Aktivieren",
  "project.details.configuration.scenario.active": "Aktiv",
  "project.details.configuration.scenario.name": "Name",
  "project.details.configuration.scenario.name.edit": "Name",
  "project.details.configuration.scenario.description": "Beschreibung",
  "project.details.configuration.scenario.description.edit": "Beschreibung",
  "project.details.configuration.scenario.inactive": "Inaktiv",
  "project.details.configuration.scenario.javaClass": "Java-Klasse",
  "project.details.configuration.scenario.javaClass.edit": "Java-Klasse",
  "project.details.configuration.scenario.properties": "Java-Properties",
  "project.details.configuration.scenario.properties.reset": "Möchten Sie wirklich die voreingestellten Java-Properties verwenden?",
  "project.details.configuration.scenario.interval": "Intervall",
  "project.details.configuration.scenario.retry": "Wiederholungsversuch",
  "project.details.configuration.scenario.retry.enabled": "Status",
  "project.details.configuration.scenario.retry.enabled.active": "Aktiv",
  "project.details.configuration.scenario.retry.enabled.inactive": "Inaktiv",
  "project.details.configuration.scenario.retry.interval": "Intervall",
  "project.details.configuration.scenario.retry.count": "Anzahl",
  "project.details.configuration.scenario.maxRuntime": "Maximale Laufzeit",
  "project.details.configuration.scenario.criterions": "Erfolgskriterien",
  "project.details.configuration.memberships": "Mitglieder",
  "project.details.configuration.memberships.members": "Mitglieder",
  "project.details.configuration.memberships.role": "Rolle",
  "project.details.configuration.memberships.sort.firstName": "Vorname",
  "project.details.configuration.memberships.sort.lastName": "Nachname",
  "project.details.configuration.memberships.sort.email": "E-Mail-Adresse",
  "project.details.configuration.memberships.sort.role": "Rolle",
  "project.details.configuration.memberships.role.Admin": "Administrator",
  "project.details.configuration.memberships.role.Guest": "Gast",
  "project.details.configuration.memberships.role.Reviewer": "Prüfer",
  "project.details.configuration.memberships.role.Tester": "Tester",
  "project.details.configuration.memberships.role.TestManager": "Testmanager",
  "project.details.configuration.memberships.add.title": "Mitglieder hinzufügen",
  "project.details.configuration.memberships.add.help": "Sie können einen oder mehrere Nutzer einem Projekt hinzufügen und ihnen gleichzeitig eine Rolle zuweisen. Beachten Sie, dass der oder die Nutzer sich mit den entsprechenden E-Mail-Adressen bereits registriert haben müssen.\n\nWenn Sie mehrere Nutzer auf einmal hinzufügen möchten, geben Sie einfach eine Liste (Komma, Leerzeichen oder Leerzeile als Trenner) von E-Mail-Adressen ein. Allen Nutzern wird die gleiche Rolle zugewiesen.",
  "project.details.configuration.memberships.add.email": "E-Mail-Adresse",
  "project.details.configuration.memberships.add.email.help": "Eingabe einer oder mehrerer durch Komma, Leerzeichen oder Leerzeile getrennter E-Mail-Adressen",
  "project.details.configuration.memberships.add.role": "Rolle",
  "project.details.configuration.memberships.add.role.help": "Wählen Sie die gewünschte Projekt-Rolle aus",
  "project.details.configuration.memberships.remove": "Entfernen",
  "project.details.configuration.memberships.remove.title": "Nutzer von Projekt entfernen",
  "project.details.configuration.memberships.remove.accept": "Nutzer entfernen",
  "project.details.configuration.memberships.remove.message": "Möchten Sie {name} wirklich entfernen? ",
  "project.details.configuration.memberships.edit": "Mitgliedschaft bearbeiten",
  "project.details.configuration.memberships.edit.menu": "Bearbeiten",
  "project.details.configuration.memberships.edit.accept": "Akzeptieren",
  "project.details.configuration.memberships.edit.role": "Rolle",
  "project.details.configuration.criterions": "Erfolgskriterien",
  "project.details.configuration.criterions.help": "Bestimmen und konfigurieren Sie die Kriterien, die nach der Ausführung des Szenarios ausgewertet werden und bei Nichtbestehen zu einem Fehler führen.",
  "project.details.configuration.criterions.name": "Kriterium",
  "project.details.configuration.criterions.value": "Grenzwert",
  "project.details.configuration.criterions.edit": "Bearbeiten",
  "project.details.configuration.criterions.remove": "Entfernen",
  "project.details.configuration.criterions.remove.message": "Möchten Sie das Erfolgskriterium wirklich entfernen?",
  "project.details.configuration.criterions.allInUse": "Alle verfügbaren Erfolgskriterien werden bereits verwendet.",
  "project.details.configuration.criterions.types": "Erfolgskriterium",
  "project.details.configuration.criterions.accept": "Akzeptieren",
  "project.details.configuration.criterions.reset": "Möchten Sie wirklich die Erfolgskriterien auf die Szenariovoreinstellungen zurücksetzen?",
  "project.details.configuration.criterions.removed": "Entfernt",
  "project.details.configuration.criterion.add": "Kriterium hinzufügen",
  "project.details.configuration.criterion.edit": "Kriterium bearbeiten",
  "project.details.configuration.credentials": "Zugangsdaten",
  "project.details.configuration.credentials.remove": "Zugangsdaten löschen",
  "project.details.configuration.credentials.aws": "AWS-Zugangsdaten",
  "project.details.configuration.credentials.aws.help": "Konfigurieren Sie Ihre Zugangsdaten für Amazon Web Services.",
  "project.details.configuration.credentials.aws.none": "Keine AWS-Zugangsdaten konfiguriert.",
  "project.details.configuration.credentials.aws.accept": "Akzeptieren",
  "project.details.configuration.credentials.aws.label": "Name",
  "project.details.configuration.credentials.aws.access": "Zugriffsschlüssel-ID",
  "project.details.configuration.credentials.aws.secret": "Geheimer Zugriffsschlüssel",
  "project.details.configuration.credentials.aws.remove.confirm": "Möchten Sie wirklich Ihre AWS-Zugangsdaten löschen?",
  "project.details.configuration.credentials.gcp": "GCP-Zugangsdaten",
  "project.details.configuration.credentials.gcp.help": "Konfigurieren Sie Ihre Zugangsdaten für Google Cloud Platform.",
  "project.details.configuration.credentials.gcp.none": "Keine GCP-Zugangsdaten konfiguriert.",
  "project.details.configuration.credentials.gcp.accept": "Akzeptieren",
  "project.details.configuration.credentials.gcp.label": "Name",
  "project.details.configuration.credentials.gcp.project": "Projekt-ID",
  "project.details.configuration.credentials.gcp.account": "Zugangs-Token",
  "project.details.configuration.credentials.gcp.account.new": "Zugangs-Token (als JSON)",
  "project.details.configuration.credentials.gcp.remove.confirm": "Möchten Sie wirklich Ihre GCP-Zugangsdaten löschen?",

  // Project Maintenance Windows
  "project.details.configuration.quietPeriod": "Ruhezeiten",
  "project.details.configuration.quietPeriod.help": "Stellen Sie hier Ihre Ruhezeiten ein. In diesen Zeiträumen werden Ihnen keine Benachrichtigungen für dieses Projekt gesendet.",
  "project.details.configuration.quietPeriod.add.title": "Ruhezeit erstellen",
  "project.details.configuration.quietPeriod.edit.title": "Ruhezeit bearbeiten",
  "project.details.configuration.quietPeriod.update.accept": "Akzeptieren",
  "project.details.configuration.quietPeriod.label": "Name",
  "project.details.configuration.quietPeriod.timeRange": "Zeitspanne",
  "project.details.configuration.quietPeriod.timeRange.order": "Der Endzeitpunkt darf nicht vor dem Startzeitpunkt sein.",
  "project.details.configuration.quietPeriod.timeRange.past": "Der Endzeitpunkt liegt in der Vergangenheit.",
  "project.details.configuration.quietPeriod.startTime": "Startzeitpunkt",
  "project.details.configuration.quietPeriod.endTime": "Endzeitpunkt",
  "project.details.configuration.quietPeriod.executeScenarios": "Szenarien ausführen",
  "project.details.configuration.quietPeriod.executeScenarios.enabled": "Ausführen",
  "project.details.configuration.quietPeriod.executeScenarios.disabled": "Nicht ausführen",
  "project.details.configuration.quietPeriod.state": "Aktiviert",
  "project.details.configuration.quietPeriod.state.enabled": "Aktivieren",
  "project.details.configuration.quietPeriod.state.disabled": "Deaktivieren",
  "project.details.configuration.quietPeriod.edit.menu": "Bearbeiten",
  "project.details.configuration.quietPeriod.remove": "Entfernen",
  "project.details.configuration.quietPeriod.remove.message": "Möchten Sie wirklich diese Ruhezeit entfernen?",

  "criterion.REQUEST_TIME": "Maximale Anfragelaufzeit",
  "criterion.value.REQUEST_TIME": "Millisekunden",
  "criterion.unit.REQUEST_TIME": "ms",
  "criterion.REQUEST_ERRORS": "Maximale Anfragefehler",
  "criterion.value.REQUEST_ERRORS": "Count",
  "criterion.ACTION_TIME": "Maximale Aktionslaufzeit",
  "criterion.value.ACTION_TIME": "Millisekunden",
  "criterion.unit.ACTION_TIME": "ms",
  "criterion.TRANSACTION_TIME": "Maximale Transaktionslaufzeit",
  "criterion.value.TRANSACTION_TIME": "Millisekunden",
  "criterion.unit.TRANSACTION_TIME": "ms",
  "criterion.DOM_LOAD_TIME": "Maximale Zeit bis zum 'DOMContentLoaded'-Event",
  "criterion.value.DOM_LOAD_TIME": "Millisekunden",
  "criterion.unit.DOM_LOAD_TIME": "ms",
  "criterion.PAGE_LOAD_TIME": "Maximale Zeit bis zum 'Load'-Event",
  "criterion.value.PAGE_LOAD_TIME": "Millisekunden",
  "criterion.unit.PAGE_LOAD_TIME": "ms",
  "criterion.FIRST_PAINT_TIME": "Maximale Zeit bis zum 'FirstPaint'-Event",
  "criterion.value.FIRST_PAINT_TIME": "Millisekunden",
  "criterion.unit.FIRST_PAINT_TIME": "ms",
  "criterion.FIRST_CONTENTFUL_PAINT_TIME": "Maximale Zeit bis zum 'FirstContentfulPaint'-Event",
  "criterion.value.FIRST_CONTENTFUL_PAINT_TIME": "Millisekunden",
  "criterion.unit.FIRST_CONTENTFUL_PAINT_TIME": "ms",
  "project.details.system": "System",
  "project.details.system.accept": "Akzeptieren",
  "project.details.system.log": "Logbuch",
  "project.mon": "Monitoring",
  "project.lt": "Lasttest",
  "dialog.button.label.accept": "Ja, fortfahren",
  "dialog.button.label.cancel": "Nein, abbrechen",
  "dialog.delete.order": "Um ein versehentliches Löschen zu vermeiden, bitten wir Sie, \"{agree}\" in das untere Feld einzugeben.",
  "dialog.delete.confirm": "Bestätigung",
  "roles.system.User": "Standardnutzer",
  "roles.system.BillingAdmin": "Abrechnungs-Administrator",
  "roles.system.Admin": "Administrator",
  "roles.tenant.Guest": "Gast",
  "roles.tenant.User": "Mitglied",
  "roles.tenant.BillingAdmin": "Abrechnungs-Administrator",
  "roles.tenant.Admin": "Administrator",
  "tenant.state.Active": "Aktiv",
  "tenant.state.Locked": "Inaktiv",
  "user.state.New": "Neu",
  "user.state.Active": "Aktiv",
  "user.state.Locked": "Inaktiv",
  "project.state.Active": "Aktiv",
  "project.state.Locked": "Inaktiv",
  "validation.minValue": "Wert muss größer oder gleich {val, number} sein",
  "validation.maxValue": "Wert muss kleiner oder gleich {val, number} sein",
  "validation.noMatch": "Die eingegebenen Daten entsprechen nicht unseren Aufzeichnungen",
  "validation.passwordRepeat.noMatch": "Passwörter stimmen nicht überein",
  "validation.email": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
  "validation.email.unusable": "E-Mail-Adresse kann nicht verwendet werden. Bitte geben Sie eine andere ein.",
  "validation.phone": "Muss mit einem '+' beginnen und mindestens 5 Ziffern enthalten",
  "validation.emailRepeat.noMatch": "E-Mail-Adressen stimmen nicht überein",
  "validation.immutable": "Nicht veränderbar",
  "validation.password": "Mindestens 8 Zeichen und keine führenden/folgenden Leerzeichen",
  "validation.notSupported": "Nicht unterstützt",
  "validation.notUnique": "Ist bereits vergeben",
  "validation.notBlank": "Darf nicht leer sein",
  "validation.requirePassword": "Diese Aktion benötigt ein gültiges Password",
  "validation.url": "Adresse (URL) ungültig",
  "validation.notFound": "Existiert nicht",
  "validation.notFoundOrMember": "Dieser Nutzer existiert im Projekt nicht.",
  "validation.relativeFilePath": "Pfad muss relativ sein",
  "validation.resourceName": "Muss mit einem Kleinbuchstaben beginnen, gefolgt von max. 39 Kleinbuchstaben, Ziffern und '-'. Darf nicht mit einem '-' enden.",
  "validation.unknownUser": "Nutzer unbekannt",
  "validation.parameter.invalid": "Ungültiger Wert",
  "validation.sshkey": "Schlüssel ungültig",
  "validation.duplicate": "Existiert bereits",
  "validation.member.duplicate": "Nutzer ist bereits Mitglied",
  "validation.no.selfedit": "Sie dürfen sich selbst nicht entfernen oder ändern. Bitte konsultieren Sie hierfür einen anderen Administrator.",
  "validation.atLeastOneChannel": "Mindestens ein Kanal muss eingetragen sein.",
  "validation.noneSelected": "Selektieren Sie mindestens einen Eintrag",
  "validation.noneBlank": "Einträge dürfen nicht leer sein",
  "validation.aws.accessId": "Zugriffsschlüssel-ID muss 20 Zeichen haben und darf nur aus Großbuchstaben und Ziffern bestehen.",
  "validation.aws.secretKey": "Geheimer Zugriffsschlüssel muss 40 Zeichen haben und darf nur aus Buchstaben, Ziffern und den Zeichen '/', '+' und '=' bestehen.",
  "validation.futureDate": "Der ausgewählte Zeitpunkt muss in der Zukunft liegen",
  "validation.successCriterion.name": "Kein gültiges Erfolgskriterium",
  "validation.successCriterion.value": "Ungültiger Wert für dieses Erfolgskriterium",
  "validation.tenantWithProjects.no.delete": "Organisation kann nicht gelöscht werden, solange dazugehörige Projekte existieren",

  "error.permission.denied": "Zugriff verweigert",
  "error.history.details.notFound": "Ausführungsdetails nicht gefunden",
  "error.notification.subscriber.notFound": "Abonnent unbekannt",
  "error.notification.subscriber.duplicate": "Abonnent ist bereits gelistet",
  "error.projectUnknown": "Projekt existiert nicht (mehr)",
  "error.phone.notSent": "Die angegebene Nummer konnte nicht erreicht werden. Bitte stellen Sie sicher, dass die Nummer korrekt ist und probieren Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie uns.",
  "error.technical": "Ein technischer Fehler ist aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie uns.",
  "error.inactive.beta": "Diese Beta-Funktionalität steht gegenwärtig nicht zur Verfügung.",
  "error.no.archive": "Repository konnte nicht gescannt werden.",
  "location.long.unknown": "Unbekannte Region",
  "location.long.asia-east1": "Taiwan",
  "location.long.asia-east2": "Hongkong",
  "location.long.asia-northeast1": "Tokio, Japan",
  "location.long.asia-northeast2": "Osaka, Japan",
  "location.long.asia-south1": "Mumbai, Indien",
  "location.long.asia-southeast1": "Singapur",
  "location.long.australia-southeast1": "Sydney, Australien",
  "location.long.europe-north1": "Hamina, Finnland",
  "location.long.europe-west1": "St. Ghislain, Belgien",
  "location.long.europe-west2": "London, Großbritannien",
  "location.long.europe-west3": "Frankfurt, Deutschland",
  "location.long.europe-west4": "Eemshaven, Niederlande",
  "location.long.europe-west6": "Zürich, Schweiz",
  "location.long.northamerica-northeast1": "Montréal, Kanada",
  "location.long.southamerica-east1": "São Paulo, Brasilien",
  "location.long.us-central1": "Iowa, USA",
  "location.long.us-east1": "South Carolina, USA",
  "location.long.us-east4": "Nord-Virginia, USA",
  "location.long.us-west1": "Oregon, USA",
  "location.long.us-west2": "Los Angeles, USA",
  "location.full.unknown": "Unbekannte Region",
  "location.full.asia-east1": "Bezirk Changhua, Taiwan",
  "location.full.asia-east2": "Hongkong",
  "location.full.asia-northeast1": "Tokio, Japan",
  "location.full.asia-northeast2": "Osaka, Japan",
  "location.full.asia-south1": "Mumbai, Indien",
  "location.full.asia-southeast1": "Jurong West, Singapur",
  "location.full.australia-southeast1": "Sydney, Australien",
  "location.full.europe-north1": "Hamina, Finnland",
  "location.full.europe-west1": "St. Ghislain, Belgien",
  "location.full.europe-west2": "London, England, Großbritannien",
  "location.full.europe-west3": "Frankfurt, Deutschland",
  "location.full.europe-west4": "Eemshaven, Niederlande",
  "location.full.europe-west6": "Zürich, Schweiz",
  "location.full.northamerica-northeast1": "Montréal, Québec, Kanada",
  "location.full.southamerica-east1": "São Paulo, Brasilien",
  "location.full.us-central1": "Council Bluffs, Iowa, USA",
  "location.full.us-east1": "Moncks Corner, South Carolina, USA",
  "location.full.us-east4": "Ashburn, Virginia, USA",
  "location.full.us-west1": "The Dalles, Oregon, USA",
  "location.full.us-west2": "Los Angeles, Kalifornien, USA",

  // AWS - Americas
  "location.long.ca-central-1": "Zentralkanada",
  "location.long.us-east-1": "Virginia, USA",
  "location.long.us-east-2": "Ohio, USA",
  "location.long.us-west-1": "Kalifornien, USA",
  "location.long.us-west-2": "Oregon, USA",
  "location.long.sa-east-1": "São Paulo, Brasilien",
  // AWS - EMEA
  "location.long.eu-north-1": "Stockholm, Schweden",
  "location.long.eu-central-1": "Frankfurt, Deutschland",
  "location.long.eu-west-1": "Irland",
  "location.long.eu-west-2": "London, Großbritannien",
  "location.long.eu-west-3": "Paris, Frankreich",
  "location.long.me-south-1": "Bahrain",
  // AWS - APAC
  "location.long.asia-northeast-1": "Tokio, Japan",
  "location.long.asia-northeast-2": "Seoul, Südkorea",
  "location.long.asia-east-1": "Hongkong",
  "location.long.asia-south-1": "Mumbai, Indien",
  "location.long.asia-southeast-1": "Singapur",
  "location.long.asia-southeast-2": "Sydney, Australien",

  // LoadTestProjectRoute
  "LoadTestProjectRoute.loadTests": "Lasttests",

  // Load Tests
  "LoadTestsScene.title": "Lasttests",
  "breadcrumb.LoadTestsScene.loadTests": "Lasttests",

  // LoadTestTable
  "LoadTestTable.sort.default": "Voreinstellung",
  "LoadTestTable.header.name": "Name",
  "LoadTestTable.header.startDate": "Startdatum",
  "LoadTestTable.header.state": "Zustand",
  "LoadTestTable.header.summary": "Resümee",
  "LoadTestTable.header.rating": "Einstufung",
  "LoadTestTable.deleteLoadTestConfirmation.title": "Lasttest löschen",
  "LoadTestTable.deleteLoadTestConfirmation.message": "Möchten Sie den Lasttest \"{name}\" inklusive aller Ergebnisse wirklich löschen?",
  "LoadTestTable.startLoadTestConfirmation.title": "Lasttest starten",
  "LoadTestTable.startLoadTestConfirmation.accept": "Lasttest starten",
  "LoadTestTable.startLoadTestConfirmation.message": "Möchten Sie den Lasttest {name} jetzt starten?",
  "LoadTestTable.startLoadTestConfirmation.message.scheduled": "Lasttest {name} ist so konfiguriert, dass er am {date} automatisch ausgeführt wird. Möchten Sie den Lasttest wirklich jetzt starten?",

  // LoadTestTableEntry
  "LoadTestTableEntry.contextMenu.view": "Anzeigen",
  "LoadTestTableEntry.contextMenu.duplicate": "Duplizieren",
  "LoadTestTableEntry.contextMenu.start": "Starten",
  "LoadTestTableEntry.contextMenu.abort": "Abbrechen",
  "LoadTestTableEntry.contextMenu.delete": "Löschen",
  "LoadTestTableEntry.state.NEW": "Neu",
  "LoadTestTableEntry.state.STARTING": "Wird gestartet",
  "LoadTestTableEntry.state.PROVISIONING": "Wird provisioniert",
  "LoadTestTableEntry.state.RUNNING": "Wird ausgeführt",
  "LoadTestTableEntry.state.STOPPING": "Wird gestoppt",
  "LoadTestTableEntry.state.DEPROVISIONING": "Wird deprovisioniert",
  "LoadTestTableEntry.state.ABORTED": "Abgebrochen",
  "LoadTestTableEntry.state.FINISHED": "Beendet",

  // AddLoadTestDialog
  "AddLoadTestDialog.title": "Lasttest hinzufügen",
  "AddLoadTestDialog.fields.name": "Name",
  "AddLoadTestDialog.buttons.accept": "Akzeptieren",

  // DuplicateLoadTestDialog
  "DuplicateLoadTestDialog.title": "Lasttest duplizieren",
  "DuplicateLoadTestDialog.fields.name": "Neuer Name",
  "DuplicateLoadTestDialog.fields.description": "Neuer Kommentar",
  "DuplicateLoadTestDialog.buttons.accept": "Lasttest duplizieren",

  // AbortLoadTestDialog
  "AbortLoadTestDialog.title": "Lasttest abbrechen",
  "AbortLoadTestDialog.message": "Möchten Sie den Lasttest \"{name}\" wirklich abbrechen?",
  "AbortLoadTestDialog.saveResults": "Ergebnisse speichern",
  "AbortLoadTestDialog.createReport": "Lasttestreport erzeugen",

  // Load Tests > Load Test
  "LoadTestDetailsScene.tabs.settings": "Einstellungen",
  "LoadTestDetailsScene.tabs.scenarioStatus": "Szenariostatus",
  "LoadTestDetailsScene.tabs.liveMetrics": "Live-Metriken",
  "LoadTestDetailsScene.tabs.reports": "Reports",
  "LoadTestDetailsScene.tabs.results": "Ergebnisse",

  // Load Tests > Load Test > Configuration > General Settings
  "GeneralSettingsView.title": "Allgemeine Einstellungen",
  "GeneralSettingsView.help": "Machen Sie allgemeine Angaben zu diesem Lasttest.",
  "GeneralSettingsView.fields.name": "Name",
  "GeneralSettingsView.fields.description": "Beschreibung",

  // Load Tests > Load Test > Configuration > Unattended Execution
  "UnattendedExecutionOptionsView.title": "Unbeaufsichtigte Ausführung",
  "UnattendedExecutionOptionsView.help": "Konfigurieren Sie die Einstellungen für die unbeaufsichtigte Ausführung dieses Lasttests.",
  "UnattendedExecutionOptionsView.noneConfigured": "Nicht konfiguriert.",
  "UnattendedExecutionOptionsView.fields.startDate": "Startzeitpunkt",

  // Load Tests > Load Test > Configuration > Load Profile
  "LoadProfileView.title": "Lastprofil",
  "LoadProfileView.help": "Konfigurieren Sie das Lastprofil, das für diesen Lasttest verwendet werden soll.",
  "LoadProfileView.fields.testPropertiesFileName": "Datei mit Testeinstellungen",
  "LoadProfileView.fields.testPropertiesFileName.help": "Um Vorschläge erneut zu laden, öffnen Sie den Editor erneut.",
  "LoadProfileView.fields.duration": "Testdauer",
  "LoadProfileView.fields.loadFactor": "Lastfaktor",

  // Load Tests > Load Test > Configuration > Repository
  "RepositorySettingsView.title": "Repository",
  "RepositorySettingsView.help": "Konfigurieren Sie spezifische Repository-Einstellungen, die die allgemeinen Repository-Einstellungen am Projekt für diesen Lasttest überschreiben.",
  "RepositorySettingsView.noneConfigured": "Nicht konfiguriert.",
  "RepositorySettingsView.fields.branchName": "Branch-Name",
  "RepositorySettingsView.deleteConfirmation.title": "Repository-Einstellungen löschen",
  "RepositorySettingsView.deleteConfirmation.message": "Möchten Sie diese Repository-Einstellungen wirklich löschen?",

  // Load Tests > Load Test > Configuration > Cloud Machines
  "CloudMachinesView.noneConfigured": "Nicht konfiguriert.",
  "CloudMachinesView.fields.regionNames": "Regionen",
  "CloudMachinesView.fields.instanceTemplateName": "Instanz-Template",
  "CloudMachinesView.fields.totalInstanceCount": "Gesamtanzahl an Instanzen",
  "CloudMachinesView.fields.agentsPerInstance": "Agenten pro Instanz",
  "CloudMachinesView.regionGroups.americas": "Amerika",
  "CloudMachinesView.regionGroups.emea": "Europa - Mittlerer Osten - Afrika",
  "CloudMachinesView.regionGroups.apac": "Asien - Pazifik",
  "CloudMachinesView.deleteConfirmation.title": "Cloud-Maschinen löschen",
  "CloudMachinesView.deleteConfirmation.message": "Möchten Sie diese Cloud-Maschinenkonfiguration wirklich löschen?",

  // Load Tests > Load Test > Configuration > AWS Machines
  "AwsMachinesView.title": "Amazon-Maschinen",
  "AwsMachinesView.help": "Konfigurieren Sie die Lastagenten-Maschinen, die für diesen Lasttest in Amazons Elastic Compute Cloud in Betrieb zu nehmen sind. Die Maschinen werden vor dem Lasttest automatisch gestartet und danach wieder beendet.",

  // Load Tests > Load Test > Configuration > GCP Machines
  "GcpMachinesView.title": "Google-Maschinen",
  "GcpMachinesView.help": "Konfigurieren Sie die Lastagenten-Maschinen, die für diesen Lasttest in Googles Compute Engine in Betrieb zu nehmen sind. Die Maschinen werden vor dem Lasttest automatisch gestartet und danach wieder beendet.",

  // Load Tests > Load Test > Configuration > Custom Machines
  "CustomMachinesView.title": "Individuelle Maschinen",
  "CustomMachinesView.help": "Wenn Sie Lastagenten-Maschinen haben, die bereits laufen, können Sie hier konfigurieren, welche dieser Maschinen Sie für diesen Lasttest verwenden wollen.",
  "CustomMachinesView.noneConfigured": "Nicht konfiguriert.",
  "CustomMachinesView.fields.hostNames": "Host-Namen",
  "CustomMachinesView.fields.totalInstanceCount": "Gesamtanzahl an Instanzen",
  "CustomMachinesView.fields.agentsPerInstance": "Agenten pro Instanz",
  "CustomMachinesView.deleteConfirmation.title": "Individuelle Maschinen löschen",
  "CustomMachinesView.deleteConfirmation.message": "Möchten Sie diese individuelle Maschinenkonfiguration wirklich löschen?",

  // Load Tests > Load Test > Execution > Scenario Status
  "ScenarioStatusTable.header.scenarioName": "Name",
  "ScenarioStatusTable.header.state": "Zustand",
  "ScenarioStatusTable.header.runningUsers": "Aktive Nutzer",
  "ScenarioStatusTable.header.totalUsers": "Nutzer insgesamt",
  "ScenarioStatusTable.header.iterations": "Iterationen",
  "ScenarioStatusTable.header.lastExecutionTime": "Letzte Zeit",
  "ScenarioStatusTable.header.averageExecutionTime": "Durchschn. Zeit",
  "ScenarioStatusTable.header.elapsedTime": "Verstrichene Zeit",
  "ScenarioStatusTable.header.events": "Ereignisse",
  "ScenarioStatusTable.header.errors": "Fehler",
  "ScenarioStatusTable.header.progress": "Fortschritt",
  "ScenarioStatusTable.tools.autoRefresh": "Auto-Aktualisierung",

  // Load Tests > Load Test > Results
  "ResultsView.noResults": "Es liegen noch keine Resultate vor.",
  "ResultsView.noEvaluation": "Es liegt noch keine detaillierte Auswertung vor.",
  "ResultsView.summaryTitle": "Resümee",
  "ResultsView.evaluationTitle": "Detaillierte Auswertung",
  "ResultsView.fields.summary": "Resümee",
  "ResultsView.fields.evaluation": "Detaillierte Auswertung",
  "ResultsView.fields.rating": "Einstufung",
  "ResultsView.deleteConfirmation.title": "Lasttest-Auswertung löschen",
  "ResultsView.deleteConfirmation.message": "Möchten Sie die Auswertung für diesen Lasttest wirklich löschen?",

  "common.buttons.accept": "Akzeptieren",

  //Markdown Component
  "markdown.write": "Schreiben",
  "markdown.preview": "Vorschau",

  // Password
  "password.show": "Passwort anzeigen",
  "password.hide": "Passwort verstecken",

  // DateTimeInput
  "DateTimeInput.labels.clear": "Löschen",
  "DateTimeInput.labels.today": "Jetzt",
  "DateTimeInput.labels.cancel": "Abbrechen",
  "DateTimeInput.labels.ok": "OK",
  "DateTimeInput.invalid.date": "Ungültiges Format",
  "DateTimeInput.invalid.max": "Datum sollte nicht nach dem letztmöglichem Datum sein",
  "DateTimeInput.invalid.min": "Datum sollte nicht vor dem frühestmöglichem Datum sein",

};

export default messages;
