import { action, makeObservable, observable } from "mobx";

import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { UserState } from "app/core/user/store/UserState";
import { UserData } from "app/core/user/types";
import { UserAPI } from "app/core/user/UserAPI";
import { AsyncActionResult } from "app/utils/store";

export interface TwoFASetup {
  email: string;
  secret?: string;
  returnUrl?: string;
  recoveryCodes?: string[];
}

type State = {
  readonly user: UserState | null;
  readonly isLoggedIn: boolean;
  readonly twoFARequired: boolean;
  readonly twoFASetup: TwoFASetup | null;
};

class UserStore extends AbstractState<State> implements State {

  @observable public readonly user: UserState | null;
  @observable public readonly isLoggedIn: boolean;
  /**
   * This variable tracks whether the current login process requires the user to enter an additional second factor.
   * The 2FA login process has two steps:
   * 1. enter username and password -> receive the request to enter the second factor
   * 2. enter the second auth factor -> receive the user data
   *
   * Since there is no user object to attach the twoFARequired after the first step, we track this requirement seperately.
   */
  @observable public readonly twoFARequired: boolean;

  /** Stores whether user has to setup 2FA in order to complete login. */
  @observable public readonly twoFASetup: TwoFASetup | null;

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState({
      user: null,
      isLoggedIn: false,
      twoFARequired: false,
      twoFASetup: null
    }));

    this.appStores = appStores;
  }

  @action.bound
  public async fetchUser(): Promise<void> {
    const result = await UserAPI.isLoggedIn();
    if (result.success && result.data) {
      const { type, user } = result.data;
      if (type === "success") {
        this.userLoginSuccess(user);
      } else if (type === "twoFASetup") {
        const userState = new UserState(user, this.appStores);
        this.setState({ user: userState, isLoggedIn: false, twoFASetup: { email: user.email } });
      }
    } else {
      this.setState({ user: null, isLoggedIn: false });
    }
  }

  @action.bound
  public async login(username: string, password: string, returnUrl?: string, unlockToken?: string): Promise<void> {
    const token = await UserAPI.getAuthToken();
    const result = await UserAPI.loginUser(username, password, token, unlockToken);

    if (result.success && result.data) {
      const type = result.data.type;
      if (type === "twoFA") {
        this.setState({ twoFARequired: true });
      } else if (type === "twoFASetup") {
        const user = new UserState(result.data.user, this.appStores);
        const activate2FAResult = await user.change2FA(true, password);
        if (activate2FAResult) {
          this.setState({ twoFASetup: { returnUrl, email: result.data.user.email, secret: activate2FAResult.secret }, user });
        }
      } else if (type === "success") {
        this.userLoginSuccess(result.data.user, returnUrl);
      }
    } else {
      throw result;
    }
  }

  @action.bound
  public async enable2FA(password: string): Promise<void> {
    if (this.twoFASetup && this.user && !!password) {
      const activate2FAResult = await this.user.change2FA(true, password);
      if (activate2FAResult) {
        this.setState({ twoFASetup: { ...this.twoFASetup, secret: activate2FAResult.secret } });
      }
    }
  }

  @action.bound
  public async confirm2FA(code: string): Promise<void> {
    if (this.twoFASetup && this.user) {
      const data = await this.user.confirm2FA(code);
      if (data) {
        this.setState({ twoFASetup: { ...this.twoFASetup, recoveryCodes: data.recoveryCodes } });
      }
    }
  }

  @action.bound
  public complete2FASetup(): void {
    if (this.twoFASetup && this.user) {
      const { returnUrl } = this.twoFASetup;
      this.setState({ twoFASetup: null, isLoggedIn: true, twoFARequired: false });
      this.appStores.localizationStore.changeLocale(this.user.language);
      this.appStores.localizationStore.changeTimezone(this.user.timeZone);
      this.appStores.sessionStore.sessionStarted(returnUrl);
      this.appStores.notificationStore.clear();
    }
  }

  @action.bound
  public async login2FA(code: string, isRecovery: boolean, returnUrl?: string): Promise<void> {
    const result = await UserAPI.loginUser2FA(code, isRecovery);

    if (result.success && result.data) {
      this.userLoginSuccess(result.data, returnUrl);
    } else {
      throw result;
    }
  }

  private userLoginSuccess(data: UserData, returnUrl?: string): void {
    this.setState({ isLoggedIn: true, user: new UserState(data, this.appStores), twoFARequired: false, twoFASetup: null });
    this.appStores.localizationStore.changeLocale(data.language);
    this.appStores.localizationStore.changeTimezone(data.timeZone);
    this.appStores.sessionStore.sessionStarted(returnUrl);
    this.appStores.notificationStore.clear();
  }

  @action.bound
  public async logout(skipNavigation?: boolean): Promise<void> {
    await UserAPI.logoutUser();

    this.setState({ user: null, isLoggedIn: false });
    this.appStores.sessionStore.sessionEnd(skipNavigation);
  }

  @action.bound
  public async fetchUserAssignments(): Promise<void> {
    const result = await UserAPI.getUserDashboard();

    if (result.success && result.data) {
      const { tenants, projects } = result.data;
      this.appStores.tenantsStore.putTenants(tenants);
      this.appStores.projectStore.putProjects(projects);
    } else {
      this.appStores.notificationStore.error("dashboard.load.error");
    }
  }

  @action.bound
  public async createAccount(firstName: string, lastName: string, email: string, password: string, passwordRepeat: string): Promise<void> {
    const locale = this.appStores.localizationStore.locale;
    const timezone = this.appStores.localizationStore.timezone;

    const result = await UserAPI.createAccount(firstName, lastName, email, password, passwordRepeat, locale, timezone);

    if (!result.success) {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async createExternalAccount(firstName: string, lastName: string, email: string, issuer: string, subject: string): Promise<void> {
    const locale = this.appStores.localizationStore.locale;
    const timezone = this.appStores.localizationStore.timezone;

    const result = await UserAPI.createExternalAccount(firstName, lastName, email, issuer, subject, locale, timezone);

    if (!result.success) {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async createInvitationAccount(
    token: string, firstName: string, lastName: string, email: string, issuer: string, subject: string
  ): Promise<void> {
    const locale = this.appStores.localizationStore.locale;
    const timezone = this.appStores.localizationStore.timezone;

    const result = await UserAPI.createInvitationAccount(token, firstName, lastName, email, issuer, subject, locale, timezone);

    if (result.success) {
      this.appStores.routerStore.navigateToLogin();
      this.appStores.sessionStore.sessionPrepare();
      this.appStores.notificationStore.ok("account.notifications.signupComplete");
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async activateAccount(token: string): Promise<any> {
    const result = await UserAPI.activateAccount(token);

    if (result && result.success) {
      this.appStores.routerStore.navigateToLogin();
      this.appStores.sessionStore.sessionPrepare();
      this.appStores.notificationStore.ok("account.notifications.signupComplete");
    } else {
      this.appStores.notificationStore.error("account.errors.signup");
    }
  }

  @action.bound
  public async resetPassword(email: string): Promise<void> {
    const result = await UserAPI.resetPassword(email);

    if (!result?.success) {
      this.appStores.notificationStore.error(result?.message ?? "account.errors.passwordReset");
    }
  }

  @action.bound
  public async newPassword(token: string, password: string, passwordReset: string)
    : AsyncActionResult<undefined, { password?: string, passwordRepeat?: string }> {
    const result = await UserAPI.newPassword(token, password, passwordReset);

    if (result && result.success) {
      this.appStores.routerStore.navigateToLogin();
      this.appStores.sessionStore.sessionPrepare();
      this.appStores.notificationStore.ok("account.notifications.newPassword");
    }
    if (result && !result.success && result.message) {
      throw result.message;
    }
    return {
      data: undefined,
      errors: result.errors || {}
    };
  }

}

export { UserStore };
export default UserStore;
