import { Color, colors, Palette, PaletteColor, useTheme } from "@mui/material";

import { hexColorToRGBA, rgbColorToHex } from "./colorUtils";

function muiColorToHexColor(color: string, palette: Palette): string {
    if (color.startsWith("#")) {
        return color;
    }

    if (color.startsWith("rgb")) {
        return rgbColorToHex(color);
    }

    const colorSplit = color.split(".");

    const foundPaletteColor: PaletteColor | string = (palette as any)[colorSplit[0]];
    if (foundPaletteColor) {
        if (typeof foundPaletteColor === "string") {
            if (foundPaletteColor.startsWith("rgb")) {
                return rgbColorToHex(foundPaletteColor);
            }
            return foundPaletteColor;
        } else {
            let paletteColor: string = foundPaletteColor.main;
            if (colorSplit.length > 1) {
                paletteColor = (foundPaletteColor as any)[colorSplit[1]];
            }

            if (paletteColor.startsWith("rgb")) {
                return rgbColorToHex(paletteColor);
            }
            return paletteColor;
        }
    } else {
        const foundColor: Color = (colors as any)[colorSplit[0]];

        if (colorSplit.length > 1) {
            return (foundColor as any)[colorSplit[1]];
        }
        return foundColor[500];
    }
}

function muiColorToRGBA(color: string, options: { palette: Palette, opacity: number }) {
    return hexColorToRGBA(muiColorToHexColor(color, options.palette), options.opacity);
}

export default function useThemeColors() {
    const theme = useTheme();

    return {
        toRGBA: (color: string, opacity: number = 1) => muiColorToRGBA(color, { palette: theme.palette, opacity }),
        toHEX: (color: string) => muiColorToHexColor(color, theme.palette),
        palette: theme.palette,
        colors,
        theme
    };
}
