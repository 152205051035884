import * as React from "react";

import Message from "app/localization/components/Message";

import { default as Dialog, Props as DialogProps } from "./ResponsiveDialog";

export type Props = {
  titleID?: string | React.ReactElement;
  acceptLabelID?: string | React.ReactElement;
  acceptHide?: boolean;
  cancelLabelID?: string | React.ReactElement;
  cancelHide?: boolean;
  contentID?: string | React.ReactElement;
  contentProps?: Pick<DialogProps, "contentProps">
  open?: boolean;
  loadingLabelID?: string| React.ReactElement;
} & Omit<DialogProps, "acceptLabel" | "cancelLabel" | "title" | "visible" | "content" | "loadingLabel">;

function translate(arg: string | React.ReactElement): React.ReactNode {
  if (typeof arg === "string") {
    return (<Message messageID={arg} />);
  }
  return arg;
}

const DialogBox: React.FunctionComponent<Props> = (props) => {

  const {
    acceptColor,
    acceptLabelID,
    acceptIcon,
    acceptHide,
    cancelLabelID,
    cancelIcon,
    cancelHide,
    contentID,
    contentProps,
    titleID,
    children,
    open,
    loadingLabelID,
    ...rest
  } = props;

  return (
    <Dialog
      title={titleID ? translate(titleID) : undefined}
      acceptColor={acceptColor}
      acceptLabel={acceptHide ? undefined : (translate(acceptLabelID || "common.buttons.accept"))}
      cancelLabel={cancelHide ? undefined : (translate(cancelLabelID || "common.buttons.cancel"))}
      loadingLabel={translate(loadingLabelID || "common.buttons.accept.loading")}
      acceptIcon={acceptHide ? undefined : acceptIcon}
      cancelIcon={cancelHide ? undefined : cancelIcon}
      content={contentID ? translate(contentID) : undefined}
      contentProps={contentProps}
      visible={open !== false}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export { DialogBox };
export default DialogBox;
