import { action } from "mobx";

abstract class AbstractState<S> {

  @action.bound
  public setState(state: Partial<S>): AbstractState<S> {
    Object.assign(this, state);
    return this;
  }
}

export { AbstractState };
export default { AbstractState };
