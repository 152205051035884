import * as React from "react";
import { IntlProvider } from "react-intl";

import { useObserver } from "mobx-react";

import Error from "components/error/Error";

import useAppStores from "app/core/store/useStore";
import { DefaultLocale } from "app/localization/package";
import { useFetch } from "app/utils/remote";

type Props = {};

const FORMATTERS = {
  bold: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
  em: (chunks: React.ReactNode[]) => <em>{chunks}</em>,
  br: () => <br />,
  code: (chunks: React.ReactNode[]) => <code>{chunks}</code>,
  p: (chunks: React.ReactNode[]) => <p>{chunks}</p>
};

const Component: React.ComponentType<Props> = (props) => {
  const { localizationStore } = useAppStores();
  const loadState = useFetch(() => localizationStore.changeLocale(localizationStore.locale), []);

  return useObserver(() => {
    if (loadState.error) {
      return loadState.error.isHandledResponseError ? null : <Error message={loadState.error.message} />;
    }
    if (!loadState.fetched) {
      return null;
    }

    return (<IntlProvider
      defaultLocale={DefaultLocale}
      locale={localizationStore.locale}
      messages={localizationStore.messages} defaultRichTextElements={FORMATTERS}>
      {React.Children.only(props.children)}
    </IntlProvider>);
  });
};

export { Component as Localized };
export default Component;
