import { Location as HistoryLocation, To } from "react-router";

import { AppRouter } from "app/routing/router/AppRouter";

export type Location = HistoryLocation;

export function currentLocation(): Location {
  return AppRouter.state.location;
}

export function replaceRoute(to: To): void {
 AppRouter.navigate(to, {replace: true});
}

export function pushRoute(to: To): void {
  AppRouter.navigate(to);
}

export function createHref(to: Location): string {
  return AppRouter.createHref(to);
}
