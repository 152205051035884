export default class PropsError extends Error {

  public readonly name = "PropsError";

  public constructor(message: string) {
    super(message);
  }

  public toString() {
    return this.name + ": " + this.message;
  }
}
