import { action, makeObservable, observable } from "mobx";

import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";

import { MonitoringProjectAPI } from "app/mon/project/MonitoringProjectAPI";
import { ExecutionDetailsResult, ExecutionResult } from "app/mon/project/types";

import { TimeFilterData } from "../components/projectDetail/history/types";

type State = {
  readonly results: ExecutionResult[];
  readonly resultsTotal: number;
  readonly resultsFiltered: number;
  readonly minTime: number;
  readonly maxTime: number;

  readonly details: ExecutionDetailsResult | null;
};

class ProjectHistoryStore extends AbstractState<State> implements State {

  private static readonly initialState: State = {
    results: [],
    resultsTotal: 0,
    resultsFiltered: 0,
    details: null,
    minTime: Date.now(),
    maxTime: Date.now()
  };

  @observable public readonly results: ExecutionResult[];
  @observable public readonly resultsTotal: number;
  @observable public readonly resultsFiltered: number;
  @observable public readonly minTime: number;
  @observable public readonly maxTime: number;

  @observable public readonly details: ExecutionDetailsResult | null;

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this);
    this.setState(ProjectHistoryStore.initialState);
    this.appStores = appStores;
  }

  public initStore(): void {
    this.setState(ProjectHistoryStore.initialState);
  }

  public findResult(id: string): ExecutionResult | null {
    return this.results.find((each) => each._id === id) || null;
  }

  @action.bound
  public putResult(prepend: boolean, result: ExecutionResult): ExecutionResult {
    const index = this.results.findIndex((each) => each._id === result._id);
    if (index > -1) {
      this.results[index] = result;
      return this.results[index];
    } else {
      if (prepend) {
        this.results.splice(0, 0, result);
        return this.results[0];
      } else {
        this.results.push(result);
        return this.results[this.results.length - 1];
      }
    }
  }

  @action.bound
  public putResults(append: boolean, results: ExecutionResult[]): ExecutionResult[] {
    if (!append) {
      this.setState({ results: [] });
    }
    return results.map((each) => this.putResult(false, each));
  }

  @action.bound
  public removeResult(id: string): void {
    const index = this.results.findIndex((each) => each._id === id);
    if (index > -1) {
      this.results.splice(index, 1);
    }
  }

  @action.bound
  public async fetchResults(
    append: boolean, projectID: string,
    start: number, count: number, sortBy: string, search: string,
    include: {
      success: boolean, warning: boolean, error: boolean, aborted: boolean, running: boolean
    },
    timeFilter?: TimeFilterData
  )
    : Promise<void> {
    const result = await MonitoringProjectAPI.loadExecutionHistoryResults(
      projectID, start, count, sortBy, search, include, timeFilter
    );
    if (result.success && result.data) {
      this.putResults(append, result.data.items.map((each) => ({
        ...each,
        locationId: each.locationId || "unknown",
        attempt: each.attempt,
        scenario: each.scenarioName,
        startDate: new Date(each.startTime),
        error: !(each.actionName || each.message) ? undefined : {
          action: each.actionName ? each.actionName : undefined,
          message: each.message || ""
        },
        singleFailedCriterion: each.singleFailedCriterion,
        duringQuietPeriod: each.duringQuietPeriod
      })));
      this.setState({
        resultsTotal: result.data.total,
        resultsFiltered: result.data.count,
        minTime: result.data.minTime ? result.data.minTime : Date.now(),
        maxTime: result.data.maxTime ? result.data.maxTime : Date.now()
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchResultDetails(projectId: string, id: string): Promise<void> {
    const result = await MonitoringProjectAPI.loadExecutionHistoryDetails(projectId, id);
    if (result.success && result.data) {
      const details = result.data.result;
      const error = (details.state === "ERROR" || details.state === "FAILURE") ? {
        action: details.actionName || "",
        message: details.message || "",
        stackTrace: details.stackTrace || "",
        date: details.failedActionTime ? new Date(details.failedActionTime) : undefined
      } : undefined;
      this.setState({
        details: ({
          _id: details._id,
          locationId: details.locationId || "unknown",
          attempt: details.attempt,
          scenario: details.scenarioName,
          duration: details.duration,
          state: details.state,
          startDate: new Date(details.startTime),
          request: {
            slowest: details.slowestRequests,
            failed: details.failedRequests
          },
          criteria: details.criteria,
          error,
          hasConsoleLog: details.hasConsoleLog,
          hasResultBrowser: details.hasResultBrowser,
          hasTimers: details.hasTimers,
          duringQuietPeriod: details.duringQuietPeriod
        })
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }


}

export { ProjectHistoryStore };
export default ProjectHistoryStore;
