import { loadLocaleData, loadLocaleMessages } from "app/localization/remote/localeLoader";

import { Locale } from "../types";
import { getCurrentLocale, getLocaleData } from "../utils/Locale";

import { LocaleData } from "./types";

export class IntlService {
  public static async getLocaleData(locale: Locale = IntlService.determineCurrentLocale()): Promise<LocaleData> {
    return Promise.all([
      loadLocaleMessages(locale),
      loadLocaleData(locale)
    ]).then((result) => {
      const messages = result[0];
      // const localeData = result[1];

      // addLocaleData(localeData);
      return Object.assign({}, getLocaleData(messages, locale)) as LocaleData;
    });
  }

  public static determineCurrentLocale(): Locale {
    return getCurrentLocale() as Locale;
  }
}
