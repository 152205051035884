import * as React from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import useAppStores from "app/core/store/useStore";
import Message from "app/localization/components/Message";

const Footer: React.FunctionComponent = (_props) => {
  const { localizationStore: { locale } } = useAppStores();
  return (
    <Box className="xtc_imprint"
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      justifyContent="center"
      alignContent="center"
      component="footer"
    >
      <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
        <Box paddingRight={2}>
          <Link
            className="xtc_contact"
            target="_blank"
            href={`https://www.xceptance.com/${locale}/contact/`}
            underline="hover">
            <Message messageID="labels.footer.contact" />
          </Link>
        </Box>
        <Box paddingRight={2}>
          <Link
            className="xtc_privacy"
            target="_blank"
            href="https://www.xceptance.com/en/contact/privacy.html"
            underline="hover">
            <Message messageID="labels.footer.privacy" />
          </Link>
        </Box>
        <Link
          className="xtc_changelog"
          target="_blank"
          href="https://docs.xceptance.com/xtc/xtc-release-notes/"
          underline="hover">
          <Message messageID="labels.footer.changelog" />
        </Link>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
        <Typography variant="caption" noWrap={true}>
          {`XTC ${APP_VERSION} \u2014 ${APP_COPYRIGHT_YEAR} \u00a9 Xceptance`}
        </Typography>
      </Box>
    </Box>
  );
};
export { Footer };
export default Footer;
