import { action, makeObservable, observable } from "mobx";

import { routeToLogin } from "app/routing/routes";

import { AbstractState } from "./AbstractState";
import { AppStores } from "./AppStores";

type State = {
  readonly loginUrl: string | null;
  readonly mobileSized: boolean;
  readonly showNavigation: boolean;
};

class SessionStore extends AbstractState<State> implements State {
  @observable public readonly loginUrl: string | null;
  @observable public readonly mobileSized: boolean;
  @observable public readonly showNavigation: boolean;

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState({
      loginUrl: null,
      mobileSized: false,
      showNavigation: true
    }));

    this.appStores = appStores;
  }

  @action.bound
  public raiseErrorMessage(error?: any): never {
    if (typeof error === "string") {
      this.appStores.notificationStore.error(error);
    }
    throw error;
  }

  @action.bound
  public sessionPrepare(): void {
    const loginUrl = this.returnURL || this.appStores.routerStore.locationHref;
    this.setState({ loginUrl });
  }

  private get returnURL(): string | null {
    const loginURL = localStorage.getItem("xtcReturnURL");
    localStorage.removeItem("xtcReturnURL");
    return loginURL;
  }

  public saveReturnURL(returnUrl: string): void {
    localStorage.setItem("xtcReturnURL", returnUrl);
  }

  @action.bound
  public sessionStarted(returnUrl?: string): void {
    const loginUrl = returnUrl || this.loginUrl;
    if (loginUrl && !loginUrl.startsWith(routeToLogin())) {
      this.appStores.routerStore.navigate(loginUrl);
    } else {
      if (this.appStores.userStore.isLoggedIn) {
        this.appStores.routerStore.navigateToUserDashboard();
      } else {
        this.appStores.routerStore.navigateToLogin();
      }
    }
    this.setState({ loginUrl: null });
    this.appStores.messageStore.fetchSystemMessages();
  }

  @action.bound
  public sessionEnd(skipNavigation?: boolean): void {
    this.setState({ loginUrl: null });
    if (!skipNavigation) {
      this.appStores.routerStore.navigateToLogin();
    }
    this.appStores.tenantsStore.initStore();
    this.appStores.projectStore.initStore();
    this.appStores.projectHistory.initStore();
    this.appStores.adminStores.userStore.initStore();
    this.appStores.adminStores.tenantsStore.initStore();
    this.appStores.adminStores.projectsStore.initStore();
    this.appStores.messageStore.fetchSystemMessages();
  }

  @action.bound
  public sessionExpired(): void {
    const loginUrl = this.appStores.routerStore.locationHref;
    this.appStores.notificationStore.error("application.error.session.expired");
    this.appStores.userStore.logout().then(() => this.setState({ loginUrl }));
  }

  @action.bound
  public permissionDenied(error?: string | null): void {
    this.appStores.notificationStore.error(error ?? "error.permission.denied");
  }

  @action.bound
  public isMobileSized(mobileSized: boolean): void {
    this.setState({ mobileSized });
  }

  @action.bound
  public setShowNavigation(showNavigation: boolean): void {
    this.setState({ showNavigation });
  }

}

export { SessionStore };
export default SessionStore;
