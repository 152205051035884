import { AsyncAPIResult, typedApiRequest } from "app/utils/remote";
import { Message, MessageEditData, MessageType, MessageErrors } from "./types";

export default class MessageAdminAPI {

  private static SERVICE_PATH = "/api/core/system/messages";

  public static async fetchMessages(): AsyncAPIResult<{ messages: Message[] }> {
    return typedApiRequest(this.SERVICE_PATH, "GET");
  }

  public static async addMessage<T extends Message>(type: MessageType, message: MessageEditData<T>)
    : AsyncAPIResult<T, MessageErrors> {
    return typedApiRequest(this.SERVICE_PATH, "POST", { ...message, type });
  }

  public static async editMessage<T extends Message>(id: string, version: number, message: MessageEditData<T>)
    : AsyncAPIResult<T, MessageErrors> {
    return typedApiRequest(`${this.SERVICE_PATH}/${id}`, "PUT", { ...message, version });
  }

  public static async deleteMessage(id: string, version: number): AsyncAPIResult {
    return typedApiRequest(`${this.SERVICE_PATH}/${id}`, "DELETE", { version });
  }

}

