import { asyncComponent } from "components/AsyncComponent";

const AsyncLoadTestAuditLogScene = asyncComponent(
  () => import(
    /* webpackChunkName: "loadTestProjectAuditLogs" */ "app/lt/project/scenes/LoadTestAuditLogScene"
  ).then(({ default: scene }) => scene),
  "LoadTestAuditLogScene"
);

export { AsyncLoadTestAuditLogScene };
export default AsyncLoadTestAuditLogScene;
