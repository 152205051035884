import { action, computed, makeObservable, observable } from "mobx";

import { ProjectAPI } from "app/core/project/remote/ProjectAPI";
import { ProjectState } from "app/core/project/store/ProjectState";
import { ProjectData, ProjectSortoptions, ProjectStates, ProjectType } from "app/core/project/types";
import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { isLoadTestProject, isMonitoringProject } from "app/utils/project/is-of-type";

import { MonitoringProjectAPI } from "app/mon/project/MonitoringProjectAPI";
import { MonitoringProjectState } from "app/mon/project/store/MonitoringProjectState";

import { LoadTestProjectAPI } from "app/lt/project/LoadTestProjectAPI";
import { LoadTestProjectState } from "app/lt/project/store/LoadTestProjectState";

type State = {
  readonly projects: ProjectState[];
};

class ProjectStore extends AbstractState<State> implements State {

  private static readonly initialState: State = { projects: [] };

  public readonly appStores: AppStores;

  @observable public readonly projects: ProjectState[];

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState(ProjectStore.initialState));

    this.appStores = appStores;
  }

  public initStore(): void {
    this.setState(ProjectStore.initialState);
  }

  @computed
  public get myProjects(): ProjectState[] {
    return this.projects.filter((each) => each.role !== "None" && each.state !== "Archived" && !each.deleteDateTime);
  }

  @computed
  public get favoriteProjects(): ProjectState[] {
    return this.myProjects.filter((each) => each.favorite);
  }

  public findProject(id: string): ProjectState | null {
    return this.projects.find((each) => each._id === id) || null;
  }

  @action.bound
  public putProject(prepend: boolean, project: ProjectState | ProjectData): ProjectState {
    const index = this.projects.findIndex((each) => each._id === project._id);
    const projectState = this.createProjectState(project);
    if (index > -1) {
      this.projects.splice(index, 1, projectState);
    } else {
      if (prepend) {
        this.projects.splice(0, 0, projectState);
      } else {
        this.projects.push(projectState);
      }
    }
    return projectState;
  }

  @action.bound
  public putProjects(projects: Array<ProjectState | ProjectData>): ProjectState[] {
    return projects.map((each) => this.putProject(false, each));
  }

  @action.bound
  public removeProject(projectId: string): void {
    const index = this.projects.findIndex((each) => each._id === projectId);
    if (index > -1) {
      this.projects.splice(index, 1);
    }
  }

  @action.bound
  public removeProjects(projectIDs: string[]): void {
    projectIDs.forEach(this.removeProject);
  }

  @action.bound
  public async fetchProject(id: string): Promise<ProjectState> {
    const result = await ProjectAPI.loadProjectDetails(id);

    if (result.success && result.data) {
      return this.putProject(false, result.data);
    } else {
      throw new Error("project.details.list.error");
    }
  }

  @action.bound
  public removeTenantProjects(tenantId: string): void {
    this.setState({
      projects: this.projects.filter((each) => each.tenant._id !== tenantId)
    });
  }

  @action.bound
  public async fetchProjects(): Promise<ProjectState[]> {
    const result = await ProjectAPI.listProjects();

    if (result.success && result.data) {
      return this.putProjects(result.data);
    } else {
      throw new Error("project.details.list.error");
    }
  }

  @action.bound
  public async fetchTenantProjects(tenantId: string): Promise<ProjectState[]> {
    const result = await ProjectAPI.listTenantProjects(tenantId);

    if (result.success && result.data) {
      return this.putProjects(result.data);
    } else {
      throw new Error("project.details.list.error");
    }
  }

  @action.bound
  public async fetchTenantProjectsFiltered(
    append: boolean, tenantId: string, start: number, count: number, sortBy: ProjectSortoptions, searchBy: string, softDeleted: boolean
  ): Promise<{ projects: ProjectData[], total: number, filtered: number }> {

    const result = await ProjectAPI.filteredTenantProjects(tenantId, start, count, sortBy, searchBy, softDeleted);

    if (result.success && result.data) {
      if (!append) {
        this.removeTenantProjects(tenantId);
      }
      const projects = this.putProjects(result.data.projects);
      return {
        projects,
        total: result.data.total,
        filtered: result.data.count
      };
    } else {
      throw new Error(result.message ?? "project.details.list.error");
    }
  }

  @action.bound
  public async pushTenantProject(
    prepend: boolean,
    tenantId: string,
    name: string,
    baseShortName: string,
    type: ProjectType): Promise<ProjectState> {
    const result = type === "mon" ? await MonitoringProjectAPI.createProject(tenantId, name, baseShortName)
      :
      await LoadTestProjectAPI.createProject(tenantId, name, baseShortName);

    if (result.success && result.data) {
      return this.putProject(prepend, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteTenantProjects(tenantId: string, projects: Array<{ id: string, version: number }>): Promise<void> {
    const result = await ProjectAPI.removeTenantProjects(tenantId, projects);

    if (result.success) {
      this.removeProjects(projects.map((each) => each.id));
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async restoreTenantProject(tenantId: string, projectId: string): Promise<void> {
    const result = await ProjectAPI.restoreTenantProject(tenantId, projectId);
    if (result.success && result.data) {
      this.putProject(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async cloneTenantProject(
    projectId: string,
    name: string,
    baseShortName: string,
    description: string,
    state: ProjectStates,
    adminUser: string
  ): Promise<ProjectState> {
    const result = await ProjectAPI.cloneProject(projectId, name, baseShortName, description, state, adminUser);

    if (result.success && result.data) {
      return this.putProject(true, result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async loadProjectStates(
    projectIds: string[]
  ): Promise<void> {
    if (projectIds.length > 0) {
      const status = await LoadTestProjectAPI.loadProjectStates(projectIds);
      if (status.success && status.data) {
        projectIds.forEach((id) => {
          const project = this.projects.find((p) => p._id === id);
          if (!!project && isLoadTestProject(project)) {
            (project as LoadTestProjectState).setProjectStatus(
              status.data?.running.includes(id) ?? false,
              status.data?.scheduled.includes(id) ?? false);
          }
        });
      }
    }
  }

  private createProjectState(project: ProjectData): ProjectState {
    if (isMonitoringProject(project)) {
      return new MonitoringProjectState(this.appStores, project);
    }
    if (isLoadTestProject(project)) {
      return new LoadTestProjectState(this.appStores, project);
    }
    throw new Error(`Don't know how to handle project of type ${project.type}`);
  }

}

export { ProjectStore };
export default ProjectStore;
