import * as React from "react";

import { default as Box, BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import BackDrop from "@mui/material/Backdrop";

type Props = Partial<{ modal: boolean, message: string }>;

const Loading: React.FunctionComponent<Props> = (props) => {
  const { modal, message } = props;
  const boxProps: BoxProps = {
    padding: modal ? 4 : 0,
    alignItems: "center",
    justifyItems: "center",
    justifySelf: "center",
    alignSelf: "center",
    flexDirection: "column",
    display: "flex"
  };

  const component = (
    <Box {...boxProps}>
      <CircularProgress />
      {message && <Typography>{message}</Typography>}
    </Box>
  );


  return modal ? <BackDrop open={true}>{component}</BackDrop> : component;
};

export default Loading;
