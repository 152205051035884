/* eslint-disable */
export const messages = {

  // General messages
  "loading": "Loading...",
  "setting.none": "None",
  "route.logout": "Logout...",

  "cookie.consent": "We use only necessary cookies to make our site work. Necessary cookies enable core functionality such as security, network management, and accessibility. You cannot disable these without breaking the functionality. For more detailed information about our privacy setting, see our ",
  "cookie.consent.title": "Cookie Usage and Privacy",
  "cookie.consent.privacyPolicy": "Privacy Policy.",
  "cookie.consent.ok": "OK",

  "menu.search.placeholder": "Search...",

  "labels.paging.pageSize": "Page Size",
  "labels.header.myaccount": "My Account",
  "labels.header.logout": "Logout",
  "labels.header.admin": "System",
  "labels.header.admin.users": "Users",
  "labels.header.admin.tenants": "Organizations",
  "labels.header.admin.projects": "Projects",
  "labels.header.admin.resources": "Resource Usage",
  "labels.header.admin.auditLog": "Audit Log",

  "labels.footer.contact": "Contact",
  "labels.footer.privacy": "Privacy",
  "labels.footer.changelog": "Release Notes",
  "labels.footer.currentTimezone": "Time Zone",
  "labels.footer.currentRole.project": "Project Role",
  "labels.footer.currentRole.tenant": "Organization Role",

  // Login scene
  "login.authToken.error": "An error occurred while retrieving the authentification token: {reason}",
  "login.error.email.empty": "Please enter your email address",
  "login.error.password.empty": "Please enter your password",
  "login.error.userpass": "Wrong email/password combination",
  "login.registerBtnLabel": "New to XTC? <rlink>Create an Account</rlink>",
  "login.resetPasswordBtnLabel": "Forgot Your Password?",
  "login.title": "Sign In to Xceptance Test Center",
  "login.email": "Email",
  "login.password": "Password",
  "login.loginBtnLabel": "Sign In",
  "login.notActive": "Your account hasn't been activated yet. An email with activation instructions was just sent to your inbox.",
  "login.userLocked": "Your account is locked. Please contact an administrator if you feel this is incorrect.",
  "login.oidc.noState": "Invalid login state. If you have reached this page by clicking on a link, this link has expired and can no longer be used. Please return to the main page of the application.",
  "login.oidc.noAccess": "The user was sucessfully authenticated, but does not have access to XTC.",
  "login.oidc.internalError": "An internal error occured when trying to sign in. Please try again later.",
  "login.oidc.alreadyRegistered": "An account for this external user already exists and cannot be registered again.",
  "login.2fa.help": "This user requires Two-Factor-Authentication. Please enter the code from your authenticator app",
  "login.2fa.code": "Authentication Code",
  "login.2fa.recoveryCode": "Recovery Code",
  "login.2fa.lostDevice": "Don't have access to your device?",
  "login.2fa.lostDevice.help": "If you don't have access to your device, please enter one of your unused recovery codes below instead.",
  "login.2fa.code.noMatch": "The code could not be verified. Please retry with the current code or use a recovery code.",
  "login.tempCode": "Please enter the code you received via email",
  "login.tempCode.help": "Your password was entered wrongly too often. In order to prevent automated attacks XTC requires you to confirm your account access with a temporary code you received via email.",
  "account.locked.temporary": "Your account has been locked due to suspicious activity. You should have received an email with an unlock link. Please follow the instructions in the email.",

  // Dashboard scene
  "dashboard.noFavoriteTenants": "No organization marked as favorite",
  "dashboard.noAssignedTenants": "You are not assigned to any organization yet",
  "dashboard.noMatchingTenants": "No organization matches your search filter",
  "dashboard.noFavoriteProjects": "No project marked as favorite",
  "dashboard.noAssignedProjects": "You are not assigned to any project yet",
  "dashboard.noVisitedProjects": "You haven't visited any project yet",
  "dashboard.noMatchingProjects": "No projects match your search filter",
  "dashboard.userProjectCount": "Assigned to projects",
  "dashboard.projects.headline": "Projects",
  "dashboard.organizations.headline": "Organizations",
  "dashboard.visits.headline": "Last Accessed Projects",
  "dashboard.tenants.showMore": "Show more...",
  "dashboard.tenants.showLess": "Show less...",
  "dashboard.load.error": "Error while fetching dashboard data",

  // Show More
  "ShowMore.text": "Show More",

  // Navigation
  "navigation.home": "Home",
  "navigation.projects": "Projects",
  "navigation.organizations": "Organizations",
  "navigation.members": "Members",
  "navigation.quietPeriods": "Quiet Periods",
  "navigation.scenarios": "Scenarios",
  "navigation.settings": "Configuration",
  "navigation.dashboard": "Dashboard",
  "navigation.dashboard.org": "{name}",
  "navigation.collapse": "Collapse",
  "navigation.expand": "Expand",
  "navigation.noTenant": "Select Organization",
  "navigation.noProject": "Select Project",
  "navigation.emptySelection": "Nothing Available",
  "navigation.resources": "Resource Usage",
  "navigation.auditLog": "Audit Log",
  "navigation.comparison": "Comparison",
  "navigation.documentation": "Documentation",

  // Navigation subheader
  "navigation.subheader.back": "Back",
  "navigation.subheader.dashboard": "Dashboard",
  "navigation.subheader.history": "History",
  "navigation.subheader.history.details": "{name} - Details",
  "navigation.subheader.loadTests": "Load Tests",
  "navigation.subheader.loadTest.details": "{name}",
  "navigation.subheader.locationPath.account": "My Account",
  "navigation.subheader.locationPath.admin": "Configuration",
  "navigation.subheader.locationPath.configuration": "Configuration",
  "navigation.subheader.locationPath.users": "Users",
  "navigation.subheader.locationPath.tenants": "Organizations",
  "navigation.subheader.locationPath.projects": "Projects",
  "navigation.subheader.locationPath.log": "Log",
  "navigation.subheader.locationPath.resultBrowser": "Result Browser",
  "navigation.subheader.locationPath.metrics": "Metrics",
  "navigation.subheader.locationPath.exports": "Exports",
  "navigation.subheader.locationPath.members": "Members",
  "navigation.subheader.locationPath.quietPeriods": "Quiet Periods",
  "navigation.subheader.locationPath.scenarios": "Scenarios",
  "navigation.subheader.locationPath.resources": "Resource Usage",
  "navigation.subheader.locationPath.auditLog": "Audit Log",
  "navigation.subheader.locationPath.comparison": "Comparison",
  "navigation.subheader.locationPath.documentation": "Documentation",
  "navigation.subheader.exploreApi": "API Explorer",
  "navigation.subheader.scenarios": "Scenarios",
  "navigation.subheader.scenario.details": "{name}",

  // LocaleSelect
  "locale.de": "German",
  "locale.en": "English",

  // Time
  "time.milliSeconds.short": "{num}msec",
  "time.seconds.short": "{num}sec",
  "time.minutes.short": "{num}min",
  "time.hours.short": "{num}hr",
  "time.days.short": "{num}d",
  "time.unit.ms": "ms",

  // Table
  "table.sortBy": "Sort By",
  "table.search": "Search",
  "table.cell.expand": "Show more...",
  "table.cell.collapse": "Show less...",

  // Filter
  "filter.time.from": "From",
  "filter.time.from.title": "From Start Point",
  "filter.time.to": "To",
  "filter.time.to.title": "To End Point",
  "filter.time.error.outOfRange": "The filter is not within the expected time range.",
  "filter.time.error.fromAfterTo": "The start time is after the end time.",

  //Errors
  "application.error.session.expired": "Session expired",
  "application.error.request.failure": "Failed to contact server",
  "application.error.request.serverError": "Failed to complete the request due to an error on the server side. Please try again later.",
  "application.error.data.missing": "Failed to retrieve required data from server.",
  "application.error.pageload.failure": "Failed to load required files from server. Please reload the page. If the problem persist, contact us.",
  "application.error.unknown": "Unknown error occurred",

  //TableItem
  "item.state.added": "Added",
  "item.state.excluded": "Excluded",

  // AuditLog
  "auditLog.timestamp": "Timestamp",
  "auditLog.timestamp.invalid": "From must be before To",
  "auditLog.typeCode": "Activity",
  "auditLog.typeCode.none": "Any",
  "auditLog.user": "User",
  "auditLog.user.email": "User Email",
  "auditLog.user.name": "User Name",
  "auditLog.auxiliaryData": "Data",
  "auditLog.from": "From",
  "auditLog.to": "To",
  "auditLog.filter.accept": "Apply Filter",
  "auditLog.api.timestamp": "Timestamp",
  "auditLog.api.activity": "Activity",
  "auditLog.api.clientId": "Client ID",
  "auditLog.api.responseCode": "Code",
  "auditLog.api.apiVersion": "Version",
  "auditLog.api.httpMethod": "Method",
  "auditLog.api.url": "URL",
  "auditLog.api.ip": "IP",
  "auditLog.api.userAgent": "User-Agent",
  "auditLog.tab.userAuditLog": "User Audit Log",
  "auditLog.tab.apiAuditLog": "API Audit Log",

  // Reset Password
  "account.resetPassword.heading": "Reset Your Password",
  "account.resetPassword.subheading": "Enter your email address below to request a password reset. We will send you an email with a reset link.",
  "account.resetPassword.email": "Email",
  "account.resetPassword.resetBtnLabel": "Reset Password",
  "account.resetPassword.cancelBtnLabel": "Cancel",
  "account.resetPassword.confirmation": "If your email address exists in our database, you will receive a password recovery link at <em>{email}</em> in a few minutes. This link will be valid for 3 hours. If you have not gotten any email, please verify that you entered the correct email address and check your spam folder. If you do not receive an email within 15 minutes please try again. If the problem persists please contact support. <br></br><br></br><em>Note:</em> If your account is in a pending invitation state, you have to ask your organization or project administrator to resend the invitation instead of using the password reset functionality.",
  "account.resetPassword.confirmation.title": "Password Reset Completed",

  // New Password
  "account.newPassword.heading": "Set New Password",
  "account.newPassword.subheading": "Please enter your new password below.<br></br><br></br><em>Note:</em> Your password reset link is only valid for a limited time. If the link has expired please go to the login page and request another reset.",
  "account.newPassword.token": "Code (Received by Email)",
  "account.newPassword.accept": "Set New Password",
  "account.newPassword.cancel": "Cancel",
  "account.newPassword.password": "New Password",
  "account.newPassword.passwordRepeat": "Repeat New Password",
  "account.notifications.newPassword": "Your new password has been set. Login using your new XTC password.",
  "account.newPassword.error.noToken": "Cannot be blank",
  "account.newPassword.error.passwordBlank": "Cannot be blank",
  "account.newPassword.error.passwordRepeatBlank": "Cannot be blank",
  "account.newPassword.error.passwordNoMatch": "Passwords do not match",
  "account.newPassword.error.tokenInvalid": "Your password reset link is invalid or has expired. Click <resetLink>here</resetLink> to request another password reset.",
  "account.newPassword.error.missingToken": "The link you are using does not contain a valid password reset token. If you copied the link from the email please ensure that you copied the whole link including the token.",

  // Use external login providers
  "account.login.provider.label": "Login Provider",
  "account.login.external": "Single Sign-On",
  "account.login.switchToExternal": "Switch to external login provider",
  "account.login.removeExternal": "Remove login provider",
  "account.login.removeExternal.help": "Remove binding to an external login provider.<br></br><em>Note:</em> You can only remove the binding if you have a password set for your account. If you currently do no have a password set, please use the password recovery flow in the login form.",
  "account.login.removeExternal.confirm": "You are about to remove the binding to your account at {provider}.<br></br><br></br>Please enter your current password to confirm removing the provider binding.<br></br><em>Note:</em> You can only unbind the external provider if your account currently has a password set.",
  "account.login.removeExternal.confirm.title": "Remove <em>{provider}</em> account",
  "account.login.removeExternal.success": "External login provider removed",
  "account.login.removeExternal.error": "Could not remove external login provider.",
  "account.login.generic.title": "or sign in with",
  "account.login.isInternal": "The user is authenticated internally.",
  "account.login.isGoogle": "Google",
  "account.login.isAzure": "Microsoft",
  "account.login.isOkta": "Okta",
  "account.login.google": "This will bind your XTC user to your Google account, allowing you to use your Google login to sign in to XTC.",
  "account.login.google.title": "Google",
  "account.login.google.signup": "Google",
  "account.login.okta": "This will bind your XTC user to your Okta account, allowing you to use your Okta login to sign in to XTC.",
  "account.login.okta.title": "Okta",
  "account.login.okta.signup": "Okta",
  "account.login.azure": "This will bind your XTC user to your Microsoft account, allowing you to use your Microsoft login to sign in to XTC.",
  "account.login.azure.title": "Microsoft",
  "account.login.azure.signup": "Microsoft",
  "account.login.replaceExternal": "This will also remove the currently configured external login provider from this user.",
  "account.login.issuerExists": "A user for the same external login already exists.",

  // Registration
  "account.signup.heading": "Sign Up for XTC",
  "account.signup.subheading": "Create an Account",
  "account.signup.firstName": "First Name",
  "account.signup.lastName": "Last Name",
  "account.signup.email": "Email",
  "account.signup.password": "Password",
  "account.signup.passwordRepeat": "Repeat Password",
  "account.signup.passwordRules.help": "Show the password rules",
  "account.signup.passwordRules.1": "Passwords have to be at least 12 characters long",
  "account.signup.passwordRules.2": "Passwords must not start or end with spaces",
  "account.signup.passwordRules.3": "If the password is shorter than 15 characters, it must contain at least one of each: lowercase and uppercase characters, digits, and special characters (e.g. punctuation)",
  "account.signup.registerBtnLabel": "Sign Up",
  "account.signup.cancelBtnLabel": "Cancel",
  "account.signup.noIssuer": "Invalid external registration data. Missing issuer and/or subject.",
  "account.signup.external.title": "or sign up with",
  "account.signup.external.hasAccount.begin": "Already have an account? ",
  "account.signup.external.hasAccount.signin": "Sign in",
  "account.signup.external.hasAccount.end": " instead.",
  "account.signup.external.signUp": "Sign up",
  "account.signup.invitation.expired": "Invitation link has expired. If you already finished your registration before, please login normally or reset your password.",
  "account.signupComplete.heading": "Complete Your Sign Up",
  "account.signupComplete.description": "Thank you for signing up. Please complete your registration process now by clicking on the activation button below.",
  "account.signupComplete.activateBtnLabel": "Activate Account",
  "account.notifications.activationTokenSent": "You will receive an email confirmation link at <em>{ email }</em> in a few minutes. This link will be valid for 3 hours. If you have not gotten any email, please verify that you entered the correct email address and check your spam folder. If you do not receive an email within 15 minutes please try again. If the problem persists please contact support.",
  "account.notifications.activationTokenSent.title": "Activation email sent",
  "account.notifications.signupComplete": "Congratulations, your account is now ready to use. Enjoy XTC!",
  "account.notifications.passwordReset": "Your password change code has been requested. Check \"{email}\" for the password change code and set your new password now.",

  //Account
  "account.settings.credentials": "Security",
  "account.settings.credentials.description": "Edit your login credentials",
  "account.settings.general": "Profile",
  "account.settings.general.description": "View and edit your user profile",
  "account.settings.preferences": "Preferences",
  "account.settings.preferences.description": "View and edit your account preferences",

  // User Profile
  "account.settings.general.avatar": "Avatar",
  "account.settings.general.name": "Name",
  "account.settings.general.firstName": "First Name",
  "account.settings.general.lastName": "Last Name",
  "account.settings.general.newFirstName": "Your first name",
  "account.settings.general.newLastName": "Your last name",
  "account.settings.general.newAvatar": "Select your avatar",
  "account.settings.general.newPhone": "Your Phone Number",
  "account.profile.saved": "Profile Updated",
  "account.profile.saved.error": "Failed to update profile",
  "account.profile.general.deleteConfirmation.title": "Delete User Avatar",
  "account.profile.general.deleteConfirmation.message": "Do you really want to delete the user avatar?",
  "account.settings.general.saved": "Saved",
  "account.settings.general.phone": "Phone Number",
  "account.settings.general.noPhone": "No phone number available",
  "account.settings.general.phone.verify": "Verify",
  "account.settings.general.phone.help": "Your phone number will be used for the following purposes:",
  "account.settings.general.phone.help.1": "Receive scenario notifications directly on your phone as text messages.",
  "account.settings.general.phone.verification.explanation": "To ensure that you can receive these messages, a validation is required. After you proceed, a text message containing a 6-digit code is sent to {phoneNumber}. This must be entered in the next dialog.",
  "account.settings.general.phone.verification.title": "Phone Verification",
  "account.settings.general.phone.verification.code": "Verification Code",
  "account.settings.general.phone.verification.incorrect": "Invalid verification code",
  "account.settings.general.phone.verification.sent": "A verification code was sent to {phoneNumber}",
  "account.settings.general.phone.verification.verify": "Verify",
  "account.settings.general.phone.remove.message": "Are you sure you want to remove your phone number?",
  "account.settings.general.phone.remove.invalid": "The supplied phone number does not match the records",

  //Account Login Address
  "account.email.heading": "Email/Login",
  "account.email.saved": "Email Confirmation Sent",
  "account.email.saved.error": "Email update request failed",
  "account.emailNew": "New Email",
  "account.emailNewReapeat": "Repeat Email",
  "account.emailRepeat.noMatch": "Emails do not match",
  "account.email.password.title": "Confirm Current Password",
  "account.email.password.label": "Current Password",
  "account.email.password": "Please enter your current password to confirm the email change",
  "account.email.password.noMatch": "The password did not match our stored data. Please try again.",
  "account.email.confirmation.sendMail.failed": "Could not send confirmation email. Please try again later.",
  "account.email.confirmation.invalid": "Invalid or expired verification token. If you copied the link please ensure there are no missing or additional characters.",
  "account.email.updated": "Sucessfully updated your Email address. Please use the new address to log in",

  //Account Password
  "account.password.heading": "Password",
  "account.password.saved": "Password Updated",
  "account.password.saved.error": "Failed to save password",
  "account.passwordCurrent": "Current Password",
  "account.passwordNew": "New Password",
  "account.passwordNewReapeat": "Repeat Password",
  "account.password.incorrect": "The entered password does not match our records",

  //Account Preferences
  "account.settings.preferences.saved": "Preferences Updated",
  "account.settings.preferences.locale": "Locale",
  "account.locale.saved": "Locale Updated",
  "account.locale.saved.error": "Failed to update locale",
  "account.settings.preferences.locale.en": "English (United States)",
  "account.settings.preferences.locale.de": "German",
  "account.settings.preferences.timezone": "Time Zone",
  "account.settings.preferences.timezone.UTC": "UTC (Coordinated Universal Time)",
  "account.settings.preferences.newLocale": "New Locale",
  "account.settings.preferences.newTimezone": "New Time Zone",

  // Account Avatar
  "account.avatar.saved": "Avatar saved",
  "account.avatar.saved.error": "Failed to save avatar",
  "account.avatar.deleted": "Avatar deleted",
  "account.avatar.deleted.error": "Failed to delete avatar",

  // Account errors
  "account.errors.login": "Failed to login",
  "account.errors.signup": "The activation code is no longer valid.",

  // SSO unbind error
  "account.sso.unbind.error": "Could not remove login provider. Unknown error",

  // SSO sign up confirmation
  "account.sso.signup.confirmation.subheading": "Confirm your registration data",
  "account.sso.signup.confirmation.finishBtnLabel": "Finish Signing Up",

  // 2FA configuration
  "account.2fa.heading": "Two-Factor Authentication",
  "account.2fa.state": "Two-Factor Authentication",
  "account.2fa.state.short": "2FA",
  "account.2fa.state.enabled": "Active",
  "account.2fa.state.disabled": "Inactive",
  "account.2fa.enable": "Enable Two-Factor Authentication",
  "account.2fa.disable": "Disable Two-Factor Authentication",
  "account.2fa.password": "Current Password",
  "account.2fa.password.help": "Please enter your current password to enable Two-Factor Authentication",
  "account.2fa.showSecret": "Set Up Authentication on Your Phone",
  "account.2fa.cannotScan": "Cannot scan code?",
  "account.2fa.accountName": "Account name",
  "account.2fa.secret.label": "Your key",
  "account.2fa.secret.type": "Type of key",
  "account.2fa.secret.type.value": "Time based",
  "account.2fa.showSecret.help": "Scan this QR code with the authentication app on your phone to set up your second authentication factor.",
  "account.2fa.showSecret.secretText": "If you are unable to scan the code, please enter the following data into your authenticator app.",
  "account.2fa.confirmCode.help": "Please enter the code shown in your app to confirm the setup.",
  "account.2fa.confirmCode.title": "Authentication Code",
  "account.2fa.showSecret.confirm": "Confirm",
  "account.2fa.showSecret.cancel": "Cancel",
  "account.2fa.showRecoveryCodes.title": "Two-Factor Authentication Enabled",
  "account.2fa.showRecoveryCodes.help": "Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes. Please store them securely and do not share them with anyone. If you lose your device and don’t have the recovery codes you will lose access to your account.<br></br>Treat your recovery codes with the same level of attention as you would your password! We recommend saving them with a password manager.<br></br><br></br><bold>Note:</bold> The recovery codes cannot be shown again later. Please store them <em>now</em>.",
  "account.2fa.showRecoveryCodes.close": "Close",
  "account.2fa.showRecoveryCodes.copy": "Copy recovery codes to clipboard",
  "account.2fa.showRecoveryCodes.copy.success": "Recovery codes copied to clipboard",
  "account.2fa.showRecoveryCodes.copy.failed": "Could not copy recovery codes to clipboard",
  "account.2fa.saved.error": "Could not change the Two-Factor Authentication setting.",
  "account.2fa.enable.title": "Enable Two-Factor Authentication",
  "account.2fa.enable.confirm": "This will enable Two-Factor Authentication (2FA) on your account. Please note that you will not be able to log in without the second factor (i.e. the PIN generated by your authenticator app) afterwards.",
  "account.2fa.enable.accept": "Enable 2FA",
  "account.2fa.disable.title": "Disable Two-Factor Authentication",
  "account.2fa.disable.accept": "Disable 2FA",
  "account.2fa.disable.confirm": "This will disable your current Two-Factor Authentication settings, rendering the setup on your authenticating device useless.<br></br><br></br><bold>Note:</bold> If you re-enable Two-Factor Authentication on your account later, <em>you will need to set up your device again!</em>",
  "account.2fa.wrongPassword": "Password does not match our records",
  "account.2fa.codeNoMatch": "The code could not be verified. Refusing to enable Two-Factor Authentication. Please retry with the current code.",
  "account.2fa.codeNoMatch.disable": "The code could not be verified. Refusing to disable Two-Factor Authentication. Please retry with the current code or use a recovery code.",
  "account.2fa.codeNoMatch.recovery": "The code could not be verified. Refusing to reset existing recovery codes. Please retry with the current code or use a recovery code.",
  "account.2fa.disable.code": "Current Code or Unused Recovery Code",
  "account.2fa.disable.code.help": "Please enter the current code or one of your unused recovery codes.",
  "account.2fa.newRecoveryCodes": "Reset Recovery Codes",
  "account.2fa.newRecoveryCodes.title": "Reset Recovery Codes",
  "account.2fa.newRecoveryCodes.confirm": "In case you have lost your recovery codes or used them all up, you can create fresh codes here. Creating new codes will invalidate all previous recovery codes. It will not affect the authentication setup on your device.",
  "account.2fa.newRecoveryCodes.accept": "Reset Codes",
  //Admin
  "admin.tenants.headline": "Manage Organizations",
  "admin.tenants.details": "General",
  "admin.tenants.details.name": "Name",
  "admin.tenants.details.tenant": "Organization",
  "admin.tenants.details.baseShortName": "Short Name",
  "admin.tenants.details.description": "Description",
  "admin.tenants.details.isPaid": "Commercial",
  "admin.tenants.details.contract.active": "Active",
  "admin.tenants.details.contract.future": "Upcoming",
  "admin.tenants.details.contract.expired": "Expired",
  "admin.tenants.details.contract": "Contract",
  "admin.tenants.details.contractFrom": "From",
  "admin.tenants.details.contractTo": "To",
  "admin.tenants.details.contractInfo": "Contract Information",
  "admin.tenants.details.adminUser": "Administrator",
  "admin.tenants.details.state": "State",
  "admin.tenants.details.new": "New",
  "admin.tenants.details.createOrganization": "Create Organization",
  "admin.tenants.details.addDate": "Date Added",
  "admin.tenants.details.experimental": "Experimental Features",
  "admin.tenants.details.experimental.allowLt": "Allow Creation of Load Testing Projects",
  "admin.tenants.details.experimental.allowMonitoring": "Allow Creation of Monitoring Projects",
  "admin.tenants.details.experimental.liveMetrics": "Enable Scenario Live Metrics",
  "admin.tenants.details.experimental.ltOtelEnabled": "Enable Load Test Live Error Logging (OTEL)",
  "admin.tenants.details.new.headline": "Create New Organization",
  "admin.tenants.details.edit.headline": "Edit Organization",
  "admin.tenants.details.baseShortName.helpText": "The short name must be unique across all of XTC's orgs. It also cannot be edited after creation anymore. Be careful!",
  "admin.tenants.details.projectCount": "Projects",

  "admin.tenants.delete.confirm": "Are you sure you want to delete organization <em>{name}</em> and all its related data? You have the opportunity to undo the operation within <bold>30</bold> days before the data is permanently deleted.",
  "admin.tenants.delete.title": "Delete Organization",
  "admin.tenants.delete.message": "You are about to delete an organization. Are you absolutely sure?",
  "admin.tenants.delete.outcome": "This action can be reverted for <bold>30</bold> days before the data is permanently deleted.",
  "admin.tenants.delete.acceptLabel": "YES, I WANT TO DELETE",
  "admin.tenants.delete.order": "To confirm the deletion, enter the organization name (not the shortcode) below.",
  "admin.tenants.delete.listItem": "Organization Name:",

  "admin.tenants.create.order": "Because the short name cannot be changed after creation, you have to explicitly verify the creation of the organization by typing the desired org short name again.",
  "admin.tenants.create.title": "Confirm Organization Creation",
  "admin.tenants.create.acceptLabel": "Confirm",

  "admin.tenants.filters.paidOnly": "Commercial only",

  "admin.tenants.delete.error": "Failed to remove organization",
  "admin.tenants.load.error": "Failed to retrieve organization data from server",
  "admin.tenants.save.error": "Failed to save organization details",

  "admin.tenants.contextMenu.edit": "Edit",
  "admin.tenants.contextMenu.lock": "Lock",
  "admin.tenants.contextMenu.unlock": "Unlock",

  "admin.tenants.multiple.locked": "Lock",
  "admin.tenants.multiple.active": "Unlock",

  "admin.tenants.error.shortName.duplicate": "Entered short-name does already exist ",

  // Admin Projects
  "admin.projects.details": "System Settings",
  "admin.projects.details.menu": "Settings",
  "admin.projects.details.project": "Project",
  "admin.projects.details.baseShortName": "Short Name",
  "admin.projects.details.type": "Type",
  "admin.projects.details.edit": "Edit",
  "admin.projects.details.name": "Name",
  "admin.projects.details.adminUser": "Administrator",
  "admin.projects.details.state": "State",
  "admin.projects.details.addDate": "Date Added",
  "admin.projects.details.edit.headline": "Edit Project",
  "admin.projects.contextMenu.edit": "Edit",
  "admin.projects.details.executor": "Executors",
  "admin.projects.details.executor.description": "Define which executors are provisioned for this project.",

  // Admin Project Secrets
  "admin.projects.restoreSecret": "Restore Secret",
  "admin.projects.restoreSecret.hint": "You are about to restore the secrets of project \"<bold>{projectName}</bold>\". Are you sure?",
  "admin.projects.restoreSecret.success": "Secret successfully restored",
  "admin.projects.recreateSecret": "Create New Secret",
  "admin.projects.recreateSecret.hint": "You are about to create a new secret for project \"<bold>{projectName}</bold>\". Currently encrypted data can no longer be decrypted with the new secret and must be recreated manually. Are you sure?",
  "admin.projects.recreateSecret.success": "Secret successfully created",
  "admin.projects.recreateSecret.error.failed": "Could not create new secret!",
  "admin.projects.restoreSecret.all": "Restore Secrets",
  "admin.projects.restoreSecret.all.hint": "You are about to restore the secrets of all live projects. This will take some time. Are you sure?",
  "admin.projects.restoreSecret.all.success": "All secrets successfully restored",
  "admin.projects.restoreSecret.all.error": "Unknown error occurred while restoring secrets for following projects:",
  "admin.projects.recreateSecret.all": "Create New Secrets",
  "admin.projects.recreateSecret.all.hint": "You are about to create new secrets for the projects listed below that had no backups available. Currently encrypted data can no longer be decrypted with the new secret and must be recreated manually. This will take some time. Are you sure?",
  "admin.projects.recreateSecret.all.success": "Successfully created new secrets",
  "admin.projects.recreateSecret.all.error": "Unknown error occurred while creating new secrets for following projects:",
  "admin.projects.secret.all.error": "Not all secrets could be restored or recreated",

  // Admin Projects Persistence
  "admin.projects.details.persistence": "Data Persistence",
  "admin.projects.details.persistence.help": "Define how long certain data will be persisted for this user project.",
  "admin.projects.details.persistence.runs.daysArtifactsSucceeded.edit": "Keep Artifacts of Successful Executions (Days)",
  "admin.projects.details.persistence.runs.daysArtifactsFailed.edit": "Keep Artifacts of Other Executions (Days)",
  "admin.projects.details.persistence.runs.daysRuns.edit": "Keep Executions (Days)",

  // Admin Projects Grafana
  "admin.projects.details.grafana.url": "URL",
  "admin.projects.details.grafana.dashboardPath": "Path to Dashboard",
  "admin.projects.details.grafana.orgId": "Organization ID",
  "admin.projects.details.grafana.authToken": "Authentification",
  "admin.projects.details.grafana.authToken.help": "Set the Grafana API-Key used to anonymously authenthicate users of this project.",
  "admin.projects.details.grafana.url.edit": "Grafana URL",
  "admin.projects.details.grafana.subPath": "Grafana Sub Path",
  "admin.projects.details.grafana.dashboardPath.edit": "Grafana Dashboard Path",
  "admin.projects.details.grafana.orgId.edit": "Grafana Organization ID",
  "admin.projects.details.grafana.authToken.edit": "Grafana API-Key",
  "admin.projects.details.grafana.charts": "Charts",
  "admin.projects.details.grafana.charts.help": "Configure the charts shown at the users project overview.",
  "admin.projects.details.grafana.charts.edit": "Overview Charts",
  "admin.projects.details.grafana.chart.editing": "Edit Chart",
  "admin.projects.details.grafana.chart.new": "New Chart",
  "admin.projects.details.grafana.chart.dashboardPath": "Grafana Dashboard Path",
  "admin.projects.details.grafana.chart.panelID": "Grafana Panel ID",
  "admin.projects.details.grafana.chart.wide": "Chart Width",
  "admin.projects.details.grafana.chart.wide.false": "Small",
  "admin.projects.details.grafana.chart.wide.true": "Wide",
  "admin.projects.details.grafana.chart.back": "Apply",
  "admin.projects.details.grafana": "Grafana",
  "admin.projects.details.grafana.help": "Configure the basic Grafana settings for this project. These are required before any other setting below can be configured.",
  "admin.projects.details.grafana.none": "None",
  "validationError.dashboardPath": "Invalid dashboard path",
  "validationError.panelId": "Invalid panel id",
  "validationError.authToken": "Invalid token",
  "validationError.orgID": "Invalid org id",

  //Admin
  "admin.users.headline": "Manage Users",
  "admin.users.details.logo": "Avatar",
  "admin.users.details.user": "User",
  "admin.users.details.addDate": "Date Added",
  "admin.users.details.name": "Name",
  "admin.users.details.firstName": "First Name",
  "admin.users.details.lastName": "Last Name",
  "admin.users.details.provider": "Login Provider",
  "admin.users.details.2fa": "2FA",
  "admin.users.details.lastLogin": "Last Login",
  "admin.users.details.email": "Email Address",
  "admin.users.details.role": "System Role",
  "admin.users.details.state": "State",

  "admin.users.details.new.headline": "Create New User",
  "admin.users.details.new.accept": "Create User",
  "admin.users.details.edit.headline": "Edit User",
  "admin.users.delete.confirm.title": "Deleting User",
  "admin.users.delete.confirm": "Deleting a user cannot be undone. Are you really sure you want to remove {name} from the system?",

  "admin.users.contextMenu.edit": "Edit",
  "admin.users.contextMenu.lock": "Lock",
  "admin.users.contextMenu.unlock": "Unlock",
  "admin.users.contextMenu.unbind": "Remove SSO Binding",
  "admin.users.contextMenu.remove2FA": "Remove 2FA",
  "admin.users.contextMenu.resetPassword": "Reset Password",

  "admin.users.unbind.title": "Removing SSO Binding",
  "admin.users.unbind.accept": "Remove SSO Binding",
  "admin.users.unbind": "Remove the SSO binding from {user}. This will disable the external login for this user and trigger a password reset process, if the user has no password configured.",
  "admin.users.unbind.error": "Failed to unbind user from SSO",
  "admin.users.remove2FA.title": "Remove Two-Factor Authentication",
  "admin.users.remove2FA.help": "You are about to remove the Two-Factor Authentication (2FA) for <em>{user} \<{email}\></em>. This will remove the entire Two-Factor setup, so the user can afterward set it up again. This might disable access to some organizations temporarily in case they require 2FA.",
  "admin.users.remove2FA.error": "Failed to remove Two-Factor Authentication for this user.",
  "admin.users.resetPassword.title": "Enforcing Password Reset",
  "admin.users.resetPassword.accept": "Reset Password",
  "admin.users.resetPassword.help": "You are about to enforce a password reset for <em>{user} \<{email}\></em>.<br></br><br></br>This will invalidate the user's password and send a password reset email to the user. The user has to reset the password before it is able to log on again. If the user has SSO configured, it will continue to work as before.",
  "admin.users.resetPassword.error": "Failed to reset password for this user.",

  "admin.users.multiple.locked": "Lock",
  "admin.users.multiple.active": "Unlock",

  "admin.tableView.noData": "No data available",

  // Resource Tracking (System)
  "admin.resources.tabs.machines": "Machines",
  "admin.resources.tabs.storage": "Storage",
  "admin.resources.filter.from": "From",
  "admin.resources.filter.to": "To",
  "admin.resources.filter.commercial": "Commercial only",
  "admin.resources.filter.title": "Advanced Filter",
  "admin.resources.machines.name": "Date",
  "admin.resources.machines.tenant": "Organization",
  "admin.resources.machines.type": "Instance Type",
  "admin.resources.machines.time": "Instance Minutes",
  "admin.resources.storage.name": "Date",
  "admin.resources.storage.tenant": "Organization",
  "admin.resources.storage.results": "Results\u00a0(MiB)",
  "admin.resources.storage.reports": "Reports\u00a0(MiB)",
  "admin.resources.storage.total": "Total\u00a0(MiB)",

  // Tenant
  "tenant.details.admin": "Settings",
  "tenant.details.heading": "Organization",
  "tenant.details.subheading": "Details and Projects",
  "tenant.details.members": "Members",
  "tenant.details.projects": "Projects",

  "tenant.admin.configuration.general": "General",
  "tenant.admin.configuration.security": "Security",
  "tenant.admin.configuration.integrations": "Integrations",

  "tenant.admin.details": "General",
  "tenant.admin.details.help": "Define basic organization details like name and description which will be visible to most users.",
  "tenant.admin.details.name": "Name",
  "tenant.admin.details.baseShortName": "Short Name",
  "tenant.admin.details.description": "Description",
  "tenant.manage.isPaid": "Commercial Project",
  "tenant.admin.details.color": "Color",
  "tenant.admin.logo": "Logo",
  "tenant.admin.logo.help": "Upload a logo for this organization which will be visible to most users. For best results, upload an image with <em>transparent background</em> with a size of <em>500x80</em> pixel. <em>Note:</em> If you have a color selected above, the logo will be overlayed on this background color.",
  "tenant.admin.logo.deleteConfirmation.title": "Delete Organization Logo",
  "tenant.admin.logo.deleteConfirmation.message": "Do you really want to delete the organization logo?",
  "tenant.admin.image.delete": "Delete Logo",
  "tenant.admin.heading": "View and Tune Settings for {org}",
  "tenant.admin.menu": "Section",
  "tenant.admin.general": "General",
  "tenant.admin.members": "Memberships",
  "tenant.admin.projects": "Projects",

  "tenant.admin.access": "Access Requirements",

  "tenant.admin.access.help": "Define authorization requirements for the members of your organization. These requirements have to be fulfilled to allow a member to access the organization, if not, the member sees an access-violation message. <br></br><br></br><em>Important</em>: This only restricts access to the organization. It does <em>not</em> prevent the user from signing in to XTC.",
  "tenant.admin.loginRequirements": "Login requirement",
  "tenant.admin.loginRequirement.none": "None",
  "tenant.admin.loginRequirement.oidc": "SSO login required",
  "tenant.admin.loginRequirement.oidc.help": "Enabling this setting will require the users to be logged in via an OpenID Connect SSO provider in order to access this organization.",
  "tenant.admin.permittedIssuers": "Required SSO issuers",
  "tenant.admin.permittedIssuers.help": "This is a list of issuer URLs (one per line) that allow access to this organization. An empty list allows access to the organization by users logged in via arbitrary issuers. Any issuer URL entered must match the appropriate OpenID Connect Identity Provider (e.g. <code>https://accounts.google.com</code> for Google Accounts).",
  "tenant.admin.loginRequirement.mfa": "Two-Factor login required",
  "tenant.admin.loginRequirement.mfa.help": "Enabling this setting will require the users to be authenticated in via second factor in order to access this organization. The second factor may be any Time-based one-time password system like Google Authenticator.",
  "tenant.admin.loginRequirements.warning.selfLockout": "You are about to require {loginRequirement} for this organization, but are authenticated using {authenticationMethod}. You will still be able to save this configuration, but <em>you will lock yourself out of this organization</em>.",
  "tenant.admin.loginRequirements.warning.selfLockout.issuerOverlap": "The external OpenID provider you used to log in is not in the list of permitted providers for this organization. You will still be able to save this configuration, but <em>you will lock yourself out of this organization</em>.",

  "tenant.admin.general.headline": "Details",

  "tenant.admin.invitation": "User Invitations",
  "tenant.admin.invitation.enabled": "Enabled",
  "tenant.admin.invitation.disabled": "Disabled",
  "tenant.admin.invitation.title": "User Invitations",
  "tenant.admin.invitation.help": "User invitations enable administrators to add people to their organization and projects who don't have an XTC account yet. This also enables project administrators to add users to their projects without the need to have these users assigned to the organization first. If invitations have already been made, disabling the invitation permission won't remove the invited users from the projects or organization. Already open invitations stay open unless the invited user is removed from the organization before accepting the invite.",

  "tenant.manage.members.addDate": "Date Added",
  "tenant.manage.members.user": "Member",
  "tenant.manage.members.name": "Name",
  "tenant.manage.members.email": "Email Address",
  "tenant.manage.members.role": "Role",
  "tenant.manage.members.state": "State",
  "tenant.manage.members.access": "Access",
  "tenant.manage.members.lastLogOn": "Last Login",
  "tenant.manage.members.lock": "Lock Member",
  "tenant.manage.members.unlock": "Unlock Member",
  "tenant.manage.members.del": "Remove",
  "tenant.manage.members.new": "Add",
  "tenant.manage.members.add": "Add Members",
  "tenant.manage.members.add.help": "You can add users to your organization and assign them a role. {hint} \n\nIf you want to add multiple users, simply enter a list of email addresses separated by comma, newline, or space. All users will be assigned the same role.",
  "tenant.manage.members.add.hint.invite": "By default, only users who already have an XTC account can be added. If desired, you can invite users without an account to XTC by email and add them to your organization at the same time.",
  "tenant.manage.members.add.hint.registered": "Only users who already have an XTC account can be added.",
  "tenant.manage.members.add.email": "Enter one or more email addresses separated by comma, space or newline",
  "tenant.manage.members.bulkDelete.accept": "Remove Members",
  "tenant.manage.members.bulkDelete.title": "Remove Members from Organization ({count})",
  "tenant.manage.members.bulkDelete.confirm": "You are about to remove {count,plural,one {# member} other {# members}} from your organization and all related projects. Are you certain?",
  "tenant.manage.members.bulkDelete.confirmation": "REMOVE",
  "tenant.manage.members.delete.accept": "Remove Member",
  "tenant.manage.members.delete.title": "Remove Member from Organization",
  "tenant.manage.members.delete.confirm": "Do you really want to remove {name} from your organization and all related projects?",
  "tenant.manage.members.invite": "Invite Users",
  "tenant.manage.members.invite.menu": "Resend Invitation",
  "tenant.manage.members.invite.title": "Invite User",
  "tenant.manage.members.invite.help": "You are about to add users to your organization. The users who do not have an account will receive an email invitation to open an account. This invitation is valid for 30 days.",
  "tenant.manage.members.edit": "Edit Member Role",
  "tenant.manage.members.edit.menu": "Edit Role",
  "tenant.manage.members.edit.email": "Email",
  "tenant.manage.members.edit.role": "Role",
  "tenant.membership.state.locked": "Locked",
  "tenant.membership.state.systemLock": "Locked by system",
  "tenant.membership.state.systemLock.reason.oidc": "The organization requires users to be logged in via an external OpenID Connect provider (e.g. using a Google Account), but the user has not set up an external login.",
  "tenant.membership.state.systemLock.reason.oidc.wrongIssuer": "The organization requires users to be logged in via one of a specific set of external OpenID Connect providers, but the user has not set up an external login with a permitted provider.",
  "tenant.membership.state.systemLock.reason.mfa": "The organization requires users to be authenticated using Two-Factor Authentication, but the user has not set up an appropriate authenticator app.",

  // combined states of a membership of a user in a tenant
  "tenant.membership.state.active": "Active",
  "tenant.membership.state.active.help": "The user is an active member of the organization.",

  "tenant.membership.state.locked": "Locked",
  "tenant.membership.state.locked.help": "The user's membership was locked by an admin of this organization. The user does not have access to this organization.",

  "tenant.membership.state.blocked": "Blocked",
  "tenant.membership.state.blocked.help": "The user's membership of this organization was automatically blocked by the system because not all security policies are fulfilled.",

  "tenant.membership.state.invalid": "Invalid",
  "tenant.membership.state.invalid.help": "We could not determine the state of the membership of this user. Access to this organization will be denied",

  "tenant.members.invite.error": "Failed to invite user(s) to organization",
  "tenant.members.load.error": "Error while fetching organization's members",
  "tenant.members.put.error": "Error while adding members to organization",
  "tenant.members.delete.error": "Error while removing members from organization",


  // combined states of a membership of a user in a tenant
  "tenant.membership.state.active": "Active",
  "tenant.membership.state.active.help": "The user is an active member of the organization.",

  "tenant.membership.state.locked": "Locked",
  "tenant.membership.state.locked.help": "The user's membership was locked by an admin of this organization. The user does not have access to this organization.",

  "tenant.membership.state.blocked": "Blocked",
  "tenant.membership.state.blocked.help": "The user's membership of this organization was automatically blocked by the system because not all security policies are fulfilled.",

  "tenant.membership.state.invalid": "Invalid",
  "tenant.membership.state.invalid.help": "We could not determine the state of the membership of this user. They will not be able to access this organization.",

  "tenant.manage.projects.addDate": "Date Added",
  "tenant.manage.projects.project": "Project",
  "tenant.manage.projects.type": "Type",
  "tenant.manage.projects.name": "Name",
  "tenant.manage.projects.baseShortName": "Short Name",
  "tenant.manage.projects.description": "Description",
  "tenant.manage.projects.adminUser": "Administrator",
  "tenant.manage.projects.state": "State",
  "tenant.manage.projects.clone.accept": "Duplicate Project",

  "tenant.manage.projects.delete.title": "Delete Project",
  "tenant.manage.projects.delete.message": "You are about to delete a project. Are you absolutely sure?",
  "tenant.manage.projects.delete.listItem": "<bold>Project Name:</bold> ",
  "tenant.manage.projects.delete.outcome": "Are you sure you want to delete the project and all its related data? You have the opportunity to undo the operation within <bold>30</bold> days before the data is permanently deleted.",
  "tenant.manage.projects.delete.order": "To confirm the deletion, enter the project name (not the shortcode) below.",
  "tenant.manage.projects.delete.acceptLabel": "YES, I WANT TO DELETE",

  "tenant.manage.projects.contextMenu.edit": "Edit",
  "tenant.manage.projects.contextMenu.edit.state": "Change State",
  "tenant.manage.projects.contextMenu.clone": "Duplicate",
  "tenant.manage.projects.clone.mon.headline": "Duplicate Monitoring Project",
  "tenant.manage.projects.clone.lt.headline": "Duplicate Load Testing Project",
  "tenant.manage.projects.clone.help": "This will copy the project including its entire configuration. Please note that the duplicated project will need to be activated explicitly though.",
  "tenant.manage.projects.create.mon": "Monitoring Project",
  "tenant.manage.projects.create.lt": "Load Testing Project",

  // Tenant add/edit project dialog
  "tenant.manage.projects.add.mon": "Create New Monitoring Project",
  "tenant.manage.projects.add.lt": "Create New Load Testing Project",
  "tenant.manage.project.edit.mon.headline": "Edit Monitoring Project",
  "tenant.manage.project.edit.lt.headline": "Edit Load Testing Project",
  "tenant.manage.project.edit.name": "Name",
  "tenant.manage.project.edit.baseShortName": "Short Name",
  "tenant.manage.project.edit.add": "Create Project",

  // tenant specific
  "tenant.manage.allowsCustomMachines": "Allow customers to bring their own machines",
  "tenant.manage.allowsCustomMachines.enabled": "Enabled",
  "tenant.manage.allowsCustomMachines.disabled": "Disabled",

  // Tenant Management Notifications
  "tenant.manage.notifications.invitationSent": "Invitation successfully sent",

  "tenant.noCustomMachines": "This organization does not allow projects with custom machines",

  // Tenant Resource Tracking
  "tenant.details.resources.tabs.machines": "Machines",
  "tenant.details.resources.tabs.storage": "Storage",
  "tenant.details.machineResources.name": "Date",
  "tenant.details.machineResources.runs": "Test Runs",
  "tenant.details.machineResources.type": "Instance Type",
  "tenant.details.machineResources.time": "Instance Minutes",
  "tenant.details.storageResources.name": "Date",
  "tenant.details.storageResources.results": "Results\u00a0(MiB)",
  "tenant.details.storageResources.reports": "Reports\u00a0(MiB)",
  "tenant.details.storageResources.total": "Total\u00a0(MiB)",

  // Tenant Data Fetching
  "tenant.details.list.error": "Error while fetching organization's details",
  "tenant.details.save.error": "Error while saving organization's details",


  // Project
  "tenant.monitoring.status.errorsLastDay": "Errors in last 24 hrs",

  //ProjectDetails
  "project.state.active": "Active",
  "project.state.inactive": "Inactive",

  "project.details.edit.state": "Project State",
  "project.details.edit.projectName": "Project Name",
  "project.details.edit.projectName.error.empty": "Project-Name is empty",
  "project.details.edit.projectShortName": "Short Name",
  "project.details.edit.projectDescription": "Description",
  "project.details.edit.color": "Project Color",

  //ProjectDetails health
  "project.details.health.state.ok": "State OK",
  "project.details.health.state.warning": "Warning:",
  "project.details.health.state.critical": "Critical:",
  "project.details.health.state.fatal": "Fatal:",

  //ProjectDetails exports
  "project.details.exports": "Exports",
  "project.details.exports.size": "Size: {size}{unit}",
  "project.details.exports.title": "Exported Test Execution Data",
  "project.details.exports.help": "Find and download monthly exports of test execution data",
  "project.details.exports.emptyList": "There are currently no monthly exports available for download",

  //ProjectDetails date selection
  "project.details.date.showing.from": "Showing data from",
  "project.details.date.showing.to": "to",
  "project.details.date.from": "Show data from",
  "project.details.date.to": "to",

  //ProjectDetails validations
  "project.details.validations": "Service Health",
  "project.details.validations.add": "Add Validation",
  "project.details.validations.type.RequestRuntime": "Request Runtime",
  "project.details.validations.type.RequestResponseSize": "Response Size",
  "project.details.validations.type.RequestFailure": "Request Failures",

  //ProjectDetails charts
  "project.details.charts.add": "Add Chart",

  //ProjectDetails overview
  "project.details.overview": "Summary",
  "project.details.overview.time.select.M": "Show last {minutes} minutes",
  "project.details.overview.time.select.H": "Show last hour",
  "project.details.overview.time.select.Hs": "Show last {hours} hours",
  "project.details.overview.time.select.D": "Show last day",
  "project.details.overview.time.select.Ds": "Show last {days} days",
  "project.details.overview.time.select.HM": "Show last {hours} hour {minutes} minutes",
  "project.details.overview.time.select.HsM": "Show last {hours} hours {minutes} minutes",
  "project.details.overview.last": "Show last",
  "project.details.overview.last.hours": "Hours",
  "project.details.overview.last.minutes": "Minutes",
  "project.details.overview.charts.timeDiffWarning": "Chart times shown in deviating timezone",

  //ProjectDetails summary
  "project.details.summary.status.critical": "Last execution Failed",
  "project.details.summary.status.ok": "Last execution Successful",
  "project.details.summary.status.unknown": "No execution result",
  "project.details.summary.errorCount": "Issues (24 hours)",
  "project.details.summary.scenario.noLastFail": "No Issues (24 hours)",
  "project.details.summary.scenario.lastFail": "Last Issue",
  "project.details.summary.scenario.noResults": "No results (24 hours)",
  "project.details.summary.scenario.successCount": "Success",
  "project.details.summary.scenario.errorCount": "Error",
  "project.details.summary.scenario.failCount": "Fail",
  "project.details.summary.scenario.abortedCount": "Aborted",

  // Scenario Table
  "scenarioTable.header.name": "Name",
  "scenarioTable.header.interval": "Interval",
  "scenarioTable.header.state": "State",
  "scenarioTable.header.notifications": "Notifications",
  "scenarioTable.contextMenu.editScenario": "Edit",
  "scenarioTable.contextMenu.disableScenario": "Disable",
  "scenarioTable.contextMenu.enableScenario": "Enable",
  "scenarioTable.deleteScenarioConfirmation.title": "Delete Scenario",
  "scenarioTable.deleteScenarioConfirmation.message": "Do you really want to delete scenario \"{name}\"?",
  "scenarioTable.details.tabs.notifications": "Notifications",
  "scenarioTable.details.tabs.settings": "Settings",

  //ProjectDetails execution
  "project.details.execution": "Execution",

  //ProjectDetails dashboard
  "project.details.dashboard": "Metrics",
  "project.details.dashboard.guestRole": "Your current role (guest) does not allow you to access any project-related information.",
  "project.details.dashboard.timeDiffWarning": "Metric times shown in deviating timezone",
  "project.details.dashboard.title.pinnedLoadTests": "Pinned Load Tests",
  "project.details.dashboard.title.pinnedReports": "Pinned Reports",
  "project.details.dashboard.title.upcomingLoadTests": "Recent Load Tests",
  "project.details.dashboard.chart.byState.name": "Load Tests by State",
  "project.details.dashboard.chart.byRating.name": "Load Tests by Rating",
  "project.details.dashboard.chart.perDay.name": "Load Tests per Day",

  //ProjectDetails history
  "project.details.history": "History",
  "project.details.history.details": "Details",
  "project.details.history.exportToCSV": "Export To CSV",
  "project.details.history.filter.state": "Hide Successful Runs",
  "project.details.history.timers": "Measurements",
  "project.details.history.sortBy": "Sort By",
  "project.details.history.sortBy.id": "Run ID",
  "project.details.history.sortBy.state": "Status",
  "project.details.history.sortBy.scriptName": "Scenario",
  "project.details.history.sortBy.startTime": "Start",
  "project.details.history.sortBy.endTime": "End",
  "project.details.history.sortBy.duration": "Runtime",
  "project.details.history.sortBy.actionName": "Failed Action",
  "project.details.history.sortBy.error": "Error Message",
  "project.details.history.state.error": "Error",
  "project.details.history.resultBrowser": "Result Browser",
  "project.details.history.logs": "Execution Log",
  "project.details.history.logs.timeDiffWarning": "Log times shown in deviating timezone",
  "project.details.history.run": "Run#",
  "project.details.history.start": "Start",
  "project.details.history.location": "Location",
  "project.details.history.retry": "Retry",
  "project.details.history.executionReason": "Execution Reason",
  "project.details.history.executionReason.regular": "Regular Execution",
  "project.details.history.executionReason.retry": "Retry #",
  "project.details.history.include": "Include",
  "project.details.history.success.include": "Success Included",
  "project.details.history.success.exclude": "Success Hidden",
  "project.details.history.warning.include": "Warning Included",
  "project.details.history.warning.exclude": "Warning Hidden",
  "project.details.history.error.include": "Error Included",
  "project.details.history.error.exclude": "Error Hidden",
  "project.details.history.aborted.include": "Aborted Included",
  "project.details.history.aborted.exclude": "Aborted Hidden",
  "project.details.history.running.include": "Running Included",
  "project.details.history.running.exclude": "Running Hidden",
  "project.details.history.action.failed": "Action Failed",
  "project.details.history.unknown.failed": "Error Occurred",
  "project.details.history.criterion.failed": "Success Criterion Not Met",
  "project.details.history.criterion.failed.message": "{value}{unit} exceeds limit of {limit}{unit}",
  "project.details.history.criteria.failed": "Success Criteria Not Met",
  "project.details.history.criteria.failed.message": "Multiple success criteria were not met",
  "project.details.history.quietPeriod": "Within Quiet Period",
  "project.details.history.quietPeriod.active": "Yes",
  "project.details.history.quietPeriod.inactive": "No",

  //ProjectDetails history details
  "project.details.history.state.ok": "Successful",
  "project.details.history.state.ok.message": "The scenario finished successfully.",
  "project.details.history.state.scenario.failed.message": "Scenario Failed",
  "project.details.history.state.scenario.failed.at": "at",
  "project.details.history.state.scenario.failed.inAction": "in the action",
  "project.details.history.state.scenario.failed.withMessage": "with the message",
  "project.details.history.state.scenario.error": "Error Occured",
  "project.details.history.state.error.message": "Due to an error the scenario could not be executed.",
  "project.details.history.state.error.insufficientPermissionForLog": "Please contact your supervisor if you require further information.",
  "project.details.history.state.error.lookAtLog": "The execution log may contain further information.",
  "project.details.history.error.causes": "Causes for such a result could be:",
  "project.details.history.error.causes.list.1": "The Java project in the repository does not compile successfully.",
  "project.details.history.error.causes.list.2": "The source code repository is misconfigured.",
  "project.details.history.error.causes.list.3": "The source code repository is unavailable.",
  "project.details.history.error.causes.list.4": "The class name configured at the scenario does not denote an existing Java class in the repository.",
  "project.details.history.state.warning.action": "Failed Action",
  "project.details.history.state.warning.noAction": "Failure",
  "project.details.history.state.warning.successCriteria": "Success Criteria Not Met",
  "project.details.history.state.warning.successCriteria.message": "One or more success criteria were not met. Please see the success criteria listing for more information.",
  "project.details.history.state.warning.message": "The scenario failed with the following message:",
  "project.details.history.state.aborted": "Aborted",
  "project.details.history.state.aborted.message": "The scenario was aborted as it had exceeded the maximum permitted time",
  "project.details.history.state.running.message": "In Progress",
  "project.details.history.duration": "Duration",
  "project.details.history.end": "End",
  "project.details.history.executionResult": "Execution Result",
  "project.details.history.successCriteria": "Success Criteria",
  "project.details.history.failedRequests": "Failed Requests",
  "project.details.history.slowRequests": "Slowest Requests",
  "project.details.history.stackTrace": "Stack Trace",

  //ProjectDetails configuration
  "project.details.configuration": "Configuration",
  "project.details.configuration.menu": "Settings",
  "project.details.configuration.repository": "Repository",
  "project.details.configuration.build": "Build",
  "project.details.configuration.persistence": "Data Persistence",
  "project.details.configuration.execution": "Execution",
  "project.details.configuration.defaults": "Scenario Defaults",
  "project.details.configuration.scenarios.useDefault": "Use Default",
  "project.details.configuration.scenarios.list": "Scenarios",
  "project.details.configuration.scenarios.defaults.help": "Define defaults to be used for all monitoring scenarios. This defaults can be overwritten for each individual scenario.",
  "project.details.configuration.criterias": "Validations",
  "project.details.configuration.error.reset": "Scenario configuration reset failed.",
  "project.details.configuration.sharing": "Sharing",
  "project.details.configuration.sharing.report.disable.confirm.title": "Disable Report Sharing Default",
  "project.details.configuration.sharing.result.disable.confirm.title": "Disable Result Sharing Default",
  "project.details.configuration.sharing.disable.confirm.accept": "Disable",
  "project.details.configuration.sharing.report.disable.confirm": "This will disable the default for report sharing and all active shares which are using this default. All individually configured shares will stay active.",
  "project.details.configuration.sharing.result.disable.confirm": "This will disable the default for result sharing and all active shares which are using this default. All individually configured shares will stay active.",
  "project.details.configuration.sharing.deleteCustom": "Delete existing custom share links",
  "project.details.configuration.sharing.deleteCustom.help": "Use these options to remove all custom links to reports or results in this project. Custom share links are links that have a custom expiration date set instead of referring to the global preset.",
  "project.details.configuration.sharing.report.deleteCustom.button": "Remove custom report shares",
  "project.details.configuration.sharing.result.deleteCustom.button": "Remove custom result shares",
  "project.details.configuration.sharing.report.deleteCustom.help.confirm.title": "Remove all report share links",
  "project.details.configuration.sharing.report.deleteCustom.help.confirm": "This will remove all existing custom share links to reports in this project.",
  "project.details.configuration.sharing.report.deleteCustom.help.confirm.accept": "Remove all report shares",
  "project.details.configuration.sharing.result.deleteCustom.help.confirm.title": "Remove all result share links.",
  "project.details.configuration.sharing.result.deleteCustom.help.confirm": "This will remove all existing custom share links to results in this project.",
  "project.details.configuration.sharing.result.deleteCustom.help.confirm.accept": "Remove all result shares",
  "project.details.configuration.dangerousOperation": "Note: This operation CANNOT be undone!",
  "project.details.configuration.sharing.result.delete.success": "Removed {num, number} custom result shares.",
  "project.details.configuration.sharing.report.delete.success": "Removed {num, number} custom report shares.",
  "project.details.configuration.sharing.result.delete.failure": "Could not remove custom report shares.",
  "project.details.configuration.sharing.report.delete.failure": "Could not remove custom result shares.",
  "project.details.configuration.environment": "Environment",
  "project.details.configuration.environment.title": "Default Execution Environment ",
  "project.details.configuration.environment.help": "Define which load test execution environment should be used by default for new load tests",
  "project.details.configuration.environment.xltVersionId": "Execution Environment",
  "project.details.configuration.environment.xltVersionId.unsupported": "No longer supported",
  "project.details.configuration.integrations": "Integrations",

  "project.details.configuration.state.title": "Project State",
  "project.details.configuration.state.help": "Project State",

  "project.details.configuration.state.toActive": "Do you really want to set this project to active? All previous users can fully interact with it again.",
  "project.details.configuration.state.toActive.title": "Activate Project",
  "project.details.configuration.state.toActive.accept": "Set Active",

  "project.details.configuration.state.toInactive": "Do you really want to set this project to inactive? The project becomes effectively read-only. Shared data will stay shared as configured. An inactive project stays billable.",
  "project.details.configuration.state.toInactive.title": "Deactivate Project",
  "project.details.configuration.state.toInactive.accept": "Set Inactive",

  "project.details.configuration.state.toArchived": "Archiving a project will prevent everyone from being able to see and access the project. All active shares will be invalidated permanently. An archived project stays billable. No data will be deleted.",
  "project.details.configuration.state.toArchived.title": "Archive Project",
  "project.details.configuration.state.toArchived.accept": "Set Archived",
  "project.details.configuration.state.toArchived.confirm": "archive it",

  "project.details.configuration.state.fromArchivedtoActive": "Do you really want to set this project to Active? All assigned user will see this project again.",
  "project.details.configuration.state.fromArchivedtoInactive": "Do you really want to set this project to Inactive? All assigned user will see this project again.",

  //ProjectDetails configuration general
  "project.details.configuration.general": "General",
  "project.details.configuration.general.help": "Define basic project details like name and description which will be visible to most users.",
  "project.details.configuration.general.status": "State",
  "project.details.configuration.general.name": "Name",
  "project.details.configuration.general.baseShortName": "Short Name",
  "project.details.configuration.general.description": "Description",
  "project.details.configuration.general.color": "Project Color",

  //ProjectDetails configuration repository
  "project.details.configuration.repository.none": "None",
  "project.details.configuration.repository.location": "Repository",
  "project.details.configuration.repository.location.help": "Define where the project's repository is located and how to resolve the test suite's code base within the repository. Default test suite path is the repository root.",
  "project.details.configuration.repository.location.none": "Not Configured",
  "project.details.configuration.repository.location.url": "Location URL",
  "project.details.configuration.repository.location.url.edit": "Location URL",
  "project.details.configuration.repository.location.url.help": "Credentials may not be part of the URL. Please configure them in the Authentication section.",
  "project.details.configuration.repository.location.suitePath": "Suite Path",
  "project.details.configuration.repository.location.suitePath.edit": "Suite Path",

  "project.details.configuration.repository.auth": "Authentication",
  "project.details.configuration.repository.auth.help": "Provide authentication details if the access to the remote repository is restricted.",
  "project.details.configuration.repository.auth.none": "No Authentication / Anonymous Access",
  "project.details.configuration.repository.auth.username": "Username",
  "project.details.configuration.repository.auth.password": "Password",
  "project.details.configuration.repository.auth.passphrase": "Key's Passphrase",
  "project.details.configuration.repository.auth.key": "SSH Key",
  "project.details.configuration.repository.auth.key.description": "Private Key",
  "project.details.configuration.repository.auth.newPassword": "New Password",
  "project.details.configuration.repository.auth.newPassphrase": "New Passphrase",
  "project.details.configuration.repository.auth.newKey": "New OpenSSH-compatible Private Key",
  "project.details.configuration.repository.auth.type": "Authentication Method",
  "project.details.configuration.repository.auth.type.creds": "Credentials",
  "project.details.configuration.repository.auth.type.sshkey": "SSH Key",

  "project.details.configuration.repository.auth.remove.confirm": "Are you sure you want to remove the configured authentication?",

  "project.details.configuration.repository.branch": "Branch",
  "project.details.configuration.repository.branch.help": "Define which branch to use by specifying either its name or a URL of a resource from which the branch name can be extracted using a regular expression.",
  "project.details.configuration.repository.branch.help.staticOnly": "Define which branch to use by specifying its name.",
  "project.details.configuration.repository.branch.none": "Not Configured",
  "project.details.configuration.repository.branch.name": "Name",
  "project.details.configuration.repository.branch.url": "Retrieval URL",
  "project.details.configuration.repository.branch.regex": "Regular Expression",
  "project.details.configuration.repository.branch.matchingGroup": "Matching Group",
  "project.details.configuration.repository.branch.type": "Type",
  "project.details.configuration.repository.branch.type.static": "Use a fix/static branch",
  "project.details.configuration.repository.branch.type.static.description": "Fix/Static",
  "project.details.configuration.repository.branch.type.dynamic": "Determine branch dynamically",
  "project.details.configuration.repository.branch.type.dynamic.description": "Dynamic",

  //ProjectDetails configuration build
  "project.details.configuration.build.buildtool": "Build Tool",
  "project.details.configuration.build.buildtool.help": "Define how to build the test suite.",
  "project.details.configuration.build.buildtool.maven.help": "Build test suite using <bold>mvn process-classes process-test-classes dependency:copy-dependencies</bold>",
  "project.details.configuration.build.buildtool.gradle.help": "Build test suite using <bold>gradle classes testClasses</bold>",
  "project.details.configuration.build.buildtool.name": "Tool Name",
  "project.details.configuration.build.buildtool.arguments": "Additional Arguments",
  "project.details.configuration.build.buildtool.arguments.help": "Additional arguments to pass to build tool (e.g. Maven profile or Gradle project properties)",

  "project.details.configuration.build.builddeps": "Build Dependency Cache",
  "project.details.configuration.build.builddeps.help": "Building a project typically involves the download of several dependencies. In order to not download the necessary dependencies for each separate build, XTC makes use of a build dependency cache. However, it might come in handy to start over with a clean cache, for example due to currupted or compromised artifacts.<br></br><br></br>Use the button below to discard the current build dependency cache.",
  "project.details.configuration.build.builddeps.discard": "Discard Cache",
  "project.details.configuration.build.builddeps.discard.success": "Build dependency cache successfully discarded",

  //ProjectDetails configuration persistence
  "project.details.configuration.persistence.help": "How long certain data will be persisted.",
  "project.details.configuration.persistence.runs.expiration.artifacts": "Execution Artifacts",
  "project.details.configuration.persistence.runs.expiration": "Executions",
  "project.details.configuration.persistence.runs.daysArtifactsFailed": "Other",
  "project.details.configuration.persistence.runs.daysArtifactsSucceeded": "Successful",
  "project.details.configuration.persistence.runs.daysRuns": "History",
  "project.details.configuration.persistence.none": "None configured",

  //ProjectDetails configuration execution
  "project.details.configuration.execution.help": "Define where and how the scenario should be executed.",
  "project.details.configuration.execution.reset": "Do you really want to use the default execution settings?",
  "project.details.configuration.execution.interval": "Interval",
  "project.details.configuration.execution.interval.edit": "Interval",
  "project.details.configuration.execution.interval.reset": "Do you really want to use the default execution interval settings?",
  "project.details.configuration.execution.retry": "Retry",
  "project.details.configuration.execution.retry.enabled": "Status",
  "project.details.configuration.execution.retry.enabled.active": "Activated",
  "project.details.configuration.execution.retry.enabled.inactive": "Deactivated",
  "project.details.configuration.execution.retry.interval": "Interval",
  "project.details.configuration.execution.retry.interval.edit": "Interval",
  "project.details.configuration.execution.retry.count": "Count",
  "project.details.configuration.execution.retry.count.edit": "Count",
  "project.details.configuration.execution.retry.reset": "Do you really want to use the default retry settings?",
  "project.details.configuration.execution.error.reset": "Configuration reset failed.",
  "project.details.configuration.execution.maxRuntime": "Maximum runtime",
  "project.details.configuration.execution.maxRuntime.edit": "Maximum runtime",
  "project.details.configuration.execution.maxRuntime.reset": "Do you really want to use the default maximum runtime setting?",


  //ProjectDetails configuration location
  "project.details.configuration.execution.location": "Locations",
  "project.details.configuration.execution.location.unavailable": "Unprovisioned",
  "project.details.configuration.execution.location.available": "Provisioned",
  "project.details.configuration.execution.location.excluded": "Excluded",
  "project.details.configuration.execution.location.none": "None",
  "project.details.configuration.execution.location.noneAvailable": "No provisioned locations available.",
  "project.details.configuration.execution.location.reset": "Do you really want to use the default location settings?",
  "project.details.configuration.execution.location.unavailable.show": "Unprovisioned shown",
  "project.details.configuration.execution.location.unavailable.hide": "Unprovisioned hidden",
  "project.details.configuration.execution.location.EU": "Europe",
  "project.details.configuration.execution.location.NA": "America",
  "project.details.configuration.execution.location.AS": "Asia-Pacific",
  "project.details.configuration.execution.location.Unknown": "Other Locations",

  //ProjectDetails configuration executor ip's
  "project.details.configuration.executor": "Execution Environment",
  "project.details.configuration.executor.help": "Details of the execution environment where the monitoring scenarios are running in.",
  "project.details.configuration.executor.ips": "IP Addresses",
  "project.details.configuration.executor.ips.none": "None",
  "project.details.configuration.executor.ips.add": "Add IP Address",
  "project.details.configuration.executor.ips.hint": "Format [0-255].[0-255].[0-255].[0-255]",
  "project.details.configuration.executor.ips.error.invalidIp": "IP not valid",
  "project.details.configuration.executor.ips.error.exists": "IP already exists",

  //ProjectDetails configuration notifications
  "project.details.configuration.notifications": "Notifications",
  "project.details.configuration.notifications.help": "Manage the notification recipients or temporary disable notifications.",
  "project.details.configuration.notifications.reset": "Do you really want to use the default notification settings?",
  "project.details.configuration.notifications.type.email": "Email Address",
  "project.details.configuration.notifications.type.text": "Text Message",
  "project.details.configuration.notifications.include": "Include",
  "project.details.configuration.notifications.subscriber": "Subscriber",
  "project.details.configuration.notifications.subscriberList": "Subscription List",
  "project.details.configuration.notifications.subscriber.default": "(from scenario defaults)",
  "project.details.configuration.notifications.subscriber.state": "State",
  "project.details.configuration.notifications.subscriber.channels.active": "Active Channels",
  "project.details.configuration.notifications.subscriber.type.email.available": "Email",
  "project.details.configuration.notifications.subscriber.type.email.unavailable": "Email (No email address configured)",
  "project.details.configuration.notifications.subscriber.type.text.available": "Text Message",
  "project.details.configuration.notifications.subscriber.type.text.unavailable": "Text Message (No phone configured)",
  "project.details.configuration.notifications.subscriber.type.slack.available": "Slack Message",
  "project.details.configuration.notifications.subscriber.type.slack.unavailable": "Slack Message (Organization/Project-defined channels)",
  "project.details.configuration.notifications.subscriber.list.empty": "No default subscribers configured. Please add some in the scenario defaults.",
  "project.details.configuration.notifications.scenario.remove": "Use Defaults",
  "project.details.configuration.notifications.scenario.remove.message": "Do you really want to use the default settings for this subscriber?",
  "project.details.configuration.notifications.scenario.lock": "Force inactive",
  "project.details.configuration.notifications.scenario.unlock": "Force active",
  "project.details.configuration.notifications.active.slider": "Activated",
  "project.details.configuration.notifications.inactive.slider": "Deactivated",
  "project.details.configuration.notifications.active": "Active",
  "project.details.configuration.notifications.inactive": "Inactive",
  "project.details.configuration.notifications.excluded": "Excluded",
  "project.details.configuration.notifications.count": "Active Subscribers",
  "project.details.configuration.notifications.add.user": "User",
  "project.details.configuration.notifications.add.user.title": "Add User",
  "project.details.configuration.notifications.edit.user.title": "Edit User",
  "project.details.configuration.notifications.add.custom": "Custom Entry",
  "project.details.configuration.notifications.add.custom.title": "Add Custom Entry",
  "project.details.configuration.notifications.edit.custom.title": "Edit Custom Entry",
  "project.details.configuration.notifications.add.slack": "Slack Channels",
  "project.details.configuration.notifications.add.slack.title": "Add Slack Channels",
  "project.details.configuration.notifications.edit.slack.title": "Edit Slack Channels",
  "project.details.configuration.notifications.edit": "Edit",
  "project.details.configuration.notifications.lock": "Deactivate",
  "project.details.configuration.notifications.lock.warn": "Attention: All notifications are currently disabled.",
  "project.details.configuration.notifications.unlock": "Activate",
  "project.details.configuration.notifications.remove": "Remove",
  "project.details.configuration.notifications.remove.message": "Do you really want to remove this subscriber?",
  "project.details.configuration.notifications.edit.name": "Subscriber's Name",
  "project.details.configuration.notifications.edit.email": "Subscriber's Address (Email)",
  "project.details.configuration.notifications.edit.text": "Subscriber's Phone Number",
  "project.details.configuration.notifications.edit.state": "Receive Notifications via",
  "project.details.configuration.notifications.failThreshold": "Send Threshold",
  "project.details.configuration.notifications.failThreshold.edit": "Fail Count",
  "project.details.configuration.notifications.failThreshold.diff": "Fails",
  "project.details.configuration.notifications.failThreshold.edit.help": "How many executions must fail to send a notification",
  "project.details.configuration.notifications.failThreshold.single": "Always send a notification when the scenario failed.",
  "project.details.configuration.notifications.failThreshold.multiple": "Send notification when the scenario failed {threshold} times in a row.",
  "project.details.configuration.notifications.failThreshold.history": "Send notification when the scenario failed {threshold} time(s) during the last {thresholdHistorySize} executions.",
  "project.details.configuration.notifications.failThreshold.reset": "Do you really want to reset the notification threshold settings?",
  "project.details.configuration.notifications.failThresholdHistorySize.edit": "Considered Executions",
  "project.details.configuration.notifications.failThresholdHistorySize.diff": "Executions",
  "project.details.configuration.notifications.failThresholdHistorySize.edit.help": "How many of the last executions should be considered to validate the fail count against",
  "project.details.configuration.notifications.replyTo": "Reply-To",
  "project.details.configuration.notifications.replyTo.edit": "Reply-To Address",
  "project.details.configuration.notifications.replyTo.edit.help": "The default reply address for received notification mails",
  "project.details.configuration.notifications.replyTo.defined": "{address} will be used as default Reply-To address.",
  "project.details.configuration.notifications.replyTo.none": "No default Reply-To address specified.",
  "project.details.configuration.notifications.replyTo.reset": "Do you really want to reset the Reply-To address?",

  //ProjectDetails configuration properties
  "project.details.configuration.properties": "Properties",
  "project.details.configuration.properties.secret": "Secret Properties",
  "project.details.configuration.properties.none": "No Properties defined.",
  "project.details.configuration.properties.secret.help": "Define secret properties to be used for test execution. These properties are used like normal properties, but their values do neither show up in the UI nor in reports.",
  "project.details.configuration.properties.help": "Define properties to be used for test execution.",
  "project.details.configuration.properties.edit": "Properties",
  "project.details.configuration.properties.reset": "Do you really want to use the default properties?",
  "project.details.configuration.properties.copiedToClipboard.failed": "Unable to copy properties to clipboard!",

  //ProjectDetails configuration scenario
  "project.details.configuration.scenarios.help": "Manage the monitoring scenarios of this project.",
  "project.details.configuration.scenarios.add": "Add New Scenario",
  "project.details.configuration.scenarios.edit.help": "Overwrite the default scenario settings with a scenario specific configuration.",
  "project.details.configuration.scenario.close": "Back",
  "project.details.configuration.scenario.general": "General",
  "project.details.configuration.scenario.general.help": "Define the basic scenario settings.",
  "project.details.configuration.scenario.overwritten": "Defaults overwritten",
  "project.details.configuration.scenarios.remove.message": "Do you really want to delete this scenario?",
  "project.details.configuration.scenario.edit": "Edit",
  "project.details.configuration.scenario.lock": "Disable",
  "project.details.configuration.scenario.unlock": "Enable",
  "project.details.configuration.scenario.active": "Active",
  "project.details.configuration.scenario.name": "Name",
  "project.details.configuration.scenario.name.edit": "Name",
  "project.details.configuration.scenario.description": "Description",
  "project.details.configuration.scenario.description.edit": "Description",
  "project.details.configuration.scenario.inactive": "Inactive",
  "project.details.configuration.scenario.javaClass": "Java Class",
  "project.details.configuration.scenario.javaClass.edit": "Java Class",
  "project.details.configuration.scenario.properties": "Properties",
  "project.details.configuration.scenario.properties.reset": "Do you really want to use the default scenario properties?",
  "project.details.configuration.scenario.interval": "Interval",
  "project.details.configuration.scenario.retry": "Retry",
  "project.details.configuration.scenario.retry.enabled": "Status",
  "project.details.configuration.scenario.retry.enabled.active": "Active",
  "project.details.configuration.scenario.retry.enabled.inactive": "Inactive",
  "project.details.configuration.scenario.retry.interval": "Interval",
  "project.details.configuration.scenario.retry.count": "Count",
  "project.details.configuration.scenario.maxRuntime": "Maximum runtime",
  "project.details.configuration.scenario.criterions": "Criteria",

  //ProjectDetails configuration memberships
  "project.details.configuration.memberships": "Memberships",
  "project.details.configuration.memberships.members": "Member",
  "project.details.configuration.memberships.role": "Role",
  "project.details.configuration.memberships.sort.email": "Email Address",
  "project.details.configuration.memberships.sort.role": "Role",
  "project.details.configuration.memberships.sort.state": "State",
  "project.details.configuration.memberships.role.Admin": "Administrator",
  "project.details.configuration.memberships.role.Guest": "Guest",
  "project.details.configuration.memberships.role.Reviewer": "Reviewer",
  "project.details.configuration.memberships.role.Tester": "Tester",
  "project.details.configuration.memberships.role.TestManager": "Test Manager",
  "project.details.configuration.memberships.state": "State",
  "project.details.configuration.memberships.lastLogOn": "Last Login",
  "project.details.configuration.memberships.new": "Add",
  "project.details.configuration.memberships.add.title": "Add Members",
  "project.details.configuration.memberships.add.help": "You can add users to your project and assign them a role. {hint}\n\nIf you want to add multiple users, simply enter a list of email addresses separated by comma, space, or newline. All users will be assigned the same role.",
  "project.details.configuration.memberships.add.hint.invite": "By default, you can only assign users who are already members of your organization. If desired, you can invite users who either don't have an XTC account yet or are not part of your organization.",
  "project.details.configuration.memberships.add.hint.registered": "Make sure the user is already signed up with this email address and is assigned to your organization.",
  "project.details.configuration.memberships.add.email": "Email Address",
  "project.details.configuration.memberships.add.email.help": "Enter one or more email addresses separated by comma, space or newline",
  "project.details.configuration.memberships.add.role": "Role",
  "project.details.configuration.memberships.add.role.help": "Choose desired project role",
  "project.details.configuration.memberships.bulkRemove.title": "Remove Members from Project ({count})",
  "project.details.configuration.memberships.bulkRemove.accept": "Remove Members",
  "project.details.configuration.memberships.bulkRemove.message": "You are about to remove {count,plural,one {# member} other {# members}} from this project. Are you certain?",
  "project.details.configuration.memberships.bulkRemove.confirmation": "REMOVE",
  "project.details.configuration.memberships.remove": "Remove",
  "project.details.configuration.memberships.remove.title": "Remove Member from Project",
  "project.details.configuration.memberships.remove.accept": "Remove Member",
  "project.details.configuration.memberships.remove.message": "Do you really want to remove {name} from your project?",
  "project.details.configuration.memberships.edit": "Edit Member Role",
  "project.details.configuration.memberships.edit.menu": "Edit Role",
  "project.details.configuration.memberships.edit.role": "Role",
  "project.details.configuration.memberships.invite": "Invite Users",
  "project.details.configuration.memberships.invite.title": "Invite User",
  "project.details.configuration.memberships.invite.help": "You are about to add users to your project who <bold>do not have an XTC account</bold> or <bold>are not members of your organization</bold>. The users who do not have an account will receive an email invitation to open an account. This invitation is valid for 30 days. All users become part of your organization. They will be assigned the Organization Guest role.",
  "project.details.configuration.memberships.invite.menu": "Resend Invitation",
  "project.members.invite.error": "Failed to invite user(s) to project",

  //ProjectDetails configuration criteria
  "project.details.configuration.criterions": "Criteria",
  "project.details.configuration.criterions.help": "Define criteria which will be validated during the scenario execution. Violations cause the scenario to be treated as failed.",
  "project.details.configuration.criterions.name": "Criterion",
  "project.details.configuration.criterions.value": "Threshold",
  "project.details.configuration.criterions.edit": "Edit",
  "project.details.configuration.criterions.remove": "Remove",
  "project.details.configuration.criterions.remove.message": "Do you really want to remove this criterion from the project?",
  "project.details.configuration.criterions.allInUse": "All available criteria are in use.",
  "project.details.configuration.criterions.types": "Criterion",
  "project.details.configuration.criterions.reset": "Do you really want to use the default criteria settings?",
  "project.details.configuration.criterions.removed": "Excluded",
  "project.details.configuration.criterion.add": "Add Criterion",
  "project.details.configuration.criterion.edit": "Edit Criterion",

  // Project Maintenance Windows
  "project.details.configuration.quietPeriod": "Quiet Periods",
  "project.details.configuration.quietPeriod.help": "Configure your quiet periods here. During these periods, notifications for this project are not sent.",
  "project.details.configuration.quietPeriod.add.title": "Add Quiet Period",
  "project.details.configuration.quietPeriod.edit.title": "Edit Quiet Period",
  "project.details.configuration.quietPeriod.label": "Label",
  "project.details.configuration.quietPeriod.timeRange": "Time Range",
  "project.details.configuration.quietPeriod.timeRange.order": "The end date may not be before the start date.",
  "project.details.configuration.quietPeriod.timeRange.past": "The end date is in the past.",
  "project.details.configuration.quietPeriod.startTime": "Start Date",
  "project.details.configuration.quietPeriod.endTime": "End Date",
  "project.details.configuration.quietPeriod.executeScenarios": "Execute Scenarios",
  "project.details.configuration.quietPeriod.executeScenarios.enabled": "Enabled",
  "project.details.configuration.quietPeriod.executeScenarios.disabled": "Disabled",
  "project.details.configuration.quietPeriod.state": "Enabled",
  "project.details.configuration.quietPeriod.state.enabled": "Enable",
  "project.details.configuration.quietPeriod.state.disabled": "Disable",
  "project.details.configuration.quietPeriod.edit.menu": "Edit",
  "project.details.configuration.quietPeriod.delete": "Delete",
  "project.details.configuration.quietPeriod.delete.message": "Do you really want to delete the quiet period from this project?",

  // default share configuration
  "project.details.configuration.sharing.report": "Report Sharing Default",
  "project.details.configuration.sharing.report.state": "State",
  "project.details.configuration.sharing.report.help": "When setting a default for the share expiration time, each report sharing will offer this time as a default. Later on, all shares can be either deactivated, extended, or reactivated at once when required. It is still possible to set individual expiration times per report, but they cannot be extended or deactivated from here. Reports won't be shared automatically.",
  "project.details.configuration.sharing.report.enabled": "Enabled",
  "project.details.configuration.sharing.report.disabled": "Disabled",
  "project.details.configuration.sharing.result": "Result Sharing Default",
  "project.details.configuration.sharing.result.state": "State",
  "project.details.configuration.sharing.result.help": "Similar to report shares, result share expiration can be configured globally for easier project management. Results won't be shared automatically.",
  "project.details.configuration.sharing.result.enabled": "Enabled",
  "project.details.configuration.sharing.result.disabled": "Disabled",
  "project.details.configuration.sharing.expires": "Expires",
  "project.details.configuration.sharing.enabled": "Enabled",
  "project.details.configuration.sharing.disabled": "Disabled",
  "project.details.configuration.sharing.invalidDeadline": "Please enter a valid expiry time in the future.",
  "project.details.configuration.sharing.deadlineTooLong": "Shared URLs may not be valid for more than 180 days.",

  // Resource Tracking
  "project.details.resources.tabs.machines": "Machines",
  "project.details.resources.tabs.storage": "Storage",
  "project.details.machineResources.index": "#",
  "project.details.machineResources.initiator": "Started By",
  "project.details.machineResources.instances": "Instances",
  "project.details.machineResources.instanceStart": "Start",
  "project.details.machineResources.instanceStop": "Stop",
  "project.details.machineResources.sortby.index": "Number",
  "project.details.machineResources.sortby.initiator": "Starting User",
  "project.details.machineResources.sortby.testIndex": "Load Test",
  "project.details.machineResources.sortby.testStart": "Test Start",
  "project.details.machineResources.testIndex": "Load Test\u00a0(#)",
  "project.details.machineResources.testOutcome": "Test Outcome",
  "project.details.machineResources.testOutcome.ABORTED": "Aborted by User",
  "project.details.machineResources.testOutcome.ERROR": "Errorneous",
  "project.details.machineResources.testOutcome.FINISHED": "Finished",
  "project.details.machineResources.testStart": "Test Start",
  "project.details.machineResources.uptime": "Uptime",
  "project.details.machineResources.instances.aws.uptime": "AWS Instance Minutes",
  "project.details.machineResources.instances.gcp.uptime": "GCP Instance Minutes",
  "project.details.machineResources.instances.hetzner.uptime": "Hetzner Instance Minutes",
  "project.details.machineResources.instances.custom.uptime": "Custom Instance Minutes",
  "project.details.machineResources.instances.uptime.total": "Total",

  "project.details.storageResources.name": "Date",
  "project.details.storageResources.results": "Results\u00a0(MiB)",
  "project.details.storageResources.reports": "Reports\u00a0(MiB)",
  "project.details.storageResources.total": "Total\u00a0(MiB)",
  "project.details.storageResources.usage.results": "Results Usage",
  "project.details.storageResources.usage.reports": "Reports Usage",
  "project.details.storageResources.usage.total": "Total Usage",

  // Project Details Notifications
  "project.details.notifications.invitationSent": "Invitation successfully sent",

  // Project Data Fetching
  "project.details.list.error": "Error while fetching project data",

  // Criterion
  "criterion.REQUEST_TIME": "Maximum Request Runtime",
  "criterion.value.REQUEST_TIME": "Milliseconds",
  "criterion.unit.REQUEST_TIME": "ms",
  "criterion.REQUEST_ERRORS": "Maximum Request Errors",
  "criterion.value.REQUEST_ERRORS": "Count",
  "criterion.ACTION_TIME": "Maximum Action Runtime",
  "criterion.value.ACTION_TIME": "Milliseconds",
  "criterion.unit.ACTION_TIME": "ms",
  "criterion.TRANSACTION_TIME": "Maximum Transaction Runtime",
  "criterion.value.TRANSACTION_TIME": "Milliseconds",
  "criterion.unit.TRANSACTION_TIME": "ms",
  "criterion.DOM_LOAD_TIME": "Maximum Time to DomContentLoaded Event",
  "criterion.value.DOM_LOAD_TIME": "Milliseconds",
  "criterion.unit.DOM_LOAD_TIME": "ms",
  "criterion.PAGE_LOAD_TIME": "Maximum Time to Load Event",
  "criterion.value.PAGE_LOAD_TIME": "Milliseconds",
  "criterion.unit.PAGE_LOAD_TIME": "ms",
  "criterion.FIRST_PAINT_TIME": "Maximum Time to FirstPaint Event",
  "criterion.value.FIRST_PAINT_TIME": "Milliseconds",
  "criterion.unit.FIRST_PAINT_TIME": "ms",
  "criterion.FIRST_CONTENTFUL_PAINT_TIME": "Maximum Time to FirstContentfulPaint Event",
  "criterion.value.FIRST_CONTENTFUL_PAINT_TIME": "Milliseconds",
  "criterion.unit.FIRST_CONTENTFUL_PAINT_TIME": "ms",
  "criterion.WV_CLS": "Maximum Cumulative Layout Shift",
  "criterion.value.WV_CLS": "Layout Shift Score",
  "criterion.WV_FCP": "Maximum Time to First Contentful Paint",
  "criterion.value.WV_FCP": "Milliseconds",
  "criterion.unit.WV_FCP": "ms",
  "criterion.WV_FID": "Maximum First Input Delay",
  "criterion.value.WV_FID": "Milliseconds",
  "criterion.unit.WV_FID": "ms",
  "criterion.WV_INP": "Maximum Time from Interaction to Next Paint",
  "criterion.value.WV_INP": "Milliseconds",
  "criterion.unit.WV_INP": "ms",
  "criterion.WV_LCP": "Maximum Time to Largest Contentful Paint",
  "criterion.value.WV_LCP": "Milliseconds",
  "criterion.unit.WV_LCP": "ms",
  "criterion.WV_TTFB": "Maximum Time to First Byte",
  "criterion.value.WV_TTFB": "Milliseconds",
  "criterion.unit.WV_TTFB": "ms",

  //ProjectDetails system
  "project.details.system": "System",
  "project.details.system.accept": "Accept",
  "project.details.system.log": "Log",

  //Common stuff
  "project.mon": "Monitoring",
  "project.lt": "Load Testing",
  "tenant.Active": "Active",
  "tenant.Locked": "Locked",

  "common.buttons.accept": "Accept",
  "common.buttons.accept.loading": "Please Wait...",
  "common.buttons.cancel": "Cancel",
  "common.buttons.saveChanges": "Save Changes",
  "common.buttons.ok": "OK",
  "common.buttons.add": "Add",
  "common.buttons.delete": "Delete",
  "common.buttons.refresh": "Refresh",
  "common.buttons.remove": "Remove",
  "common.buttons.new": "New",
  "common.buttons.discard": "Discard",

  //Dialog stuff
  "dialog.delete.agree": "DELETE",
  "dialog.delete.confirm": "Confirmation",
  "dialog.delete.more": "and {more} more",
  "dialog.delete.order": "Please type \"{agree}\" to confirm.",
  "dialog.delete.outcome": "This action can be reverted for <bold>30</bold> days before the data is permanently deleted.",
  "dialog.create.order": "To complete the creation, please confirm your intention by typing \"{agree}\" in the box below.",
  "dialog.create.confirm": "Confirmation",

  // Common soft delete
  "softDeleted.table.header.deleteDateTime": "Deletion Date",
  "softDeleted.table.menu.excluded": "Hide Deleted Items",
  "softDeleted.table.menu.included": "Show Deleted Items",
  "softDeleted.item.menu.undelete": "Undo Deletion",

  // RestoreConfirmation
  "RestoreConfirmation.undelete.title": "Undo Deletion",
  "RestoreConfirmation.undelete.message": "Do you really want to undo the deletion of \"<bold>{contentItemName}</bold>\"?",
  "RestoreConfirmation.undelete.accept": "Undo Deletion",
  "RestoreConfirmation.undelete.cancel": "Cancel",

  // Roles
  "roles.system.User": "User",
  "roles.system.BillingAdmin": "Billing Administrator",
  "roles.system.OrganizationsAdmin": "Organizations Administrator",
  "roles.system.Admin": "Administrator",

  "roles.tenant.Guest": "Guest",
  "roles.tenant.User": "Member",
  "roles.tenant.BillingAdmin": "Billing Manager",
  "roles.tenant.Admin": "Administrator",

  // State
  "tenant.state.Active": "Active",
  "tenant.state.Locked": "Locked",

  "user.state.New": "New",
  "user.state.New.help": "The user was registered, but has not confirmed their email adress yet and cannot log into XTC.",
  "user.state.Active": "Active",
  "user.state.Locked": "Locked",
  "user.state.Invited": "Invitation Sent",

  "project.state.Active": "Active",
  "project.state.Inactive": "Inactive",
  "project.state.Archived": "Archived",

  // integrations
  "integrations.slack": "Slack",
  "integrations.slack.help": "Configure the Slack integration settings to enable load test execution communication using Slack.",
  "integrations.slack.test": "Send test message",
  "integrations.slack.enabled": "Send Messages",
  "integrations.slack.enabled.enabled": "Enabled",
  "integrations.slack.enabled.disabled": "Disabled",
  "integrations.slack.token": "Slack Integration",
  "integrations.slack.workspace": "Slack Workspace",
  "integrations.slack.token.access.failed": "Could not install XTC to your workspace.",
  "integrations.slack.token.help": "This will redirect you to Slack to integrate the XTC App into your workspace. Depending on your workspace setup, additional workspace administrator approvals might be required before the integration becomes operational.",
  "integrations.slack.token.create": "Set up Slack Integration",
  "integrations.slack.token.override": "Override Organization Slack Integration",
  "integrations.slack.tokenSet": "Done",
  "integrations.slack.tokenUnset": "Open",
  "integrations.slack.token.delete": "Remove Slack Integration",
  "integrations.slack.token.delete.help": "This removes the integration from your workspace including but not limited to permissions and approvals. If you only want to pause notifications for a while, consider disabling message sending rather than removing the integration.",
  "integrations.slack.channels": "Target Channels",
  "integrations.slack.channels.help": "Send messages to multiple channels by separating the channel names with spaces: #channel1 #channel2",
  "integrations.slack.config.error.channelName": "Please insert only valid channel names. Channel names must be no longer than 80 characters, start with # and only contain lowercase letters, numbers, hyphens, underscores, and periods.",
  "integrations.slack.config.delete.title": "Delete Slack Configuration",
  "integrations.slack.config.delete.content": "This will remove the Slack Integration Config including the Access Token used to authenticate XTC in your Slack workspace. If you want to re-enable the integration later on, you (and, depending on your workspace settings, an Admin) will need to confirm the installation again.",
  "integrations.slack.config.delete.confirm": "Delete Configuration",
  "integrations.slack.token.delete.title": "Remove Slack Integration",
  "integrations.slack.token.delete.content": "This will remove the Slack Integration Data including the Access Token used to authenticate XTC in your Slack workspace. If you want to re-enable the integration later on, you (and, depending on your workspace settings, an Admin) will need to confirm the installation again.",
  "integrations.slack.token.delete.confirm": "Remove Integration",
  "integrations.slack.channels.useDefault": "Use default channels",
  "integrations.slack.channels.validation.scenario.noChannels": "Slack notifications must either use default channels or configure custom entries.",
  "integrations.slack.groupMessages": "Group related messages into threads",
  "integrations.slack.groupMessages.enabled": "Enabled",
  "integrations.slack.groupMessages.disabled": "Disabled",
  "integrations.slack.compact": "Compact message view",
  "integrations.slack.compact.enabled": "Enabled",
  "integrations.slack.compact.disabled": "Disabled",

  // Form validation
  "validation.minValue": "Value must be greater than or equal to {val, number}",
  "validation.maxValue": "Value must be less than or equal to {val, number}",
  "validation.noMatch": "Incorrect email or password. Keep in mind that your account might use Single Sign-On (SSO) as the exclusive sign-in method.",
  "validation.passwordRepeat.noMatch": "Passwords do not match",
  "validation.email": "Please specify a valid email address",
  "validation.email.unusable": "Email address cannot be used. Please choose another one.",
  "validation.phone": "Must start with a '+' and have at least 5 numbers",
  "validation.emailRepeat.noMatch": "Emails do not match",
  "validation.immutable": "Cannot be changed",
  "validation.password.isBlank": "The password must not be blank",
  "validation.password.surroundingWhitespace": "The password must not contain leading/trailing whitespace",
  "validation.password.tooShort": "The password must not be shorter than 12 characters",
  "validation.password.tooSimple": "The password must be more complex. Either increase the length to at least 15 characters or use mixed case along with at least one digit and one special character.",
  "validation.password.invalidCharacters": "The password must not contain control characters like tabs or line breaks.",
  "validation.password.unknown": "Unknown error validating password",
  "validation.notANumber": "Value is not a number",
  "validation.notSupported": "Not supported",
  "validation.notUnique": "Already in use",
  "validation.notBlank": "Cannot be blank",
  "validation.requirePassword": "This action requires a valid password",
  "validation.url": "URL not valid",
  "validation.url.noCredentials": "URL must not contain credentials",
  "validation.notFound": "Does not exist",
  "validation.notFoundOrMember": "This user doesn't exist in the project.",
  "validation.relativeFilePath": "Path must be relative",
  "validation.resourceName": "Must start with a lower-case letter, followed by at most 39 lower-case letters, digits and hyphens, and must not end with a hyphen",
  "validation.unknownUser": "User unknown",
  "validation.parameter.invalid": "Invalid value",
  "validation.sshkey": "Key not valid",
  "validation.duplicate": "Already exists",
  "validation.member.duplicate": "User is already a member",
  "validation.no.selfedit": "You may not change or remove your own account. Please consult another administrator.",
  "validation.atLeastOneChannel": "At least one channel must be filled.",
  "validation.noneSelected": "Select at least one item",
  "validation.noneBlank": "Entries must not be blank",
  "validation.futureDate": "The selected date and time must be in the future",
  "validation.dateNotBeforeToday": "The selected date must be today or later",
  "validation.successCriterion.name": "No valid success criterion",
  "validation.successCriterion.value": "Value is invalid for this success criterion",
  "validation.outdated": "Your changes could not be applied as the resource you're editing has changed in the meanwhile. Please reload this page and try again.",
  "validation.owner.no.delete": "Organization/Project owners cannot be deleted",
  "validation.ips.invalid": "At least one of the IP addresses is not valid",
  "validation.tenantWithProjects.no.delete": "Organization cannot be deleted as long as projects belong to it.",
  "validation.enumValue": "Unsupported value",
  "validation.notNull": "Value missing",
  "validation.tenant.contract.noAccess": "You do not have the required access to change the contract information of an organization",
  "validation.tenant.contract.endBeforeStart": "The contract end date must be after the start date",
  "validation.htmlColor": "Color must be a valid HTML color of the form #rrggbb",
  "validation.project.hasRunningTasks": "Project has at least one running test or report creation process. Please wait for all tasks to finish and try again.",
  "validation.project.hasRunningOrScheduledTests": "Project has at least one running or scheduled test. Only projects with no running or scheduled tests can be set to inactive or archived.",
  "validation.project.unknown": "Project unknown",
  "validation.apiVersion.unsupported": "API version not supported",
  "validation.loadFactor": "Load Factor must be a single number or a formula",
  "validation.loadFactor.rampUpNotZero": "Load Factor formulas are only allowed if ramp up time equals zero",
  "validation.loadFactor.durationExceeded": "Load Factor formula must contain timestamps within the measurement time",
  "validation.loadFactor.invalidTime": "Load factor formula time values must be greater/equal than the previous",
  "validation.loadFactor.noDuration": "Validation failed, measurement time must not be empty",
  "validation.loadFactor.incompleteFormula": "Formula invalid. Please enter a second time/factor pair or enter a single factor for time zero.",

  // Errors
  "error.permission.denied": "Access denied",
  "error.history.details.notFound": "No such error",
  "error.notification.subscriber.notFound": "No such subscriber",
  "error.notification.subscriber.duplicate": "Subscriber already exists",
  "error.projectUnknown": "Project does not exist (anymore)",
  "error.tenantUnknown": "Organization does not exist (anymore)",
  "error.userUnknown": "User does not exist (anymore)",
  "error.phone.notSent": "The phone number could not be reached. Please make sure that the phone number is correct and try it again. If the problem persist, contact us.",
  "error.technical": "A technical error occurred. Please try it again at a later date. If the problem persist, contact us.",
  "error.inactive.beta": "This beta feature is currently not available.",
  "error.report.delete.pending": "Report can not be deleted while being created/unpacked. Please try again once it has finished.",
  "error.report.delete.isPinned": "One or more reports are currently pinned and can therefore not be deleted.",
  "error.result.delete.pending": "Result can not be deleted while being uploaded. Please try again once it has finished.",
  "error.result.delete.isPinned": "One or more results are currently pinned and can therefore not be deleted.",
  "error.privileges.userLockedFromTenant": "<p>You are not authorized to access this organization even though you are a member.</p><p>Your membership has been suspended by the organization. In case of questions, please contact your organization administrator.</p>",
  "error.privileges.userLockedFromTenant.oidc": "<p>You are not authorized to access this organization even though you are a member.</p><p>The organization requires you to use an <bold>SSO-based login</bold>, such as Google or Microsoft.</p><p>Please set up your SSO sign-in provider in {accountLink} > Security > Single Sign-On. Simply logging in with an external provider will not activate it, you must first connect your account to the external provider.</p><p>In case of questions, please contact your organization administrator.</p>",
  "error.privileges.userLockedFromTenant.oidc.invalidIssuer": "<p>You are not authorized to access this organization even though you are a member.</p><p>The external sign-on provider (SSO) used to log in is not recognized by this organization. The organization may have recently changed its security requirements. Please go to {accountLink} > Security > Single Sign-On and register with an approved external provider.</p><p>In case of questions, please contact your organization administrator.</p>",
  "error.privileges.userLockedFromTenant.2fa": "<p>You are not authorized to access this organization even though you are a member.</p><p>The organization requires you to be logged in using <bold>two-factor authentication</bold>. Please go to {accountLink} > Security > Two-Factor Authentication and set up two-factor authentication.</p><p>In case of questions, please contact your organization administrator.</p>",
  "error.privileges.userLockedFromTenant.link.ssoAccount": "My Account",
  "error.privileges.loginType.oidc": "an external OpenID Connect Provider",
  "error.privileges.loginType.pwd": "Username/Password",
  "error.privileges.loginType.mfa": "Two-Factor Authentication",
  "error.privileges.loginType.error": "an unknown login type that caused an internal error in the system",
  "error.xltVersionUnsupported": "Previously configured execution environment is no longer supported",
  "error.requestForbidden": "You might not have the proper permissions to access this area of XTC or the information is just outdated. Sorry, this is all we can tell you at the moment. If this was a shared link, ask the sender; if this was a bookmarked link, navigate to the information in question from the homepage.",
  "error.noSecretBackup": "Secret backup was not found!",
  "error.projectNoSecret": "This project is missing the secret. Please contact support!",
  // Load test lifecycle errors
  "error.loadtests.start.alreadyStarted": "Load test {name} is already running. Please refresh this view.",
  "error.loadtests.stop.alreadyStopped": "Load test {name} has already ended. Please refresh this view.",
  "error.loadtests.delete.currentlyRunning": "Load test {name} is currently running and can therefore not be deleted. Please refresh this view.",
  "error.loadtests.delete.isPinned": "One or more load tests are currently pinned and can therefore not be deleted.",
  "error.loadtests.delete.hasPinnedArtifacts": "One or more load tests have pinned reports or results and can therefore not be deleted.",
  "error.loadtests.stop.notStarted": "Load test {name} cannot be stopped as it wasn't started yet. Please refresh this view.",
  "error.loadtests.startFailed": "Start of load test {name} failed.",
  "error.loadtests.endFailed": "End of load test {name} failed.",

  // Locations
  // long
  "location.long.unknown": "Unknown Location",
  "location.long.asia-east1": "Taiwan",
  "location.long.asia-east2": "Hong Kong",
  "location.long.asia-northeast1": "Tokyo, Japan",
  "location.long.asia-northeast2": "Osaka, Japan",
  "location.long.asia-northeast3": "Seoul, South Korea",
  "location.long.asia-south1": "Mumbai, India",
  "location.long.asia-south2": "Delhi, India",
  "location.long.asia-southeast1": "Singapore",
  "location.long.asia-southeast2": "Jakarta, Indonesia",
  "location.long.australia-southeast1": "Sydney, Australia",
  "location.long.australia-southeast2": "Melbourne, Australia",
  "location.long.europe-central2": "Warsaw, Poland",
  "location.long.europe-north1": "Hamina, Finland",
  "location.long.europe-west1": "St. Ghislain, Belgium",
  "location.long.europe-west2": "London, UK",
  "location.long.europe-west3": "Frankfurt, Germany",
  "location.long.europe-west4": "Eemshaven, Netherlands",
  "location.long.europe-west6": "Zürich, Switzerland",
  "location.long.northamerica-northeast1": "Montréal, Canada",
  "location.long.northamerica-northeast2": "Toronto, Canada",
  "location.long.southamerica-east1": "São Paulo, Brazil",
  "location.long.us-central1": "Iowa, USA",
  "location.long.us-east1": "South Carolina, USA",
  "location.long.us-east4": "Northern Virginia, USA",
  "location.long.us-west1": "Oregon, USA",
  "location.long.us-west2": "Los Angeles, USA",
  "location.long.us-west3": "Utah, USA",
  "location.long.us-west4": "Nevada, USA",
  // full
  "location.full.unknown": "Unknown Location",
  "location.full.asia-east1": "Changhua County, Taiwan",
  "location.full.asia-east2": "Hong Kong",
  "location.full.asia-northeast1": "Tokyo, Japan",
  "location.full.asia-northeast2": "Osaka, Japan",
  "location.full.asia-northeast3": "Seoul, South Korea",
  "location.full.asia-south1": "Mumbai, India",
  "location.full.asia-south2": "Delhi, India",
  "location.full.asia-southeast1": "Jurong West, Singapore",
  "location.full.asia-southeast2": "Jakarta, Indonesia",
  "location.full.australia-southeast1": "Sydney, Australia",
  "location.full.australia-southeast2": "Melbourne, Australia",
  "location.full.europe-central2": "Warsaw, Poland",
  "location.full.europe-north1": "Hamina, Finland",
  "location.full.europe-west1": "St. Ghislain, Belgium",
  "location.full.europe-west2": "London, England, UK",
  "location.full.europe-west3": "Frankfurt, Germany",
  "location.full.europe-west4": "Eemshaven, Netherlands",
  "location.full.europe-west6": "Zürich, Switzerland",
  "location.full.northamerica-northeast1": "Montréal, Québec, Canada",
  "location.full.northamerica-northeast2": "Toronto, Ontario, Canada",
  "location.full.southamerica-east1": "São Paulo, Brazil",
  "location.full.us-central1": "Council Bluffs, Iowa, USA",
  "location.full.us-east1": "Moncks Corner, South Carolina, USA",
  "location.full.us-east4": "Ashburn, Virginia, USA",
  "location.full.us-west1": "The Dalles, Oregon, USA",
  "location.full.us-west2": "Los Angeles, California, USA",
  "location.full.us-west3": "Salt Lake City, Utah, USA",
  "location.full.us-west4": "Las Vegas, Nevada, USA",

  // AWS - Americas
  "location.long.ca-central-1": "Central Canada",
  "location.long.us-east-1": "Virginia, USA",
  "location.long.us-east-2": "Ohio, USA",
  "location.long.us-west-1": "California, USA",
  "location.long.us-west-2": "Oregon, USA",
  "location.long.sa-east-1": "São Paulo, Brazil",
  // AWS - EMEA
  "location.long.eu-north-1": "Stockholm, Sweden",
  "location.long.eu-central-1": "Frankfurt, Germany",
  "location.long.eu-west-1": "Ireland",
  "location.long.eu-west-2": "London, UK",
  "location.long.eu-west-3": "Paris, France",
  "location.long.eu-south-1": "Milan, Italy",
  // AWS - APAC
  "location.long.ap-northeast-1": "Tokyo, Japan",
  "location.long.ap-northeast-2": "Seoul, South Korea",
  "location.long.ap-northeast-3": "Osaka, Japan",
  "location.long.ap-east-1": "Hong Kong",
  "location.long.ap-south-1": "Mumbai, India",
  "location.long.ap-southeast-1": "Singapore",
  "location.long.ap-southeast-2": "Sydney, Australia",

  // Hetzner
  "location.long.ash": "Ashburn, USA",
  "location.long.fsn1": "Falkenstein,  Germany",
  "location.long.nbg1": "Nuremberg, Germany",
  "location.long.hel1": "Helsinki, Finland",

  // LoadTestProjectRoute
  "LoadTestProjectRoute.loadTests": "Load Tests",

  // LoadTestTable
  "LoadTestTable.sort.index": "Number",
  "LoadTestTable.header.index": "#",
  "LoadTestTable.header.name": "Name",
  "LoadTestTable.header.startDate": "Test Start Time",
  "LoadTestTable.header.state": "State",
  "LoadTestTable.header.summary": "Summary",
  "LoadTestTable.header.rating": "Rating",
  "LoadTestTable.header.duration": "Duration",
  "LoadTestTable.deleteLoadTestConfirmation.title": "Delete Load Test",
  "LoadTestTable.deleteLoadTestConfirmation.message": "Are you sure you want to delete the <em>{name}</em> load test and all its results? You have the opportunity to undo the operation within <bold>30</bold> days before the data is permanently deleted.",
  "LoadTestTable.bulkDeleteConfirmation.title": "Delete Load Tests ({count})",
  "LoadTestTable.bulkDeleteConfirmation.message": "You are about to delete {count,plural,one {# load test} other {# load tests}}. Are you certain?",

  "LoadTestTable.startLoadTestConfirmation.title": "Start Load Test",
  "LoadTestTable.startLoadTestConfirmation.accept": "Start Load Test",
  "LoadTestTable.startLoadTestConfirmation.startNow": "Start Test Now",
  "LoadTestTable.startLoadTestConfirmation.message": "Do you want to start load test {name} now?",
  "LoadTestTable.startLoadTestConfirmation.message.scheduled": "You are about to start {name} before its scheduled time ({date}). Do you really want to start it now?",

  // LoadTestTableEntry
  "LoadTestTableEntry.contextMenu.view": "View",
  "LoadTestTableEntry.contextMenu.edit": "Edit Details",
  "LoadTestTableEntry.contextMenu.duplicate": "Duplicate",
  "LoadTestTableEntry.contextMenu.start": "Start",
  "LoadTestTableEntry.contextMenu.schedule": "Schedule",
  "LoadTestTableEntry.contextMenu.abort": "Abort",
  "LoadTestTableEntry.contextMenu.pin": "Pin",
  "LoadTestTableEntry.contextMenu.unpin": "Unpin",
  "LoadTestTableEntry.state.NEW": "New",
  "LoadTestTableEntry.state.SCHEDULED": "Scheduled",
  "LoadTestTableEntry.state.STARTING": "Starting",
  "LoadTestTableEntry.state.PROVISIONING": "Provisioning",
  "LoadTestTableEntry.state.RUNNING": "Running",
  "LoadTestTableEntry.state.STOPPING": "Stopping",
  "LoadTestTableEntry.state.DEPROVISIONING": "Deprovisioning",
  "LoadTestTableEntry.state.ABORTED": "Aborted",
  "LoadTestTableEntry.state.FINISHED": "Finished",
  "LoadTestTableEntry.state.ERROR": "Error",

  // GenericEditLoadTestDialog
  "GenericEditLoadTestDialog.description": "Description",
  "GenericEditLoadTestDialog.rating": "Quick Rating",

  // AddLoadTestDialog
  "AddLoadTestDialog.title": "Add Load Test",
  "AddLoadTestDialog.fields.name": "Name",
  "AddLoadTestDialog.fields.description": "Comment",

  // EditLoadTestDialog
  "EditLoadTestDialog.title": "#{num} - Edit Details",
  "EditLoadTestDialog.fields.name": "Name",
  "EditLoadTestDialog.fields.description": "Comment",
  "EditLoadTestDialog.fields.color": "Highlight Color",

  // DuplicateLoadTestDialog
  "DuplicateLoadTestDialog.title": "Duplicate Load Test",
  "DuplicateLoadTestDialog.fields.name": "New Name",
  "DuplicateLoadTestDialog.fields.description": "New Comment",
  "DuplicateLoadTestDialog.fields.color": "New Highlight Comment",
  "DuplicateLoadTestDialog.buttons.accept": "Duplicate Load Test",

  // AbortLoadTestDialog
  "AbortLoadTestDialog.title": "Abort Load Test",
  "AbortLoadTestDialog.dialog.title": "Abort Load Test ({executionState})",
  "AbortLoadTestDialog.message": "Do you really want to abort load test \"{name}\"?",
  "AbortLoadTestDialog.saveResults": "Save results",
  "AbortLoadTestDialog.measurements": "Measurements",
  "AbortLoadTestDialog.downloadLogs": "Logs",
  "AbortLoadTestDialog.downloadResultBrowser": "Result browsers",
  "AbortLoadTestDialog.createReport": "Create load test report",
  "AbortLoadTestDialog.accept": "Abort Load Test",

  // Load Tests > Load Test
  "LoadTestDetailsScene.tabs.status": "Status",
  "LoadTestDetailsScene.tabs.settings": "Settings",
  "LoadTestDetailsScene.tabs.scenarioStatus": "Scenario Status",
  "LoadTestDetailsScene.tabs.errorReporting": "Error Reporting",
  "LoadTestDetailsScene.tabs.liveMetrics": "Live Metrics",
  "LoadTestDetailsScene.tabs.results": "Results",
  "LoadTestDetailsScene.tabs.reports": "Reports",
  "LoadTestDetailsScene.tabs.evaluation": "Evaluation",
  "LoadTestDetailsScene.tabs.machines": "Machines",

  "LoadTestDetailsScene.legacyMachinesSection.title": "Machines",
  "LoadTestDetailsScene.legacyMachinesSection.info": "The machine configuration has been moved to the new tab <bold>Machines</bold>.",

  // LoadTestDetailsHeader
  "LoadTestDetailsHeader.toolbar.loadTest.edit": "Edit",
  "LoadTestDetailsHeader.toolbar.loadTest.duplicate": "Duplicate",
  "LoadTestDetailsHeader.toolbar.loadTest.start": "Start",
  "LoadTestDetailsHeader.toolbar.loadTest.schedule": "Schedule",
  "LoadTestDetailsHeader.toolbar.loadTest.abort": "Abort",
  "LoadTestDetailsHeader.toolbar.loadTest.createReport": "Create Report",
  "LoadTestDetailsHeader.toolbar.loadTest.previous": "Previous",
  "LoadTestDetailsHeader.toolbar.loadTest.next": "Next",

  // Load Tests > Load Test > Configuration > Schedule
  "ScheduleLoadTestDialog.title": "Scheduling a Load Test",
  "ScheduleLoadTestDialog.description": "You are about to schedule a load test execution. Please note that the set time will be the start time of the test in XTC. Therefore, the environment under test will likely see its first traffic a few minutes later depending on the setup time of code and machines.",
  "ScheduleLoadTestDialog.confirmation": "The test start is within the next minutes (at {time}). Ensure that this is intended.",
  "ScheduleLoadTestDialog.start": "Schedule",
  "ScheduleLoadTestDialog.delete": "Delete Schedule",
  "ScheduleLoadTestDialog.update": "Update Schedule",
  "ScheduleLoadTestDialog.fields.startDate": "Start Time",
  "ScheduleLoadTestDialog.fields.startDate.isPast": "The scheduled start time must not be in the past",

  // Load Tests > Load Test > Configuration > Load Profile
  "LoadProfileView.title": "Load Profile",
  "LoadProfileView.help": "Configure the load profile to use for this load test. All settings are optional. If you specify values here, they will override their corresponding values in your test suite.",
  "LoadProfileView.noneConfigured": "none",
  "LoadProfileView.fields.testPropertiesFileName": "Test Properties File",
  "LoadProfileView.fields.testPropertiesFileName.help": "To reload suggestions, reopen the editor.",
  "LoadProfileView.fields.duration": "Measurement Time",
  "LoadProfileView.fields.rampUp": "Ramp-Up Time",
  "LoadProfileView.fields.shutdown": "Shutdown Time",
  "LoadProfileView.fields.loadFactor": "Load Factor",
  "LoadProfileView.fields.overwrite": "Overwrite",
  "LoadProfileView.fields.edit.chart.notAvailable": "Chart is not available. Click \"Overwrite\" to enable it.",
  "LoadProfileView.fields.edit.chart.invalidData": "Chart is not available. Data is invalid.",
  "LoadProfileView.fields.overview.chart.notAvailable": "Chart is not available.",


  // Load Tests > Load Test > Configuration > Repository
  "RepositorySettingsView.title": "Repository",
  "RepositorySettingsView.help": "Configure specific repository settings that override the general repository settings at the project for this load test.",
  "RepositorySettingsView.noneConfigured": "None configured.",
  "RepositorySettingsView.fields.branchName": "Branch Name",
  "RepositorySettingsView.deleteConfirmation.title": "Delete Repository Settings",
  "RepositorySettingsView.deleteConfirmation.message": "Do you really want to delete these repository settings?",

  // Load Tests > Load Test > Configuration > Common Machine Configuration
  "CommonMachineConfigurationView.title": "Common Machine Configuration",
  "CommonMachineConfigurationView.help": "Configure settings that apply to all agent machines.",
  "CommonMachineConfigurationView.fields.masterControllerPassword": "Master/Agent Password",
  "CommonMachineConfigurationView.fields.masterControllerPassword.help": "Password to secure the communication between XLT master controller and agent controllers",
  "CommonMachineConfigurationView.fields.xltVersionId": "Execution Environment",
  "CommonMachineConfigurationView.fields.xltVersionId.unsupported": "No longer supported",

  "XltVersionSelect.xltVersion.label": "Execution Environment",
  "XltVersionSelect.xltVersion.help": "Version of the execution environment to execute the load test with",
  "XltVersionSelect.xltVersion.default": "(Recommended)",

  // Load Tests > Load Test > Configuration > Machines
  "CloudMachinesView.noneConfigured": "None configured.",
  "CloudMachinesView.fields.regionNames": "Regions",
  "CloudMachinesView.fields.instanceTemplateName": "Instance Template",
  "CloudMachinesView.fields.instanceTemplateName.aws.tiny": "tiny — 2 vCPUs / 4 GB RAM / 200 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.aws.small": "small — 4 vCPUs / 8 GB RAM / 200 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.aws.medium": "medium — 8 vCPUs / 16 GB RAM / 200 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.aws.large": "large — 16 vCPUs / 32 GB RAM / 200 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.gcp.tiny": "tiny — 2 vCPUs / 4 GB RAM / 50 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.gcp.small": "small — 4 vCPUs / 8 GB RAM / 100 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.gcp.medium": "medium — 8 vCPUs / 16 GB RAM / 200 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.gcp.large": "large — 16 vCPUs / 32 GB RAM / 200 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.hetzner.hev-tiny": "tiny (Shared vCPUs) — 2 vCPUs / 4 GB RAM / 40 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.hetzner.hev-small": "small (Shared vCPUs) — 4 vCPUs / 8 GB RAM / 160 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.hetzner.hev-medium": "medium (Shared vCPUs) — 8 vCPUs / 16 GB RAM / 240 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.hetzner.hev-large": "large (Shared vCPUs) — 16 vCPUs / 32 GB RAM / 360 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.hetzner.hed-tiny": "tiny — 2 vCPUs / 8 GB RAM / 80 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.hetzner.hed-small": "small — 4 vCPUs / 16 GB RAM / 160 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.hetzner.hed-medium": "medium — 8 vCPUs / 32 GB RAM / 240 GB SSD",
  "CloudMachinesView.fields.instanceTemplateName.hetzner.hed-large": "large — 16 vCPUs / 64 GB RAM / 360 GB SSD",
  "CloudMachinesView.fields.totalInstanceCount": "Total Instance Count",
  "CloudMachinesView.fields.agentsPerInstance": "Agents per Instance",
  "CloudMachinesView.regionGroups.americas": "Americas",
  "CloudMachinesView.regionGroups.emea": "Europe - Middle East - Africa",
  "CloudMachinesView.regionGroups.apac": "Asia - Pacific",
  "CloudMachinesView.deleteConfirmation.title": "Delete Cloud Machine Configuration",
  "CloudMachinesView.deleteConfirmation.message": "Do you really want to delete the cloud machine configuration?",
  "CloudMachinesView.deleteConfirmation.message.google": "Do you really want to delete the Google Cloud machine configuration?",
  "CloudMachinesView.deleteConfirmation.message.aws": "Do you really want to delete the Amazaon Web Services machine configuration?",
  "CloudMachinesView.deleteConfirmation.message.hetzner": "Do you really want to delete the Hetzner Cloud machine configuration?",

  // Load Tests > Load Test > Configuration > AWS Machines
  "AwsMachinesView.title": "Amazon Cloud Machines",
  "AwsMachinesView.help": "Configure the load agent machines to be launched in Amazon's Elastic Compute Cloud for this load test. These machines will automatically be started prior to the load test and terminated afterwards.",

  // Load Tests > Load Test > Configuration > GCP Machines
  "GcpMachinesView.title": "Google Cloud Machines",
  "GcpMachinesView.help": "Configure the load agent machines to be launched in Google's Compute Engine for this load test. These machines will automatically be started prior to the load test and terminated afterwards.",

  // Load Tests > Load Test > Configuration > Hetzner Machines
  "HetznerMachinesView.title": "Hetzner Cloud Machines",
  "HetznerMachinesView.help": "Configure the load agent machines to be launched in Hetzner Cloud for this load test. These machines will automatically be started prior to the load test and terminated afterwards.",

  // Load Tests > Load Test > Configuration > Custom Machines
  "CustomMachinesView.title": "Custom Machines",
  "CustomMachinesView.help": "If you have load agent machines that are already running, you can configure here which of these machines you want to use for this load test.",
  "CustomMachinesView.noneConfigured": "None configured.",
  "CustomMachinesView.fields.hostNames": "Host Names",
  "CustomMachinesView.fields.totalInstanceCount": "Total Instance Count",
  "CustomMachinesView.fields.agentsPerInstance": "Agents per Instance",
  "CustomMachinesView.deleteConfirmation.title": "Delete Custom Machines",
  "CustomMachinesView.deleteConfirmation.message": "Do you really want to delete these custom machines?",

  // Load Tests > Load Test > Execution > Scenario Status
  "ScenarioStatusTable.header.scenarioName": "Name",
  "ScenarioStatusTable.header.state": "State",
  "ScenarioStatusTable.header.runningUsers": "Running Users",
  "ScenarioStatusTable.header.totalUsers": "Total Users",
  "ScenarioStatusTable.header.iterations": "Iterations",
  "ScenarioStatusTable.header.lastExecutionTime": "Last Time",
  "ScenarioStatusTable.header.averageExecutionTime": "Avg. Time",
  "ScenarioStatusTable.header.elapsedTime": "Elapsed Time",
  "ScenarioStatusTable.header.events": "Events",
  "ScenarioStatusTable.header.errors": "Errors",
  "ScenarioStatusTable.header.progress": "Progress",
  "ScenarioStatusTable.tools.autoRefresh": "Auto-Refresh",

  // Load Tests > Load Test > Execution > Scenario Status
  "LiveMetrics.chart.scalingFactor": "Scaling Factor",
  "LiveMetrics.chart.scenarios": "Scenarios",
  "LiveMetrics.chart.scenarios.selectAll": "Select All",
  "LiveMetrics.chart.axes.time": "Time",
  "LiveMetrics.chart.axes.users": "Users",
  "LiveMetrics.chart.axes.errors": "Errors",
  "LiveMetrics.chart.totalUsers.title": "limit",
  "LiveMetrics.chart.errors.title": "Errors",

  // Load Tests > Load Test > Results
  "ResultTable.index": "#",
  "ResultTable.label": "Label",
  "ResultTable.creationTime": "Creation Time",
  "ResultTable.startTime": "Start Time",
  "ResultTable.endTime": "End Time",
  "ResultTable.duration": "Duration",
  "ResultTable.size": "Size",
  "ResultTable.sort.index": "Number",
  "ResultTable.deleteConfirmation.title": "Delete Result {name}",
  "ResultTable.deleteConfirmation.message": "Are you sure you want to delete the result {name} and all its related data? You have the opportunity to undo the operation within <bold>30</bold> days before the data is permanently deleted.",
  "ResultTable.bulkDeleteConfirmation.title": "Delete Load Test Results ({count})",
  "ResultTable.bulkDeleteConfirmation.message": "You are about to delete {count,plural,one {# load test result} other {# load test results}}. Are you certain?",

  "ResultTableEntry.labelHelp.noMeasurements": "Result contains no measurement data",
  "ResultTableEntry.contextMenu.createReport": "Create Report",
  "ResultTableEntry.contextMenu.download": "Download",

  // Load Tests > Load Test > Reports
  "LoadTestReports.index": "#",
  "LoadTestReports.label": "Label",
  "LoadTestReports.creationDate": "Creation Date",
  "LoadTestReports.startTime": "Start Time",
  "LoadTestReports.endTime": "End Time",
  "LoadTestReports.duration": "Duration",
  "LoadTestReports.result": "Result",
  "LoadTestReports.result.help": "Created from result {resultIndex}",
  "LoadTestReports.sort.default": "Default",
  "LoadTestReports.sort.index": "Number",
  "LoadTestReports.contextMenu.edit": "Edit",
  "LoadTestReports.contextMenu.showDetails": "Show Settings",
  "LoadTestReports.contextMenu.download": "Download",
  "LoadTestReports.contextMenu.download.preparing": "Preparing Download...",
  "LoadTestReports.contextMenu.resurrect": "Extract archived report",
  "LoadTestReports.contextMenu.markForComparison": "Set Comparable",
  "LoadTestReports.contextMenu.unmarkForComparison": "Unset Comparable",
  "LoadTestReports.resurrect.failure": "Could not unpack report archive",
  "LoadTestReports.resurrect.unknownType": "Tried to unpack unknown report type",
  "LoadTestReports.resurrect.title": "Unpack Report {name}",
  "LoadTestReports.resurrect.message": "Unpacking the report makes it available for viewing on the web again for another 180 days. Note: This operation may take some time to complete. The unpacked report will incur additional storage cost.",
  "LoadTestReports.resurrect.accept": "Unpack Report",
  "LoadTestReports.delete.title": "Delete Report {name}",
  "LoadTestReports.delete.message": "Are you sure you want to delete the report {name} and all its related data? You have the opportunity to undo the operation within <bold>30</bold> days before the data is permanently deleted.",
  "LoadTestReports.bulkDeleteConfirmation.title": "Delete Reports ({count})",
  "LoadTestReports.bulkDeleteConfirmation.message": "You are about to delete {count,plural,one {# report} other {# reports}}. Are you certain?",
  "LoadTestReports.edit.title": "Edit Report \"{name}\"",

  "LoadTestReport.label.lifecycleState.active": "{name}",
  "LoadTestReport.label.lifecycleState.unpacking": "{name} (unpacking)",
  "LoadTestReport.label.lifecycleState.retired": "{name} (retired)",
  "LoadTestReport.LifecycleState.unpacking.details": "The report is currently being unpacked and will be available shortly.",

  // Comparison
  "loadTestProject.comparison.reports": "Reports",
  "loadTestProject.comparison.comparisonReports": "Comparison Reports",
  "loadTestProject.comparison.createDiffReport": "Compare",
  "loadTestProject.comparison.createDiffReport.new": "Create Comparison Report",
  "loadTestProject.comparison.createDiffReport.new.accept": "Create Report",
  "loadTestProject.comparison.createDiffReport.name": "Label",
  "loadTestProject.comparison.createDiffReport.description": "Description",
  "loadTestProject.comparison.createDiffReport.reverseOrder": "Reverse Order",

  "LoadTestReports.baseline": "Baseline",
  "LoadTestReports.baseline.start": "Baseline Start",
  "LoadTestReports.measurement": "Measurement Run",
  "LoadTestReports.measurement.start": "Measurement Start",
  "LoadTestReports.loadTest": "Load Test",

  "liveErrorLogging.type": "Type",
  "liveErrorLogging.testCase": "Scenario",
  "liveErrorLogging.eventOrAction": "Event/Action",
  "liveErrorLogging.agent": "Agent",
  "liveErrorLogging.event": "Event",
  "liveErrorLogging.error": "Error",
  "liveErrorLogging.message": "Message",
  "liveErrorLogging.creation_time": "Time",
  "liveErrorLogging.stack": "Stack",
  "liveErrorLogging.severity": "Stack",

  "liveErrorLogging.loadMore": "Load More",

  "liveErrorLogging.refinement.placeholder": "Errors and Events",
  "liveErrorLogging.refinement.errorsLabel": "Errors",
  "liveErrorLogging.refinement.eventsLabel": "Events",

  "liveErrorLogging.dateShortcut.lastMinute": "Last Minute",
  "liveErrorLogging.dateShortcut.last5Minutes": "Last 5 Minutes",
  "liveErrorLogging.dateShortcut.last10Minutes": "Last 10 Minutes",
  "liveErrorLogging.dateShortcut.last15Minutes": "Last 15 Minutes",
  "liveErrorLogging.dateShortcut.last30Minutes": "Last 30 Minutes",
  "liveErrorLogging.dateShortcut.last60Minutes": "Last 60 Minutes",
  "liveErrorLogging.dateShortcut.custom": "Custom",

  "liveErrorLogging.displayOption.stackedView": "Stacked",

  "diffReport.baseline.loadTestIndex": "Base - Load Test",
  "diffReport.baseline.start": "Report Start",
  "diffReport.baseline.end": "Report End",
  "diffReport.baseline.duration": "Report Duration",
  "diffReport.target.loadTestIndex": "Target - Load Test",
  "diffReport.target.start": "Report Start",
  "diffReport.target.end": "Report End",
  "diffReport.target.duration": "Report Duration",

  // Common Share
  "share.validToMilliseconds": "Expiration Date",
  "share.error.validToMilliseconds": "Expiration date must be after {validFrom}",
  "share.error.maxDurationExceeded": "A shared URL must not last longer than 180 days",
  "share.error.alreadyExpired": "Cannot set an expiry time in the past",
  "share.useGlobalPreset.checked": "Use project default",

  // LoadTestReport share url
  "LoadTestReports.share": "Share",
  "LoadTestReports.share.title": "Share Report - {name}",
  "LoadTestReports.share.help": "This will create a link for public sharing. Anyone with the link can access the report without authentication. All links are time limited with a maximum possible duration of 180 days and will expire automatically. Links don't display any project details and can be invalidated at any time manually (unshare option).\n\nYou can change the expiration time of an already shared report later if needed.",
  "LoadTestReports.share.link": "Shared Link",
  "LoadTestReports.share.link.expired": "Shared Link (expired)",
  "LoadTestReports.share.accept": "Create Share",
  "LoadTestReports.share.copiedToClipboard.failed": "Unable to copy shared report link to clipboard!",
  "LoadTestReports.share.edit": "Edit Share",
  "LoadTestReports.share.edit.accept": "Update Expiration",
  "LoadTestReports.share.edit.title": "Update Report Share - {name}",
  "LoadTestReports.share.edit.help": "You can modify the expiration date of a share. The link won't change at all, just the lifetime. If you want to invalidate a shared link, select the \"Unshare\" option instead.",
  "LoadTestReports.unshare": "Unshare",
  "LoadTestReports.unshare.title": "Remove Report Share - {name}",
  "LoadTestReports.unshare.accept": "Remove Share",
  "LoadTestReports.unshare.message": "Do you really want to remove this share? This will invalidate the previously generated link forever. You can create a new share at any time if needed.",

  // Share Load Test Result
  "loadTestResult.share": "Share",
  "loadTestResult.share.title": "Share Result - {name}",
  "loadTestResult.share.help": "This will create a link for public sharing. Anyone with the link can access the result without authentication. All links are time limited and will expire automatically. Links don't display any project details and can be invalidated at any time manually (unshare option).\n\nYou can change the expiration time of an already shared result later if needed.",
  "loadTestResult.share.link": "Shared Link",
  "loadTestResult.share.link.expired": "Shared Link (expired)",
  "loadTestResult.share.accept": "Create Share",
  "loadTestResult.share.copiedToClipboard.failed": "Unable to copy shared result link to clipboard!",
  "loadTestResult.share.edit": "Edit Share",
  "loadTestResult.share.edit.title": "Update Result Share - {name}",
  "loadTestResult.share.edit.help": "You can modify the expiration date of a share. The link won't change at all, just the lifetime. If you want to invalidate a shared link, select the \"Unshare\" option instead.",
  "loadTestResult.share.edit.accept": "Update Expiration",
  "loadTestResult.unshare": "Unshare",
  "loadTestResult.unshare.title": "Remove Result Share - {name}",
  "loadTestResult.unshare.message": "Do you really want to remove this share? This will invalidate the previously generated link forever. You can create a new share at any time if needed.",
  "loadTestResult.unshare.accept": "Remove Share",

  // Load Tests > Load Test > Reports > AddReport
  "LoadTestReport.add.title": "Create Load Test Report",
  "LoadTestReport.add.titleForResult": "Create Report for Result #{resultIndex}{resultLabel}",
  "LoadTestReport.add.step.general": "General",
  "LoadTestReport.add.label": "Label",
  "LoadTestReport.add.label.help": "Required",
  "LoadTestReport.add.description": "Description",
  "LoadTestReport.add.comment": "Report Comment",
  "LoadTestReport.add.metadata.include": "Include XTC Meta Data",
  "LoadTestReport.add.testCase.include": "Test Scenario Include Pattern",
  "LoadTestReport.add.testCase.exclude": "Test Scenario Exclude Pattern",
  "LoadTestReport.add.step.agentIncludes": "Agent Includes/Excludes",
  "LoadTestReport.add.step.testCaseIncludes": "Test Scenario Includes/Excludes",
  "LoadTestReport.add.agents.include": "Agent Include Pattern",
  "LoadTestReport.add.agents.exclude": "Agent Exclude Pattern",
  "LoadTestReport.add.step.properties": "Report Generator Properties",
  "LoadTestReport.add.properties": "Properties",
  "LoadTestReport.add.properties.help": "May overwrite properties set by the project",
  "LoadTestReport.add.step.mergeRules": "Merge Rules",
  "LoadTestReport.add.mergeRules": "Merge Rules",
  "LoadTestReport.add.mergeRules.help": "May overwrite rules set by the project",
  "LoadTestReport.add.step.cmdArgs": "Additional Command Line Arguments",
  "LoadTestReport.add.step.misc": "Misc Settings",
  "LoadTestReport.add.timezone": "Report Timezone",
  "LoadTestReport.add.timezone.help": "Default: UTC (E.g. EST, America/New_York)",
  "LoadTestReport.add.accept": "Create Report",
  "LoadTestReport.add.cancel": "Cancel",

  "LoadTestReport.settings.details": "Settings for Report {name}",
  "LoadTestReport.settings.details.close": "Close",
  "LoadTestReport.mergeRules.empty": "No merge rules configured",

  // Errors shown for failed attempts to create report
  "customReport.error.failedToStart": "Failed to start the creation of the report.",
  "customReport.error.resultNotFound": "Cannot create the report as the underlying result does no longer exist.",
  "intermediateReport.error.failedToStart": "Failed to start the creation of interim report.",
  "intermediateReport.error.stillInProgress": "Another interim report is still being calculated. Please try again once it has finished.",
  "intermediateReport.error.notRunning": "Cannot create an interim report as load test is currently not running.",
  "diffReport.error.baseline.notFound": "Cannot create comparison report as the baseline report does no longer exist.",
  "diffReport.error.target.notFound": "Cannot create comparison report as the target report does no longer exist.",
  "diffReport.error.failedToStart": "Failed to start the creation of comparison report.",

  // Time Range Setting
  "LoadTestReport.add.step.time": "Time Range",
  "LoadTestReport.add.timeRange.loadTest": "This is the original test execution time. You can create a different view by redefining start and end time.",
  "LoadTestReport.add.timeRange.report": "This is the calculated reporting time based on the settings above.",
  "LoadTestReport.add.timeRange.summary.startTime": "Start",
  "LoadTestReport.add.timeRange.summary.endTime": "End",
  "LoadTestReport.add.timeRange.summary.duration": "Duration",
  "LoadTestReport.add.timeRange.startTime": "Report Start",
  "LoadTestReport.add.timeRange.startTime.beforeResultStart": "Report start time must not be before the load test start",
  "LoadTestReport.add.timeRange.startTime.notInRange": "Report start time must be before the load test ended",
  "LoadTestReport.add.timeRange.endTime": "Report End",
  "LoadTestReport.add.timeRange.endTime.notInRange": "Report end time must be after the report start time and not be outside the load test run.",
  "LoadTestReport.add.timeRange.start.heading": "Configure the start time of the report.",
  "LoadTestReport.add.timeRange.end.heading": "Configure the end time of the report.",
  "LoadTestReport.add.timeRange.start.absolute": "Absolute",
  "LoadTestReport.add.timeRange.start.relative": "Offset from Start",
  "LoadTestReport.add.timeRange.start.noRampUp": "Excluding Ramp-Up ({rampUp})",
  "LoadTestReport.add.timeRange.end.absolut": "Absolute",
  "LoadTestReport.add.timeRange.end.relative": "Offset from End",
  "LoadTestReport.add.timeRange.end.duration": "Duration",

  // Load Tests > Load Test > Evaluation
  "EvaluationView.noEvaluation": "There is no evaluation yet.",
  "EvaluationView.noDetailedEvaluation": "A detailed evaluation is not available yet.",
  "EvaluationView.noSummary": "A summary is not available yet.",
  "EvaluationView.summaryTitle": "Summary",
  "EvaluationView.evaluationTitle": "Detailed Evaluation",
  "EvaluationView.fields.summary": "Summary",
  "EvaluationView.fields.evaluation": "Detailed Evaluation",
  "EvaluationView.fields.rating": "Rating",
  "EvaluationView.deleteConfirmation.title": "Delete Load Test Evaluation",
  "EvaluationView.deleteConfirmation.message": "Do you really want to delete the evaluation data? This cannot be undone.",
  "EvaluationView.deleteConfirmation.accept": "Delete Evaluation",

  "LoadTestStatus.title": "Status",
  "LoadTestStatus.configured": "This load test was not started yet.",
  "LoadTestStatus.scheduled": "This load test is scheduled for execution on {start}.",

  // Load Tests > Load Test > Status
  "LoadTestStatus.steps.start": "Start Time",
  "LoadTestStatus.steps.end": "End Time",
  "LoadTestStatus.steps.error.code": "Error Code",
  "LoadTestStatus.steps.error.message": "Error Message",
  "LoadTestStatus.steps.error.log": "Log",
  "LoadTestStatus.steps.preparation": "Preparation",
  "LoadTestStatus.steps.execution": "Execution",
  "LoadTestStatus.steps.cleanup": "Evaluation and Clean Up",

  "LoadTestStatus.steps.state": "State",
  "LoadTestStatus.steps.state.NOT_EXECUTED": "Not Executed",
  "LoadTestStatus.steps.state.FINISHED": "Success",
  "LoadTestStatus.steps.state.ABORTED": "Aborted",
  "LoadTestStatus.steps.state.ERROR": "Error",
  "LoadTestStatus.steps.state.IN_PROGRESS": "Pending",

  // Load Tests > Load Test > Status > Error Messages
  "LoadTestStatus.steps.error.compile": "Could not compile the project.",
  "LoadTestStatus.steps.error.internal": "An internal error occurred.",

  // Load Tests > Load Test > Status > Step Names
  "loadtest.step.STARTING": "Starting Environment",
  "loadtest.step.DOWNLOAD_PROJECT": "Downloading from GIT",
  "loadtest.step.LOAD_DEPENDENCY_CACHE": "Downloading Dependency Cache",
  "loadtest.step.BUILD_PROJECT": "Building",
  "loadtest.step.STORE_DEPENDENCY_CACHE": "Storing Dependency Cache",
  "loadtest.step.PROVISION": "Deploying Agents",
  "loadtest.step.UPLOAD_PROJECT": "Uploading to Agents",
  "loadtest.step.START_LOADTEST": "Launching Test",
  "loadtest.step.EXECUTE_LOADTEST": "Running Test",
  "loadtest.step.STOP_LOADTEST": "Stopping Test",
  "loadtest.step.DOWNLOAD_RESULTS": "Downloading Test Results",
  "loadtest.step.STORE_RESULTS": "Archiving Test Results",
  "loadtest.step.DEPROVISION": "Decommissioning Agents",
  "loadtest.step.CREATE_REPORT": "Creating Test Report",
  "loadtest.step.STORE_REPORT": "Publishing Test Report",
  "loadtest.step.STORE_REPORT_RESULT_BROWSER": "Publishing Result Browsers",
  "loadtest.step.STORE_REPORT_ARCHIVE": "Archiving Test Report",

  // Load Tests > Load Test > Status > Step Names > Description
  "loadtest.step.description.STARTING": "XTC starts a dedicated environment for testing.",
  "loadtest.step.description.DOWNLOAD_PROJECT": "XTC fetches the project from the referenced version control system and branch.",
  "loadtest.step.description.LOAD_DEPENDENCY_CACHE": "XTC fetches a previously created dependency cache to speed up compilation. If this indicates a failure, the dependency cache is expired or does not exist yet.",
  "loadtest.step.description.BUILD_PROJECT": "XTC builds the project either with Maven or Gradle and uses the dependency cache previously downloaded, if available.",
  "loadtest.step.description.STORE_DEPENDENCY_CACHE": "XTC preserves dependencies for later reuse to keep compilation of projects fast. If this step indicates a failure, two project compilations took place in parallel. Only one copy is stored. No data is lost.",
  "loadtest.step.description.PROVISION": "XTC starts hardware in the desired locations and deploys the load test agents for later execution. If this step fails, the test start is aborted. A failure might occur due to a lack of available hardware in a particular datacenter, issues with one of the cloud providers, or problems contacting the started machines.",
  "loadtest.step.description.UPLOAD_PROJECT": "XTC uploads your code and data to each agent.",
  "loadtest.step.description.START_LOADTEST": "XTC notifies each agent to start the test, effectively starting up XLT.",
  "loadtest.step.description.EXECUTE_LOADTEST": "The test runs and XTC periodically retrieves the status of each agent and publishes a summary on the Scenario Status tab.",
  "loadtest.step.description.STOP_LOADTEST": "XTC notifies each agent to stop the test, effectively shutting down the XLT execution engine.",
  "loadtest.step.description.DOWNLOAD_RESULTS": "XTC downloads the results of the load test from each agent.",
  "loadtest.step.description.STORE_RESULTS": "All downloaded results are archived for later use and backup purposes.",
  "loadtest.step.description.DEPROVISION": "All hardware used for agents is shut down.",
  "loadtest.step.description.CREATE_REPORT": "XTC creates the test report based on the downloaded result data.",
  "loadtest.step.description.STORE_REPORT": "XTC publishes the test report and makes it available for the desired target audience.",
  "loadtest.step.description.STORE_REPORT_RESULT_BROWSER": "XTC publishes the result browsers which can be viewed as part of the test report. Result browser access might be slightly delayed because the test report is published first and as quickly as possible.",
  "loadtest.step.description.STORE_REPORT_ARCHIVE": "XTC archives the test report for later retrieval, independent of the published version.",

  // Project > Documentation
  "project.documentation.cancel.title": "Discard Changes",
  "project.documentation.edit.cancel": "You are about to discard all your changes to the documentation. Are you sure?",
  "project.documentation.edit.navigate": "You are about to navigate away from this page. Any changes you have made to the documentation will be lost.",
  "project.documentation.message.default": "No documentation has been added.",

  // Load Test Evaluation Rating Values
  "loadtest.evaluation.rating.APlus": "A+",
  "loadtest.evaluation.rating.A": "A",
  "loadtest.evaluation.rating.B": "B",
  "loadtest.evaluation.rating.C": "C",
  "loadtest.evaluation.rating.D": "D",
  "loadtest.evaluation.rating.F": "F",
  "loadtest.evaluation.rating.None": "None",

  // Load Test Default Report Naming
  "loadTest.report.name.FINISHED": "Default Report",
  "loadTest.report.name.ABORTED": "Default Report (aborted)",
  "loadTest.report.name.INTERMEDIATE": "Interim Report",
  "loadTest.report.name.CUSTOM": "Custom Report",
  "loadTest.report.name.UNDEFINED": "Report",
  "loadTest.report.name.intermediate.title": "Generate Interim Report",
  "loadTest.report.name.intermediate.dialog.title": "Generate Interim Report",
  "loadTest.report.name.intermediate.dialog.message": "Please select what should be included in the interim result (measurements are always included):",
  "loadTest.report.name.intermediate.dialog.logs": "Logs",
  "loadTest.report.name.intermediate.dialog.resultBrowsers": "Result browsers",
  "loadTest.report.name.calculate": "Report creation started",

  // report errors
  "LoadTestReports.error.details": "Error Details",
  "LoadTestReports.error.details.log": "Logs",
  "LoadTestReports.error.details.close": "Close",
  "LoadTestReports.error.key.INTERMEDIATE_ALREADY_STARTED": "Interim Report Already Running",
  "LoadTestReports.error.description.INTERMEDIATE_ALREADY_STARTED": "There is already an interim report in progress. Only one interim report can be created at the same time. Please try again later.",

  // Load Test Default Result Naming
  "loadTest.result.name.FINISHED": "Full Dataset",
  "loadTest.result.name.ABORTED": "Full Dataset (aborted)",
  "loadTest.result.name.INTERMEDIATE": "Interim Dataset",
  "loadTest.result.name.UNDEFINED": "Result",

  // AutoRefresh
  "autoReload.menuItem.noRefresh": "Auto-refresh off",
  "autoReload.menuItem.5s": "Refresh every 5s",
  "autoReload.menuItem.10s": "Refresh every 10s",
  "autoReload.menuItem.15s": "Refresh every 15s",
  "autoReload.menuItem.30s": "Refresh every 30s",
  "autoReload.menuItem.60s": "Refresh every 60s",

  // Wizard
  "wizard.advanced.show": "Show Advanced Settings",
  "wizard.advanced.hide": "hide Advanced Settings",

  // Markdown Component
  "markdown.write": "Write",
  "markdown.preview": "Preview",

  // Password
  "password.show": "Show Password",
  "password.hide": "Hide Password",

  // DateTimeInput
  "DateTimeInput.invalid.date": "Invalid Date Format",
  "DateTimeInput.invalid.max": "Date should not be after maximal date",
  "DateTimeInput.invalid.min": "Date should not be before minimal date",
  "DateTimeInput.shortcuts.now": "NOW",
  "DateTimeInput.shortcuts.halfHour": "+30min",
  "DateTimeInput.shortcuts.hour": "+1h",
  "DateTimeInput.shortcuts.twoHours": "+2h",
  "DateTimeInput.shortcuts.threeHours": "+3h",
  "DateTimeInput.shortcuts.sixHours": "+6h",

  // IntervalInput
  "IntervalInput.formatDescription": "Time period must be given either as 's' or 'm:s' or 'h:m:s' where 's' and 'm' must not be greater than 59",
  "IntervalInput.invalid.min": "Time period must be greater than or equal to {minInterval}",
  "IntervalInput.invalid.max": "Time period must be less than or equal to {maxInterval}",

  // Notifications
  "notifications.error.message.gone": "Message no longer available",
  "notifications.menu.title": "Notifications",
  "notifications.menu.none": "No active notifications",
  "notifications.menu.all": "All News",
  "notifications.menu.futureNotifications": "Upcoming Notifications",
  "notifications.menu.outdatedNotifications": "Outdated Notifications",
  "notifications.details.close": "Close",
  "notifications.details.title.add": "Add Notification",
  "notifications.details.title.view": "Notification",
  "notifications.details.title.edit": "Edit Notification",
  "notifications.details.subject": "Subject",
  "notifications.details.summary": "Summary",
  "notifications.details.severity": "Notification Severity",
  "notifications.details.severity.system": "System Critical Notification",
  "notifications.details.severity.info": "System Info Notification",
  "notifications.details.activeFrom": "Active From",
  "notifications.details.activeTo": "Active To",
  "notifications.details.activeTo.error.beforeActiveFrom": "Active-To is before Active-From",
  "notifications.details.content": "Content",
  "notifications.delete": "Delete Notification",
  "notifications.delete.message": "This will delete the following notification",

  // FavoriteToggle
  "FavoriteToggle.isFavorite.text": "Star",
  "FavoriteToggle.noFavorite.text": "Unstar",

  // FavoriteIconToggle
  "FavoriteIconToggle.isFavorite.text": "Star",
  "FavoriteIconToggle.noFavorite.text": "Unstar",

  // HeaderFavoriteFilterToggle
  "HeaderFavoriteFilterToggle.showFavorite.text": "Show All",
  "HeaderFavoriteFilterToggle.hideFavorite.text": "Show Starred",

  // HorizontalViewToggle
  "LayoutToggle.enabled.text": "Row Layout",
  "LayoutToggle.disabled.text": "Column Layout",
  "LayoutToggle.mobile.enabled.text": "Projects First",
  "LayoutToggle.mobile.disabled.text": "Organizations First",

  // TableView
  "TableView.selectedRowCount": "{count,plural,=0 {no rows} one {# row} other {# rows}} selected",

  // DataGridView
  "DataGridView.noData": "No data available",

  // CopyToClipboard
  "CopyToClipboard.title": "Copy to clipboard",
  "CopyToClipboard.message.success": "Copied to Clipboard",
  "CopyToClipboard.message.failure": "Unable to copy to clipboard!",

  // ApiVersionSelect
  "ApiVersionSelect.version.label": "API Version",

  // api versions
  "api.id.v0": "v0",
  "api.id.v1": "v1",
  "api.id.v2": "v2",

  "api.state.DEPRECATED": "API Deprecated",
  "api.state.PREVIEW": "Preview",
  "api.state.SHUTDOWN": "End Of Life",
  "api.state.REMOVED": "API Removed",
  "api.state.LEGACY": "Legacy",
  "api.state.LATEST": "Latest",

  // api credentials
  "api.credentials.state.DISABLED": "Disabled",
  "api.credentials.state.EXPIRED": "Expired",
  "api.credentials.state.org.OVERWRITE": "Overwritten",
  "api.credentials.state.org.DERIVED": "Provided by Organization",

  // ApiConfiguration
  "ApiConfiguration.title": "API Client Credentials",
  "ApiConfiguration.help.org": "Manage API client credentials for programmatic access to the organization. You can create up to 20 entries. Projects will automatically inherit these credentials, but if a project should not inherit them, you can disable the credential entry at the project level.",
  "ApiConfiguration.help.project": "Manage API client credentials for programmatic access to the project. You can create up to 20 entries. Projects automatically inherit organization credentials, but you can disable or overwrite the credential entry if necessary.",
  "ApiConfiguration.apiLink": "API Explorer",
  "ApiConfiguration.apiCredentials.new": "Add",
  "ApiConfiguration.apiCredentials.new.maxReached": "Maximum Credentials Reached",
  "ApiConfiguration.apiCredentials.added.title": "API Client Secret",
  "ApiConfiguration.apiCredentials.added.help": "Please copy the client secret to the clipboard and save it for later use. You will not be able to access it again. If you lose or forget the secret, fresh credentials have to be created.",
  "ApiConfiguration.apiCredentials.added.accept": "Close",
  "ApiConfiguration.apiCredentials.added.clipboard.copy": "Copy Client-Credentials-Secret to clipboard",
  "ApiConfiguration.apiCredentials.added.clipboard.copy.sucess": "Sucessfully copied Client-Credentials-Secret to clipboard",
  "ApiConfiguration.apiCredentials.added.clipboard.copy.failed": "Failed to copy Client-Credentials-Secret to clipboard",
  "ApiConfiguration.apiCredentials.delete.title": "Delete API Client Credentials",
  "ApiConfiguration.apiCredentials.delete.help": "This will permanently delete the chosen credentials.",
  "ApiConfiguration.apiCredentials.delete.accept": "Delete",
  "ApiConfiguration.apiCredentials.toggle.enable.title": "Enable API Client Credentials",
  "ApiConfiguration.apiCredentials.toggle.enable.help": "You are about to enable credentials defined by the organization. Are you sure?",
  "ApiConfiguration.apiCredentials.toggle.enable.accept": "Enable",
  "ApiConfiguration.apiCredentials.toggle.disable.title": "Disable API Client Credentials",
  "ApiConfiguration.apiCredentials.toggle.disable.help": "You are about to disable credentials defined by the organization for this project. Are you sure? You can enable them again at any time.",
  "ApiConfiguration.apiCredentials.toggle.disable.accept": "Disable",
  "ApiConfiguration.apiCredentials.id": "Client ID",
  "ApiConfiguration.apiCredentials.id.chip": "ID: {id}",
  "ApiConfiguration.apiCredentials.apiId": "API Version",
  "ApiConfiguration.apiCredentials.expire": "Valid Through",
  "ApiConfiguration.apiCredentials.expire.never": "Always",
  "ApiConfiguration.apiCredentials.lastUse": "Last Used",
  "ApiConfiguration.apiCredentials.lastUse.never": "Never",
  "ApiConfiguration.apiCredentials.scopes": "Scopes",

  // AddApiCredentialsDialog
  "AddApiCredentialsDialog.title": "Add API Client Credentials",
  "AddApiCredentialsDialog.help": "Please provide a clear and descriptive name and select an API version. If you wish to limit the lifetime, specify an expiration date. Otherwise, leave it blank. Expiration will occur at the end of the given day (UTC). Additionally, please ensure that you have selected at least one scope.",
  "AddApiCredentialsDialog.accept": "Add Credentials",
  "AddApiCredentialsDialog.apiCredentials.id": "Name",
  "AddApiCredentialsDialog.apiCredentials.expire": "Valid Through",
  "AddApiCredentialsDialog.apiCredentials.expire.help": "Leave blank for infinite lifetime",
  "AddApiCredentialsDialog.apiCredentials.scopes": "Scopes",
  "AddApiCredentialsDialog.apiCredentials.scopes.selectAll": "Select All",
  "AddApiCredentialsDialog.apiCredentials.scopes.selectNone": "Select None",

  // ApiExplorer
  "ApiExplorer.renderStyle.focused": "Focused Layout",
  "ApiExplorer.renderStyle.read": "Read Layout",
  "ApiExplorer.renderStyle.view": "View Layout",

  // application window titles
  "application.title.user.dashboard": "XTC - My Dashboard",
  "application.title.tenant.dashboard": "XTC - {tenant} - Org Dashboard",
  "application.title.tenant.projects": "XTC - {tenant} - Projects",
  "application.title.tenant.members": "XTC - {tenant} - Members",
  "application.title.tenant.configuration": "XTC - {tenant} - Configuration",
  "application.title.tenant.resources": "XTC - {tenant} - Resource Usage",
  "application.title.project.dashboard": "XTC - {tenant}/{project} - Project Dashboard",
  "application.title.project.loadTests": "XTC - {tenant}/{project} - Load Tests",
  "application.title.project.loadTest.details": "XTC - {tenant}/{project} - {loadTest}",
  "application.title.project.members": "XTC - {tenant}/{project} - Members",
  "application.title.project.configuration": "XTC - {tenant}/{project} - Configuration",
  "application.title.project.resources": "XTC - {tenant}/{project} - Resource Usage",
  "application.title.project.scenarios": "XTC - {tenant}/{project} - Scenarios",
  "application.title.project.metrics": "XTC - {tenant}/{project} - Metrics",
  "application.title.project.history": "XTC - {tenant}/{project} - History",
  "application.title.project.quietPeriods": "XTC - {tenant}/{project} - Quiet Periods",
  "application.title.project.exports": "XTC - {tenant}/{project} - Exports",
  "application.title.admin": "XTC - Admin",
  "application.title.apiExplorer": "Xceptance Test Center - API Explorer",
};

export default messages;
