import { APIResult, fetch } from "app/utils/remote";

import {
  AbortLoadTestData, LiveErrorLogEntries, LiveErrorLogFilterOptions, LoadTestExecutionState, LoadTestExecutionStatusHistory, ScenarioStatus,
  StepStatus
} from "./types";

export default class LoadTestExecutionAPI {

  public static startLoadTest(projectId: string, loadTestId: string):
    Promise<APIResult<LoadTestExecutionState>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/start`,
      method: "POST"
    });
  }

  public static stopLoadTest(projectId: string, loadTestId: string, abortData: AbortLoadTestData):
    Promise<APIResult<LoadTestExecutionState>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/stop`,
      method: "POST",
      data: abortData
    });
  }

  public static fetchScenarioStatus(projectId: string, loadTestId: string): Promise<APIResult<ScenarioStatus[]>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/scenarioStatus`,
      method: "GET"
    });
  }

  public static fetchStepStatus(projectId: string, loadTestId: string): Promise<APIResult<StepStatus[]>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/stepStatus`,
      method: "GET"
    });
  }

  public static fetchLoadTestExecutionStatusHistory(projectId: string, loadTestId: string,
    start: number, end: number, points: number): Promise<APIResult<LoadTestExecutionStatusHistory>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/scenarioStatus/history`,
      method: "GET",
      data: {
        start, end, points
      }
    });
  }

  public static fetchLiveErrorLogEntries(projectId: string, loadTestId: string, filter: LiveErrorLogFilterOptions)
    : Promise<APIResult<LiveErrorLogEntries>> {
    return fetch<APIResult<LiveErrorLogEntries>>({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/logs`,
      method: "GET",
      data: {
        logType: [
          filter.showErrors && "error",
          filter.showEvents && "event"
        ].filter(Boolean),
        q: filter.search,
        from: filter.from,
        to: filter.to,
        histo: filter.histo,
        size: 100
      }
    });
  }

}
