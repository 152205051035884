import {
  ProjectData, ProjectMembership, ProjectMembershipSortOption, ProjectRoles, ProjectSortoptions, ProjectStates, RepositoryAuthentication,
  RepositoryDynamicBranch, RepositorySettings, RepositoryStaticBranch
} from "app/core/project/types";
import { UserData } from "app/core/user/types";
import { APIResult, fetch } from "app/utils/remote";

import {
  AddSubscriptionData, DefaultScenarioConfig, ExecutionResultState, HistoryExport, IdWithVersionData, MonitoringExecutionState,
  MonitoringExecutorSettings, MonitoringLocation, MonitoringStatus, NewQuietPeriod, PersistenceSettings, QuietPeriod,
  ScenarioConfigSettings, ScenarioData, ScenarioExecutionSettings, ScenarioSettings, ScenarioSubscriptionData, SubscriptionData,
  SuccessCriterion
} from "app/mon/project/types";

import { QuietPeriodSortOption } from "./components/projectDetail/configuration/quietperiods/QuietPeriodConfiguration";
import { TimeFilterData } from "./components/projectDetail/history/types";

type BaseHistoryResult = {
  _id: string,
  actionName?: string,
  message?: string,
  scenarioName: string,
  locationId?: string,
  startTime: number,
  state: ExecutionResultState,
  attempt: number,
  hasResultBrowser: boolean,
  hasConsoleLog: boolean,
  hasTimers: boolean,
  duringQuietPeriod: boolean
};

type HistoryResult = BaseHistoryResult & {
  hasFailedCriteria: boolean,
  singleFailedCriterion?: SuccessCriterion;
};

type HistoryDetailsResult = BaseHistoryResult & {
  duration: number,
  failedRequests: string[],
  slowestRequests: Array<{ url: string, runtime: number }>,
  stackTrace: string | null,
  criteria: Array<{ satisfied: boolean, name: string, limit: number, value: number }>,
  failedActionTime?: number
  hasResultBrowser: boolean,
  hasConsoleLog: boolean,
  hasTimers: boolean
};

type ProjectsResult = {
  projects: ProjectData[],
  total: number,
  count: number
};

type ExecutionHistoryDetailsResult = {
  result: HistoryDetailsResult
};

type ExecutionHistoryResult = {
  count: number,
  total: number,
  items: HistoryResult[],
  minTime?: number,
  maxTime?: number
};

type MembershipsResult = {
  count: number,
  total: number,
  members: ProjectMembership[]
};

type RemoveMembershipResult = {
  total: number
};

type ProjectMemberSuggestResult = {
  matches: UserData[]
};


type ProjectSettingsResult = {
  repository: RepositorySettings,
  persistence: PersistenceSettings,
  scenarioDefaults: DefaultScenarioConfig,
  locations: MonitoringLocation[],
  executorSettings: MonitoringExecutorSettings;
};

type ScenarioDefaultsResult = {
  _ver: number;
  scenarioDefaults: DefaultScenarioConfig
};

type RepositorySettingsResult = {
  _ver: number;
  repository: RepositorySettings;
};

type NotificationRecipientSuggestionResult = {
  matches: Array<{ name: string, email: string }>
};

type QuietPeriodsListResult = {
  quietPeriods: QuietPeriod[];
  total: number;
  filtered: number;
};

export class MonitoringProjectAPI {

  public static async filteredTenantProjects(tenantId: string, start: number, count: number, sortBy: ProjectSortoptions, searchBy: string)
    : Promise<APIResult<ProjectsResult>> {
    return fetch<APIResult<ProjectsResult>>({
      url: `/api/core/tenants/${tenantId}/projects`,
      data: { start, size: count, sortby: sortBy, q: searchBy }
    });
  }

  public static async listProjects(): Promise<APIResult<ProjectData[]>> {
    return fetch<APIResult<ProjectData[]>>({ url: "/api/core/projects" });
  }

  public static async listTenantProjects(tenantId: string): Promise<APIResult<ProjectData[]>> {
    return fetch<APIResult<ProjectData[]>>({ url: `/api/core/tenants/${tenantId}/myprojects` });
  }

  public static removeTenantProjects(tenantId: string, projects: Array<{ id: string, version: number }>): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({ url: `/api/core/tenants/${tenantId}/projects`, method: "DELETE", data: projects });
  }

  public static loadProjectDetails(id: string): Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({ url: `/api/core/projects/${id}` });
  }

  public static createProject(tenantId: string, name: string, baseShortName: string): Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({ url: `/api/mon/tenants/${tenantId}/projects`, method: "POST", data: { name, baseShortName } });
  }

  public static saveProjectDetails(
    projectId: string, projectVersion: number,
    name: string, baseShortName: string, description: string, state: ProjectStates, color: string | undefined, adminUser: string)
    : Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/edit`,
      method: "PUT",
      data: { version: projectVersion, name, baseShortName, description, state, color, adminUser }
    });
  }

  public static uploadPicture(projectId: string, projectVersion: number, picture: File): Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/picture`,
      method: "POST",
      data: { file: picture, version: projectVersion }
    });
  }

  public static loadExecutionHistoryResults(
    projectId: string, start: number, count: number, sortBy: string, search: string,
    include: { success: boolean, warning: boolean, error: boolean, aborted: boolean, running: boolean },
    timeFilter?: TimeFilterData)
    : Promise<APIResult<ExecutionHistoryResult>> {
    return fetch<APIResult<ExecutionHistoryResult>>({
      url: `/api/mon/projects/${projectId}/runs`, data: {
        start, size: count, sortby: sortBy, q: search,
        include: 0
          + (include.success ? 1 : 0)
          + (include.warning ? 2 : 0)
          + (include.error ? 4 : 0)
          + (include.aborted ? 8 : 0)
          + (include.running ? 16 : 0),
        from: timeFilter && timeFilter.from, to: timeFilter && timeFilter.to
      }
    });
  }

  public static loadExecutionHistoryDetails(projectId: string, id: string): Promise<APIResult<ExecutionHistoryDetailsResult>> {
    return fetch<APIResult<ExecutionHistoryDetailsResult>>({ url: `/api/mon/projects/${projectId}/runs/${id}` });
  }

  public static loadMemberships(
    projectId: string,
    start: number, count: number, sortBy: ProjectMembershipSortOption, ascending: boolean, search: string
  ): Promise<APIResult<MembershipsResult>> {
    const data: any = { start, size: count, q: search };
    if (sortBy !== "none") {
      data.sortby = (ascending ? "" : "-") + sortBy;
    }

    return fetch<APIResult<MembershipsResult>>({ url: `/api/core/projects/${projectId}/members`, data });
  }

  public static addProjectMember(
    projectId: string,
    projectVersion: number,
    email: string,
    role: ProjectRoles
  ): Promise<APIResult<ProjectMembership>> {
    return fetch<APIResult<ProjectMembership>>({
      url: `/api/core/projects/${projectId}/members`,
      method: "POST",
      data: { email, role, version: projectVersion }
    });
  }

  public static updateProjectMember(
    projectId: string,
    memberId: string,
    memberVersion: number,
    email: string,
    role: ProjectRoles
  ): Promise<APIResult<ProjectMembership>> {
    return fetch<APIResult<ProjectMembership>>({
      url: `/api/core/projects/${projectId}/members/${memberId}`,
      method: "PUT",
      data: { email, role, version: memberVersion }
    });
  }

  public static removeProjectMembers(
    projectId: string,
    members: Array<{ version: number, id: string }>
  ): Promise<APIResult<RemoveMembershipResult>> {
    return fetch<APIResult<RemoveMembershipResult>>({
      url: `/api/core/projects/${projectId}/members`,
      method: "DELETE",
      data: members
    });
  }

  public static suggestProjectMembers(projectId: string, query: string): Promise<APIResult<ProjectMemberSuggestResult>> {
    return fetch<APIResult<ProjectMemberSuggestResult>>({ url: `/api/core/projects/${projectId}/members/suggest`, data: { q: query } });
  }

  public static loadProjectSettings(projectId: string): Promise<APIResult<ProjectSettingsResult>> {
    return fetch<APIResult<ProjectSettingsResult>>({ url: `/api/mon/projects/${projectId}/config` });
  }

  public static saveRepositorySettings(
    projectId: string,
    version: number,
    payload: RepositorySettings
  ): Promise<APIResult<RepositorySettingsResult>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/repository`,
      method: "PUT",
      data: { ...payload, version }
    });
  }

  public static saveRepositoryAuthentication(
    projectId: string,
    version: number,
    payload: { auth: RepositoryAuthentication | null }
  ): Promise<APIResult<RepositorySettingsResult>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/repositoryAuth`,
      method: "PUT",
      data: { ...payload, version }
    });
  }

  public static saveRepositoryBranch(
    projectId: string,
    version: number,
    payload: RepositoryDynamicBranch | RepositoryStaticBranch
  ): Promise<APIResult<RepositorySettingsResult>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/repositoryBranch`,
      method: "PUT",
      data: { branch: payload, version }
    });
  }

  private static saveScenarioDefaults(projectId: string, version: number, payload: Partial<ScenarioConfigSettings>)
    : Promise<APIResult<ScenarioDefaultsResult>> {
    const locations = !payload.locations ? undefined : payload.locations.filter((each) => each.selected).map((each) => each.region);

    return fetch<APIResult<ScenarioDefaultsResult>>({
      url: `/api/mon/projects/${projectId}/config/scenarioDefaults`,
      method: "PUT",
      data: { ...payload, locations, version }
    });
  }

  public static saveExecutionDefaults(projectId: string, version: number, payload: ScenarioExecutionSettings)
    : Promise<APIResult<ScenarioDefaultsResult>> {
    return this.saveScenarioDefaults(projectId, version, payload);
  }

  public static savePropertiesDefaults(projectId: string, version: number, payload: string)
    : Promise<APIResult<ScenarioDefaultsResult>> {
    return this.saveScenarioDefaults(projectId, version, { properties: payload });
  }

  public static saveNotificationSettingsDefault(
    projectId: string, version: number, payload: Pick<ScenarioConfigSettings, "notifications">)
    : Promise<APIResult<ScenarioDefaultsResult>> {
    return this.saveScenarioDefaults(projectId, version, payload);
  }

  public static toggleDefaultNotificationsActive(projectId: string, projectVersion: number, active: boolean)
    : Promise<APIResult<ScenarioDefaultsResult>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/scenarioDefaults/notifications/setActive`,
      method: "POST",
      data: { active, version: projectVersion }
    });
  }

  public static toggleNotificationsActive(
    projectId: string,
    scenarioId: string,
    version: number,
    active: boolean
  ): Promise<APIResult<ScenarioData>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/scenario/notifications/setActive`,
      method: "POST",
      data: { active, scenarioId, version }
    });
  }

  public static addRecipientToNotificationDefaults(projectId: string, version: number, subscriber: AddSubscriptionData)
    : Promise<APIResult<SubscriptionData>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/scenarioDefaults/notifications`,
      method: "POST",
      data: { version, ...subscriber }
    });
  }

  public static forceSubscriber(
    projectId: string,
    scenarioId: string,
    version: number,
    subscriber: ScenarioSubscriptionData
  ): Promise<APIResult<ScenarioSubscriptionData>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/scenario/notifications`,
      method: "POST",
      data: { ...subscriber, scenarioId, version }
    });
  }

  public static removeRecipientsFromNotificationDefaults(projectId: string, subscribers: IdWithVersionData[])
    : Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/mon/projects/${projectId}/config/scenarioDefaults/notifications`,
      method: "DELETE",
      data: { subscribers }
    });
  }

  public static removeRecipientsFromNotifications(
    projectId: string,
    scenarioId: string,
    subscribers: IdWithVersionData[]
  ): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/mon/projects/${projectId}/config/scenario/notifications`,
      method: "DELETE",
      data: { scenarioId, subscribers }
    });
  }

  public static updateDefaultNotificationRecipient(
    projectId: string,
    version: number,
    subscriberId: string,
    update: { subscriber: SubscriptionData }
  ): Promise<APIResult<SubscriptionData>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/scenarioDefaults/notifications`,
      method: "PUT",
      data: { subscriberId, ...update.subscriber, version }
    });
  }

  public static suggestRecipientsForNotificationDefaults(
    projectId: string,
    query: string
  ): Promise<APIResult<NotificationRecipientSuggestionResult>> {
    return fetch<APIResult<NotificationRecipientSuggestionResult>>({
      url: `/api/mon/projects/${projectId}/config/scenarioDefaults/notifications/suggest`,
      data: { q: query }
    });
  }

  public static saveSuccessCriteriaDefaults(projectId: string, version: number, criteria: { [key: string]: number })
    : Promise<APIResult<ScenarioDefaultsResult>> {
    return this.saveScenarioDefaults(projectId, version, { successCriteria: criteria });
  }


  public static loadProjectScenarios(projectId: string): Promise<APIResult<ScenarioData[]>> {
    return fetch({ url: `/api/mon/projects/${projectId}/config/scenarios` });
  }

  public static createScenario(projectId: string, payload: ScenarioSettings):
    Promise<APIResult<ScenarioData>> {
    return fetch<APIResult<ScenarioData>>({
      url: `/api/mon/projects/${projectId}/config/scenarios`,
      method: "POST",
      data: {
        ...payload,
        configuration: {
          ...payload.configuration,
          locations: payload.configuration.locations.filter((each) => each.selected).map((each) => each.region)
        }
      }
    });
  }

  public static updateScenario(projectId: string, version: number, payload: Required<ScenarioSettings>):
    Promise<APIResult<ScenarioData>> {
    const { id, ...rest } = payload;
    return fetch<APIResult<ScenarioData>>({
      url: `/api/mon/projects/${projectId}/config/scenarios/${id}`,
      method: "PUT",
      data: {
        ...rest,
        configuration: {
          ...rest.configuration,
          locations: rest.configuration.locations.filter((each) => each.selected).map((each) => each.region)
        },
        version
      }
    });
  }

  public static loadScenario(projectId: string, scenarioId: string):
    Promise<APIResult<ScenarioData>> {
    return fetch<APIResult<ScenarioData>>({
      url: `/api/mon/projects/${projectId}/config/scenarios/${scenarioId}`
    });
  }

  public static removeScenarios(projectId: string, scenarios: Array<{ id: string, version: number }>): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({ url: `/api/mon/projects/${projectId}/config/scenarios`, method: "DELETE", data: scenarios });
  }

  public static toggleScenarioActive(projectId: string, scenarios: Array<{ id: string, version: number }>, isActive: boolean):
    Promise<APIResult<ScenarioData[]>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/config/scenarios/setActive`,
      method: "POST", data: { active: isActive, scenarios }
    });
  }

  public static loadMonitoringScenarioStates(projectId: string): Promise<APIResult<MonitoringExecutionState[]>> {
    return fetch<APIResult<MonitoringExecutionState[]>>({
      url: `/api/mon/projects/${projectId}/scenarioState`
    });
  }

  public static loadMonitoringStatus(projectId: string): Promise<APIResult<MonitoringStatus>> {
    return fetch<APIResult<MonitoringStatus>>({
      url: `/api/mon/projects/${projectId}/state`
    });
  }

  public static loadMonitoringQuietPeriodsSettings(
    projectId: string, start: number, count: number,
    sortBy: QuietPeriodSortOption, ascending: boolean, search: string
  ): Promise<APIResult<QuietPeriodsListResult>> {
    const data: any = { start, size: count, q: search };
    if (sortBy !== "none") {
      data.sortby = (ascending ? "" : "-") + sortBy;
    }
    return fetch<APIResult<QuietPeriodsListResult>>({
      url: `/api/mon/projects/${projectId}/quietPeriods`, data
    });
  }

  public static updateMonitoringQuietPeriodsSettings(
    projectId: string, quietPeriod: QuietPeriod
  ): Promise<APIResult<QuietPeriod>> {
    return fetch<APIResult<QuietPeriod>>({
      url: `/api/mon/projects/${projectId}/quietPeriod`,
      method: "PUT",
      data: { ...quietPeriod }
    });
  }

  public static createMonitoringQuietPeriod(projectId: string, quietPeriod: NewQuietPeriod): Promise<APIResult<QuietPeriod>> {
    return fetch({ url: `/api/mon/projects/${projectId}/quietPeriods`, method: "POST", data: quietPeriod });
  }

  public static removeMonitoringQuietPeriodsSettings(
    projectId: string, quietPeriod: QuietPeriod
  ): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/mon/projects/${projectId}/quietPeriods`,
      method: "DELETE",
      data: {
        id: quietPeriod._id,
        version: quietPeriod._ver
      }
    });
  }

  public static loadHistoryExports(projectId: string): Promise<APIResult<HistoryExport[]>> {
    return fetch({
      url: `/api/mon/projects/${projectId}/exports`
    });
  }
}
