import { action, computed, makeObservable } from "mobx";

import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { createHref, currentLocation, Location, pushRoute, replaceRoute } from "app/routing/router";
import {
  routeToDashboard, routeToLogin, routeToNewPassword, routeToProjectHistoryRun,
  routeToResetPassword
} from "app/routing/routes";

type State = {};

class RouterStore extends AbstractState<State> implements State {

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this);

    this.appStores = appStores;
  }

  @computed
  public get location(): Location {
    return currentLocation();
  }

  @computed
  public get locationHref(): string {
    return createHref(this.location);
  }

  @action.bound
  public navigate(path: string, replaceHistory: boolean = false): void {
    if (replaceHistory) {
      replaceRoute(path);
    } else {
      pushRoute(path);
    }
  }

  @action.bound
  public navigateToLogin(): void {
    this.navigate(routeToLogin());
  }

  @action.bound
  public navigateToPasswordReset(): void {
    this.navigate(routeToResetPassword());
  }

  @action.bound
  public navigateToNewPassword(b64encodedEmail: string): void {
    this.navigate(routeToNewPassword(b64encodedEmail));
  }

  @action.bound
  public navigateToHistory(tenantID: string, projectID: string, runID: string): void {
    this.navigate(routeToProjectHistoryRun(projectID, tenantID, runID));
  }

  @action.bound
  public navigateToUserDashboard(): void {
    this.navigate(routeToDashboard());
  }
}

export { RouterStore };
export default RouterStore;
