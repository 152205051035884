
export class CancelablePromise<T> implements PromiseLike<T> {
  private _promise: PromiseLike<T>;
  private _canceled: boolean;

  constructor(executor: Promise<T> | ((resolve: ((value: T | PromiseLike<T>) => void), reject: ((reason: any) => void)) => void)) {
    this._canceled = false;
    this._promise = typeof executor === "object" ? executor : new Promise(executor);
  }

  public then<T1 = T, T2 = never>(
    onfulfilled?: (val: T) => T1 | PromiseLike<T1>,
    onrejected?: (reason: any) => T2 | PromiseLike<T2>
  ) {
    const p = new CancelablePromise((resolve, reject) => {
      this._promise.then((val) => {
        if (this._canceled) {
          p.cancel();
        }
        try {
          if (onfulfilled && !this._canceled) {
            resolve(onfulfilled(val));
            return;
          }
          resolve(val);
        } catch (e) {
          reject(e);
        }
      },
        (reason) => {
          if (this._canceled) {
            p.cancel();
          }
          try {
            if (onrejected && !this._canceled) {
              resolve(onrejected(reason));
              return;
            }
            reject(reason);
          } catch (e2) {
            reject(e2);
          }
        });
    });
    return p;
  }

  public cancel() {
    this._canceled = true;
    return this;
  }
}
