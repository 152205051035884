import * as React from "react";

import { default as FilledInput, FilledInputProps } from "@mui/material/FilledInput";
import { default as Input, InputProps } from "@mui/material/Input";

type BaseProps = Partial<{
  onDOMChange: (event: any) => void;
  onChange: (value: string) => void;
}>;
type FilledTBProps = BaseProps & { filled?: true } & Omit<FilledInputProps, "type" | "onChange">;
type DefaultTBProps = BaseProps & { filled: false } & Omit<InputProps, "type"|"onChange">;

export type TextBoxProps = FilledTBProps | DefaultTBProps;
const TextBox: React.FunctionComponent<TextBoxProps> = (props) => {
  const { onDOMChange, onChange, filled = true, ...rest } = props;
  const Component = filled ? FilledInput : Input;
  return <Component
      type="text"
      onChange={onDOMChange
        ? onDOMChange
        : (onChange !== undefined
          ? (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => onChange(event.target.value)
          : undefined)
      }
      {...rest}
    />;
};

export {TextBox};
export default TextBox;
