import moment, { Moment } from "moment";
import { paddedNumber } from "app/utils/text/formatting";

export const getDateTimeFormatString = () => "YYYY-MM-DD\u00a0\u00a0HH:mm:ss\u00a0UTC";

export const DATE_FORMAT_STRING = "YYYY-MM-DD";

export const dateToString = (value: number | Date | Moment) => moment(value).format(DATE_FORMAT_STRING);

/**
 * Type representing an interval
 */
export type Interval = { hours: number, minutes: number, seconds: number };

/**
 * Get the number of seconds in that interval.
 *
 * @param interval The to turn into seconds
 * @returns The seconds represented by that interval
 */
export function intervalToSeconds(interval: Partial<Interval>): number {
  const hours = interval.hours || 0;
  const minutes = interval.minutes || 0;
  const seconds = interval.seconds || 0;

  return hours * 3600 + minutes * 60 + seconds;
}

/**
 * Turn the given number of seconds to an interval.
 *
 * @param nsec The number of seconds
 * @param allFields If set to true, the function will set all fields of the interval to a numeric value. If false (the default)
 * only fields with a non-zero value will be set.
 * The following exceptions apply:
 * - the minutes part will be additionally set when the computed values of hours and seconds are non-zero
 * - the seconds part will be additionally set when the computed values of hours and minutes are zero
 * @returns The interval representing the seconds given in the input
 */
export function secondsToInterval(nsec: number, allFields: boolean = false): Partial<Interval> {
  const hours = Math.trunc(nsec / 3600);
  const minutes = Math.trunc(nsec % 3600 / 60);
  const seconds = Math.trunc(nsec % 3600 % 60);

  if (allFields) {
    return { hours, minutes, seconds };
  }

  return {
    hours: hours || undefined,
    minutes: minutes || (hours && seconds) ? minutes : undefined,
    seconds: seconds || (!hours && !minutes) ? seconds : undefined
  };
}

/**
 * Turn the given number of minutes to an interval.
 *
 * @param nsec The number of minutes
 * @param allFields If set to true, the function will set all fields of the interval to a numeric value. If false (the default)
 * only fields with a non-zero value will be set.
 * @returns The interval representing the minutes given in the input
 */
 export function minutesToInterval(nmin: number, allFields: boolean = false): Partial<Interval> {
  const hours = Math.trunc(nmin / 60);
  const minutes = Math.trunc(nmin % 60);

  if (allFields) {
    return { hours, minutes, seconds: 0 };
  }

  return {
    hours: hours || undefined,
    minutes: minutes || undefined
  };
}

/**
 * Normalizes the given (partial) interval by calculating the overall number of seconds it represents first and
 * computing the proper interval from that value finally. This is done to ensure that minutes and seconds lie within range [0,...,59].
 *
 * @param interval the (partial) interval
 * @returns given interval normalized and complete
 */
 export function normalizeInterval(interval: Partial<Interval> | undefined): Interval | undefined {
  if (interval === undefined) {
    return interval;
  }

  return secondsToInterval(intervalToSeconds(interval), true) as Interval;
}

/**
 * Returns a string representation of the given (partial) interval.
 * Minutes and seconds will be left-padded with zero to width 2. Hours won't be padded at all.
 *
 * @param interval the (partial) interval
 * @returns string representation of given (partial) interval
 */
 export function intervalToString(interval: Partial<Interval> | undefined): string {
  if (interval === undefined) {
    return "";
  }

  return [
    paddedNumber(interval.hours, 1),
    paddedNumber(interval.minutes),
    paddedNumber(interval.seconds)
  ].join(":");
}
