import * as React from "react";

import clsx from "clsx";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";

export type Props = {
  className?: string;
  shrink?: boolean;
  htmlFor?: string;
  label?: React.ReactNode;
  error?: React.ReactNode;
  help?: React.ReactNode;
  required?: boolean;
  disabled?: boolean
};
const FormField: React.FunctionComponent<Props> = (props) => {
  const { help, label, error, required, className, disabled } = props;
  return (
    <FormControl
      className={clsx("xtc_formField", className)}
      error={!!error}
      fullWidth={true}
      size="small"
      margin="normal"
      required={required}
      disabled={disabled}
    >
      {label &&
        <InputLabel htmlFor={props.htmlFor} shrink={props.shrink !== false}  >{label}</InputLabel>
      }

      {React.Children.only(props.children)}

      {(error || help) &&
        <FormHelperText error={!!error} component="div" className={error ? "xtc_error" : "xtc_help"}>
          {error ?? help}
        </FormHelperText>
      }
    </FormControl >
  );
};

export default FormField;
