import { asyncComponent } from "components/AsyncComponent";

const AsyncProjectDocumentationScene = asyncComponent(
  () => import(/* webpackChunkName: "ProjectDocumentationScene" */"app/core/project/scenes/ProjectDocumentationScene")
    .then(({ default: scene }) => scene),
  "ProjectDocumentationScene"
);

export { AsyncProjectDocumentationScene };
export default AsyncProjectDocumentationScene;
