import { indigo, red } from "@mui/material/colors";
import { alpha, createTheme, responsiveFontSizes, Theme, ThemeOptions } from "@mui/material/styles";

import type { } from "@mui/x-data-grid/themeAugmentation";
import type { } from "@mui/x-date-pickers/themeAugmentation";

const plainRoboto = {
  fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
};
const condensedRoboto = {
  fontFamily: ["Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(",")
};
const monoRoboto = {
  fontFamily: ["Roboto Mono", "Helvetica", "Arial", "sans-serif"].join(",")
};
const backgroundColor = "#fafafa";
const darkGrey = "#585858";
const dividerColor = "#cccccc";
const dividerLight = alpha(dividerColor, 0.5);

const baseTheme = createTheme({
  typography: {
    ...condensedRoboto,
    button: plainRoboto
  },
  palette: {
    primary: {
      main: indigo[500]
    },
    secondary: {
      main: red[900]
    },
    success: {
      main: "#51b53f"
    },
    error: {
      main: "#de1244"
    },
    warning: {
      main: "#f2d400"
    },
    text: {
      primary: "#000000",
      secondary: darkGrey,
      hint: darkGrey
    },
    divider: dividerColor,
    action: {
      selected: "rgba(0,0,0,0.06)",
      selectedOpacity: 0.06
    },
    background: {
      default: backgroundColor
    }
  }
});

const customTheme: ThemeOptions = {
  typography: {
    ...baseTheme.typography,
    plainRoboto,
    monoRoboto
  },
  main: { contentPadding: 2 },
  components: {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexWrap: "wrap"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        },
        colorPrimary: {
          backgroundColor: indigo[900]
        }
      }
    },
    MuiButton: {
      defaultProps: {
        color: "inherit"
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "unset",
          fontWeight: 300,
          "& .MuiDataGrid-columnHeader": {
            backgroundColor
          },
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
            padding: "0 4px"
          },
          "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell, &.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
            borderBottom: `1px solid ${baseTheme.palette.mode === "light" ? dividerLight : dividerColor}`
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 400
          },
          "& .MuiDataGrid-columnHeaderTitleContainer, .xtc_contextMenu": {
            padding: 0
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none"
          },
          // eslint-disable-next-line max-len
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
            outline: "unset"
          }
        }
      }
    },
    MuiDateTimeField: {
      defaultProps: {
        variant: "filled",
        fullWidth: true
      }
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          backgroundColor: dividerLight
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.06)"
        },
        adornedStart: {
          paddingLeft: undefined
        },
        input: {
          padding: "8px 10px"
        },
        multiline: {
          padding: 0
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: plainRoboto
      }
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          "label + &": {
            marginTop: 0
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: plainRoboto,
        input: {
          lineHeight: "1.1876em",
          height: "1.1876em",
          padding: "6px 0 7px"
        },
        inputSizeSmall: {
          paddingTop: undefined
        },
        inputMultiline: {
          resize: "vertical"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          position: "relative",
          lineHeight: 1
        },
        shrink: {
          transform: "translate(0, 1.5px) scale(0.75)"
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: "hover"
      }
    },
    MuiRadio: {
      defaultProps: {
        color: "primary"
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: condensedRoboto
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...condensedRoboto,
          fontSize: "1rem",
          fontWeight: 300,
          textTransform: "unset",
          "&.Mui-selected": {
            fontWeight: 400
          }
        },
        textColorInherit: {
          opacity: "unset"
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "primary"
      }
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`${theme.breakpoints.down("lg")}`]: {
            minWidth: theme.spacing(125)
          }
        })
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderBottomStyle: "none"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          /* Enable hover effect only for rows rendered by our TableController */
          ".xtc_table.xtc_table-hover tbody &:not(.Mui-selected):hover": {
            backgroundColor: baseTheme.palette.action.hover
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...condensedRoboto,
          fontWeight: 300,
          borderBottom: `1px solid ${baseTheme.palette.mode === "light" ? dividerLight : dividerColor}`
        },
        head: {
          ...condensedRoboto,
          fontWeight: 400,
          backgroundColor
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          inherit: "span"
        }
      }
    }
  }
};

const appTheme: Theme = responsiveFontSizes(createTheme(baseTheme, customTheme));

export default appTheme;
export { appTheme };
