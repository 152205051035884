import * as React from "react";

import OrgIcon from "@mui/icons-material/BusinessOutlined";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import UsageIcon from "@mui/icons-material/DataUsageOutlined";
import MembersIcon from "@mui/icons-material/GroupOutlined";
import AuditLogIcon from "@mui/icons-material/PolicyOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ProjectsIcon from "@mui/icons-material/WorkOutlineOutlined";

import Navigation from "app/core/components/Navigation";
import {
  routeToTenant, routeToTenantAuditLog, routeToTenantConfiguration, routeToTenantMembers, routeToTenantProjects, routeToTenantResources
} from "app/routing/routes";

type Props = {
  tenantId: string;
  tenantName: string;
  tenantIsAdmin: boolean;
  tenantIsBillingAdmin: boolean;
};
const TenantSideNavigation: React.FunctionComponent<Props> = (props) => {
  const { tenantId, tenantName, tenantIsAdmin, tenantIsBillingAdmin } = props;

  return <Navigation
    header={{
      className: "xtc_tenantHome",
      labelId: tenantName,
      icon: <OrgIcon />,
      path: routeToTenant(tenantId)
    }}
    entries={[
      {
        className: "xtc_tenantDashboard",
        labelId: "navigation.dashboard",
        icon: <DashboardIcon />,
        path: routeToTenant(tenantId),
        selectedOnExactPath: true
      },
      tenantIsAdmin && {
        className: "xtc_tenantProjects",
        labelId: "navigation.projects",
        icon: <ProjectsIcon />,
        path: routeToTenantProjects(tenantId),
        selectedOnExactPath: true
      },
      tenantIsAdmin && {
        className: "xtc_tenantMembers",
        labelId: "navigation.members",
        icon: <MembersIcon />,
        path: routeToTenantMembers(tenantId),
        selectedOnExactPath: true
      },
      tenantIsAdmin && {
        className: "xtc_tenantConfig",
        labelId: "navigation.settings",
        icon: <SettingsIcon />,
        path: routeToTenantConfiguration(tenantId),
        selectedOnExactPath: true
      },
      tenantIsBillingAdmin && {
        className: "xtc_tenantResourceUsage",
        labelId: "navigation.resources",
        icon: <UsageIcon />,
        path: routeToTenantResources(tenantId),
        selectedOnExactPath: true
      },
      tenantIsAdmin && {
        className: "xtc_tenantAuditLog",
        labelId: "navigation.auditLog",
        icon: <AuditLogIcon />,
        path: routeToTenantAuditLog(tenantId),
        selectedOnExactPath: true
      }
    ]}
  />
    ;
};

export default TenantSideNavigation;
export { TenantSideNavigation };
