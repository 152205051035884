import { action, computed, makeObservable, observable } from "mobx";

import { SlackConfigUpdate } from "components/slackConfig/SlackConfiguration";

import { ProjectState } from "app/core/project/store/ProjectState";
import { ProjectSortoptions, ProjectStates, ProjectType } from "app/core/project/types";
import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { TenantAPI } from "app/core/tenant/TenantAPI";
import { PlatformPrivileges, TenantData, TenantMembership, TenantRoles, TenantSortoptions, TenantStates } from "app/core/tenant/types";
import { hasTenantRole } from "app/core/tenant/utils/roleValidation";
import { ApiCredentials, ApiVersion, SlackConfigData } from "app/core/types";

import TenantResourcesAPI from "../TenantResourcesAPI";

interface State {
  readonly _id: string;
  readonly _ver: number;
  readonly name: string;
  readonly baseShortName: string;
  readonly shortName: string;
  readonly deleteDateTime?: number;
  readonly description: string;
  readonly adminUser: string;
  readonly state: TenantStates;
  readonly platformPrivileges: PlatformPrivileges;
  readonly slackIntegration: SlackConfigData | undefined;
  readonly color: string | undefined,
  readonly hasLogo: boolean;

  readonly role: TenantRoles;

  readonly members: TenantMembership[];
  readonly membersTotal: number;
  readonly membersFiltered: number;

  readonly projectsListingTotal: number;
  readonly projectsListingFiltered: number;
  readonly projectCount: number;

  readonly allowsUserInvitation: boolean;

  readonly requiresSSO: boolean;
  readonly permittedIssuers: string[];
  readonly requires2FA: boolean;
  readonly isLocked: boolean;
  readonly lockDetails: string;

  readonly apiCredentials: ApiCredentials[];
  readonly availableApiVersions: ApiVersion[];
}

export class TenantState extends AbstractState<State> implements State {

  public readonly appStores: AppStores;

  public readonly _id: string;
  @observable public readonly _ver: number;

  @observable public readonly name: string;
  @observable public readonly baseShortName: string;
  @observable public readonly shortName: string;
  @observable public readonly deleteDateTime?: number;
  @observable public readonly description: string;

  @observable public readonly adminUser: string;
  @observable public readonly state: TenantStates;
  @observable public readonly platformPrivileges: PlatformPrivileges;

  @observable public readonly color: string | undefined;
  @observable public readonly hasLogo: boolean;

  @observable public readonly role: TenantRoles;

  @observable public readonly favorite: boolean;

  @observable public readonly members: TenantMembership[];
  @observable public readonly membersTotal: number;
  @observable public readonly membersFiltered: number;

  @observable public readonly projectsListingTotal: number;
  @observable public readonly projectsListingFiltered: number;
  @observable public readonly projectCount: number;

  @observable public readonly allowsUserInvitation: boolean;

  @observable public readonly requiresSSO: boolean;
  @observable public readonly permittedIssuers: string[];

  @observable public readonly requires2FA: boolean;

  @observable public readonly isLocked: boolean;
  @observable public readonly lockDetails: string;

  @observable public readonly slackIntegration: SlackConfigData | undefined;

  @observable public readonly apiCredentials: ApiCredentials[];
  @observable public readonly availableApiVersions: ApiVersion[];

  public constructor(appStores: AppStores, tenant: TenantData) {
    super();

    makeObservable(this.setState({
      role: "None",
      members: [],
      membersTotal: 0,
      membersFiltered: 0,
      projectsListingTotal: 0,
      projectsListingFiltered: 0,
      projectCount: 0,
      isLocked: false,
      lockDetails: undefined,
      ...tenant,
      permittedIssuers: tenant.permittedIssuers ?? []
    }));

    this.appStores = appStores;
  }

  @computed
  public get isAdmin(): boolean {
    return hasTenantRole(this.role, "Admin");
  }

  @computed
  public get isBillingAdmin(): boolean {
    return hasTenantRole(this.role, "BillingAdmin");
  }

  @computed
  public get isUser(): boolean {
    return hasTenantRole(this.role, "User");
  }

  @computed
  public get projects(): ProjectState[] {
    return this.appStores.projectStore.myProjects.filter((each) => each.tenant._id === this._id);
  }

  @computed
  public get adminProjects(): ProjectState[] {
    return this.appStores.projectStore.projects.filter((each) => each.tenant._id === this._id && !!!each.deleteDateTime);
  }

  @computed
  public get adminProjectsSoftDeleted(): ProjectState[] {
    return this.appStores.projectStore.projects.filter((each) => each.tenant._id === this._id && each.deleteDateTime);
  }

  @action.bound
  private updateProjectCounts(value: number): void {
    this.setState({
      projectsListingTotal: this.projectsListingTotal + value || 0,
      projectsListingFiltered: this.projectsListingFiltered + value || 0
    });
  }

  // @action.bound
  // private incrementVersion(): void {
  //   this.setState({ _ver: this._ver + 1 });
  // }

  @action.bound
  public async saveDetails(
    name: string,
    baseShortName: string,
    description: string,
    state: TenantStates,
    color: string | undefined): Promise<void> {
    const result = await TenantAPI.saveTenantDetails(this._id, this._ver, { name, baseShortName, description, state, color });
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.details.save.error"));
    }
  }

  @action.bound
  public async savePicture(picture: File): Promise<void> {
    const result = await TenantAPI.uploadPicture(this._id, this._ver, picture);
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.details.save.error"));
    }
  }

  @action.bound
  public async deletePicture(): Promise<void> {
    const result = await TenantAPI.deletePicture(this._id, this._ver);
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.details.save.error"));
    }
  }

  @action.bound
  private updateMemberCounts(value: number): void {
    this.setState({
      membersTotal: this.membersTotal + value || 0,
      membersFiltered: this.membersFiltered + value || 0
    });
  }

  @action.bound
  private putMember(prepend: boolean, member: TenantMembership, updateMemberCounts: boolean = true): TenantMembership {
    const index = this.members.findIndex((each) => each._id === member._id);
    if (index > -1) {
      this.members.splice(index, 1, member);
      return this.members[index];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      updateMemberCounts && this.updateMemberCounts(1);
      if (prepend) {
        this.members.splice(0, 0, member);
        return this.members[0];
      } else {
        this.members.push(member);
        return this.members[this.members.length - 1];
      }
    }
  }

  @action.bound
  private putMembers(prepend: boolean, members: TenantMembership[], updateMemberCounts: boolean = true): TenantMembership[] {
    return members.map((each) => this.putMember(prepend, each, updateMemberCounts));
  }

  @action.bound
  private removeMember(member: TenantMembership): void {
    const index = this.members.findIndex((each) => each._id === member._id);
    if (index > -1) {
      this.members.splice(index, 1);
      this.updateMemberCounts(-1);
    }
  }

  @action.bound
  private removeMembers(members: TenantMembership[]): void {
    members.forEach(this.removeMember.bind(this));
  }

  @action.bound
  public async fetchMembers(append: boolean, start: number, count: number,
    sortBy: TenantSortoptions | undefined, searchBy: string | undefined
  ): Promise<void> {
    const result = await TenantAPI.fetchMembers(this._id, start, count, sortBy, searchBy);
    if (result.success && result.data) {
      if (append) {
        this.putMembers(false, result.data.members, false);
        this.setState({
          membersTotal: result.data.total,
          membersFiltered: result.data.count
        });
      } else {
        this.setState({
          members: result.data.members,
          membersTotal: result.data.total,
          membersFiltered: result.data.count
        });
      }
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.members.load.error"));
    }
  }

  @action.bound
  public async addMembers(emails: string[], role: TenantRoles, inviteUnknownUser: boolean): Promise<TenantMembership[]> {
    const result = await TenantAPI.addMembers(this._id, this._ver, emails, role, inviteUnknownUser);
    if (result.success && result.data) {
      return this.putMembers(true, result.data);
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.members.put.error"));
    }
  }

  @action.bound
  public async inviteUsers(emails: string[], role: TenantRoles): Promise<TenantMembership[]> {
    const result = await TenantAPI.inviteUsers(this._id, this._ver, emails, role);
    if (result.success && result.data) {
      return this.putMembers(true, result.data);
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.members.invite.error"));
    }
  }

  public async inviteMember(member: TenantMembership): Promise<void> {
    const result = await TenantAPI.inviteMember(this._id, member._id, member._ver);
    if (result.success) {
      this.appStores.notificationStore.ok("tenant.manage.notifications.invitationSent");
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.members.invite.error"));
    }
  }

  @action.bound
  public async updateMember(member: TenantMembership): Promise<TenantMembership> {
    const result = await TenantAPI.updateMember(this._id, member._id, member._ver, member.user.email, member.role, member.isLocked);
    if (result.success && result.data) {
      return this.putMember(false, result.data);
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.members.put.error"));
    }
  }

  @action.bound
  public async deleteMembers(members: Array<{ version: number, id: string }>): Promise<void> {
    const result = await TenantAPI.deleteMembers(this._id, members);
    if (result.success) {
      this.removeMembers(this.members.filter((each) => members.findIndex((m) => m.id === each._id) > -1));
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.members.delete.error"));
    }
  }

  @action.bound
  public async fetchProjects(): Promise<void> {
    await this.appStores.projectStore.fetchTenantProjects(this._id);
    this.setState({
      projectsListingTotal: this.projects.length,
      projectsListingFiltered: this.projects.length
    });
  }

  @action.bound
  public async fetchProjectsFiltered(
    append: boolean, start: number, count: number, sortBy: ProjectSortoptions, searchBy: string, softDeleted: boolean
  ): Promise<void> {
    const result = await this.appStores.projectStore
      .fetchTenantProjectsFiltered(append, this._id, start, count, sortBy, searchBy, softDeleted);

    this.setState({
      projectsListingTotal: result.total,
      projectsListingFiltered: result.filtered
    });
  }

  @action.bound
  public async pushProject(name: string, baseShortName: string, type: ProjectType): Promise<ProjectState> {
    const projectState = await this.appStores.projectStore.pushTenantProject(true, this._id, name, baseShortName, type);
    this.updateProjectCounts(1);
    return projectState;
  }

  @action.bound
  public async updateProject(
    id: string,
    name: string,
    baseShortName: string,
    type: ProjectType,
    description: string,
    state: ProjectStates,
    color: string | undefined,
    adminUser: string): Promise<ProjectState> {
    let project: ProjectState | undefined;
    if (this.isAdmin) {
      project = this.adminProjects.find((each) => each._id === id);
    } else {
      project = this.projects.find((each) => each._id === id);
    }
    if (project) {
      await project.updateProjectCommon(name, baseShortName, type, description, state, adminUser);
      return project;
    } else {
      throw Error("No project for id:" + id);
    }
  }

  @action.bound
  public async updateProjectState(id: string, state: ProjectStates): Promise<ProjectState> {
    let project: ProjectState | undefined;
    if (this.isAdmin) {
      project = this.adminProjects.find((each) => each._id === id);
    } else {
      project = this.projects.find((each) => each._id === id);
    }
    if (project) {
      await project.updateProjectState(state);
      return project;
    } else {
      throw Error("No project for id:" + id);
    }
  }

  @action.bound
  public async deleteProjects(projects: Array<{ id: string, version: number }>): Promise<void> {
    await this.appStores.projectStore.deleteTenantProjects(this._id, projects);
    this.updateProjectCounts(-1 * projects.length);
  }

  @action.bound
  public async restoreProject(projectId: string): Promise<void> {
    await this.appStores.projectStore.restoreTenantProject(this._id, projectId);
    this.updateProjectCounts(-1);
  }

  @action.bound
  public async updateSecuritySettings(allowsUserInvitation: boolean,
    requiresSSO: boolean, permittedIssuers: string[], requires2FA: boolean): Promise<void> {
    const result = await TenantAPI.updateSecuritySettings(this._id, this._ver, {
      allowsUserInvitation, requiresSSO, permittedIssuers, requires2FA
    });
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : new Error(result.message || "tenant.details.save.error"));
    }
  }

  public async fetchMachineRecords() {
    const result = await TenantResourcesAPI.fetchMachineRecords(this._id, 0, 50);
    if (result.success && result.data) {
      return result;
    }
    throw new Error(result.message ?? "tenant.details.list.error");
  }

  public async fetchStorageRecords() {
    const result = await TenantResourcesAPI.fetchStorageRecords(this._id, 0, 50);
    if (result.success && result.data) {
      return result;
    }
    throw new Error(result.message ?? "tenant.details.list.error");
  }

  /**
   * Action to set this tenant to the locked state. This is triggered whenever a part of the UI detects a
   * reply from the server that indicates, that the tenant is locked and causes all UI parts to display the correct
   * lock-out screen
   */
  @action.bound
  public lock(message?: string): void {
    this.setState({
      isLocked: true,
      lockDetails: message
    });
  }

  @action.bound
  public async updateTenantFavorite(favorite: boolean): Promise<void> {
    const result = await TenantAPI.updateTenantFavorite(this._id, favorite);
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateSlackIntegration(settings: SlackConfigUpdate): Promise<void> {
    const result = await TenantAPI.updateSlackIntegration(this._id, this._ver, settings);
    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async sendSlackTestMessage(settings: SlackConfigUpdate): Promise<void> {
    return await TenantAPI.sendSlackTestMessage(this._id, settings);
  }

  @action.bound
  public async addApiCredentials(apiCredentialsName: string, apiId: string, scopes: string[], expire?: number): Promise<string> {
    const result = await TenantAPI.addApiCredentials(this._id, apiCredentialsName, apiId, scopes, expire);

    if (result.success && result.data) {
      this.setState(result.data.tenant);
      return result.data.apiCredentialsSecret;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async deleteApiCredentials(apiCredentialsId: string): Promise<void> {
    const result = await TenantAPI.deleteApiCredentials(this._id, apiCredentialsId);

    if (result.success && result.data) {
      this.setState(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

}

