import { action, computed, makeObservable, observable } from "mobx";

import { Message, MessageEditData, SystemMessage, SystemMessageErrors } from "app/core/message/types";
import { AbstractState } from "app/core/store/AbstractState";
import AppStores from "app/core/store/AppStores";

import MessageAdminAPI from "../MessageAdminAPI";
import MessageAPI from "../MessageAPI";

type State = {
  readonly messages: Message[];
};

class MessageStore extends AbstractState<State> implements State {

  private static readonly initialState: State = { messages: [] };

  @observable public readonly messages: Message[];

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState(MessageStore.initialState));

    this.appStores = appStores;
  }

  @action.bound
  public initStore(): void {
    this.setState(MessageStore.initialState);
  }

  @computed
  public get systemMessages(): SystemMessage[] {
    return this.messages.filter((message) => message.type === "SYSTEM") as SystemMessage[];
  }

  @computed
  public get activeCriticalSystemMessages(): SystemMessage[] {
    const now: number = Date.now();
    return this.systemMessages
      .filter((message) =>
        message.severity === "CRITICAL"
        && now >= message.activeFrom
        && now <= message.activeTo
      );
  }

  @computed
  public get activeSystemMessages(): SystemMessage[] {
    const now: number = Date.now();
    return this.systemMessages
      .filter((message) =>
        now >= message.activeFrom
        && now <= message.activeTo
      );
  }

  @computed
  public get upcomingSystemMessages(): SystemMessage[] {
    const now: number = Date.now();
    return this.systemMessages
      .filter((message) =>
        now < message.activeFrom
      );
  }

  @computed
  public get outdatedSystemMessages(): SystemMessage[] {
    const now: number = Date.now();
    return this.systemMessages
      .filter((message) =>
        now > message.activeTo
      );
  }

  @computed
  public get isSysAdmin() {
    const { userStore: { user } } = this.appStores;
    return user && user.role === "Admin";
  }

  @action.bound
  public setMessages(messages: Message[]): void {
    this.messages.splice(0, this.messages.length, ...messages);
  }

  @action.bound
  public putMessage(message: Message) {
    const idx = this.messages.findIndex((eachMessage) => eachMessage._id === message._id);
    if (idx < 0) {
      this.messages.push(message);
    } else {
      this.messages.splice(idx, 1, message);
    }
  }

  public removeMessage(id: string) {
    this.setMessages(this.messages.filter((eachMessage) => eachMessage._id !== id));
  }

  protected lookupMessage(id: string) {
    return (id && this.messages.find((eachMessage) => eachMessage._id === id)) || null;
  }

  public async addSystemMessage(message: MessageEditData<SystemMessage>): Promise<void | { errors: SystemMessageErrors }> {
    const result = await MessageAdminAPI.addMessage("SYSTEM", message);
    if (result.success && result.data) {
      this.putMessage(result.data);
    } else if (result.errors) {
      return { errors: result.errors };
    } else {
      this.appStores.notificationStore.error(result.message || "application.error.request.failure");
    }
  }

  public async editMessage(id: string, message: MessageEditData<SystemMessage>): Promise<void | { errors: SystemMessageErrors }> {
    const _message = this.lookupMessage(id);
    if (_message) {
      const result = await MessageAdminAPI.editMessage(id, _message._ver, message);
      if (result.success && result.data) {
        // replace old message with new one
        this.removeMessage(_message._id);
        this.putMessage(result.data);
      } else if (result.errors) {
        return { errors: result.errors };
      } else {
        this.appStores.notificationStore.error(result.message || "application.error.request.failure");
      }
    } else {
      this.appStores.notificationStore.error("notifications.error.message.gone");
    }
  }

  public async readSystemMessage(id: string): Promise<void> {
    const _message = this.lookupMessage(id);
    if (_message) {
      const result = await MessageAPI.markMessageRead(id, _message._ver);
      if (result.success && result.data) {
        this.putMessage(result.data);
      } else {
        this.appStores.notificationStore.error(result.message || "application.error.request.failure");
      }
    } else {
      // NOP - if a message is gone while I was reading it then I dont care if it could not be marked as read
    }
  }

  public async deleteSystemMessage(id: string): Promise<void> {
    const _message = this.lookupMessage(id);
    if (_message) {
      const result = await MessageAdminAPI.deleteMessage(id, _message._ver);
      if (result.success) {
        this.removeMessage(id);
      } else {
        this.appStores.notificationStore.error(result.message || "application.error.request.failure");
      }
    } else {
      // NOP - if a message was deleted while I was trying to delete it too, then don't care
    }
  }

  /**
   * Fetches the system notification messages from the server and stores them in store.
   * N.B. Errors will be silently ignored as messages are expected to be fetched at regular interval.
   */
  public async fetchSystemMessages(): Promise<void> {
    const API = this.isSysAdmin ? MessageAdminAPI : MessageAPI;
    try {
      const result = await API.fetchMessages();
      if (result.success && result.data) {
        this.setMessages(result.data.messages);
      }
    } catch (e) {
      // just eat it
    }
  }
}

export { MessageStore };
export default MessageStore;
