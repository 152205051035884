import * as React from "react";

import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";

import DialogBox from "components/dialog/DialogBox";
import CheckToggle from "components/forms/CheckToggle";
import Form from "components/forms/Form";
import FormField from "components/forms/FormField";
import TextBox from "components/forms/TextBox";
import MarkdownEditor from "components/markdown/MarkdownEditor";
import Status from "components/status/Status";
import TimeRangePicker from "components/time/TimeRangePicker";
import TruncatingText from "components/TruncatingText";

import Message from "app/localization/components/Message";
import { Timestamp } from "app/utils/time/Timestamp";

import { SystemMessage, SystemMessageErrors } from "../types";

export type DetailsMode = "EDIT" | "ADD" | "VIEW";
export type DetailsHandlerParameter = Pick<SystemMessage, "subject" | "summary" | "richContent" | "severity" | "activeFrom" | "activeTo">;

type Props = {
  mode?: DetailsMode;
  message: Partial<SystemMessage>;
  onAdd?: (message: DetailsHandlerParameter) => Promise<void | SystemMessageErrors>;
  onAccept?: (id: string, message: DetailsHandlerParameter) => Promise<void | SystemMessageErrors>;
  onClose: () => void;
};

const MessageDetails: React.ComponentType<Props> = (props) => {
  const { mode = "VIEW", message, onAdd, onAccept, onClose } = props;

  const [subject, setSubject] = React.useState(message.subject || "");
  const [summary, setSummary] = React.useState(message.summary || "");
  const [richContent, setRichContent] = React.useState(message.richContent || "");
  const [severity, setSeverity] = React.useState(message.severity || "INFO");
  const [activeFrom, setActiveFrom] = React.useState(message.activeFrom || Date.now());
  const [activeTo, setActiveTo] = React.useState(message.activeTo || (Date.now() + 604800000)); // add one week
  const [errors, setErrors] = React.useState<SystemMessageErrors>();

  function getStateAsSystemMessage(): DetailsHandlerParameter {
    return { severity, subject, summary, richContent, activeFrom, activeTo };
  }

  function hasChanges(): boolean {
    return subject !== message.subject
      || summary !== message.summary
      || severity !== message.severity
      || richContent !== message.richContent
      || activeFrom !== message.activeFrom
      || activeTo !== message.activeTo;
  }

  function canAccept(): boolean {
    return !!subject && activeTo > activeFrom && hasChanges();
  }

  type NotificationLabelIDs = {
    messageLabelID: string;
    acceptButtonLabelID: string
  };

  const getNotificationLabelIDs = (): NotificationLabelIDs => {
    switch (props.mode) {
      case "ADD":
        return { messageLabelID: "notifications.details.title.add", acceptButtonLabelID: "common.buttons.add" };
      case "EDIT":
        return { messageLabelID: "notifications.details.title.edit", acceptButtonLabelID: "common.buttons.saveChanges" };
      default:
        return { messageLabelID: "notifications.details.title.view", acceptButtonLabelID: "common.buttons.accept" };
    }
  };

  const notificationLabelIDs = getNotificationLabelIDs();

  return (
    <DialogBox
      titleID={
        <Box display="flex" alignContent="space-between" alignItems="center" flexWrap="wrap">
          <Box flexGrow={1} marginRight={1}>
            <Message
              messageID={notificationLabelIDs.messageLabelID}
            />
          </Box>
        </Box>
      }
      cancelLabelID="notifications.details.close"
      acceptLabelID={notificationLabelIDs.acceptButtonLabelID}
      acceptHide={mode === "VIEW"}
      acceptEnabled={mode === "VIEW" ? false : canAccept()}
      onAccept={() =>
        mode === "ADD"
          ? onAdd && onAdd(getStateAsSystemMessage()).then((addErrors) => setErrors(addErrors || undefined))
          : mode === "EDIT"
            ? onAccept && onAccept(props.message._id!, getStateAsSystemMessage()).then((editErrors) => setErrors(editErrors || undefined))
            : undefined
      }
      onClose={onClose}
    >
      {mode === "VIEW" ?
        <Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="column">
              {mode === "VIEW" &&
                <Box color={grey[700]}>
                  <Timestamp value={message.activeFrom || 0} />
                </Box>
              }
              <TruncatingText variant="h4" noWrap={true}>
                {message.subject}
              </TruncatingText>
              <Box color={grey[700]}>
                <TruncatingText variant="body1">
                  {message.summary}
                </TruncatingText>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginRight={1}>
              <Status value={props.message.severity === "CRITICAL" ? "critical" : "disabled"} size="xlarge" />
            </Box>
          </Box>

          {richContent &&
            <MarkdownEditor value={richContent} minPreviewHeight={0} hideBorder={true} />
          }
        </Box>
        :
        <Form plain={true}>
          <FormField
            className="xtc_subject"
            label={<Message messageID="notifications.details.subject" />}
            error={errors && errors.subject && <Message messageID={errors.subject} />}
          >
            <TextBox value={subject} onChange={(newSubject) => setSubject(newSubject)} />
          </FormField>
          <FormField
            className="xtc_summary"
            label={<Message messageID="notifications.details.summary" />}
            error={errors && errors.summary && <Message messageID={errors.summary} />}
          >
            <TextBox value={summary} onChange={(newSummary) => setSummary(newSummary)} />
          </FormField>
          <FormField
            className="xtc_severity"
            label={<Message messageID="notifications.details.severity" />}
            error={errors && errors.severity && <Message messageID={errors.severity} />}
          >
            <CheckToggle
              toggle={true}
              controlColor="secondary"
              defaultChecked={severity === "CRITICAL"}
              checkedLabelID="notifications.details.severity.system"
              unCheckedLabelID="notifications.details.severity.info"
              onChange={(checked) => setSeverity(checked ? "CRITICAL" : "INFO")}
            />
          </FormField>

          <TimeRangePicker
            label={{
              from: { message: "notifications.details.activeFrom" },
              to: { message: "notifications.details.activeTo" }
            }}
            textFieldProps={{
              from: {
                error: (errors && !!errors.activeFrom) || undefined,
                helperText: errors && errors.activeFrom && <Message messageID={errors.activeFrom} />
              },
              to: {
                error: (activeTo < activeFrom) || (errors && !!errors.activeTo),
                helperText: (
                  activeTo < activeFrom
                    ? <Message messageID="notifications.details.activeTo.error.beforeActiveFrom" />
                    : (errors && errors.activeTo && <Message messageID={errors.activeTo} />)
                )
              }
            }}
            from={activeFrom}
            to={activeTo}
            onTimeChange={{
              setFrom: setActiveFrom,
              setTo: setActiveTo
            }}
          />

          <FormField
            className="xtc_content"
            label={<Message messageID="notifications.details.content" />}
            error={errors && errors.richContent && <Message messageID={errors.richContent} />}
          >
            <Box marginTop={2}>
              <MarkdownEditor
                value={richContent}
                onChange={(newRichContent) => setRichContent(newRichContent)}
              />
            </Box>
          </FormField>
        </Form>
      }
    </DialogBox>
  );
};

export { MessageDetails };
export default MessageDetails;
