import * as React from "react";

import clsx from "clsx";

import { ButtonBase, ButtonBaseProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "PlainButton";

const classes = {
  button: `${PREFIX}-button`
};

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  [`& .${classes.button}`]: {
    transition: theme.transitions.create("background-color", {}),
    "&:hover": {
      "&.primary": {
        backgroundColor: theme.palette.primary.dark
      },
      "&.secondary": {
        backgroundColor: theme.palette.secondary.dark
      }
    }
  }
}));

type Props = Omit<ButtonBaseProps, "className"> & {
  hoverColor: "primary" | "secondary"
};

const PlainButton: React.ComponentType<Props> = (props) => {
  const { hoverColor, ...rest } = props;
  const activeProps: ButtonBaseProps = {
    ...rest,
    className: clsx(classes.button, hoverColor),
    focusRipple: props.focusRipple || true
  };

  return (
    <StyledButtonBase {...activeProps}>
      {props.children}
    </StyledButtonBase>
  );
};

export { PlainButton };
export default PlainButton;
