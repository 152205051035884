import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringAuditLogScene = asyncComponent(
  () => import(
    /* webpackChunkName: "monitoringProjectAuditLogs" */ "app/mon/project/scenes/MonitoringAuditLogScene"
  ).then(({ default: scene }) => scene),
  "MonitoringAuditLogScene"
);

export { AsyncMonitoringAuditLogScene };
export default AsyncMonitoringAuditLogScene;
