import { asyncComponent } from "components/AsyncComponent";

const AsyncApiRequestDefaultScene = asyncComponent(
  () => import(/* webpackChunkName: "apiRequestDefault" */ "app/core/scenes/ApiRequestDefaultScene")
    .then(({ default: scene }) => scene),
  "ApiRequestDefaultScene"
);

export { AsyncApiRequestDefaultScene };
export default AsyncApiRequestDefaultScene;
