import * as React from "react";

import clsx from "clsx";

import CheckIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import UnknownIcon from "@mui/icons-material/IndeterminateCheckBox";
import InfoIcon from "@mui/icons-material/Info";
import WarnIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/material/styles";
import { SvgIconProps } from "@mui/material/SvgIcon";

type Props = {
  className?: string;
  value?: "ok" | "warning" | "critical" | "disabled" | "unknown";
  icon?: React.ComponentType<SvgIconProps>
  size?: "xsmall" | "small" | "large" | "inherit" | "xlarge";
  color?: string;
};

const VALUE_ICON = {
  critical: ErrorIcon,
  disabled: InfoIcon,
  ok: CheckIcon,
  unknown: UnknownIcon,
  warning: WarnIcon
};


const Status = (props: Props) => {
  const { value = "unknown", className, size, icon, color } = props;
  const Icon = icon || VALUE_ICON[value];
  const theme = useTheme();

  const COLORS = {
    critical: theme.palette.error.main,
    disabled: theme.palette.grey[500],
    ok: theme.palette.success.main,
    unknown: theme.palette.grey[400],
    warning: theme.palette.warning.main
  };

  return <Icon
    className={clsx("xtc_status", `xtc_status-${value}`, className)}
    htmlColor={color || COLORS[value]}
    fontSize={size !== "xsmall" && size !== "xlarge" ? size : undefined}
    style={{
      fontSize: (size === "xsmall" ? "1rem" : size === "xlarge" ? "4rem" : undefined)
    }}
  />;
};
export { Status, Props as StatusProps };
export default Status;
