import { action, makeObservable } from "mobx";

import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { remoteErrorToResolvedError } from "app/utils/error";

import { AuditLogAPI } from "../remote/AuditLogAPI";
import { ApiAuditLogResult, AuditLogResult } from "../types";

type State = {
};

class AuditLogStore extends AbstractState<State> implements State {

  private static readonly initialState: State = {};

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState(AuditLogStore.initialState));

    this.appStores = appStores;
  }

  public initStore(): void {
    this.setState(AuditLogStore.initialState);
  }

  @action.bound
  public async fetchSystemAuditLogs(
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, typeCodes?: string[])
    : Promise<AuditLogResult> {

    const result = await AuditLogAPI.systemAuditLogData(start, size, sortby, q, fromMillis, toMillis, typeCodes);

    if (result.success && result.data) {
      const auditLogs = result.data.items;
      return {
        items: auditLogs,
        totalCount: result.data.totalCount,
        filteredCount: result.data.filteredCount
      };
    } else {
      throw remoteErrorToResolvedError(result);
    }
  }

  @action.bound
  public async fetchProjectAuditLogs(projectId: string,
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, typeCodes?: string[])
    : Promise<AuditLogResult> {

    const result = await AuditLogAPI.projectUserAuditLogData(projectId, start, size, sortby, q, fromMillis, toMillis, typeCodes);

    if (result.success && result.data) {
      const auditLogs = result.data.items;
      return {
        items: auditLogs,
        totalCount: result.data.totalCount,
        filteredCount: result.data.filteredCount
      };
    } else {
      throw remoteErrorToResolvedError(result);
    }
  }

  @action.bound
  public async fetchTenantAuditLogs(tenantId: string,
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, typeCodes?: string[])
    : Promise<AuditLogResult> {

    const result = await AuditLogAPI.tenantUserAuditLogData(tenantId, start, size, sortby, q, fromMillis, toMillis, typeCodes);

    if (result.success && result.data) {
      const auditLogs = result.data.items;
      return {
        items: auditLogs,
        totalCount: result.data.totalCount,
        filteredCount: result.data.filteredCount
      };
    } else {
      throw remoteErrorToResolvedError(result);
    }
  }

  @action.bound
  public async fetchApiSystemAuditLogs(
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, activities?: string[])
    : Promise<ApiAuditLogResult> {

    const result = await AuditLogAPI.systemApiAuditLogData(start, size, sortby, q, fromMillis, toMillis, activities);

    if (result.success && result.data) {
      const auditLogs = result.data.items;
      return {
        items: auditLogs,
        totalCount: result.data.totalCount,
        filteredCount: result.data.filteredCount
      };
    } else {
      throw remoteErrorToResolvedError(result);
    }
  }

  @action.bound
  public async fetchApiProjectAuditLogs(projectId: string,
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, activities?: string[])
    : Promise<ApiAuditLogResult> {

    const result = await AuditLogAPI.projectApiAuditLogData(projectId, start, size, sortby, q, fromMillis, toMillis, activities);

    if (result.success && result.data) {
      const auditLogs = result.data.items;
      return {
        items: auditLogs,
        totalCount: result.data.totalCount,
        filteredCount: result.data.filteredCount
      };
    } else {
      throw remoteErrorToResolvedError(result);
    }
  }

  @action.bound
  public async fetchApiTenantAuditLogs(tenantId: string,
    start: number, size: number, sortby?: string, q?: string, fromMillis?: number, toMillis?: number, activities?: string[])
    : Promise<ApiAuditLogResult> {

    const result = await AuditLogAPI.tenantApiAuditLogData(tenantId, start, size, sortby, q, fromMillis, toMillis, activities);

    if (result.success && result.data) {
      const auditLogs = result.data.items;
      return {
        items: auditLogs,
        totalCount: result.data.totalCount,
        filteredCount: result.data.filteredCount
      };
    } else {
      throw remoteErrorToResolvedError(result);
    }
  }

}

export { AuditLogStore };
export default AuditLogStore;
