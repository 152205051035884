import * as React from "react";
import { useObserver } from "mobx-react";
import * as Zone from "moment-timezone";

import { getDateTimeFormatString } from "components/time/utils";
import TruncatingText from "components/TruncatingText";

import useAppStores from "app/core/store/useStore";

type Props = {
  value: number;
  locale?: string;
  timezone?: string;
  format?: string;
  noTrunc?: boolean;
};
type PropsInternal = Required<Props> & {

};

const Timestamp: React.ComponentType<PropsInternal> = (props) => {
  const { value, noTrunc, locale, timezone, format } = props;
  const timeString = value >= 0 ? Zone.utc(value).locale(locale).tz(timezone).format(format) : undefined;

  return noTrunc
    ? (
      <React.Fragment>
        {timeString}
      </React.Fragment>
    )
    : (
      <TruncatingText title={timeString} noWrap>
        {timeString}
      </TruncatingText>
    );
};

const Component: React.ComponentType<Props> = (props) => {
  const { localizationStore } = useAppStores();
  return useObserver(() => {
    const { value, locale, timezone, format, noTrunc } = props;
    const { locale: appLocale, timezone: appTimezone } = localizationStore;
    return (
      <Timestamp
        value={value}
        locale={locale || appLocale}
        timezone={timezone || appTimezone}
        format={format || getDateTimeFormatString()}
        noTrunc={!!noTrunc}
      />
    );
  });
};
export { Component as Timestamp };
export default Component;
