import { asyncComponent } from "components/AsyncComponent";

const AsyncAccountActivationScene = asyncComponent(
  () => import(/* webpackChunkName: "registerComplete" */ "app/core/user/scenes/AccountActivationScene")
    .then(({ default: scene }) => scene),
  "AccountActivationScene"
);

export { AsyncAccountActivationScene };
export default AsyncAccountActivationScene;
