import * as React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import { ButtonProps, default as Button } from "@mui/material/Button";
import { default as IconButton, IconButtonProps } from "@mui/material/IconButton";
import { default as Link, LinkProps as MuiLinkProps } from "@mui/material/Link";
import { default as ListItem, ListItemProps } from "@mui/material/ListItem";
import { default as MenuItem, MenuItemProps } from "@mui/material/MenuItem";

const AdapterLink: React.FunctionComponent<RouterLinkProps> =
  React.forwardRef((props, ref) => <RouterLink ref={ref as any} {...props} />);

const routerProps = (path?: string) => (path ? { component: AdapterLink, to: path } : {});

const RouterAnchor: React.FunctionComponent<{ path?: string, disabled?: boolean } & MuiLinkProps> = (props) => {
  const { path, children, disabled, ...rest } = props;
  if (disabled || !path) {
    const { id, className } = rest;
    return <span id={id} className={className}>{children}</span>;
  }
  const linkProps = Object.assign(routerProps(path), rest);
  return <Link {...linkProps} >{children}</Link>;
};

const RouterButton: React.FunctionComponent<{ path?: string } & ButtonProps> = React.forwardRef((props, ref) => {
  const { path, ...rest } = props;
  const buttonProps = Object.assign(routerProps(path), rest);

  return <Button ref={ref} {...buttonProps} />;
});

const RouterIconButton: React.FunctionComponent<{ path?: string } & IconButtonProps> = React.forwardRef((props, ref) => {
  const { path, ...rest } = props;
  const buttonProps = Object.assign(routerProps(path), rest);

  return <IconButton ref={ref} size="large" {...buttonProps} />;
});

const RouterListItem: React.FunctionComponent<{ path?: string } & ListItemProps> = React.forwardRef((props, ref) => {
  const { path, ...rest } = props;
  const itemProps = Object.assign(routerProps(path), rest, {button: true});
  // wrap with 'li' element to produce correct HTML (necessary due to override of 'component' prop)
  return itemProps.component ? <li ref={ref}><ListItem {...itemProps as any} /></li> : <ListItem ref={ref} {...itemProps as any} />;
});

const RouterMenuItem: React.FunctionComponent<{ path?: string } & MenuItemProps> =
  React.forwardRef((props, ref) => <MenuItem ref={ref} component={RouterListItem} {...props as any} />);

export { RouterAnchor, RouterButton, RouterIconButton, RouterListItem, RouterMenuItem };

const RouterControls = {
  Anchor: RouterAnchor,
  Button: RouterButton,
  IconButton: RouterIconButton,
  ListItem: RouterListItem,
  MenuItem: RouterMenuItem
};
export default RouterControls;
