import { ProjectData } from "app/core/project/types";

type P = ProjectData | null;

export function isMonitoringProject(project?: P): boolean {
    return (!!project && project.type === "mon");
}

export function isLoadTestProject(project?: P): boolean {
    return (!!project && project.type === "lt");
}
