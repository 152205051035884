import * as React from "react";
import { useLocation } from "react-router";

import { useObserver } from "mobx-react";

import Error from "components/error/Error";
import Loading from "components/loading/Loading";

import useAppStores from "app/core/store/useStore";
import { useFetch } from "app/utils/remote";

type Props = {
  children: (loggedIn: boolean) => JSX.Element;
  enforceLogout: (path: string) => boolean;
};

const Component: React.ComponentType<Props> = (props) => {
  const [resolved, setResolved] = React.useState(true);
  const { pathname } = useLocation();
  const { userStore } = useAppStores();
  const loadState = useFetch(() => userStore.fetchUser(), []);
  return useObserver(() => {
    if (loadState.error) {
      return loadState.error.isHandledResponseError ? null : <Error message={loadState.error.message} />;
    }
    if (!loadState.fetched || !resolved) {
      return <Loading message="loading" />;
    }

    const isLoggedIn = userStore.isLoggedIn;
    if (isLoggedIn && props.enforceLogout(pathname)) {
      setResolved(false);
      userStore.logout(true).finally(() => setResolved(true));
      return null;
    }

    return props.children(isLoggedIn);
  });
};

export { Component };
export default Component;
