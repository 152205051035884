import { action, makeObservable, observable } from "mobx";

import { FormattedMessage, NotificationData, NotificationStatus } from "components/error/types";

import { Notifications } from "app/utils/error/notifications";

import { AbstractState } from "./AbstractState";
import { AppStores } from "./AppStores";

type State = {
    notifications: NotificationData[];
};

export class NotificationStore extends AbstractState<State> implements State, Notifications {
    @observable public notifications: NotificationData[];

    public readonly appStores: AppStores;
    private msgIndex: number = 0;

    public constructor(appStores: AppStores) {
        super();

        makeObservable(this.setState({
            notifications: []
        }));

        this.appStores = appStores;
    }

    @action.bound
    public notify(msg: FormattedMessage, status?: NotificationStatus, modal?: boolean, onClose?: () => void) {
        // deliberate wrap around instead of risking doubled IDs once we get to really high numbers (JS number is IEEE-754 double
        //   which get's imprecise even beyond +/-1 at a certain number size)
        this.msgIndex = this.msgIndex++ % 1_000_000;
        this.setState({
            notifications: [
                ...this.notifications,
                {
                    id: this.msgIndex.toString(),
                    status: status ?? "unknown",
                    message: msg,
                    modal: !!modal,
                    onClose
                }
            ]
        });
    }

    public error = (key: string, values?: Record<string, string>, modal?: boolean, onClose?: () => void) => {
        this.notify({ key, values }, "critical", modal, onClose);
    };

    public warning = (key: string, values?: Record<string, string>, modal?: boolean, onClose?: () => void) => {
        this.notify({ key, values }, "warning", modal, onClose);
    };

    public ok = (key: string, values?: Record<string, string>, modal?: boolean, onClose?: () => void) => {
        this.notify({ key, values }, "ok", modal, onClose);
    };

    @action.bound
    public remove(id: string) {
        const toRemove = this.notifications.find((n) => n.id === id);
        this.setState({
            notifications: this.notifications.filter((n) => n.id !== id)
        });
        if (toRemove && toRemove.onClose) {
            // schedule the onClose callback in the next microtask to be outside the context of the MobX action
            setTimeout(toRemove.onClose, 0);
        }
    }

    @action.bound
    public clear() {
        this.setState({
            notifications: []
        });
    }
}

export default NotificationStore;
