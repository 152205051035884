import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringQuietPeriodsScene = asyncComponent(
  () => import(/* webpackChunkName: "monitoringQuietPeriods" */ "app/mon/project/scenes/MonitoringQuietPeriodsScene")
    .then(({ default: scene }) => scene),
  "MonitoringQuietPeriodsScene"
);

export { AsyncMonitoringQuietPeriodsScene };
export default AsyncMonitoringQuietPeriodsScene;
