import { Link } from "react-router-dom";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Box from "@mui/material/Box";

import Typography from "components/Typography";

import Message from "app/localization/components/Message";

type UserLockOutProps = {
  details: string
};

export const UserLockOut: React.FunctionComponent<UserLockOutProps> = (props) => (
  <Box textAlign="center">
    <ReportProblemOutlinedIcon style={{
      fontSize: "25vh",
      lineHeight: "0.2"
    }} color="error" />
    <Typography className="xtc_userLockOutMessage" variant="h5" color="error" style={{ maxWidth: "40em", margin: "auto" }}><Message
      messageID={props.details} values={{
        accountLink: <Link to="/account"><Message messageID="error.privileges.userLockedFromTenant.link.ssoAccount" /></Link>
      }} /></Typography>
  </Box>
);
