import * as React from "react";

import { AppStores } from "./AppStores";

const AppStoreContext = React.createContext<AppStores>(null as any);

const { Consumer: AppStoreConsumer, Provider: AppStoreProvider } = AppStoreContext;
export const Provider = AppStoreProvider;
export const Context = AppStoreContext;

function getDisplayName(Component: React.ComponentType<any>) {
  return Component.displayName || Component.name || "Component";
}

export type WrappedComponentProps<StorePropName extends string = "appStores"> = {
  [k in StorePropName]: AppStores;
};

export default function injectStore<P extends WrappedComponentProps>(WrappedComponent: React.ComponentType<P>) {
  const WithStore: React.FunctionComponent<P> = (props) => (
    <AppStoreConsumer>
      { (stores) => <WrappedComponent {...props} appStores={stores} />}
    </AppStoreConsumer>
  );
  WithStore.displayName = `injectStore(${getDisplayName(WrappedComponent)})`;
  return WithStore;
}
