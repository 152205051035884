import * as React from "react";
import { useParams } from "react-router-dom";

import { observer } from "mobx-react";

import Error from "components/error/Error";
import Section from "components/layout/Section";

import useAppStores from "app/core/store/useStore";
import { isLoadTestProject, isMonitoringProject } from "app/utils/project/is-of-type";
import { useFetch } from "app/utils/remote";

import MonitoringProjectSideNavigation from "app/mon/project/components/MonitoringProjectSideNavigation";

import LoadTestProjectSideNavigation from "app/lt/project/components/LoadTestProjectSideNavigation";

const ProjectRoute: React.FunctionComponent = observer((props) => {
  const { projectStore } = useAppStores();
  const { pid } = useParams();
  const loadState = useFetch(() => projectStore.fetchProject(pid!), [pid]);

  if (loadState.error) {
    return loadState.error.isHandledResponseError ? null : <Error message={loadState.error.message} />;
  }

  const project = loadState.fetched && pid ? projectStore.findProject(pid) : null;

  if (!project) {
    return null;
  }

  if (!project.hasSecret) {
    return <Error message="error.projectNoSecret" closer modal />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
});

export { ProjectRoute };
export default ProjectRoute;

export const RequiresMonitoringProject: React.FunctionComponent = observer((props) => {
  const { projectStore, projectHistory, tenantsStore } = useAppStores();
  const { pid } = useParams();
  const project = pid ? projectStore.findProject(pid) : null;
  const tenant = project?.tenant?._id ? tenantsStore.findTenant(project?.tenant._id) : null;

  React.useEffect(() => {
    projectHistory.initStore();
  }, [pid]);

  if (project && isMonitoringProject(project)) {
    return (
      <React.Fragment>
        <MonitoringProjectSideNavigation
          projectId={project._id}
          projectName={project.name}
          isProjectReviewer={project.isReviewer}
          isProjectTester={project.isTester}
          isProjectAdmin={project.isAdmin}
          tenantId={project.tenant._id}
          tenantName={tenant?.name ?? ""}
          projectState={project.state}
        />
        {props.children}

      </React.Fragment>
    );
  }
  return null;
});

export const RequiresLoadTestingProject: React.FunctionComponent = observer((props) => {
  const { projectStore, loadTestsStore, tenantsStore } = useAppStores();
  const { pid } = useParams();
  const project = pid ? projectStore.findProject(pid) : null;
  const tenant = project?.tenant?._id ? tenantsStore.findTenant(project?.tenant._id) : null;

  React.useEffect(() => {
    loadTestsStore.initStore();
  }, [pid]);

  if (project && isLoadTestProject(project)) {
    return (
      <React.Fragment>
        <LoadTestProjectSideNavigation
          projectId={project._id}
          projectName={project.name}
          isProjectReviewer={project.isReviewer}
          isProjectTester={project.isTester}
          isProjectAdmin={project.isAdmin}
          tenantId={project.tenant._id}
          tenantName={tenant?.name ?? ""}
          projectState={project.state}
        />
        <Section>
          {props.children}
        </Section>
      </React.Fragment>
    );
  }
  return null;
});
