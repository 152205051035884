import { FormError, ErrorMap, toMap } from "./FormError";


export type RemoteError = {
  message?: string;
  errors?: FormError[];
};

export type ResolvedError = {
  message?: string
  errors?: ErrorMap
};

export function remoteErrorToResolvedError(error: RemoteError | FormError[] | string): ResolvedError {
  let message: string | undefined;
  let errors: ErrorMap | undefined;

  if (error) {
    if (typeof error === "string") {
      message = error;
    } else if (Array.isArray(error)) {
      errors = toMap(error);
    } else {
      if (typeof error.message === "string") {
        message = error.message;
      }
      if (Array.isArray(error.errors)) {
        errors = toMap(error.errors);
      }
    }
  }
  if (!message && !errors) {
    message = "application.error.unknown";
  }
  return { message, errors };
}

export function convertToErrorMessageAndArray(error: any): RemoteError {
  let message: string | undefined;
  let errors: FormError[] | undefined;

  /* Unfortenatly this is all bad, it is just random guessing.
     We can just try to detect something which might be an error that we can handle.
     But in the end it could be anything like number boolean void never or a even an Error object which
     was thrown somewhere at the promise chain.
     So we can just supress the unexpected errors or tell something very general eg. "something went wrong".
  */
  if (error) {
    if (typeof error === "string") {
      message = error;
    } else if (Array.isArray(error)) {
      errors = error;
    } else {
      if (typeof error.message === "string") {
        message = error.message;
      }
      if (Array.isArray(error.errors)) {
        errors = error.errors;
      }
    }
  }
  if (!message && !errors) {
    message = "application.error.unknown";
  }
  return { message, errors };
}

/**
 * Gets the message of the supplied error if it is specified, otherwise it gets the fieldName of the supplied error.
 * Furthermore, if arrayData is supplied as argument, the function checks if the key
 * `<arrayName>[<index>].<fieldName>` is in the error and returns it. If this is not in the error object,
 * undefined is returned.
 *
 * For example, assume the following function call:
 * ```typescript
 * resolvePotentialArrayError(
 *     { "channels[0].email": "validation.required" },
 *     "email",
 *     { arrayName="channels", index=0 }
 * )
 * ```
 * then `validation.required` is returned.
 *
 * @param error the ErrorMap
 * @param fieldName the name of the field
 * @param arrayName the name of the array
 * @param index the index to look for the fieldName
 */
export function resolvePotentialArrayError(errors: ErrorMap, fieldName: string, arrayData?: { arrayName: string, index: number })
  : string | undefined {
  if (arrayData) {
    const { arrayName, index } = arrayData;
    const qualifiedFieldName = arrayName.concat("[", String(index), "]", ".", fieldName);
    if (qualifiedFieldName in errors) {
      return errors[qualifiedFieldName];
    }
  }

  return fieldName in errors ? errors[fieldName] : undefined;
}
