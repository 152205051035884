import * as React from "react";

import Notification from "./Notification";

type Props = {
  modal?: boolean,
  message: string | string[],
  onClose?: () => void,
  closer?: boolean
};

export default class Error extends React.PureComponent<Props> {

  public render(): JSX.Element | null {
    return (
      <Notification status="critical" {...this.props} />
    );
  }
}
