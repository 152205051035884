import * as React from "react";

import useAppStores from "app/core/store/useStore";
import { Message } from "app/localization/components/Message";

type InternalProps = { logout: () => Promise<void> };
type ExternalProps = { };
type Props = ExternalProps & InternalProps;

class LogoutRoute extends React.Component<Props> {

  public componentDidMount() {
    setTimeout(() => this.props.logout(), 300);
  }

  public render(): React.ReactNode {
    return (
      <div>
        <Message messageID="route.logout" />
      </div>
    );
  }
}

const Component: React.FunctionComponent<ExternalProps> = (props) => {
  const {userStore} = useAppStores();
  return <LogoutRoute logout={userStore.logout} />;
};

export { Component as LogoutRoute };
export default Component;
