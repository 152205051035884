import * as React from "react";

import ComparisonReportIcon from "@mui/icons-material/CompareOutlined";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
// import ResourcesIcon from "@mui/icons-material/TrendingUpOutlined";
import ResourcesIcon from "@mui/icons-material/DataUsageOutlined";
import MembersIcon from "@mui/icons-material/GroupOutlined";
import LoadTestsIcon from "@mui/icons-material/ListOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import AuditLogIcon from "@mui/icons-material/PolicyOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ProjectIcon from "@mui/icons-material/SpeedOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";

import { ProjectNavigation } from "app/core/project/components/ProjectNavigation";
import { ProjectStates } from "app/core/project/types";
import {
  routeToLoadTestProjectComparisonReports, routeToLoadTestResources, routeToLoadTests, routeToProject, routeToProjectAuditLog,
  routeToProjectConfiguration, routeToProjectDocumentation, routeToProjectMembers
} from "app/routing/routes";

type Props = {
  projectId: string,
  projectName: string,
  isProjectTester: boolean,
  isProjectReviewer: boolean,
  isProjectAdmin: boolean,
  tenantId: string,
  tenantName: string,
  projectState: ProjectStates
};
const LoadTestProjectSideNavigation: React.FunctionComponent<Props> = (props) => {
  const { projectId, projectName, isProjectTester, isProjectReviewer, isProjectAdmin, tenantId, tenantName, projectState } = props;
  const icon = projectState === "Archived"
    ? <StopCircleOutlinedIcon style={{ color: "darkred" }}/>
    : projectState === "Inactive"
      ? <PauseCircleOutlineOutlinedIcon style={{ color: "darkred" }} />
      : <ProjectIcon />;

  return <ProjectNavigation
    tenantName={tenantName}
    tenantId={tenantId}
    header={{
      className: "xtc_loadTestProjectHome",
      labelId: projectName,
      icon,
      path: routeToProject(projectId, tenantId)
    }}
    entries={[
      {
        className: "xtc_loadTestProjectDashboard",
        labelId: "navigation.dashboard",
        icon: <DashboardIcon />,
        path: routeToProject(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectReviewer && {
        className: "xtc_loadTestProjectDocumentation",
        labelId: "navigation.documentation",
        icon: <NoteAltOutlinedIcon />,
        path: routeToProjectDocumentation(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectReviewer && {
        className: "xtc_loadTestProjectTests",
        labelId: "LoadTestProjectRoute.loadTests",
        icon: <LoadTestsIcon />,
        path: routeToLoadTests(projectId, tenantId),
        selectedOnExactPath: false
      },
      isProjectReviewer && {
        className: "xtc_loadTestProjectComparisonReports",
        labelId: "navigation.comparison",
        icon: <ComparisonReportIcon />,
        path: routeToLoadTestProjectComparisonReports(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectTester && {
        className: "xtc_loadTestProjectMembers",
        labelId: "navigation.members",
        icon: <MembersIcon />,
        path: routeToProjectMembers(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectTester && {
        className: "xtc_loadTestProjectConfig",
        labelId: "navigation.settings",
        icon: <SettingsIcon />,
        path: routeToProjectConfiguration(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectTester && {
        className: "xtc_loadTestProjectResources",
        labelId: "navigation.resources",
        icon: <ResourcesIcon />,
        path: routeToLoadTestResources(projectId, tenantId),
        selectedOnExactPath: true
      },
      isProjectAdmin && {
        className: "xtc_loadTestProjectAuditLog",
        labelId: "navigation.auditLog",
        icon: <AuditLogIcon />,
        path: routeToProjectAuditLog(projectId, tenantId),
        selectedOnExactPath: true
      }
    ]}
  />
    ;
};

export default LoadTestProjectSideNavigation;
export { LoadTestProjectSideNavigation };
