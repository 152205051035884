import { APIResult, fetch } from "app/utils/remote";

import {
  AwsMachines, CommonMachineConfiguration, CustomMachines, GcpMachines, HetznerMachines, LoadProfile, LoadTestData, Repository,
  UnattendedExecutionOptions
} from "./types";

export default class LoadTestConfigurationAPI {

  public static saveExecutionConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number, executionOptions: UnattendedExecutionOptions)
    : Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/executionOptions?version=${loadTestVersion}`,
      method: "PUT",
      data: executionOptions
    });
  }

  public static deleteExecutionConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number)
    : Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/executionOptions?version=${loadTestVersion}`,
      method: "DELETE"
    });
  }

  public static saveLoadProfileConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number, loadProfile: LoadProfile)
    : Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/loadProfile?version=${loadTestVersion}`,
      method: "PUT",
      data: loadProfile
    });
  }

  public static saveRepositoryConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number, repositorySettings: Repository)
    : Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/repository?version=${loadTestVersion}`,
      method: "PUT",
      data: repositorySettings
    });
  }

  public static deleteRepositoryConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number)
    : Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/repository?version=${loadTestVersion}`,
      method: "DELETE"
    });
  }

  public static saveCommonMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number, commonMachineConfiguration: CommonMachineConfiguration)
    : Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/common?version=${loadTestVersion}`,
      method: "PUT",
      data: commonMachineConfiguration
    });
  }

  public static saveAwsMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number, awsMachines: AwsMachines): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/aws?version=${loadTestVersion}`,
      method: "PUT",
      data: awsMachines
    });
  }

  public static deleteAwsMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/aws?version=${loadTestVersion}`,
      method: "DELETE"
    });
  }

  public static saveGcpMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number, gcpMachines: GcpMachines): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/gcp?version=${loadTestVersion}`,
      method: "PUT",
      data: gcpMachines
    });
  }

  public static deleteGcpMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/gcp?version=${loadTestVersion}`,
      method: "DELETE"
    });
  }

  public static saveHetznerMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number, hetznerMachines: HetznerMachines): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/hetzner?version=${loadTestVersion}`,
      method: "PUT",
      data: hetznerMachines
    });
  }

  public static deleteHetznerMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/hetzner?version=${loadTestVersion}`,
      method: "DELETE"
    });
  }

  public static saveCustomMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number, customMachines: CustomMachines): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/custom?version=${loadTestVersion}`,
      method: "PUT",
      data: customMachines
    });
  }

  public static deleteCustomMachineConfiguration(
    projectId: string, loadTestId: string, loadTestVersion: number): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/machines/custom?version=${loadTestVersion}`,
      method: "DELETE"
    });
  }

  public static saveProperties(
    projectId: string, loadTestId: string, loadTestVersion: number, properties?: string, secretProperties?: string):
    Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/configuration/properties?version=${loadTestVersion}`,
      method: "PUT",
      data: { properties, secretProperties }
    });
  }
}
