import * as React from "react";
import { createBrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AsyncManageProjectsScene, AsyncManageTenantsScene, AsyncManageUsersScene, AsyncResourceTrackingScene } from "app/admin/scenes";
import { AsyncAuditLogScene } from "app/admin/scenes/AsyncAuditLogScene";
import AsyncApiExplorerScene from "app/core/apiExplorer/scenes/ApiExplorerScene";
import { SubHeaderSlotContent } from "app/core/components/SubHeaderSlot";
import { AsyncProjectsScene } from "app/core/project/scenes";
import { AsyncProjectDocumentationScene } from "app/core/project/scenes/AsyncProjectDocumentationScene";
import { AsyncApiRequestDefaultScene, AsyncNotFoundScene } from "app/core/scenes";
import { AsyncAppScene } from "app/core/scenes/AsyncAppScene";
import { IndexScene } from "app/core/scenes/IndexScene";
import {
  AsyncTenantConfigurationScene, AsyncTenantDashboardScene, AsyncTenantMembersScene, AsyncTenantProjectsScene, AsyncTenantResourcesScene,
  AsyncTenantsScene
} from "app/core/tenant/scenes";
import { AsyncTenantAuditLogScene } from "app/core/tenant/scenes/AsyncTenantAuditLogScene";
import {
  AsyncAccountActivationScene, AsyncAccountScene, AsyncCreateAccountInvitationScene, AsyncCreateAccountScene, AsyncDashboardScene,
  AsyncLoginScene, AsyncNewPasswordScene, AsyncResetPasswordScene, LogoutScene
} from "app/core/user/scenes";
import { AsyncRegisterExternalAccountScene } from "app/core/user/scenes/AsyncRegisterExternalAccountScene";
import { RoutePaths } from "app/routing/router";
import ProjectRoute, { RequiresLoadTestingProject, RequiresMonitoringProject } from "app/routing/router/restrictions/ProjectRoute";
import SysAdminRoute, { RequiresSysAdmin, RequiresSysBillingAdmin } from "app/routing/router/restrictions/SysAdminRoute";
import TenantRoute, { RequiresTenantAdmin, RequiresTenantBillingAdmin } from "app/routing/router/restrictions/TenantRoute";
import UserRoute from "app/routing/router/restrictions/UserRoute";

import {
  AsyncMonitoringConfigurationScene, AsyncMonitoringExportsScene, AsyncMonitoringHistoryLogScene, AsyncMonitoringHistoryResultBrowserScene,
  AsyncMonitoringHistoryScene, AsyncMonitoringMetricsScene, AsyncMonitoringOverviewScene
} from "app/mon/project/scenes";
import { AsyncMonitoringAuditLogScene } from "app/mon/project/scenes/AsyncMonitoringAuditLogScene";
import { AsyncMonitoringMembersScene } from "app/mon/project/scenes/AsyncMonitoringMembersScene";
import { AsyncMonitoringQuietPeriodsScene } from "app/mon/project/scenes/AsyncMonitoringQuietPeriodsScene";
import { AsyncMonitoringScenariosScene } from "app/mon/project/scenes/AsyncMonitoringScenariosScene";

import {
  AsyncLoadTestConfigurationScene, AsyncLoadTestMembersScene, AsyncLoadTestProjectDashboardScene, AsyncLoadTestResourcesScene,
  AsyncLoadTestsScene
} from "app/lt/project/scenes";
import { AsyncLoadTestAuditLogScene } from "app/lt/project/scenes/AsyncLoadTestAuditLogScene";
import { AsyncLoadTestComparisonReportsScene } from "app/lt/project/scenes/AsyncLoadTestComparisonReportsScene";

/*
 * Route paths that require user to be logged out.
 */
const REQUIRES_USER_LOGGED_OUT_PATHS = [
  RoutePaths.newPassword, RoutePaths.register, RoutePaths.registerExternal,
  RoutePaths.registerInvitation, RoutePaths.resetPassword, RoutePaths.signupComplete
].map((p) => p.path);
const pathRequiresLoggedOut = (path: string) => {
  if (path.startsWith("/")) {
    path = path.substring(1);
  }

  return REQUIRES_USER_LOGGED_OUT_PATHS.includes(path);
};


const loggedInRoutes = (
  <Routes>
    <Route path="/api/*" element={<AsyncApiRequestDefaultScene />} />
    <Route element={<AsyncAppScene />}>
      <Route index element={<AsyncDashboardScene />} />
      <Route path="/exploreApi" element={<AsyncApiExplorerScene />} />
      <Route path="/account" element={<AsyncAccountScene />} />
      <Route path="/logout" element={<LogoutScene />} />
      <Route path="/admin" element={<SysAdminRoute />}>
        <Route element={<RequiresSysAdmin />}>
          <Route path="users" element={<AsyncManageUsersScene />} />,
          <Route path="projects">
            <Route index element={<AsyncManageProjectsScene />} />
            <Route path=":pid" element={<AsyncManageProjectsScene />} />
          </Route>
          <Route path="auditLog" element={<AsyncAuditLogScene />} />,
        </Route>
        <Route element={<RequiresSysBillingAdmin />}>
          <Route path="tenants" element={<AsyncManageTenantsScene />} />,
          <Route path="resources" element={<AsyncResourceTrackingScene />} />
        </Route>
      </Route>
      <Route path="/tenants">
        <Route index element={<AsyncTenantsScene />} />
        <Route path=":tid" element={<TenantRoute />}>
          <Route index element={<AsyncTenantDashboardScene />} />
          <Route element={<RequiresTenantAdmin />}>
            <Route path="configuration" element={<AsyncTenantConfigurationScene />} />,
            <Route path="projects" element={<AsyncTenantProjectsScene />} />,
            <Route path="members" element={<AsyncTenantMembersScene />} />,
            <Route path="auditLog" element={<AsyncTenantAuditLogScene />} />
          </Route>
          <Route element={<RequiresTenantBillingAdmin />}>
            <Route path="resources" element={<AsyncTenantResourcesScene />} />
          </Route>
          <Route path="projects/:pid/*" element={
            <ProjectRoute>
              <RequiresMonitoringProject >
                <Routes>
                  <Route index element={<AsyncMonitoringOverviewScene />} />
                  <Route path="configuration" element={<AsyncMonitoringConfigurationScene />} />
                  <Route path="members" element={<AsyncMonitoringMembersScene />} />
                  <Route path="quietPeriods" element={<AsyncMonitoringQuietPeriodsScene />} />
                  <Route path="scenarios/*" element={
                    <React.Fragment>
                      <SubHeaderSlotContent header="navigation.subheader.scenarios" />
                      <Routes>
                        <Route index element={<AsyncMonitoringScenariosScene />} />
                        <Route path=":sid" element={<AsyncMonitoringScenariosScene />} />
                      </Routes>
                    </React.Fragment>
                  } />
                  <Route path="metrics" element={<AsyncMonitoringMetricsScene />} />
                  <Route path="exports" element={<AsyncMonitoringExportsScene />} />
                  <Route path="auditLog" element={<AsyncMonitoringAuditLogScene />} />
                  <Route path="documentation" element={<AsyncProjectDocumentationScene />} />
                  <Route path="history/*" element={
                    <React.Fragment>
                      <SubHeaderSlotContent header="navigation.subheader.history" />
                      <Routes>
                        <Route index element={<AsyncMonitoringHistoryScene />} />
                        <Route path=":run" element={<AsyncMonitoringHistoryScene />} />
                        <Route path=":run/log" element={<AsyncMonitoringHistoryLogScene />} />
                        <Route path=":run/resultBrowser" element={<AsyncMonitoringHistoryResultBrowserScene />} />
                      </Routes>
                    </React.Fragment>
                  }>
                  </Route>
                </Routes>

              </RequiresMonitoringProject>
              <RequiresLoadTestingProject>
                <Routes>
                  <Route index element={<AsyncLoadTestProjectDashboardScene />} />
                  <Route path="configuration" element={<AsyncLoadTestConfigurationScene />} />
                  <Route path="members" element={<AsyncLoadTestMembersScene />} />
                  <Route path="resources" element={<AsyncLoadTestResourcesScene />} />
                  <Route path="auditLog" element={<AsyncLoadTestAuditLogScene />} />
                  <Route path="comparison" element={<AsyncLoadTestComparisonReportsScene />} />
                  <Route path="documentation" element={<AsyncProjectDocumentationScene />} />
                  <Route path="loadTests/*" element={
                    <React.Fragment>
                      <SubHeaderSlotContent header="navigation.subheader.loadTests" />
                      <Routes>
                        <Route index element={<AsyncLoadTestsScene />} />
                        <Route path=":ltid" element={<AsyncLoadTestsScene />} />
                      </Routes>
                    </React.Fragment>
                  } />
                </Routes>
              </RequiresLoadTestingProject>
            </ProjectRoute>
          } />
        </Route>
      </Route>
      <Route path="/projects" element={<AsyncProjectsScene />} />
      <Route path="*" element={<AsyncNotFoundScene />} />
    </Route>
  </Routes >
);


function AppRoot() {
  return (
    <UserRoute enforceLogout={pathRequiresLoggedOut}>
      {(loggedIn) =>
        <IndexScene>
          {loggedIn
            ? loggedInRoutes
            :
            <Routes>
              <Route path="/login" element={<AsyncLoginScene />} />
              <Route path="/register" element={<AsyncCreateAccountScene />} />
              <Route path="/register_external" element={<AsyncRegisterExternalAccountScene />} />
              <Route path="/register_invitation/:token" element={<AsyncCreateAccountInvitationScene />} />
              <Route path="/signupComplete/:token" element={<AsyncAccountActivationScene />} />
              <Route path="/resetPassword" element={<AsyncResetPasswordScene />} />
              <Route path="/newPassword" element={<AsyncNewPasswordScene />} />
              <Route path="/exploreApi" element={<AsyncApiExplorerScene apiExplorerProps={{ renderStandalone: true }} />} />
              <Route path="/*" element={<Navigate to="/login" />} />
            </Routes>
          }
        </IndexScene>
      }</UserRoute>

  );
}

export const AppRouter = createBrowserRouter([{ path: "*", Component: AppRoot }]);
