/**
 * Error handled by some application response handler.
 */
export default class HandledResponseError extends Error {
  public readonly name = "HandledResponseError";

  private _status: number;

  public constructor(status: number, message: string) {
    super(message);
    this._status = status;
  }

  public get status() {
    return this._status;
  }

  public toString() {
    return `${this.name}: ${this.message} [${this._status}]`;
  }
};
