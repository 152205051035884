import * as React from "react";

import Error from "components/error/Error";
import Loading from "components/loading/Loading";

type Props<P> = P & {
  load: (onLoaded: (component: React.ComponentType<P>) => void, onError: (reason: any) => void) => void
};

type State<P> = {
  component?: React.ComponentType<P>;
  error?: any;
  showLoading: boolean;
};

export { AsyncComponent };
export default class AsyncComponent<P> extends React.PureComponent<Props<P>, State<P>> {

  private _timer: Timer | null;
  private _isMounted: boolean;

  public constructor(props: Props<P>) {
    super(props);

    this._timer = null;
    this._isMounted = false;
    this.state = { showLoading: false };
  }

  public render(): React.ReactNode {
    const { component: Component, showLoading, error } = this.state;
    if (Component) {
      return <Component {...this.props} />;
    }
    if (showLoading) {
      return <Loading />;
    }
    if (error) {
      return <Error modal message={(typeof error === "string") ? error : "application.error.pageload.failure"} />;
    }
    return null;
  }

  private _clearTimer(): void {
    if (this._timer) {
      clearTimeout(this._timer);
    }
    this._timer = null;
  }


  public componentDidMount() {
    this._isMounted = true;
    this._timer = setTimeout(() => {
      if (this._isMounted) {
        this.setState((prevState) => ({ showLoading: !prevState.component }));
      }
    }, 200);

    this.props.load(
      (component) => {
        this._clearTimer();
        if (this._isMounted) {
          this.setState({ component, showLoading: false });
        }
      },
      (reason) => {
        this._clearTimer();
        if (this._isMounted) {
          this.setState({ showLoading: false, error: reason });
        }
      });
  }

  public componentWillUnmount() {
    this._clearTimer();
    this._isMounted = false;
  }
}

export function asyncComponent<P>(
  load: () => Promise<React.ComponentType<P>>,
  name?: string
): React.ComponentClass<P> {
  return class AsyncComponentWrapper extends React.PureComponent<P> {
    public render(): React.ReactElement<P> {
      // In case 1st attempt to load component has failed, try to load it again
      // Finally, let promise invoke proper handler
      return <AsyncComponent load={(onLoaded, onError) => load().catch(() => load()).then(onLoaded, onError)} {...this.props} />;
    }

    public static displayName = name || "AsyncComponentWrapper";
  };
}
