import { action, computed, makeObservable, observable } from "mobx";

import { SystemAdminAPI } from "app/admin/SystemAdminAPI";
import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { TenantState } from "app/core/tenant/store/TenantState";
import { SaveTenantData, SystemTenantData } from "app/core/tenant/types";

type State = {
  readonly tenants: TenantState[];
  readonly tenantsTotal: number;
  readonly tenantsFiltered: number;
};

class AdminTenantStore extends AbstractState<State> implements State {

  private static readonly initialState: State = { tenants: [], tenantsTotal: 0, tenantsFiltered: 0 };

  @observable public readonly tenants: TenantState[];
  @observable public readonly tenantsTotal: number;
  @observable public readonly tenantsFiltered: number;

  public readonly appStores: AppStores;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState(AdminTenantStore.initialState));

    this.appStores = appStores;
  }

  public initStore(): void {
    this.setState(AdminTenantStore.initialState);
  }

  @computed
  public get systemTenants(): TenantState[] {
    return this.tenants;
  }

  public findTenant(id: string): TenantState | null {
    return this.appStores.tenantsStore.findTenant(id);
  }

  @action.bound
  public putTenant(prepend: boolean, tenant: SystemTenantData): TenantState {
    const index = this.tenants.findIndex((each) => each._id === tenant._id);
    const tenantState = this.appStores.tenantsStore.putTenant(tenant);
    if (index > -1) {
      this.tenants[index] = tenantState;
      return this.tenants[index];
    } else {
      if (prepend) {
        this.tenants.splice(0, 0, tenantState);
        return this.tenants[0];
      } else {
        this.tenants.push(tenantState);
        return this.tenants[this.tenants.length - 1];
      }
    }
  }

  @action.bound
  public putTenants(append: boolean, tenants: SystemTenantData[]): TenantState[] {
    if (!append) {
      this.setState({ tenants: [] });
    }
    return tenants.map((each) => this.putTenant(false, each));
  }

  @action.bound
  public removeTenant(id: string): void {
    const index = this.tenants.findIndex((each) => each._id === id);
    if (index > -1) {
      this.tenants.splice(index, 1);
      this.appStores.tenantsStore.removeTenant(id);
    }
  }

  @action.bound
  public addOrUpdateTenants(tenants: SystemTenantData[]): TenantState[] {
    return tenants.map((each) => this.putTenant(false, each));
  }

  @action.bound
  public async fetchTenants(
    append: boolean, start: number, count: number, paid: boolean, sortBy: string, search: string, softDeleted: boolean
  ): Promise<void> {
    const result = await SystemAdminAPI.listTenants(start, count, paid, sortBy, search, softDeleted);
    if (result.success && result.data) {
      this.putTenants(append, result.data.tenants);
      this.setState({
        tenantsTotal: result.data.total,
        tenantsFiltered: result.data.count
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async fetchAllTenants(): Promise<void> {
    const result = await SystemAdminAPI.listAllTenants();
    if (result.success && result.data) {
      this.putTenants(false, result.data.tenants);
      this.setState({
        tenantsTotal: result.data.total,
        tenantsFiltered: result.data.count
      });
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async pushTenant(payload: SaveTenantData)
    : Promise<TenantState> {
    const result = await SystemAdminAPI.createTenant(payload);
    if (result.success && result.data) {
      const tenantState = this.putTenant(true, result.data.tenant);
      this.setState({
        tenantsTotal: this.tenantsTotal + 1,
        tenantsFiltered: this.tenantsFiltered + 1
      });
      return tenantState;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateTenant(
    id: string, payload: SaveTenantData)
    : Promise<TenantState> {
    const tenant = this.findTenant(id);
    if (tenant) {
      const result = await SystemAdminAPI.saveTenantDetails(id, tenant._ver, payload);
      if (result.success && result.data) {
        tenant.setState(result.data.tenant);
        return tenant;
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    } else {
      throw new Error("No Tenant for id: " + id);
    }
  }

  @action.bound
  public async deleteTenant(id: string): Promise<void> {
    const tenant = this.findTenant(id);
    if (tenant) {
      const result = await SystemAdminAPI.removeTenant([{ id, version: tenant._ver }]);
      if (result.success) {
        this.removeTenant(id);
        this.setState({
          tenantsTotal: this.tenantsTotal - 1,
          tenantsFiltered: this.tenantsFiltered - 1
        });
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    }
  }

  @action.bound
  public async restoreTenant(tenantId: string): Promise<void> {
    const result = await SystemAdminAPI.restoreTenant(tenantId);
    if (result.success && result.data) {
      this.removeTenant(result.data._id);
      this.setState({
        tenantsTotal: this.tenantsTotal - 1,
        tenantsFiltered: this.tenantsFiltered - 1
      });
      this.appStores.tenantsStore.putTenant(result.data);
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }

  @action.bound
  public async updateTenantActive(id: string, active: boolean): Promise<void> {
    const tenant = this.findTenant(id);
    if (tenant) {
      const result = active
        ? await SystemAdminAPI.unlockTenants([{ id, version: tenant._ver }])
        : await SystemAdminAPI.lockTenants([{ id, version: tenant._ver }]);

      if (result.success && result.data) {
        this.addOrUpdateTenants(result.data);
      } else {
        throw ("errors" in result ? result.errors : result.message);
      }
    } else {
      throw new Error("No Tenant for id: " + id);
    }
  }

  @action.bound
  public async fetchUserSuggestions(search: string): Promise<string[]> {
    const result = await SystemAdminAPI.searchUser(search);
    if (result.success && result.data) {
      return result.data.matches;
    } else {
      throw ("errors" in result ? result.errors : result.message);
    }
  }
}

export { AdminTenantStore };
export default AdminTenantStore;
