import { ErrorMap, toMap } from "app/utils/error";
import RemoteService from "app/utils/remote/remote-service";

import HandledResponseError from "../error/HandledResponseError";

type Result = {};

export type FetchResult<T> = Result & T;

export type APIResult<P> = {
  success: boolean;
  message?: string;
  errors?: any[];
  data?: P;
};

export type APIResult2<D = undefined, E extends ErrorMap | undefined = undefined, M extends string | undefined = undefined> = {
  success: boolean
  message?: M
  errors?: E
  data?: D
};

export type AsyncAPIResult<D = undefined, E extends ErrorMap | undefined = undefined,
  M extends string | undefined = undefined> = Promise<APIResult2<D, E, M>>;

export type APIRequestMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface Request {
  url: string;
  method?: APIRequestMethod;
  data?: any;
  cache?: boolean;
  credentials?: "same-origin" | "include" | "omit";
  onError?: ResponseHandler;
  bypass?: boolean;
}

type APIRequestResult = { success: boolean, message?: string, data?: any, errors?: any };

/**
 * Callback signature of response handlers.
 * May return true to signal that response was successfully handled and stop further processing.
 */
export type ResponseHandler = (response: Response) => void | boolean;

export async function typedApiRequest<D extends any = undefined, E extends ErrorMap | undefined = undefined,
  M extends string | undefined = undefined>(
    url: string, method: APIRequestMethod, requestData?: any
  ): AsyncAPIResult<D, E, M> {

  const result = await fetch<APIRequestResult>({ url, method, data: requestData });
  const errors = Array.isArray(result.errors) ? toMap(result.errors) : result.errors;

  return {
    success: result.success,
    message: result.message as M,
    data: result.data as D,
    errors: errors as E
  };
}


const AppResponseHandlers: Record<string, ResponseHandler> = {};
export function addAppResponseHandler(id: string, handler: ResponseHandler) {
  if (!!id && !!handler) {
    AppResponseHandlers[id] = (AppResponseHandlers[id] || handler);
  }
}
export function removeAppResponseHandler(id: string) {
  if (!!id && id in AppResponseHandlers) {
    delete AppResponseHandlers[id];
  }
}
function invokeAppResponseHandlers(response: Response) {
  return Object.getOwnPropertyNames(AppResponseHandlers).some((id) => {
    const handler = AppResponseHandlers[id];
    if (!!handler) {
      return handler(response);
    }
  });
}

/**
 * Determines whether the given response holds JSON content.
 *
 * @param response the response content should be examined
 * @returns true in case the given response holds JSON content, false otherwise
 */
function isJsonContent(response: Response) {
  for (const header of response.headers.entries()) {
    const [name, value] = header;
    if (name.toLocaleLowerCase() === "content-type") {
      return /application\/([a-zA-Z0-9\.\-_]+\+)?json/.test(value);
    }
  }
  return false;
}

export function fetch<T extends Result>(request: Request): Promise<T> {
  const { url, ...options } = request;
  return RemoteService.doCall(url, options)
    .then((response) => {
      const { status } = response || { status: 0 };

      let handled = !options.bypass && !!invokeAppResponseHandlers(response);

      if (status !== 200) {
        if (options.onError) {
          handled = handled || !!options.onError(response);
        }

        if (!handled) {
          throw response;
        }

        throw new HandledResponseError(status, response.statusText);
      }

      return response.text()
        .then((t) => isJsonContent(response) ? JSON.parse(t) : { success: response.ok, statusCode: status, text: t });
    });
}
