import { asyncComponent } from "components/AsyncComponent";

const AsyncTenantResourcesScene = asyncComponent(() => import(
    /* webpackChunkName: "tenantResources" */ "app/core/tenant/scenes/TenantResourcesScene"
).then(({ default: scene }) => scene),
  "TenantResourcesScene"
);

export default AsyncTenantResourcesScene;
export { AsyncTenantResourcesScene };
