import { action, computed, makeObservable, observable } from "mobx";

import { AbstractState } from "app/core/store/AbstractState";
import { AppStores } from "app/core/store/AppStores";
import { TenantState } from "app/core/tenant/store/TenantState";
import { TenantAPI } from "app/core/tenant/TenantAPI";
import { TenantData } from "app/core/tenant/types";

interface State {
  readonly tenants: TenantState[];
  readonly currentTenant: TenantState | null;
}

class TenantStore extends AbstractState<State> implements State {

  private static readonly initialState: State = { tenants: [], currentTenant: null };

  public readonly appStores: AppStores;

  @observable public readonly tenants: TenantState[];
  @observable public readonly currentTenant: TenantState | null;

  public constructor(appStores: AppStores) {
    super();

    makeObservable(this.setState(TenantStore.initialState));

    this.appStores = appStores;
    this.currentTenant = null;
  }

  public initStore(): void {
    this.setState(TenantStore.initialState);
  }

  @computed
  public get myTenants() {
    return this.tenants.filter((each) => each.role !== "None" && !each.deleteDateTime);
  }

  @computed
  public get favoriteTenants(): TenantState[] {
    return this.myTenants.filter((each) => each.favorite);
  }

  public findTenant(id: string): TenantState | null {
    return this.tenants.find((each) => each._id === id) || null;
  }

  @action.bound
  public putTenant(tenant: TenantData): TenantState {
    const index = this.tenants.findIndex((each) => each._id === tenant._id);
    if (index > -1) {
      const fountTenant = this.tenants[index];
      fountTenant.setState(tenant);
      return this.tenants[index];
    } else {
      this.tenants.push(this.createTenantState(tenant));
      return this.tenants[this.tenants.length - 1];
    }
  }

  @action.bound
  public putTenants(tenants: TenantData[]): TenantState[] {
    return tenants.map((each) => this.putTenant(each));
  }

  @action.bound
  public removeTenant(id: string): void {
    const index = this.tenants.findIndex((each) => each._id === id);
    if (index > -1) {
      this.tenants.splice(index, 1);
    }
  }

  @action.bound
  public async fetchMyTenants(): Promise<void> {
    const result = await TenantAPI.listTenants();

    if (result.success && result.data) {
      this.putTenants(result.data);
    } else {
      throw new Error("tenant.details.list.error");
    }
  }

  @action.bound
  public async fetchMyTenant(id: string): Promise<TenantState> {
    const result = await TenantAPI.getTenantDetails(id);

    if (result.success && result.data) {
      return this.putTenant(result.data);
    }
    throw new Error(result.message ?? "tenant.details.list.error");
  }

  @action.bound
  public setCurrentTenant(tenant: TenantState | null) {
    this.setState({ currentTenant: tenant });
  }

  private createTenantState(tenant: TenantData): TenantState {
    return new TenantState(this.appStores, tenant);
  }

}

export { TenantStore };
export default TenantStore;
