import React from "react";

import clsx from "clsx";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";

import Message from "app/localization/components/Message";

const PREFIX = "CookieConsent";

const classes = {
  consentScreen: `${PREFIX}-consentScreen`,
  consentMessage: `${PREFIX}-consentMessage`
};

const StyledBox = styled(Box)({
  [`& .${classes.consentScreen}`]: {
    position: "absolute",
    bottom: "3rem",
    zIndex: 1000,
    padding: "1em"
  },
  [`& .${classes.consentMessage}`]: {
    marginBottom: "1em",
    maxWidth: "60em"
  }
});

export interface CookieConsentProps {
  onClose: () => void;
}

export const CookieConsent: React.FunctionComponent<CookieConsentProps> = (props) => (
  <StyledBox display="grid" gridTemplateColumns="100%" justifyItems="center">
    <Card className={clsx(classes.consentScreen, "xtc_cookieDialog")} elevation={5}>
      <DialogTitle><Message messageID="cookie.consent.title" /></DialogTitle>
      <DialogContent className={classes.consentMessage}>
        <Message messageID="cookie.consent" />
        <Link
          id="xtc_privacyPolicy"
          href="https://www.xceptance.com/en/contact/privacy.html"
          underline="hover">
          <Message messageID="cookie.consent.privacyPolicy" />
        </Link>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={props.onClose} className="xtc_accept">
          <Message messageID="cookie.consent.ok" /></Button>
      </DialogActions>
    </Card>
  </StyledBox>
);
