import { asyncComponent } from "components/AsyncComponent";

const AsyncLoadTestResourcesScene = asyncComponent(
  () => import(/* webpackChunkName: "loadTestResources" */"app/lt/project/scenes/LoadTestResourcesScene")
    .then(({ default: scene }) => scene),
  "LoadTestResourcesScene"
);

export { AsyncLoadTestResourcesScene };
export default AsyncLoadTestResourcesScene;
