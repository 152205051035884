import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringExportsScene = asyncComponent(
  () => import(/* webpackChunkName: "projectExports" */ "app/mon/project/scenes/MonitoringExportsScene")
    .then(({ default: scene }) => scene),
  "MonitoringExportsScene"
);

export { AsyncMonitoringExportsScene };
export default AsyncMonitoringExportsScene;
