import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringConfigurationScene = asyncComponent(
  () => import(/* webpackChunkName: "projectConfig" */ "app/mon/project/scenes/MonitoringConfigurationScene")
    .then(({ default: scene }) => scene),
  "MonitoringConfigurationScene"
);

export { AsyncMonitoringConfigurationScene };
export default AsyncMonitoringConfigurationScene;
