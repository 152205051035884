import { ProjectData, ProjectStates, RepositoryAuthentication, RepositorySettings, RepositoryStaticBranch } from "app/core/project/types";
import { APIResult, fetch } from "app/utils/remote";

import { BuildToolSettings, GlobalSharingPresets, LoadTestProjectData, LoadTestProjectStatusData } from "./types";

type RepositorySettingsResult = {
  _ver: number;
  repository: RepositorySettings;
};

type BuildToolSettingsResult = {
  _ver: number;
  build: BuildToolSettings;
};

type PropertiesSettingsResult = {
  _ver: number;
  properties: string;
  secretProperties: string;
};

type ShareConfigResult = {
  _ver: number;
  sharingPresets: GlobalSharingPresets
};

export class LoadTestProjectAPI {

  public static createProject(tenantId: string, name: string, baseShortName: string): Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({ url: `/api/lt/tenants/${tenantId}/projects`, method: "POST", data: { name, baseShortName } });
  }

  public static saveProjectDetails(
    projectId: string, projectVersion: number,
    name: string, baseShortName: string, description: string, state: ProjectStates, color: string | undefined, adminUser: string)
    : Promise<APIResult<ProjectData>> {
    return fetch<APIResult<ProjectData>>({
      url: `/api/core/projects/${projectId}/edit`,
      method: "PUT",
      data: { version: projectVersion, name, baseShortName, description, state, color, adminUser }
    });
  }

  public static saveRepositorySettings(
    projectId: string,
    version: number,
    payload: RepositorySettings
  ): Promise<APIResult<RepositorySettingsResult>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/config/repository`,
      method: "PUT",
      data: { ...payload, version }
    });
  }

  public static saveRepositoryAuthentication(
    projectId: string,
    version: number,
    payload: { auth: RepositoryAuthentication | null }
  ): Promise<APIResult<RepositorySettingsResult>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/config/repositoryAuth`,
      method: "PUT",
      data: { ...payload, version }
    });
  }

  public static saveRepositoryBranch(
    projectId: string,
    version: number,
    payload: RepositoryStaticBranch
  ): Promise<APIResult<RepositorySettingsResult>> {
    return fetch({
      url: `/api/lt/projects/${projectId}/config/repositoryBranch`,
      method: "PUT",
      data: { branch: payload, version }
    });
  }

  public static saveBuildToolSettings(
    projectId: string,
    version: number,
    settings: BuildToolSettings
  ): Promise<APIResult<BuildToolSettingsResult>> {
    return fetch<APIResult<BuildToolSettingsResult>>({
      url: `/api/lt/projects/${projectId}/config/buildTool`,
      method: "PUT",
      data: { ...settings, version }
    });
  }

  public static discardBuildDependencyCache(projectId: string): Promise<APIResult<never>> {
    return fetch<APIResult<never>>({
      url: `/api/lt/projects/${projectId}/config/buildDependencyCache`,
      method: "DELETE"
    });
  }

  public static updateShareReportsConfig(
    projectId: string,
    version: number,
    enabled: boolean,
    validToMilliseconds: number | undefined
  ): Promise<APIResult<ShareConfigResult>> {
    return fetch<APIResult<ShareConfigResult>>({
      url: `/api/lt/projects/${projectId}/config/sharing/reports`,
      method: "PUT",
      data: {
        version,
        enabled,
        validToMilliseconds
      }
    });
  }

  public static updateShareResultsConfig(
    projectId: string,
    version: number,
    enabled: boolean,
    validToMilliseconds: number | undefined
  ): Promise<APIResult<ShareConfigResult>> {
    return fetch<APIResult<ShareConfigResult>>({
      url: `/api/lt/projects/${projectId}/config/sharing/results`,
      method: "PUT",
      data: {
        version,
        enabled,
        validToMilliseconds
      }
    });
  }

  public static updateDefaultXltVersion(
    projectId: string,
    version: number,
    defaultXltVersionId: string
  ): Promise<APIResult<LoadTestProjectData>> {
    return fetch<APIResult<LoadTestProjectData>>({
      url: `/api/lt/projects/${projectId}/config/defaultXltVersion`,
      method: "PUT",
      data: {
        version,
        defaultXltVersionId
      }
    });
  }

  public static deleteCustomReportShares(
    projectId: string
  ): Promise<APIResult<string[]>> {
    return fetch<APIResult<string[]>>({
      url: `/api/lt/projects/${projectId}/reports/shares`,
      method: "DELETE"
    });
  }

  public static deleteCustomResultShares(
    projectId: string
  ): Promise<APIResult<string[]>> {
    return fetch<APIResult<string[]>>({
      url: `/api/lt/projects/${projectId}/results/shares`,
      method: "DELETE"
    });
  }

  public static updateProperties(
    projectId: string,
    version: number,
    properties?: string,
    secretProperties?: string): Promise<APIResult<PropertiesSettingsResult>> {
    return fetch<APIResult<PropertiesSettingsResult>>({
      url: `/api/lt/projects/${projectId}/config/properties`,
      method: "PUT",
      data: { properties, secretProperties, version }
    });
  }

  public static loadProjectStates(projectIds: string[]) {
    return fetch<APIResult<LoadTestProjectStatusData>>({
      method: "POST", // Use POST as request method as payload might become too large for URL query
      url: "/api/lt/projects/status",
      data: { ids: projectIds }
    });
  }
}
