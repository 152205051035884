import "rapidoc";

import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ApiVersionSelect from "components/forms/ApiVersionSelect";

import { OpenApiSpecList } from "app/core/apiExplorer/types";
import { SubHeaderSlot, SubHeaderSlotContent } from "app/core/components/SubHeaderSlot";
import Message from "app/localization/components/Message";
import useThemeColors from "app/utils/color/useThemeColors";

const renderStyles: string[] = ["focused", "read", "view"];

function nextRenderStyleIndex(renderStyleIndex: number) {
  return renderStyleIndex + 1 >= renderStyles.length ? 0 : renderStyleIndex + 1;
}

type Props = {
  renderStandalone?: boolean;
  specList?: OpenApiSpecList;
};
const ApiExplorer: React.FunctionComponent<Props> = (props) => {
  const specs = props.specList?.specs || [];
  const themeColors = useThemeColors();

  const [selectedSpecId, setSelectedSpecId] = React.useState<string>(
    (specs.find((each) => each.apiVersionState === "LATEST")?.apiVersionId) || ""
  );
  const [renderStyleIndex, setRenderStyle] = React.useState(0);

  const selectedSpec = specs.find((each) => each.apiVersionId === selectedSpecId);

  return (
    <React.Fragment>
      {props.renderStandalone && <SubHeaderSlot />}

      <SubHeaderSlotContent
        header="navigation.subheader.exploreApi"
        actionItems={
          <React.Fragment>
            <Button
              size="small"
              color="primary"
              onClick={() => setRenderStyle(nextRenderStyleIndex(renderStyleIndex))}
            >
              <Message messageID={`ApiExplorer.renderStyle.${renderStyles[nextRenderStyleIndex(renderStyleIndex)]}`} />
            </Button>

            <Box marginLeft={1}>
              <ApiVersionSelect
                apiVersionId={selectedSpecId}
                supportedApiVersions={specs.map((each) => ({
                  id: each.apiVersionId,
                  state: each.apiVersionState
                }))}
                onApiVersionChange={setSelectedSpecId}
                disableFormField={true}
                selectBoxProps={{ filled: false, size: "small" }}
              />
            </Box>
          </React.Fragment>
        }
      />

      <rapi-doc
        spec-url={selectedSpec?.specPath || "/public/api"}
        allow-authentication={selectedSpec?.apiVersionState !== "LEGACY"}
        allow-try={selectedSpec?.apiVersionState !== "LEGACY"}
        persist-auth="true"
        render-style={renderStyles[renderStyleIndex]}
        theme="light"
        show-header="false"
        show-info="false"
        allow-server-selection="false"
        show-method-in-nav-bar="as-colored-text"
        default-schema-tab="schema"
        schema-description-expanded="true"
        load-fonts="false"
        primary-color={themeColors.theme.palette.primary.main}
        nav-bg-color={themeColors.theme.palette.background.paper}
        style={{
          height: "100vh",
          width: "100%",
          overflow: "auto"
        }}
      />
    </React.Fragment>
  );
};

export default ApiExplorer;
export { Props as ApiExplorerProps };
