import * as React from "react";

import { useObserver } from "mobx-react";

import Cookies from "js-cookie";

import Box from "@mui/material/Box";

import { NotificationContainer } from "components/error/NotificationContainer";

import Footer from "app/core/components/Footer";
import { getErrorFromCookie, resetErrorCookie } from "app/utils/cookies";
import { useNotifications } from "app/utils/error/notifications";

import { CookieConsent } from "../components/CookieConsent";
import { MessagesFetcher } from "../message/components/MessagesFetcher";
import useAppStores from "../store/useStore";

type Props = {};

const COOKIE_CONSENT_COOKIE = "xtc-cookie-consent";

const Component: React.ComponentType<Props> = (props) => {
  const { userStore, notificationStore } = useAppStores();
  const notifications = useNotifications();

  const remoteError = getErrorFromCookie();
  resetErrorCookie();
  if (remoteError) {
    notifications.error(remoteError);
  }

  return useObserver(() => {
    const loggedIn = userStore.isLoggedIn;

    const [showCookieConsent, setShowCookieConsent] = React.useState(!Cookies.get(COOKIE_CONSENT_COOKIE));

    return (
      <Box height="100vh" width="100vw" display="flex" flexDirection="column" overflow="auto">
        <MessagesFetcher updateSeconds={60} />

        <NotificationContainer notifications={notificationStore.notifications} onClose={notificationStore.remove} />

        {!loggedIn
          ? <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="space-between" overflow="auto">
            {props.children}
            <Footer />
          </Box>
          :
          props.children
        }

        {showCookieConsent &&
          <CookieConsent onClose={() => {
            setShowCookieConsent(false);
            Cookies.set(COOKIE_CONSENT_COOKIE, "true", { expires: 30 });
          }} />
        }

      </Box>);
  });
};


export { Component as IndexScene };
export default Component;
