import { AsyncAPIResult, typedApiRequest } from "app/utils/remote";
import { Message } from "./types";

export default class MessageAPI {

  private static SERVICE_PATH = "/api/core/messages";

  public static async fetchMessages(): AsyncAPIResult<{ messages: Message[] }> {
    return typedApiRequest(this.SERVICE_PATH, "GET");
  }

  public static async markMessageRead(id: string, version: number): AsyncAPIResult<Message> {
    return typedApiRequest(`${this.SERVICE_PATH}/${id}`, "PUT", { read: true, version });
  }

}

