import { asyncComponent } from "components/AsyncComponent";

const AsyncLoadTestComparisonReportsScene = asyncComponent(
  () => import(/* webpackChunkName: "loadTestComparisonReports" */"app/lt/project/scenes/LoadTestComparisonReportsScene")
    .then(({ default: scene }) => scene),
  "LoadTestComparisonReportsScene"
);

export { AsyncLoadTestComparisonReportsScene };
export default AsyncLoadTestComparisonReportsScene;
