import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText, { DialogContentTextProps } from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type Callback<T = void> = () => T;

export type Props = Partial<{
  title: React.ReactNode;
  disableKeyboardActions: boolean;
  acceptAutofocus: boolean;
  acceptColor: "primary" | "secondary";
  acceptEnabled: boolean;
  acceptIcon: React.ReactNode;
  acceptLabel: React.ReactNode;
  loadingLabel: React.ReactNode;
  loadingIcon: React.ReactNode;
  cancelAutofocus: boolean;
  cancelIcon: React.ReactNode;
  cancelLabel: React.ReactNode;
  content: React.ReactNode;
  contentProps: DialogContentTextProps;
  contentSized: boolean;
  noMaxWidth: boolean;
  onAccept: Callback<void | Promise<unknown>>;
  onClose: Callback;
  visible: boolean;
}>;
export { Props as ResponsiveDialogProps };

const ResponsiveDialog: React.FunctionComponent<Props> = (props) => {

  const {
    onAccept,
    onClose,
    acceptColor = "primary",
    acceptLabel,
    loadingLabel,
    loadingIcon,
    cancelLabel,
    acceptEnabled,
    title,
    acceptIcon,
    cancelIcon,
    content,
    contentProps,
    visible,
    contentSized,
    acceptAutofocus,
    cancelAutofocus,
    disableKeyboardActions
  } = props;

  const hasCancel = !!(cancelIcon || cancelLabel);
  const hasAccept = !!(acceptIcon || acceptLabel);
  const hasAcceptCancelAutofocus = hasCancel && hasAccept && acceptAutofocus && cancelAutofocus;

  const [accepted, setAccepted] = React.useState<boolean>(false);
  const _mounted = React.useRef<boolean>(false);
  const theme = useTheme();
  const modal = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    // Remember component is mounted
    _mounted.current = true;
    // (Re)Initialize 'accepted' state
    setAccepted(false);

    return () => {
      // Remember component is NOT mounted anymore
      _mounted.current = false;
    };
  }, []);

  const accept = () => {
    if (!accepted && acceptEnabled !== false) {
      // mark dialog as accepted to prevent multi-accepts
      setAccepted(true);

      const _retValue = onAccept?.();
      const _onCompleted = () => {
        if (_mounted.current) {
          setAccepted(false);
        }
      };
      // check type of returned value -> if not 'undefined' it must be promise
      if (typeof _retValue !== "undefined") {
        // re-allow accept once promise has settled
        _retValue.finally(_onCompleted);
      } else {
        // re-allow accept after 500ms when 'onAccept' did not return a promise
        setTimeout(_onCompleted, 500);
      }
    }
  };

  const cancel = () => {
    if (!accepted) {
      onClose?.();
    }
  };

  const aIcon = accepted ? (loadingIcon || acceptIcon) : acceptIcon;
  const aLabel = accepted ? (loadingLabel || acceptLabel) : acceptLabel;
  return (
    <Dialog
      fullScreen={!modal}
      maxWidth={props.noMaxWidth ? false : "sm"}
      open={!!visible}
      onClose={(_event: any, reason?: "escapeKeyDown" | "backdropClick") => {
        if (!disableKeyboardActions && reason !== "backdropClick") {
          cancel();
        }
      }}
      aria-labelledby="responsive-dialog-title"
      className="xtc_dialog"
      onKeyDown={disableKeyboardActions ? undefined :
        (e) => {
          const { target, key } = e;
          switch (key) {
            case "Esc":
            case "Escape":
              e.stopPropagation();
              cancel();
              break;
            case "Enter":
              if (!(target && (target as any).localName === "textarea")) {
                e.stopPropagation();
                accept();
              }
              break;
          }
        }}
      PaperProps={{
        style: {
          flexGrow: !contentSized ? 1 : undefined
        }
      }}
    >
      {title && <DialogTitle className="xtc_title" id="responsive-dialog-title">{title}</DialogTitle>}
      <DialogContent className="xtc_content">
        {content && <DialogContentText {...contentProps}>{content}</DialogContentText>}
        {props.children}
      </DialogContent>
      <DialogActions>
        {hasCancel &&
          <Button className="xtc_close" variant="text" color="primary"
            disabled={accepted}
            onClick={cancel}
            onKeyDown={(e) => e.key === "Enter" && e.stopPropagation()}
            autoFocus={hasAcceptCancelAutofocus ? undefined : cancelAutofocus}
          >
            {cancelIcon}
            {cancelLabel}
          </Button>
        }

        {hasAccept &&
          <Button className="xtc_accept" variant="contained" color={acceptColor}
            disabled={accepted || acceptEnabled === false}
            autoFocus={hasAcceptCancelAutofocus ? undefined : acceptAutofocus}
            onClick={accept}
            onKeyDown={(e) => e.key === "Enter" && e.stopPropagation()}
          >
            {aIcon}
            {aLabel}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default ResponsiveDialog;
