
export function rgbColorToHex(color: string) {
    const match = color.match(/rgba?\((.+)\)/);
    if (match) {
        const colorSplit = match[1].replace(" ", "").split(",");
        const rHex = parseInt(colorSplit[0], 10).toString(16).padStart(2, "0");
        const gHex = parseInt(colorSplit[1], 10).toString(16).padStart(2, "0");
        const bHex = parseInt(colorSplit[2], 10).toString(16).padStart(2, "0");

        return `#${rHex}${gHex}${bHex}`;
    }
    throw Error("Color is not in the form of a css rgb(a) color definition");
}

export function hexColorToRGBA(hexCode: string, opacity: number = 1): string {
    let hex = hexCode.replace("#", "");

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
};
