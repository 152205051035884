import * as React from "react";
import { useIntl } from "react-intl";

import clsx from "clsx";

import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";

import NotificationMessage from "./NotificationMessage";
import { FormattedMessage, NotificationStatus } from "./types";

const PREFIX = "XtcAlert";

const classes = {
  alert: `${PREFIX}-alert`,
  snackbar: `${PREFIX}-snackbar`
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .${classes.alert}`]: {
    ...theme.typography.body1,
    margin: 0,
    padding: 0,
    maxWidth: "65ch"
  },

  [`&.${classes.snackbar}`]: {
    top: theme.spacing(10)
  }
}));

type Props = {
  modal?: boolean,
  message: FormattedMessage | FormattedMessage[],
  onClose?: () => void,
  closer?: boolean,
  status?: NotificationStatus
};

export const Notification: React.FunctionComponent<Props> = (props) => {
  const [visible, setVisible] = React.useState(true);

  const intl = useIntl();

  const _onClose = (): void => {
    setVisible(false);
    props.onClose?.();
  };

  const { status, modal, message, closer } = props;

  const items = Array.isArray(message) ? [...message] : [message];

  if (!(visible && items && items.length)) {
    return null;
  }

  const msgs = (
    items.map((m, i) => {
      let key, values;
      if (typeof m === "string") {
        key = m;
      } else {
        key = m.key;
        values = m.values;
      }

      const msg = intl.formatMessage({ id: key, defaultMessage: key }, values);

      return (
        <NotificationMessage className={clsx(classes.alert, "xtc_notificationMessage")}
          status={status}
          key={key}
          closer={closer}
          onClose={_onClose}
          message={msg}
        />
      );
    })
  );

  if (modal) {
    return (
      <Dialog open={true}>
        {msgs}
      </Dialog>
    );
  }

  return (
    <StyledSnackbar
      open={true}
      autoHideDuration={15000}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      onClose={_onClose}
      className={classes.snackbar}
    >
      <div>
        {msgs}
      </div>
    </StyledSnackbar>
  );
};

export default Notification;
