import { APIResult, fetch } from "app/utils/remote";
import { LoadTestEvaluation, LoadTestData } from "./types";


export default class LoadTestEvaluationAPI {

  public static saveLoadTestEvaluation(
    projectId: string, loadTestId: string, loadTestVersion: number, evaluation: LoadTestEvaluation): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/evaluation?version=${loadTestVersion}`,
      method: "PUT",
      data: evaluation
    });
  }

  public static deleteLoadTestEvaluation(
    projectId: string, loadTestId: string, loadTestVersion: number): Promise<APIResult<LoadTestData>> {

    return fetch({
      url: `/api/lt/projects/${projectId}/loadTests/${loadTestId}/evaluation?version=${loadTestVersion}`,
      method: "DELETE"
    });
  }
}
