import * as React from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";

import { observer } from "mobx-react";

import { hasSystemRole } from "app/admin/utils/roleValidation";
import useAppStores from "app/core/store/useStore";

type SysAdminContext = { isSysAdmin: boolean; isSysBillingAdmin: boolean };
export function useSysAdmin() {
  return useOutletContext<SysAdminContext>();
};

const SysAdminRoute: React.ComponentType<{}> = observer(() => {
  const { userStore } = useAppStores();

  const user = userStore.user;
  const sysAdmin = user ? hasSystemRole(user.role, "Admin") : false;
  const sysBillingAdmin = user ? hasSystemRole(user.role, "BillingAdmin") : false;
  if (sysAdmin || sysBillingAdmin) {
    return <Outlet context={{ isSysAdmin: sysAdmin, isSysBillingAdmin: sysBillingAdmin }} />;
  } else {
    return <Navigate to="/" />;
  }
});

export { SysAdminRoute };
export default SysAdminRoute;

export const RequiresSysAdmin: React.FunctionComponent = () => {
  const { isSysAdmin } = useSysAdmin();
  return isSysAdmin ? <Outlet /> : null;
};
export const RequiresSysBillingAdmin : React.FunctionComponent = () => {
  const { isSysBillingAdmin } = useSysAdmin();
  return isSysBillingAdmin ? <Outlet /> : null;
};
