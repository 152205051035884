import { asyncComponent } from "components/AsyncComponent";

const AsyncLoadTestConfigurationScene = asyncComponent(
  () => import(
    /* webpackChunkName: "loadTestConfiguration" */ "app/lt/project/scenes/LoadTestConfigurationScene"
  ).then(({ default: scene }) => scene),
  "LoadTestConfigurationScene"
);

export { AsyncLoadTestConfigurationScene };
export default AsyncLoadTestConfigurationScene;
