import * as React from "react";
import { useIntl } from "react-intl";

import clsx from "clsx";
import * as Moment from "moment";

import Box from "@mui/material/Box";

import DateTimeInput, { Props as DateTimeInputProps } from "components/forms/DateTimeInput";

import Message from "app/localization/components/Message";

type TimeRangeLabel = {
  from: { message: string, title?: string },
  to: { message: string, title?: string }
};

type Props = {
  from?: number;
  to?: number;
  onTimeChange: {
    setFrom: (from: number) => void;
    setTo: (to: number) => void;
  }

  min?: number;
  max?: number;
  onChangeCommitted?: {
    commitFrom: (from: number) => void;
    commitTo: (to: number) => void;
  }
  label?: TimeRangeLabel;
  /**
   * Controls whether variant 'filled' or variant 'standard' is used for date-time text field.
   * Will be ignored when 'textFieldProps.[from|to].variant' is given.
   */
  filled?: boolean;
  shortcuts?: Record<"from" | "to", DateTimeInputProps["shortcuts"]>;
  textFieldProps?: Record<"from" | "to", DateTimeInputProps["textFieldProps"]>;
} & Omit<DateTimeInputProps, "defaultValue" | "label" | "value"
  | "onChange" | "onClose" | "minDate" | "maxDate" | "minTime"
  | "maxTime" | "shortcuts" | "textFieldProps"
>;

const TimeRangePicker: React.FunctionComponent<Props> = (props) => {
  const {
    from,
    to,
    min,
    max,
    onTimeChange,
    onChangeCommitted,
    label,
    className,
    filled = true,
    shortcuts,
    textFieldProps,
    ...rest
  } = props;

  const intl = useIntl();
  const rangeError = React.useMemo(
    () => (to ?? 0) < (from ?? 0) ? <Message messageID="filter.time.error.fromAfterTo" /> : undefined,
    [from, to]
  );

  const start = from ?? min ?? null;
  const end = to ?? max ?? null;
  const fromFieldProps = textFieldProps?.from;
  const toFieldProps = textFieldProps?.to;
  const fallbackVariant = filled ? "filled" : "standard";
  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className={clsx("xtc_timeRangePicker", className)}
      >
        <DateTimeInput
          className="xtc_from"
          label={label &&
            <Box title={label.from.title && intl.formatMessage({ id: label.from.title })}>
              <Message messageID={label.from.message} />
            </Box>
          }
          value={start === null ? start : Moment.utc(start)}
          onChange={(val) => val && onTimeChange.setFrom(val.valueOf())}
          onAccept={
            (fromDate) => fromDate && (onChangeCommitted ?
              onChangeCommitted.commitFrom(fromDate.valueOf())
              : onTimeChange.setFrom(fromDate.valueOf()))
          }
          minDate={min !== undefined ? Moment.utc(min) : undefined}
          maxDate={max !== undefined ? Moment.utc(max) : undefined}
          shortcuts={shortcuts?.from}
          textFieldProps={{
            ...fromFieldProps,
            variant: fromFieldProps?.variant ?? fallbackVariant
          }}
          sx={{ paddingRight: 0.5 }}
          {...rest}
        />
        <Box px={1}>&nbsp;</Box>
        <DateTimeInput
          className="xtc_to"
          label={label &&
            <Box title={label.to.title && intl.formatMessage({ id: label.to.title })}>
              <Message messageID={label.to.message} />
            </Box>
          }
          value={end === null ? end : Moment.utc(end)}
          onChange={(val) => val && onTimeChange.setTo(val.valueOf())}
          onAccept={
            (toDate) => toDate && (onChangeCommitted ?
              onChangeCommitted.commitTo(toDate.valueOf())
              : onTimeChange.setTo(toDate.valueOf()))
          }
          minDate={min !== undefined ? Moment.utc(min) : undefined}
          maxDate={max !== undefined ? Moment.utc(max) : undefined}
          shortcuts={shortcuts?.to}
          textFieldProps={{
            ...toFieldProps,
            error: toFieldProps?.error ?? (!!rangeError || undefined),
            helperText: toFieldProps?.helperText ?? rangeError,
            variant: toFieldProps?.variant ?? fallbackVariant
          }}
          sx={{ paddingLeft: 0.5 }}
          {...rest}
        />
      </Box>

    </React.Fragment>
  );
};

export default TimeRangePicker;
export { TimeRangePicker };
