import * as React from "react";

import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";

import InputHandler from "components/InputHandler";

import { Message } from "app/localization/components/Message";

const DEFAULT_ACCEPT_STYLE = "auto";
const DEFAULT_ACCEPT_COLOR = "primary";

type Callback<T = void> = () => T;

export type FormProps = Partial<{
  acceptIcon: React.ReactNode;
  acceptLabel: string;
  acceptStyle: "auto" | "shrink" | "full";
  acceptColor: ButtonProps["color"];
  acceptEnabled: boolean;
  onAccept: Callback<void | Promise<unknown>>;

  cancelColor: ButtonProps["color"];
  cancelLabel: string;
  cancelVariant: ButtonProps["variant"];
  onCancel: Callback;

  buttonLayout: "start" | "end" | "justify";

  plain: boolean;
  stopPropagation: boolean;

  focusFieldIndex: number;

  loadingLabelID: string | React.ReactNode;
  loadingIcon: React.ReactNode;
}>;

function translate(arg: string | React.ReactNode): React.ReactNode {
  if (typeof arg === "string") {
    return (<Message messageID={arg} />);
  }
  return arg;
}
export const Form: React.FunctionComponent<FormProps> = (props) => {
  const { acceptEnabled, acceptLabel, onAccept, onCancel } = props;

  const theme = useTheme();
  const [accepted, setAccepted] = React.useState<boolean>(false);

  const _mounted = React.useRef<boolean>();
  const fields: React.RefObject<HTMLFormElement> = React.createRef();


  React.useEffect(() => {
    // Remember component is mounted
    _mounted.current = true;
    // (Re)Initialize 'accepted' state
    setAccepted(false);

    return () => {
      // Remember component is NOT mounted anymore
      _mounted.current = false;
    };
  }, []);

  const accept = () => {
    if (!accepted && acceptEnabled !== false) {
      // mark dialog as accepted to prevent multi-accepts
      setAccepted(true);

      const _retValue = onAccept?.();
      const _onCompleted = () => {
        if (_mounted.current) {
          setAccepted(false);
        }
      };
      // check type of returned value -> if not 'undefined' it must be promise
      if (typeof _retValue !== "undefined") {
        // re-allow accept once promise has settled
        _retValue.finally(_onCompleted);
      } else {
        // re-allow accept after 500ms when 'onAccept' did not return a promise
        setTimeout(_onCompleted, 500);
      }
    }
  };

  const cancel = () => {
    if (!accepted) {
      onCancel?.();
    }
  };


  const getInput = (index?: number): HTMLInputElement | HTMLTextAreaElement | null => {
    const indexed = index ?? 0;
    const form = fields.current;
    if (form) {
      const inputs = form.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>("input,textarea");
      if (inputs.length > indexed) {
        return inputs[indexed];
      } else if (inputs.length > 0) {
        return inputs[0];
      }
    }
    return null;
  };

  const hasFormFocus = (): boolean => {
    const form = fields.current;
    if (form) {
      const inputs = form.querySelectorAll("input,textarea");
      for (const each of inputs) {
        if (document.activeElement === each) {
          return true;
        }
      }
    }
    return false;
  };

  React.useLayoutEffect(() => {
    const { focusFieldIndex } = props;
    const initialInput = getInput(focusFieldIndex);
    if (initialInput) {
      initialInput.focus();
    }

    let focusInterval: Timer | undefined;
    let focusTimeout: Timer | undefined;

    const clearTimers = () => {
      if (focusInterval) {
        clearInterval(focusInterval);
        focusInterval = undefined;
      }
      if (focusTimeout) {
        clearTimeout(focusTimeout);
        focusTimeout = undefined;
      }

    };

    if (!hasFormFocus()) {
      focusInterval = setInterval(() => {
        const input = getInput(focusFieldIndex);
        if (input) {
          input.focus();
        }
        if (hasFormFocus()) {
          clearTimers();
        }
      }, 50);
      focusTimeout = setTimeout(clearTimers, 1000);
    }

    return () => clearTimers();
  }, []);

  const canAccept = !!(acceptEnabled && onAccept);


  const acceptStyle = props.acceptStyle || DEFAULT_ACCEPT_STYLE;
  const acceptColor = props.acceptColor ?? DEFAULT_ACCEPT_COLOR;
  const buttonLayout = props.buttonLayout ?? "justify";

  const hasAccept = !!(props.acceptIcon || acceptLabel);
  const hasCancel = !!(props.cancelLabel);

  const aIcon = accepted ? (props.loadingIcon ?? props.acceptIcon) : props.acceptIcon;
  const aLabel = acceptLabel
    ? translate(accepted ? (props.loadingLabelID ?? "common.buttons.accept.loading") : acceptLabel)
    : undefined;


  const formElement = (
    <form ref={fields} className="xtc_form"
      onSubmit={(e: any) => {
        e.preventDefault();
        e.stopPropagation();

        accept();
      }}
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        {props.children}
      </Box>

      {(hasAccept || hasCancel) &&
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={buttonLayout === "justify" ? "space-between" : `flex-${buttonLayout}`}
          paddingY={1}
          margin={0.1}
          gap={buttonLayout === "justify" ? undefined : theme.spacing(2)}
        >
          {props.cancelLabel &&
            <Button
              className="xtc_cancel"
              variant={props.cancelVariant ?? "contained"}
              color={props.cancelColor ?? "secondary"}
              onClick={cancel}
            >
              <Message messageID={props.cancelLabel} />
            </Button>
          }

          {hasAccept &&
            <Button
              className="xtc_accept"
              variant="contained"
              disabled={accepted || !canAccept}
              type={canAccept ? "submit" : "button"}
              color={acceptColor}
              style={acceptStyle === "full" ? { maxWidth: "unset" } : undefined}
              fullWidth={acceptStyle === "full"}
            >
              {aIcon}
              {aLabel}
            </Button>
          }

        </Box>
      }
    </form>
  );

  const component = props.plain ? formElement : <Container fixed={true} maxWidth={false}>{formElement}</Container>;

  return (
    (!onCancel && !onAccept && !props.stopPropagation) ? component
      : <InputHandler stopPropagation={props.stopPropagation ?? !!(onCancel || onAccept)} onCancel={cancel}>
        {component}
      </InputHandler>

  );
};

export default Form;
