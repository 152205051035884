import { asyncComponent } from "components/AsyncComponent";

const AsyncTenantConfigurationScene = asyncComponent(() => import(
    /* webpackChunkName: "tenantConfiguration" */ "app/core/tenant/scenes/TenantConfigurationScene"
).then(({ default: scene }) => scene),
  "TenantConfigurationScene"
);

export { AsyncTenantConfigurationScene };
export default AsyncTenantConfigurationScene;
