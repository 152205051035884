import { asyncComponent } from "components/AsyncComponent";

const AsyncMonitoringHistoryScene = asyncComponent(
  () => import(/* webpackChunkName: "projectHistory" */ "app/mon/project/scenes/MonitoringHistoryScene")
    .then(({ default: scene }) => scene),
  "MonitoringHistoryScene"
);

export { AsyncMonitoringHistoryScene };
export default AsyncMonitoringHistoryScene;
